import $ from 'jquery';
import L from 'leaflet';
import Loading from './Loading';


class Twg {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.storage = window.sessionStorage;
        this.layerListKeys = config.layerListKeys;
        this.storedData = {};
        this.onSearch = false;
        this.newtwgCount = 0;
        this.timer = 0;

        this.loading = new Loading(config, settings)

        this.paths = {
            turysta: 'https://administracja.gison.pl/turysta-v8-1/',
        }


        this.states = {
            count: 0,
            section: '',
            part: 0
        }

        this.timer = 0;
        this.searchText = ''

        this.sections = {
            Poi: 'GetPublicFeatures2Poi',
            Szlak: 'GetPublicFeatures2Szlak',
            Wydarzenie: 'GetPublicFeatures2Wydarzenie',
            Nocleg: 'GetPublicFeatures2Nocleg'
        }

        /* var that = this;
        setInterval(function() {
            console.log(that.states.section)
        }, 2000) */

        this.icon = L.icon({
            iconUrl: './assets/images/marker-icon.png',
            iconSize: [25, 41],
            iconAnchor: [13, 0],
            popupAnchor: [0, 0]
        });

        if (window.location.href.includes('dobroszyce_kultura')){
        // if (window.location.href.includes('dobroszyce_kultura')){
            // || window.location.href.includes('localhost')) {
            this.profilId = 642;
            this.lokalizacja = 'Dobroszyce'
            $('.twgContainer').addClass('twgContainer--active');
            this.events();
            if (navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)) {
                $('.twgPanel__menu').css('display', 'flex')
            }
        }



    }

    events() {
        var that = this;

        $(document).on('click tap', '.twg-show', function() {
            $('.twgPanel__groups').removeClass('hidden')
            if ($('.twgPanel').hasClass('twgPanel--visible')) {
                $('.twgPanel').removeClass('twgPanel--visible')
                $('.sideLinkButtonPanel').addClass('sideLinkButtonPanel--visible')
                $('.twg-show').removeClass('twg-show--panelVisible')
                $('.twg-show').children('.fa').removeClass('fa-angle-double-right').addClass('fa-angle-double-left')

            } else {

                if ($('.newsPanel').hasClass('newsPanel--visible')) {
                    $('.news-show').click();
                }

                that.closeDetailsPopup();
                if ($('.twgPanel__groups--group-active').length < 1) {
                    that.gettwg(that.profilId, 0, that.sections.Poi)

                } else {
                    const actualSection = $('.twgPanel__groups--group-active').first().attr('data-section')
                    that.clearPanel();
                    that.gettwg(that.profilId, 0, actualSection)
                }
                that.showPanel()
                if ($(window).height() < 769 || $(window).width() < 1630) {
                    $('.twgPopup').removeClass('overlay--active')
                }
            }
        })



        $(document).on('click tap', '.twgPanel-close', function() {
            if ((window.location.href.includes('dobroszyce_kultura') || window.location.href.includes('localhost')) && (navigator.userAgent.match(/Android/i) ||
                    navigator.userAgent.match(/webOS/i) ||
                    navigator.userAgent.match(/iPhone/i) ||
                    navigator.userAgent.match(/iPad/i) ||
                    navigator.userAgent.match(/iPod/i) ||
                    navigator.userAgent.match(/BlackBerry/i) ||
                    navigator.userAgent.match(/Windows Phone/i))) {
                $('.starting-panel').addClass('starting-panel--active')
            } else {
                $('.twgPanel').removeClass('twgPanel--visible')
                $('.twg-show').removeClass('twg-show--panelVisible')
                $('.sideLinkButtonPanel').addClass('sideLinkButtonPanel--visible')
                $('.twg-show').children('.fa').removeClass('fa-angle-double-right').addClass('fa-angle-double-left')
                that.storage.setItem('mode', 'none')
            }
        })

        $(document).on('click tap', '.twgPopup-close', function() {
            if ($(window).height() < 769 || $(window).width() < 1630) {
                $('.twgPanel').addClass('twgPanel--visible')
                $('.twg-show').addClass('twg-show--panelVisible')
                $('.twg-show').children('.fa').removeClass('fa-angle-double-left').addClass('fa-angle-double-right')
            }
            that.storage.setItem('twgID', 'none')
        })

        $(document).on('click tap', '#twgPanelSearch', function() {
            if ($('.twgPanel__search').hasClass('twgPanel__search--visible')) {
                that.hideSearch()
            } else {
                that.showSearch()
            }
        })

        // wyszukiwanie po treści
        $('#search-text-twg').on('input', function(e) {
            clearTimeout(this.timer);
            this.timer = setTimeout(function() {
                that.searchText = $(e.target).val();
                that.searchTwg(that.profilId, 0, that.states.section, that.searchText);
            }, 1000)
        });

        $('.twgPanel__groups--group').on('click tap', function() {
            that.states.section = $(this).attr('data-section');
            that.states.part = 0
            $('.twgPanel__groups--group').removeClass('twgPanel__groups--group-active')
            $(this).addClass('twgPanel__groups--group-active')
            that.closeDetailsPopup();
            that.clearPanel()
            that.gettwg(that.profilId, that.states.part, that.states.section)
        })

        $('.twgPanel__search-button-clear').on('click tap', function() {
            that.clearPanel()
            $('#search-text-twg').val('')
            that.gettwg(that.profilId, 0, that.states.section)
        })

        $('.twgPanel__menu').on('click', function() {
            $('.starting-panel').addClass('starting-panel--active')
        })

    }


    showPanel() {
        $('.twgPanel').addClass('twgPanel--visible')
        $('.twg-show').addClass('twg-show--panelVisible')
        $('.sideLinkButtonPanel').removeClass('sideLinkButtonPanel--visible')
        $('.twg-show').children('.fa').removeClass('fa-angle-double-left').addClass('fa-angle-double-right')
    }

    setPanelHeight(data) {
        this.counttwg = data.length
            /*         if ($(window).width() > 992) {
                        if (data.length < 2) {
                            $('.twgPanel').css('height', '320px')
                            $('.twgPanel__container--item').css('height', '200px')
                        } else if (data.length < 3) {
                            $('.twgPanel').css('height', '540px')
                            $('.twgPanel__container--item').css('height', '200px')
                        } else if (data.length < 4) {
                            $('.twgPanel').css('height', '615px')
                            $('.twgPanel__container--item').css('height', '200px')
                        }
                    } else if ($(window).width() > 600) {
                        if (data.length < 2) {
                            $('.twgPanel').css('height', '300px')
                            $('.twgPanel__container--item').css('height', '220px')
                        } else if (data.length < 3) {
                            $('.twgPanel').css('height', '580px')
                            $('.twgPanel__container--item').css('height', '220px')
                        }
                    } */
    }

    showSearch() {
        $('#twgPanelSearch').addClass('twgPanel__btns--btn-active')
        var diff = 60,
            height = 0;
        if (this.counttwg < 2) {
            diff = 60;
            height = parseFloat($('.twgPanel').css('height')) + diff;
            $('.twgPanel').css('height', height + 'px')
            $('.twgPanel__search').addClass('twgPanel__search--visible')
        } else {
            diff = this.counttwg < 2 ? 90 : (this.counttwg < 3 ? 80 : 60);
            height = parseFloat($('.twgPanel__container').css('height')) - diff;
            $('.twgPanel__container').css('height', height + 'px')
            $('.twgPanel__search').addClass('twgPanel__search--visible')
        }
        $('#search-text-twg').val('').focus();

    }


    hideSearch() {
        $('#twgPanelSearch').removeClass('twgPanel__btns--btn-active')
        var diff = 60,
            height = 0;
        if (this.counttwg < 2) {
            diff = 60;
            height = parseFloat($('.twgPanel').css('height')) - diff;
            $('.twgPanel').css('height', height + 'px')
            $('.twgPanel__search').removeClass('twgPanel__search--visible')
        } else {
            diff = this.counttwg < 2 ? 90 : (this.counttwg < 3 ? 80 : 60);
            height = parseFloat($('.twgPanel__container').css('height')) + diff;
            $('.twgPanel__container').css('height', height + 'px')
            $('.twgPanel__search').removeClass('twgPanel__search--visible')
        }
        $('#search-text-twg').val('')
    }

    gettwg(id, part, section, specificLayer) {
        let that = this,
            sectionActive = section,
            specific = '';

        let modeOptions = { id: id, part: part || 0, section: section || '', specific: specificLayer || '' }
        that.storage.setItem('modeOptions', JSON.stringify(modeOptions))

        that.loading.makeLoadingScreen(".twgPanel", "Poczekaj...")
        if (specificLayer !== undefined && specificLayer != '') {
            specific = '&kl[0]=' + specificLayer;
        }

        $.ajax({
            // url: that.paths.turysta + 'common/' + section + '?szukaj=&from=' + part + '&to=10&_profilid=' + id + '&temat=true&turyst=true' + specific,
            url: window.location.origin + '/cgi-bin/proxy.cgi?url=' + encodeURIComponent(that.paths.turysta + 'common/' + section + '?szukaj=&from=' + part + '&to=10&_profilid=' + id + '&temat=true&turyst=true' + specific),
            error: function(a, b, c) {
                console.log('error')
                console.log(a)
                console.log(b)
                console.log(c)
                $('.twgContainer').removeClass('twgContainer--active')
                that.storage.setItem('mode', 'none')
            },
            success: function(data) {
                that.storage.setItem('mode', 'turysta')

                $('[data-section=' + sectionActive + ']').addClass('twgPanel__groups--group-active')
                if (data.allCount > 0) {
                    var dataArray = data.flist
                    that.states.count = data.allCount
                    that.states.section = sectionActive
                    that.states.part = parseInt(part, 10)
                    that.states.specific = specificLayer
                    that.addtwg(dataArray)
                    that.loading.removeLoadingScreen(".twgPanel")
                    if (part == 0) {
                        that.addPagination()
                    }
                } else {
                    that.clearPanel()
                    $('.twgPanel__container').html('brak obiektów')
                    that.loading.removeLoadingScreen(".twgPanel")
                }
            }
        })
    }

    storeData(data) {
        var that = this;
        $.each(data, function(k, v) {
            that.storedData[k] = v.tytul + ' ' + v.opis
        })
    }

    addtwg(array) {
        var that = this;
        $.each(array, function(i, item) {
            item = JSON.parse(item)
            that.addtwgItem(item)
        })
        if (this.newtwgCount > 0) {
            $('.twg-show__num').css('display', 'flex')
            $('.twg-show__num').text(this.newtwgCount)
        }
    }

    getShortcut(html) {
        var span = document.createElement('span');
        span.innerHTML = html;
        return span.textContent.substr(0, 180) || span.innerText.substr(0, 180);
    }

    createHtmlItem(item) {
        var that = this;
        if (that.states.section == 'GetPublicFeatures2Poi' || that.states.section == 'GetPublicFeatures2Nocleg') {

            return `<div class="twgPanel__container--item">
                    <div class="row twgPanel__container--item-title">
                        <img class="twgPanel__container--item-title-icon" src="` + that.paths.turysta + item.properties.icon + `" />
                        <p>` + (item.properties.label ? item.properties.label : '') + `</p>
                    </div>
                    <div class="row twgPanel__container--item-content">
                        <div class="twgPanel__container--item-contentImg">
                            <img src="` + (item.properties.photolist != undefined && item.properties.photolist.length > 0 ? that.paths.turysta + item.properties.photolist[0].imgs : '') + `"/>
                        </div>
                        <div class="twgPanel__container--item-contentAddress">` + (item.properties.address != null ? item.properties.address : '') + `</div>
                    </div>
                    <div class="row twgPanel__container--item-more">
                        <button class="twgPanel__container--item-moreBtn" id="twgBtn-` + item.properties.id + `">
                            Czytaj więcej
                            <i class="fa fa-angle-double-right"></i>
                        </button>
                    </div>
                </div>`;
        } else if (that.states.section == 'GetPublicFeatures2Szlak') {
            return `<div class="twgPanel__container--item">
            <div class="row twgPanel__container--item-title">
                <img class="twgPanel__container--item-title-icon" src="` + that.paths.turysta + item.properties.icon + `" />
                <p>` + (item.properties.nazwa ? item.properties.nazwa : '') + `</p>
            </div>
            <div class="row twgPanel__container--item-content">
                <div class="twgPanel__container--item-contentImg">
                    <img src="` + (item.properties.photolist != undefined && item.properties.photolist.length > 0 ? that.paths.turysta + item.properties.photolist[0].imgs : '') + `"/>
                </div>
            </div>
            <div class="row twgPanel__container--item-more">
                <button class="twgPanel__container--item-moreBtn" id="twgBtn-` + item.properties.id + `">
                    Czytaj więcej
                    <i class="fa fa-angle-double-right"></i>
                </button>
            </div>
        </div>`;
        } else if (that.states.section == 'GetPublicFeatures2Wydarzenie') {
            return `<div class="twgPanel__container--item">
            <div class="row twgPanel__container--item-title">
                <img class="twgPanel__container--item-title-icon" src="` + (item.properties.icon != undefined ? that.paths.turysta + item.properties.icon : '') + `" />
                <p>` + (item.properties.nazwa ? item.properties.nazwa : '') + `</p>
            </div>
            <div class="row twgPanel__container--item-content">
                <div class="twgPanel__container--item-contentImg">
                    <img src="` + (item.properties.photolist != undefined && item.properties.photolist.length > 0 ? that.paths.turysta + item.properties.photolist[0].imgs : '') + `"/>
                </div>
            </div>
            <div class="row twgPanel__container--item-more">
                <button class="twgPanel__container--item-moreBtn" id="twgBtn-` + item.properties.id + `">
                    Czytaj więcej
                    <i class="fa fa-angle-double-right"></i>
                </button>
            </div>
        </div>`;
        }
    }

    addtwgItem(item) {
        var that = this,
            html = this.createHtmlItem(item)

        $('.twgPanel__container').prepend(html);
        //    var contentAll = content + `<br>` + attachment;
        $('#twgBtn-' + item.properties.id).on('click tap', function() {

            $('#pagination-container').css('display', 'none')
            $('.twgPanel__container').removeClass('twgPanel__container--active');
            $('.twgPanel__container--details').addClass('twgPanel__container--details-active');

            if (that.states.section == 'GetPublicFeatures2Poi' || that.states.section == 'GetPublicFeatures2Nocleg') {
                $('.twgPanel__container--details-info').addClass('twgPanel__container--details-active')
                $('.twgPanel__container--details-photo').addClass('twgPanel__container--details-active')
                $('.twgPanel__container--details-natrasie').removeClass('twgPanel__container--details-active')

                that.goToCoordinates(item, item.properties.icon);

                if (item.properties.label != null) {
                    // $('.twgPanel__container--details-title').html(item.properties.label);
                    $('.twgPanel__container--details-title').html(`<img class="twgPanel__container--details-title-icon" src="` + that.paths.turysta + item.properties.icon + `" /><p>` + item.properties.label + `</p>`);
                } else {
                    $('.twgPanel__container--details-title').html('');
                }
                if (item.properties.photolist.length > 0) {
                    $('.twgPanel__container--details-photo').html('<a href="' + that.paths.turysta + item.properties.photolist[0].imgl + '" rel="photo" href="" data-fancybox="true"><img src="' + that.paths.turysta + item.properties.photolist[0].imgm + '"/></a>');
                } else {
                    $('.twgPanel__container--details-photo').html('')
                }
                $('.twgPanel__container--details-info-content').html(that.addContentToDetails(item));
                if (item.properties.description != null) {
                    $('.twgPanel__container--details-description-content').html(item.properties.description);
                } else {
                    $('.twgPanel__container--details-description-content').html('')
                }
            } else if (that.states.section == 'GetPublicFeatures2Szlak') {

                $('.twgPanel__container--details-natrasie').addClass('twgPanel__container--details-active')
                $('.twgPanel__container--details-info').addClass('twgPanel__container--details-active')
                $('.twgPanel__container--details-photo').addClass('twgPanel__container--details-active')
                that.goToCoordinates(item, item.properties.icon);

                if (item.properties.nazwa != null) {
                    $('.twgPanel__container--details-title').html(item.properties.nazwa);
                } else {
                    $('.twgPanel__container--details-title').html('');
                }

                if (item.properties.photolist.length > 0) {
                    if (item.properties.photolist[0].imgm) {
                        $('.twgPanel__container--details-photo').html('<a href="' + that.paths.turysta + item.properties.photolist[0].imgl + '" rel="photo" href="" data-fancybox="true"><img src="' + that.paths.turysta + item.properties.photolist[0].imgm + '"/></a>');
                    } else {
                        $('.twgPanel__container--details-photo').html('<a href="' + that.paths.turysta + item.properties.photolist[0].imgl + '" rel="photo" href="" data-fancybox="true"><img src="' + that.paths.turysta + item.properties.photolist[0].img + '"/></a>');
                    }
                } else {
                    $('.twgPanel__container--details-photo').html('')
                }

                $('.twgPanel__container--details-info-content').html(that.addContentToDetails(item));
                $('.twgPanel__container--details-natrasie-content').html(that.addContentToNatrasie(item));


                if ((window.location.href.includes('dobroszyce_kultura') || window.location.href.includes('localhost')) && ((navigator.userAgent.match(/Android/i) ||
                        navigator.userAgent.match(/webOS/i) ||
                        navigator.userAgent.match(/iPhone/i) ||
                        navigator.userAgent.match(/iPad/i) ||
                        navigator.userAgent.match(/iPod/i) ||
                        navigator.userAgent.match(/BlackBerry/i) ||
                        navigator.userAgent.match(/Windows Phone/i)))) {

                    var szlakUrl = that.getSzlakUrl(item)

                    if (item.properties.description != null) {
                        $('.twgPanel__container--details-description-content').html(item.properties.description + `<br><a class="twgPanel__container--details-addToCalendar" href="` + szlakUrl + `" target="_blank"><i class="fas fa-search-location"></i> Pokaż na mapie</a>`);
                    } else {
                        $('.twgPanel__container--details-description-content').html(`<br><a class="twgPanel__container--details-addToCalendar" href="` + szlakUrl + `" target="_blank"><i class="fas fa-search"></i> Pokaż na mapie</a>`);
                    }

                } else {

                    var szlakUrl = that.getSzlakUrl(item)

                    if (item.properties.description != null) {
                        $('.twgPanel__container--details-description-content').html(item.properties.description);
                    }
                }

            } else if (that.states.section == "GetPublicFeatures2Wydarzenie") {
                $('.twgPanel__container--details-photo').addClass('twgPanel__container--details-active')

                that.goToCoordinates(item, item.properties.icon);

                if (item.properties.nazwa != null) {
                    $('.twgPanel__container--details-title').html(item.properties.nazwa);
                } else {
                    $('.twgPanel__container--details-title').html('');
                }

                if (item.properties.photolist.length > 0) {
                    $('.twgPanel__container--details-photo').html('<a href="' + that.paths.turysta + item.properties.photolist[0].img + '" rel="photo" href="" data-fancybox="true"><img src="' + that.paths.turysta + item.properties.photolist[0].img + '"/></a>');
                }


                var googleUrl = ''
                if (item.properties.datado != null) {
                    googleUrl = that.getGoogleCalendarUrl(item.properties.nazwa, item.properties.dataod, item.properties.datado)
                } else {
                    googleUrl = that.getGoogleCalendarUrl(item.properties.nazwa, item.properties.dataod, item.properties.dataod)
                }

                if (item.properties.opis != null) {
                    $('.twgPanel__container--details-description-content').html(item.properties.opis + `<br><a class="twgPanel__container--details-addToCalendar" href="` + googleUrl + `" target="_blank"><i class="far fa-calendar-alt"></i> Dodaj do kalendarza</a>`);
                } else {
                    $('.twgPanel__container--details-description-content').html(`<a class="twgPanel__container--details-addToCalendar" href="` + googleUrl + `" target="_blank"><i class="far fa-calendar-alt"></i> Dodaj do kalendarza</a>`);
                }

                // if (item.properties.opis != null) {
                //     $('.twgPanel__container--details-description-content').html(item.properties.opis);
                // } else {
                //     $('.twgPanel__container--details-description-content').html('');
                // }
            }




            $('.twgPanel__container--details-close').on('click tap', function(e) {
                that.closeDetailsPopup();

            })

            $('.twgPanel__container--details-photo').on('click tap', function(e) {
                if (navigator.userAgent.match(/Android/i) ||
                    navigator.userAgent.match(/webOS/i) ||
                    navigator.userAgent.match(/iPhone/i) ||
                    navigator.userAgent.match(/iPad/i) ||
                    navigator.userAgent.match(/iPod/i) ||
                    navigator.userAgent.match(/BlackBerry/i) ||
                    navigator.userAgent.match(/Windows Phone/i)) {} else {
                    $('.twgPanel').removeClass('twgPanel--visible')
                    $('.twg-show').removeClass('twg-show--panelVisible')
                    $('.twg-show').children('.fa').removeClass('fa-angle-double-right').addClass('fa-angle-double-left')
                }
                /* $(".fancybox-button--close svg").on('click', function() {
                    $('.twgPanel').addClass('twgPanel--visible')
                    $('.twg-show').addClass('twg-show--panelVisible')
                    $('.twg-show').children('.fa').addClass('fa-angle-double-right')
                }) */
            })

        })
    }

    getSzlakUrl(item) {
        let lat = item.geometry.coordinates[0][1],
            lng = item.geometry.coordinates[0][0],
            currentUrl = window.location.href,
            permalink = (currentUrl.indexOf('?') > -1) ? currentUrl.slice(0, currentUrl.indexOf('?') + 1) : currentUrl + '?';

        return permalink + 'map=true&zoom=16&lat=' + lat + '&lng=' + lng;
    }

    getGoogleCalendarUrl(nazwa, dataod, datado) {
        let that = this;
        return "https://www.google.com/calendar/render?action=TEMPLATE&text=" + nazwa.replace(/\s/g, '+') + "&dates=" + that.getGoogleCalendarDate(dataod, datado) + (that.lokalizacja ? "&location=" + that.lokalizacja : '')
    }

    getGoogleCalendarDate(dataod, datado) {
        let DateFrom = new Date(dataod),
            DateTo = new Date(datado);

        function addZero(num) {
            return num.length == 1 ? 0 + num : num
        }

        // let resultDateString = DateFrom.getFullYear().toString() + addZero((DateFrom.getMonth() + 1).toString()) + addZero((DateFrom.getDate() - 1).toString()) + 'T' + '220000' + 'Z/' + DateTo.getFullYear().toString() + addZero((DateTo.getMonth() + 1).toString()) + addZero((DateTo.getDate() - 1).toString()) + 'T' + '220000Z'
        let resultDateString = DateFrom.getFullYear().toString() + addZero((DateFrom.getMonth() + 1).toString()) + addZero((DateFrom.getDate()).toString()) + '/' + DateTo.getFullYear().toString() + addZero((DateTo.getMonth() + 1).toString()) + addZero((DateTo.getDate() + 1).toString())
        return resultDateString
    }

    goToCoordinates(item, iconUrl) {
        const that = this;
        let latlng, zoom;
        if (item.geometry.type == 'LineString') {
            latlng = [item.geometry.coordinates[0][1], item.geometry.coordinates[0][0]]
            zoom = 16
        } else {
            latlng = [item.geometry.coordinates[1], item.geometry.coordinates[0]]
            zoom = 18
        }

        that.map.setView(latlng, zoom)
        if (iconUrl) {
            const icon = L.icon({
                iconUrl: that.paths.turysta + iconUrl,
                iconSize: [33, 35],
                iconAnchor: [13, 0],
                popupAnchor: [0, 0]
            });
            const markerIcon = L.marker(latlng, {
                icon: icon
            })
            markerIcon.addTo(that.map);
            $('.twgPanel__container--details-close').on(
                'click',
                function () {
                     markerIcon.removeFrom(that.map);
                }
            );
        } else {
            const marker = L.marker(latlng, {
                icon: that.icon
            })
            marker.addTo(that.map);
            setTimeout(function() {
                marker.removeFrom(that.map)
            }, 3000)
        }
    }

    closeDetailsPopup() {
        $('.twgPanel__container--details').children().removeClass('twgPanel__container--details-active')
        $('.twgPanel__container').addClass('twgPanel__container--active');
        $('.twgPanel__container--details').removeClass('twgPanel__container--details-active');
        $('#pagination-container').css('display', 'flex')
    }

    addContentToDetails(item) {
        return (item.properties.address != null ? `<div><i class="fas fa-home"></i>` + item.properties.address + `</div>` : ``) +
            (item.properties.dlugosc != null ? `<div><i class="fas fa-route"></i> Długość: ` + item.properties.dlugosc + `</div>` : ``) +
            (item.properties.trudnosc != null ? `<div><i class="fas fa-signal"></i> Trudność:` + item.properties.trudnosc + `</div>` : ``) +
            (item.geometry.coordinates && item.geometry.coordinates.length < 2 ? `<div><i class="fas fa-map-marker-alt"></i>` + item.geometry.coordinates[1] + `, ` + item.geometry.coordinates[0] + `</div>` : ``) +
            (item.properties.mail != null ? `<div><i class="fas fa-envelope"></i>` + item.properties.mail + `</div>` : ``) +
            (item.properties.phone != null ? `<div><i class="fas fa-phone"></i>` + item.properties.phone + `</div>` : ``) +
            (item.properties.www != null ? `<div><i class="fas fa-globe"></i>` + item.properties.www + `</div>` : ``);
    }

    addContentToNatrasie(item) {
        var that = this,
            list = item.properties.poilist
        if (list.length > 0) {
            var listDOM = $('<ul>', { class: 'twgPanel__container--details-natrasie-content-list' })
            $('.twgPanel__container--details-natrasie-content').append(listDOM)
            $.each(list, function(i, v) {
                if (v.label.length > 0) {
                    listDOM.append(`<li class="twgPanel__container--details-natrasie-content-list-item"><img class="twgPanel__container--details-natrasie-content-icon" src="` + that.paths.turysta + v.icon + `" />` + v.label + `</li>`)
                }
            })
            return listDOM
        } else {
            return '';
        }
    }

    addPagination(search) {
        var search = search || false;
        var that = this

        $('.twgPanel__pagination--item-forward').off('click tap')
        $('.twgPanel__pagination--item-back').off('click tap')
        $('.twgPanel__pagination--item').off('click tap')

        /*  if (that.states.part == 0) {
             $('.twgPanel__pagination--item-back').addClass('twgPanel__pagination--item-disabled')
         } else {
             $('.twgPanel__pagination--item-back').removeClass('twgPanel__pagination--item-disabled')
         } */
        that.checkPaginationDisability()

        $('.twgPanel__pagination--item-forward').on('click tap', function() {
            if (!$(this).hasClass('twgPanel__pagination--item-disabled')) {
                that.clearPanel()
                that.states.part += 10
                if (search) {
                    that.searchTwg(that.profilId, that.states.part, that.states.section, that.searchText)
                } else {
                    that.gettwg(that.profilId, that.states.part, that.states.section, that.states.specific)
                }
            }
        })

        $('.twgPanel__pagination--item-back').on('click tap', function() {
            if (!$(this).hasClass('twgPanel__pagination--item-disabled')) {
                that.clearPanel()
                that.states.part -= 10
                if (search) {
                    that.searchTwg(that.profilId, that.states.part, that.states.section)
                } else {
                    that.gettwg(that.profilId, that.states.part, that.states.section, that.states.specific)
                }
            }
        })

        $('.twgPanel__pagination--item').on('click tap', function() {
            that.checkPaginationDisability()
        })

    }

    checkPaginationDisability() {
        var that = this;
        if (that.states.part + 10 == parseInt(that.states.count / 10 + 1) * 10) {
            $('.twgPanel__pagination--item-forward').addClass('twgPanel__pagination--item-disabled')
        } else {
            $('.twgPanel__pagination--item-forward').removeClass('twgPanel__pagination--item-disabled')
        }
        if (that.states.part == 0) {
            $('.twgPanel__pagination--item-back').addClass('twgPanel__pagination--item-disabled')
        } else {
            $('.twgPanel__pagination--item-back').removeClass('twgPanel__pagination--item-disabled')
        }
    }

    clearPanel() {
        $('.twgPanel__container').html('')
    }

    getPermalink(num) {
        var currentUrl = window.location.href,
            permalink = (currentUrl.indexOf('?') > -1) ? currentUrl.slice(0, currentUrl.indexOf('?') + 1) : currentUrl + '?';
        permalink += 'm=n' + num
        return permalink;
    }

    // getShareUrl(id) {
    //     var url = window.location.href;
    //     if (url.indexOf('?') > -1) {
    //         if ((url.indexOf('&m=') > -1) || (url.indexOf('?m=') > -1)) {
    //             url = url.replace(/&m=n[0-9]*/, '&m=n' + id);
    //             url = url.replace(/\?m=n[0-9]*/, '?m=n' + id);
    //         } else {
    //             url = url + '&m=n' + id;
    //         }
    //     } else {
    //         url = url + '?m=n' + id;
    //     }
    //     return url;
    // }


    searchTwg(id, part, section, querystring) {
        var that = this,
            sectionActive = section;
        that.loading.makeLoadingScreen(".twgPanel", "Poczekaj...")

        $.ajax({
            // url: that.paths.turysta + 'common/' + section + '?from=' + part + '&to=10&_profilid=' + id + '&turyst=true&temat=true&szukaj=' + querystring,
            url: window.location.origin + '/cgi-bin/proxy.cgi?url=' + encodeURIComponent(that.paths.turysta + 'common/' + section + '?from=' + part + '&to=10&_profilid=' + id + '&szukaj=' + querystring),
            error: function(a, b, c) {
                console.log('error')
                console.log(a)
                console.log(b)
                console.log(c)
                $('.twgContainer').removeClass('twgContainer--active')
            },
            success: function(data) {
                that.clearPanel()
                $('[data-section=' + sectionActive + ']').addClass('twgPanel__groups--group-active')
                if (data.allCount > 0) {
                    var dataArray = data.flist
                    that.states.count = data.allCount
                    that.states.section = sectionActive
                    that.states.part = part
                    that.addtwg(dataArray)
                    that.loading.removeLoadingScreen(".twgPanel")
                    if (part == 0) {
                        that.addPagination(true)
                    }
                } else {
                    that.clearPanel()
                    $('.twgPanel__container').html('brak obiektów')
                    that.loading.removeLoadingScreen(".twgPanel")
                }
            }
        })
    }



}
export default Twg;
