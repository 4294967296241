import Tools from './Tools';
import L from 'leaflet';
import $ from 'jquery';
// import fancybox from '@fancyapps/fancybox';

import '../vendor/jquery.mousewheel-3.0.6.pack.js';
// import '../vendor/jquery.fancybox.min.js';
// import '../vendor/jquery.fancybox-1.3.4_patch.js';

var tools = new Tools();

// pobierz opcje mapy
class MapInfo {
     constructor(config, settings) {
          this.map = settings.map;
          this.toolsClass = settings.toolsClass;
          this.tools = config.MapConf || {};
          this.states = settings.states;
          this.states.onInfo = true;
          this.storage = window.sessionStorage;
          this.lStorage = window.localStorage;
          this.profilname = config.profilName;
          this.infoIconProps = tools.createElPropertiesFromClass(
               this.toolsClass + '__info'
          );
          this.params = {};
          this.statsParams = {};
          if (config.Guid != null) {
               this.activeLayersManager = settings.activeLayersManager;
               this.layerListKeys = config.layerListKeys;
               this.events();
               this.activeLayersIds = this.activeLayersManager.getActiveLayersIds();
          }
     }

     events() {
          var that = this;

          this.map.on('click tap', function (e) {
               if (that.states.onInfo && that.tools.Info) {
                    that.clickParams = {
                         latlng: e.latlng,
                         zoom: that.map.getZoom(),
                         xy: e.containerPoint,
                    };
                    that.activeLayersIds = that.activeLayersManager.getActiveLayersIds();
                    var promises = that.getInfo();

                    Promise.all(promises)
                         .then((responses) => {
                              var okResponses = responses.filter((response) => {
                                   if (response.status == 200) {
                                        return response;
                                   }
                              });
                              return Promise.all(
                                   okResponses.map((res) => res.text())
                              );
                         })
                         .then((texts) => {
                              that.getInfoDone(texts);
                         });
               }
          });
     }

     updateLayerListKeys(configuration) {
          this.layerListKeys = configuration.layerListKeys;
          this.activeLayersIds = this.activeLayersManager.getActiveLayersIds();
     }

     searchIdByName(layerListKeys, layerName) {
          let layerId;
          Object.keys(layerListKeys).forEach((element) => {
               if (layerListKeys[element].name === layerName) {
                    layerId = layerListKeys[element].id;
               }
          });

          return layerId;
     }

     getInfo() {
          var that = this;
          let activeLayersIds = this.activeLayersIds,
               promises = [],
               layersUrls = [];

          if (that.tools.showMPZPWithSWDE) {
               activeLayersIds.push(
                    that.searchIdByName(this.layerListKeys, 'Działki-SWDE')
               );
          }

          activeLayersIds.forEach((activeLayerId) => {
               let layer = this.layerListKeys[activeLayerId];

               // tylko w wersji dev
               // layer.proxy = false;
               if (layer.link) {
                    if (layer.queryable) {
                         let linkPromise = this.linkPromise(layer);
                         promises.push(linkPromise);
                    }
               } else {
                    if (
                         layer.queryable &&
                         layer.queryUrl &&
                         !layersUrls.includes(layer.queryUrl)
                    ) {
                         if (layer.queryUrl.includes(';')) {
                              var requestUrls = layer.queryUrl.split(';');
                              $.each(requestUrls, function (i, url) {
                                   url = that.parseUrl(url);
                                   if (layer.proxy) {
                                        url = that.proxyUrl(url);
                                   }
                                   promises.push(fetch(url));
                              });
                         } else {
                              let request = this.getRequest(layer);
                              promises.push(request);
                         }
                    } else if (
                         layer.queryable &&
                         !layersUrls.includes(layer.queryUrl)
                    ) {
                         let request = this.getRequest(layer);
                         promises.push(request);
                    }
                    if (
                         layer.queryUrl &&
                         !layersUrls.includes(layer.queryUrl)
                    ) {
                         layersUrls.push(layer.queryUrl);
                    }
               }
          });
          return promises;
     }

     getRequest(layer) {
          var url = this.queryUrl(layer);
          if (layer.proxy) {
               url = this.proxyUrl(url);
          }
          return fetch(url);
     }

     queryUrl(layer) {
          return layer.queryUrl ? this.ajaxUrl(layer) : this.wmsUrl(layer);
     }

     ajaxUrl(layer) {
          return this.parseUrl(layer.queryUrl);
     }

     wmsUrl(layer) {
          var point = this.clickParams.xy,
               layers = layer.options.layers
                    ? layer.options.layers.split(',')
                    : [],
               // TODO: pobierac source z mapinit zamiast tworzyc nowy
               source = L.WMS.source(layer.url, layer.options);
          source._map = this.map;
          var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(
               source,
               point,
               layers
          );
          wmsParams.INFO_FORMAT = 'text/html';
          wmsParams.app = 'sip';
          wmsParams.username = localStorage['username']
               ? localStorage['username']
               : '';
          var url = layer.url + L.Util.getParamString(wmsParams, layer.url);
          url = this.removeGwcServiceFromUrl(url);

          source.remove();
          return url;
     }

     linkPromise(layer) {
          var html = `
      <a href="${this.btnLink(layer)}" target="_blank">
        <button class="przycisk">${layer.name} >></button>
      </a>`;
          return new Promise(function (resolve) {
               var myResponse = new Response(html, {
                    status: 200,
                    statusText: 'OK',
               });
               resolve(myResponse);
          });
     }

     btnLink(layer) {
          var url = layer.url;
          url = this.removeGwcServiceFromUrl(url);
          url = this.queryUrl(layer);
          if (layer.proxy) {
               url = this.proxyUrl(url);
          }
          return url;
     }

     encodeUrl(url) {
          return encodeURIComponent(url);
     }

     proxyUrl(url) {
          return window.PROXY + this.encodeUrl(url);
     }

     parseUrl(url) {
          let { lat, lng } = this.clickParams.latlng;
          this.updateWmsParams(this.map);
          this.updateStatsParams();
          // var crs = window.options.crs;
          // this.params.crs = crs.code;
          url = url
               .replace(/%lat%/g, lat)
               .replace(/%lon%|%lng%/g, lng)
               .replace(/%zoom%/g, this.clickParams.zoom)
               .replace(/%bbox%/g, this.params.bbox)
               .replace(/%width%/g, this.params.width)
               //.replace(/%username%/g, this.statsParams.user)
               // .replace(/%tematnazwa%/g, this.statsParams.tematnazwa)
               // .replace(/%profilnazwa%/g, this.statsParams.profilnazwa)
               .replace(
                    /%x%/g,
                    this.map.latLngToLayerPoint(L.latLng(lat, lng)).x
               )
               .replace(/%height%/g, this.params.height)
               .replace(
                    /%y%/g,
                    this.map.latLngToLayerPoint(L.latLng(lat, lng)).y
               );
               if(location.href.indexOf('map2.gisoncloud') == -1) {
                    url = url
                    .replace(/http:/g, 'https:')
               }
          url = url + '&app=sip&username=' + this.statsParams.username
          return url;
     }

     updateWmsParams(map) {
          var bounds = map.getBounds(),
               size = map.getSize(),
               wmsVersion = 1.1,
               crs = map.options.crs;
          this.params.crs = crs.code;
          var projectionKey = 'srs',
               nw = crs.project(bounds.getNorthWest()),
               se = crs.project(bounds.getSouthEast());
          this.params = {
               width: size.x,
               height: size.y,
          };
          this.params[projectionKey] = crs.code;
          this.params.bbox = (wmsVersion >= 1.3 && crs === L.CRS.EPSG4326
               ? [se.y, nw.x, nw.y, se.x]
               : [nw.x, se.y, se.x, nw.y]
          ).join(',');
     }

     updateStatsParams() {
          this.statsParams.username = localStorage['username']
               ? localStorage['username']
               : '';
     }

     removeGwcServiceFromUrl(url) {
          return url.replace('gwc/service/', '');
     }

     getInfoDone(data) {
          var html = $.trim(this.getDataHtml(data));
          if (html) {
               this.openPopup(html);
               this.initImgGallery();
          }
     }

     initImgGallery() {
          var photosAll = $('a[rel=photo]');
          $('a[rel=photo]').attr('data-fancybox', 'true');
          /* var photos = photosAll.filter((i, photo) =>
                !$(photo).hasClass('photolist')
            ) */
          photosAll.fancybox();
     }

     openPopup(html) {
          if (html.indexOf('SZLAK') > -1 && this.hasHashColor(html)) {
               html = this.addColorToHtml(html);
          }
          return L.popup()
               .setLatLng(this.clickParams.latlng)
               .setContent(html)
               .openOn(this.map);
     }

     hasHashColor(html) {
          return /#([0-9a-f]{3}){1,2}/i.test(html); //check if html contains hex code for color (with #)
     }

     addColorToHtml(html) {
          var htmlWithColorSpan = html.replace(
               /#([0-9a-f]{3}){1,2}/i,
               function (color) {
                    return (
                         `<span title="` +
                         color +
                         `" class="leaflet-popup-content__color" style="background-color:` +
                         color +
                         `"></span>`
                    );
               }
          );
          return htmlWithColorSpan;
     }

     parseJsonToHtml(data) {
          var html = '';
          var that = this;
          try {
               var json = JSON.parse(data);
               if (json.popup) {
                    html = json.popup;
               } else {
                    if (json.length > 1) {
                         $.each(json, function (i, v) {
                              var json2 = JSON.parse(v);
                              html += '<br/>'+that.createParcelPopup(json2);
                         })
                    } else {
                         var json2 = JSON.parse(json);
                         html = this.createParcelPopup(json2);
                    }
               }
          } catch (err) {
              // console.log(err)
               html = data;
          }
          return html;
     }

     createParcelPopup(json) {
          return (
               `<div><u><b>` +
               json.properties.layer +
               `</b></u><br><b>Numer:  </b><span>` +
               json.properties.numer +
               `</span><br><b>Obręb: </b><span>` +
               json.properties.obreb +
               (json.properties.powEwid && json.properties.powEwid != 0
                    ? `</span><br><b>Powierzchnia ewidencyjna:  </b><span>` +
                    json.properties.powEwid +
                    `m<sup>2</sup>`
                    : ` `) +
               (json.properties.powGeom && (!json.properties.powEwid || json.properties.powEwid == 0)
                    ? `</span><br><b>Powierzchnia geometryczna:  </b><span>` +
                    json.properties.powGeom +
                    `m<sup>2</sup>`
                    : ` `) +
               `</span><br><b>ID: </b><span>` +
               json.properties.id +
               `</span></div>`
          );
     }

     getDataHtml(dataArr) {
          var html = '',
               htmls = [],
               dataHtml;
          dataArr.forEach((data) => {
               if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {
                    dataHtml = this.getBodyHtml(data);
               } else if (/<\/ServiceException/g.test(data)) {
                    dataHtml = '';
               } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                    dataHtml = '';
               } else if ($.trim(data) == '[]' || data == []) {
                    dataHtml = '';
               } else {
                    dataHtml = this.parseJsonToHtml(data);
               }
               var check = 0;
               $.each(htmls, function (i, v) {
                    if (dataHtml == v) {
                         check++;
                    }
               });
               if (check == 0) {
                    if (dataHtml != '' && dataHtml.length > 1) {
                         htmls.push(dataHtml);
                    }
               }
          });
          html = htmls.join('<hr>');
          return html;
     }

     getBodyHtml(html) {
          var match = html.match(/<body>[\s\S]*?<\/body>/g);
          if (match && match[0]) {
               html = match[0].replace('<body>', '').replace('</body>', '');
          } else {
               return html;
          }
          return /\S/g.test(html) ? html : '';
     }
}

export default MapInfo;
