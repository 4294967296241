import Tools from './Tools';
import L from 'leaflet';
import $ from 'jquery';
var tools = new Tools();

class StreetView {
    constructor(config, settings) {
        if (config.Guid != null) {
            this.map = settings.map;
            this.toolsClass = settings.toolsClass;
            this.tools = config.MapConf.tools || {};
            this.storage = window.sessionStorage;
            this.streetViewWindowClass = '.streetViewPopup';
            this.streetViewWindowActiveClass = this.streetViewWindowClass + '--active';
            this.streetViewWindowCloseClass = this.streetViewWindowClass + '-close';
            this.streetViewProps = tools.createElPropertiesFromClass(this.toolsClass + '__streetView');
            this.states = settings.states;
            this.states.onStreetView = false;
            this.googleStreets = L.tileLayer('https://mts1.googleapis.com/vt?hl=en-US&lyrs=svv|cb_client:apiv3&style=40,18&x={x}&y={y}&z={z}', {})
            this.humanIcon = L.icon({
                iconUrl: './assets/images/icons/bt_streetview.png',
                iconSize: [30, 30],
            });
            this.humanCursor = L.marker([0, 0], {
                icon: this.humanIcon
            });
            this.mapEvents();
            this.events();
        }
    }

    events() {
        var that = this;

        $(document).on('click tap', this.streetViewProps.selector, function() {
            that.states.onStreetView = !that.states.onStreetView;
            if (that.states.onStreetView) {
                if ($(window).width() > 767) {
                    that.humanCursor.addTo(that.map);
                }
                that.mapEvents();
                that.googleStreets.addTo(that.map);
                $('.modePanel').addClass('modePanel--active');
                $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie wyboru miejsca');
                that.storage.setItem('mode', 'streetview');
                that.states.onInfo = false;
            } else {
                that.googleStreets.removeFrom(that.map);
                that.humanCursor.removeFrom(that.map);
                $('.modePanel__body--btn-cancel').click();
                that.states.onInfo = true;
            }
        });

        $(document).on('click tap', this.streetViewWindowCloseClass, function() {
            $(that.streetViewProps.selector).find('.tools__tool-icon').removeClass('tools__tool-icon-focused');
            $(that.streetViewProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-focused');
            that.states.onStreetView = false;
            that.states.onInfo = true;
            $('.modePanel__body--btn-cancel').click();
            if ($(window).width() <= 768) {
                $('.responsive-icon-d').css('display', 'flex')
            }
        });

        $(document).on('click tap', '.modePanel__body--btn-cancel', function() {
            $('.modePanel').removeClass('modePanel--active');
            $('.small-icon-modePanel').css('display', 'none');
            $('.streetViewPopup').removeClass('overlay--active');
            that.googleStreets.removeFrom(that.map);
            that.storage.setItem('mode', 'none');
        })

        $('.streetViewPopup').on('click tap', function() {
            if ($(window).width() > 768 || $(window).height() > 768) {
                $(this).draggable({
                    cursor: 'grab',
                    opacity: 0.8,
                });
            }
        })

    }

    mapEvents() {
        var that = this;

        that.map.on('click tap', function(e) {
            if (that.states.onStreetView) {
                that.init({
                    latitude: e.latlng.lat,
                    longitude: e.latlng.lng,
                });
                $(that.streetViewWindowClass).addClass('overlay--active');
                that.map.removeLayer(that.humanCursor);
                if ($(window).width() > 767) {
                    $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie street view');
                } else {
                    $('.modePanel').removeClass('modePanel--active');
                }
            }
        })

        that.map.on('mousemove', function(e) {
            if (that.states.onStreetView) {
                that.humanCursor.setLatLng([e.latlng.lat, e.latlng.lng]);
            }
        });
    }

    streetView(settings, window) {
        var viewOptions = {
                fov: Number(settings.fov),
                heading: Number(settings.heading),
                key: "AIzaSyApYuJiboagJDJIw9bcZiYaQeVtgnDFVXk",
                lat: Number(settings.latitude),
                long: Number(settings.longitude),
                pitch: Number(settings.pitch),
                windowName: window.windowID,
            },
            svFrame = $('<iframe/>', {
                class: window.svFrameClassName,
                frameborder: "0",
                src: "https://www.google.com/maps/embed/v1/streetview?key=AIzaSyApYuJiboagJDJIw9bcZiYaQeVtgnDFVXk&location=" + viewOptions.lat + "," +
                    viewOptions.long + "&heading=" + viewOptions.heading + "&pitch=" + viewOptions.pitch + "&fov=" + viewOptions.fov
            });
        return svFrame;
    }

    fitDialog(dialogEl, svEl) {
        var size = {
            newHeight: dialogEl.parent().height() - dialogEl.prev().outerHeight(),
            newWidth: dialogEl.parent().width()
        }
        return svEl.width(size.newWidth).height(size.newHeight);
    }

    dialogSize(el, newHeight, newWidth) {
        return el.parent().outerWidth(newWidth).outerHeight(newHeight);
    }

    init(options) {
        var that = this,
            settings = $.extend({
                fov: 90,
                latitude: that.map.getCenter().lat,
                longitude: that.map.getCenter().lng,
                heading: 0,
                pitch: 0,
            }, options),
            window = {
                loc: $("body"),
                svFrameClassName: "sv-window",
                windowID: "popup",
            },
            dialogEl = $("#streetView__container");
        dialogEl.html(that.streetView(settings, window));
        var svEl = dialogEl.find("." + window.svFrameClassName + "");
        that.dialogSize(dialogEl, settings.windowHeight, settings.windowWidth);
        that.fitDialog(dialogEl, svEl);
        dialogEl.on("dialogresize", function() {
            that.fitDialog(dialogEl, svEl);
        });
    }

}

export default StreetView;