import $ from "jquery";

class Responsive {
    constructor(config, settings) {
        this.compositions = config.tematList;
        this.states = settings.states;
        this.storage = window.sessionStorage;
        this.showLayers = config.MapConf.Warstwy;
        this.topLogos = config.mapConfig.MapConf.PasekNaGorze;
        this.$baseContainer = $(
            "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(1)"
        );
        this.$compositionsContainer = $(
            "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(3)"
        );
        this.$overlayContainer = $(
            "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(5)"
        );
        this.$titleContainer = $(
            "#map > div.leaflet-control-container > div.leaflet-top.leaflet-right > .composition-title"
        );
        this.$startingMenu = $(".starting-panel");
        this.greenCol = "#7DA83F";
        this.blueCol = "#0080D4";
        this.addSpecialClass();
        var that = this;
        if (
            ($(window).width() <= 768 && $(window).height() <= 768) ||
            $(window).width() <= 768 ||
            $(window).height() <= 768
        ) {
            this.offEvents();
            this.events();
            this.setSmallIcons();
            that.hide(this.$titleContainer);
            $(".printPopup").removeClass("overlay__low");
            $(".coordsSearchPopup").removeClass("overlay__low");
            $(".permalinkPopup").removeClass("overlay__low");
        }

        $(window).resize(function () {
            if ($(window).width() > 768 && $(window).height() > 768) {
                //!sip110 - left bar actions

                that.offEvents();
                that.hide(".leaflet-control-layers");
                that.hide(".small-icon-settings");

                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                // //!----

                $(".small-icon").removeClass("si-focused");
                // that.hide(".responsive-icon");
                // if (that.showLayers) {
                //   that.show(that.$baseContainer, "block");
                //   if (
                //     !$(".leaflet-control-compositions").hasClass(
                //       ".leaflet-control-compositions--hidden"
                //     )
                //   ) {
                //     that.show(that.$compositionsContainer, "block");
                //   }
                //   that.show(that.$overlayContainer, "block");
                // }
                // that.show(that.$titleContainer, "flex");
                // $(".printPopup").addClass("overlay__low");
                // $(".coordsSearchPopup").addClass("overlay__low");
                // $(".permalinkPopup").addClass("overlay__low");
            } else if (
                ($(window).width() <= 768 && $(window).height() <= 768) ||
                $(window).width() <= 768 ||
                $(window).height() <= 768
            ) {
                //todo szerokość i wysokość mniejsze od 768 albo sama wysokość albo sama szerokośc
                that.offEvents();
                that.events();
                //!sip110 - left bar actions
                that.hide(".leaflet-control-compositions");
                that.hide(".leaflet-control-layers");

                $(".small-icon-baseLayers").css({
                    "background-color": that.blueCol,
                });
                $(".small-icon-overlayLayers").css({
                    "background-color": that.blueCol,
                });
                $(".small-icon-compositions").css({
                    "background-color": that.blueCol,
                });
                //!----

                that.show(".responsive-icon-d", "flex");
                that.setSmallIcons();

                $(".small-icon").removeClass("si-focused");

                // that.hide(".formPanel");
                // that.hide(that.$titleContainer);
                // that.hide(that.$baseContainer);
                // that.hide(that.$compositionsContainer);
                // that.hide(that.$overlayContainer);
                // $(".printPopup").removeClass("overlay__low");
                // $(".coordsSearchPopup").removeClass("overlay__low");
                // $(".permalinkPopup").removeClass("overlay__low");
            }
        });
    }
    events() {
        var that = this;

        $(".small-icon-baseLayers--responsive").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(that.$baseContainer);
                that.showElements();
            } else {
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                $(".tools__tool-focused").click();
                that.show(that.$baseContainer, "block");
                that.setRest();
                if ($(window).width() <= 480) {
                    that.hideElements();
                }
                $(this).css({
                    "background-color": that.greenCol,
                });
                $(this).addClass("si-focused");
                that.hide("#overlayLayers");
                if (
                    ($(window).width() <= 768 && $(window).height() <= 768) ||
                    $(window).width() <= 768
                ) {
                    that.hide(".eservicePanel");
                    that.hide(".wyboryPanel");
                    that.hide(".formPanel");
                }
                $(".tools__tool-focused").click();
                if ($(window).width() <= 768) {
                    that.hide(".search");
                }
                that.hide(".bar-top__tools");
                that.hide(".leaflet-control-compositions");
                that.setContainer(that.$baseContainer);
                that.show(".leaflet-control-layers-list", "block");
                // that.states.onInfo = false;
            }
        });

        $(".small-icon-overlayLayers--responsive").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(that.$overlayContainer);
                that.showElements();
            } else {
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                $(".tools__tool-focused").click();
                that.show(that.$overlayContainer, "block");
                that.setRest();
                if ($(window).width() <= 480) {
                    that.hideElements();
                }
                $(this).css({
                    "background-color": that.greenCol,
                });
                $(this).addClass("si-focused");
                if ($(window).width() <= 768) {
                    that.hide(".search");
                }
                that.hide(".bar-top__tools");
                that.hide(".leaflet-control-compositions");
                if (
                    ($(window).width() <= 768 && $(window).height() <= 768) ||
                    $(window).width() <= 768
                ) {
                    that.hide(".eservicePanel");
                    that.hide(".wyboryPanel");
                    that.hide(".formPanel");
                }
                that.setContainer(that.$overlayContainer);
                that.show(".leaflet-control-layers-list", "block");
                // that.states.onInfo = false;
            }
        });

        $(".small-icon-compositions--responsive").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(that.$compositionsContainer);
                that.showElements();
            } else {
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                $(".tools__tool-focused").click();
                that.show(that.$compositionsContainer, "block");
                that.setRest();
                if ($(window).width() <= 480) {
                    that.hideElements();
                }
                $(this).css({
                    "background-color": that.greenCol,
                });
                $(this).addClass("si-focused");
                if ($(window).width() <= 768) {
                    that.hide(".search");
                }
                that.hide(".bar-top__tools");
                if (
                    ($(window).width() <= 768 && $(window).height() <= 768) ||
                    $(window).width() <= 768
                ) {
                    that.hide(".eservicePanel");
                    that.hide(".wyboryPanel");
                    that.hide(".formPanel");
                }
                that.setContainer(that.$compositionsContainer);
                that.hide(".leaflet-control-layers-list");
                // that.states.onInfo = false;
            }
        });

        $(".small-icon-menu").on("click tap", function () {
            $(".responsive-icon").removeClass("si-focused");
            $(".responsive-icon").css({
                "background-color": that.blueCol,
            });
            $(".tools__tool-focused").click();
            that.show(that.$startingMenu, "flex");
            that.setRest();
            if ($(window).width() <= 480) {
                that.hideElements();
            }
            $(this).css({
                "background-color": that.greenCol,
            });
            $(this).addClass("si-focused");
            if ($(window).width() <= 768) {
                that.hide(".search");
            }
            that.hide(".bar-top__tools");
            if (
                ($(window).width() <= 768 && $(window).height() <= 768) ||
                $(window).width() <= 768
            ) {
                that.hide(".eservicePanel");
                that.hide(".wyboryPanel");
                that.hide(".formPanel");
            }
            that.setContainer(that.$compositionsContainer);
            that.hide(".leaflet-control-layers-list");
        });

        $(".starting-panel--close-btn").on("click tap", function () {
            that.makeFocused();
            that.hide(that.$startingMenu);
            that.showElements();
        });

        //todo event to search icon
        $(".small-icon-search").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(".search");
                that.showElements();
            } else {
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                that.setRest();
                that.hideElements();
                $(".tools__tool-focused").click();
                $(this).css({
                    "background-color": that.greenCol,
                });
                $(this).addClass("si-focused");
                that.show(".search", "flex");
                that.show(".searchn", "flex");
                that.setContainer(".search");
                that.setContainer(".searchn");
                that.hide(".leaflet-control-layers-list");
                that.hide(".leaflet-control-layers");
                that.hide(".bar-top__tools");
                if (
                    ($(window).width() <= 768 && $(window).height() <= 768) ||
                    $(window).width() <= 768
                ) {
                    that.hide(".eservicePanel");
                    that.hide(".wyboryPanel");
                    that.hide(".formPanel");
                }
                that.hide(".leaflet-control-compositions");
                // that.states.onInfo = false;
            }
        });

        //todo event to setting icon
        $(".small-icon-settings").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(".bar-top__tools");
                that.showElements();
            } else {
                that.setRest();
                /* if ($(window).width() <= 480) {
                   that.hideElements()
                 }*/
                $(".tools__tool-focused").click();
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                $(this).css({
                    "background-color": that.greenCol,
                });
                $(this).addClass("si-focused");
                if ($(window).width() <= 768) {
                    that.hide(".search");
                }
                that.show(".bar-top__tools", "flex");
                $(".bar-top__tools").css({
                    width: "224px",
                });

                that.hide(".leaflet-control-layers");
                if (
                    ($(window).width() <= 768 && $(window).height() <= 768) ||
                    $(window).width() <= 768
                ) {
                    that.hide(".eservicePanel");
                    that.hide(".wyboryPanel");
                    that.hide(".formPanel");
                }
                that.setContainer(".bar-top__tools");
                // that.states.onInfo = false;
            }
        });

        $(".bar-top__tools-hide").on("click tap", function () {
            that.hide(".bar-top__tools");
        });

        $(".search-hide").on("click tap", function () {
            that.hide(".search");
            that.showElements();
        });

        $(".leaflet-control-compositions-hide").on("click tap", function () {
            that.hide(that.$compositionsContainer);
        });

        $(".small-icon-forms").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(".formPanel");
                that.hide(".bar-top__tools");
                that.showElements();
            } else {
                that.setRest();
                if ($(window).width() <= 480) {
                    that.hideElements();
                }
                $(".tools__tool-focused").click();
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                that.show(".formPanel", "flex");
                $(this).addClass("si-focused");
                $(this).css({
                    "background-color": that.greenCol,
                });
                if ($(window).height() <= 768 && $(window).width() > 768) {
                    $(this).css("display", "none");
                }
                if ($(window).width() <= 768) {
                    that.hide(".search");
                    that.hide(".wyboryPanel");
                    that.hide(".eservicePanel");
                }
                if ($(window).width() <= 768 || $(window).height() <= 768) {
                    that.hide(".leaflet-control-compositions");
                    that.hide(".leaflet-control-layers");
                    that.hide(".bar-top__tools");
                }
            }
        });

        $(".small-icon-eservices").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(".eservicePanel");
                that.hide(".wyboryPanel");
                that.hide(".bar-top__tools");
            } else {
                that.setRest();
                $(".tools__tool-focused").click();
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                that.show(".eservicePanel", "flex");
                $(this).addClass("si-focused");
                $(this).css({
                    "background-color": that.greenCol,
                });
                if ($(window).height() <= 768 && $(window).width() > 768) {
                    $(this).css("display", "none");
                }
                if ($(window).width() <= 768) {
                    that.hide(".search");
                    that.hide(".wyboryPanel");
                    that.hide(".formPanel");
                }
                if ($(window).width() <= 768 || $(window).height() <= 768) {
                    that.hide(".leaflet-control-compositions");
                    that.hide(".leaflet-control-layers");
                    that.hide(".bar-top__tools");
                }
            }
        });

        $(".small-icon-wybory").on("click tap", function () {
            if ($(this).hasClass("si-focused")) {
                that.makeFocused();
                that.hide(".eservicePanel");
                that.hide(".formPanel");
                that.hide(".wyboryPanel");
                that.hide(".bar-top__tools");
            } else {
                that.setRest();
                $(".tools__tool-focused").click();
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                that.show(".wyboryPanel", "flex");
                $(this).addClass("si-focused");
                $(this).css({
                    "background-color": that.greenCol,
                });
                if ($(window).height() <= 768 && $(window).width() > 768) {
                    $(this).css("display", "none");
                }
                if ($(window).width() <= 768) {
                    that.hide(".search");
                    that.hide(".eservicePanel");
                    that.hide(".formPanel");
                }
                if ($(window).width() <= 768 || $(window).height() <= 768) {
                    that.hide(".leaflet-control-compositions");
                    that.hide(".leaflet-control-layers");
                    that.hide(".bar-top__tools");
                }
            }
        });

        $(".formPanel__title--close-resp").on("click tap", function () {
            that.hide(".formPanel");
            that.show(".responsive-icon-d", "flex");
            that.show(".bar-bottom", "flex");
            that.hide(".modePanel-show");
            that.show(".bar-bottom", "flex");
        });

        //todo event to <<
        $(".fa-angle-double-left").on("click tap", function () {
            that.show(".responsive-icon-d", "flex");
            that.show(".bar-bottom", "flex");
            that.hide(".modePanel-show");
            that.hide(".leaflet-control-layers");
            that.showElements();
            that.show(".bar-bottom", "flex");
            $(".responsive-icon").css({
                "background-color": that.blueCol,
            });
            $(".responsive-icon").removeClass("si-focused");
        });

        /*$('.fa-angle-double-right').on('click tap', function () {
              that.show('.responsive-icon-d', 'flex');
              that.hide('.modePanel-show');
               if ($('.composition-title__name').text() == '') {
                 that.hide('.small-icon-composition-title');
               }
              //      that.show('.leaflet-control-zoom', 'block');
              that.show('.bar-bottom', 'flex');
              that.hide('.leaflet-control-layers-list');
              that.hide('.leaflet-control-layers');
              $('.responsive-icon').css({
                'background-color': that.blueCol,
              });
              $('.responsive-icon').removeClass('si-focused');
            })*/

        $(".composition-title-hide").on("click tap", function () {
            that.hide(that.$titleContainer);

            //      that.show('.responsive-icon-d', 'flex');
            //      that.hide('.modePanel-show');
            //      if ($('.composition-title__name').text() == '') {
            //        that.hide('.small-icon-composition-title');
            //      }
            //      that.show('.leaflet-control-zoom', 'block');
            //      that.show('.bar-bottom', 'flex');
            //      that.hide('.leaflet-control-layers-list');
            //      that.hide('.leaflet-control-layers');
            //      $('.responsive-icon').css({
            //        'background-color': that.blueCol,
            //      });
            //      $('.responsive-icon').removeClass('si-focused');
        });

        /*$('.priority-nav__btn-toggle').on('click tap', function () {
              var arrows = $(this).find('.fa--toggle');
              if (arrows.hasClass('fa-angle-double-right')) {
                $(this).css('display', 'none');
                that.show('.priority-nav__btn--close', 'flex');
              }
              //      that.hideElements()
            })

            $('.priority-nav__btn--close').on('click tap', function () {
              $(this).css('display', 'none');
              that.show('.priority-nav__btn', 'flex');
              $('.priority-nav__hidden-links').addClass('hidden');
              that.showElements()
            });*/

        $(".si__print").on("click tap", function () {
            if ($(this).hasClass("tools__tool-focused")) {
                that.showElements();
            } else {
                $(".responsive-icon").removeClass("si-focused");
                $(".responsive-icon").css({
                    "background-color": that.blueCol,
                });
                that.setRest();
                if ($(window).width() <= 480) {
                    that.hideElements();
                }
                that.hide(".leaflet-control-layers-list");
                that.hide(".leaflet-control-layers");
                that.hide(".bar-top__tools");
                that.hide(".leaflet-control-compositions");
                if ($(window).width() <= 768) {
                    that.hide(".search");
                    that.hide(".eservicePanel");
                    that.hide(".wyboryPanel");
                    that.hide(".formPanel");
                }
            }
            // that.states.onInfo = false;
        });

        $(".si__measure").on("click tap", function () {
            $(".responsive-icon").removeClass("si-focused");
            $(".responsive-icon").css({
                "background-color": that.blueCol,
            });
            that.setRest();
            that.hide(".leaflet-control-layers-list");
            that.hide(".leaflet-control-layers");
            that.hide(".bar-top__tools");
            that.hide(".leaflet-control-compositions");
            if ($(window).width() <= 768) {
                that.hide(".search");
                that.hide(".eservicePanel");
                that.hide(".wyboryPanel");
                that.hide(".formPanel");
            }
            // that.states.onInfo = false;
        });

        $(".si__layerOpacity").on("click tap", function () {
            $(".responsive-icon").removeClass("si-focused");
            $(".responsive-icon").css({
                "background-color": that.blueCol,
            });
            that.setRest();
            that.hide(".leaflet-control-layers-list");
            that.hide(".leaflet-control-layers");
            that.hide(".bar-top__tools");
            that.hide(".leaflet-control-compositions");
            if ($(window).width() <= 768) {
                that.hide(".search");
                that.hide(".eservicePanel");
                that.hide(".wyboryPanel");
                that.hide(".formPanel");
            }
            // that.states.onInfo = false;
        });

        $(".si__streetView").on("click tap", function () {
            $(".responsive-icon").removeClass("si-focused");
            $(".responsive-icon").css({
                "background-color": that.blueCol,
            });
            that.setRest();
            that.hide(".leaflet-control-layers-list");
            that.hide(".leaflet-control-layers");
            that.hide(".bar-top__tools");
            that.hide(".leaflet-control-compositions");
            if ($(window).width() <= 768) {
                that.hide(".search");
                that.hide(".eservicePanel");
                that.hide(".wyboryPanel");
                that.hide(".formPanel");
            }
            // that.states.onInfo = false;
        });

        $(".si__legend").on("click tap", function () {
            $(".responsive-icon").removeClass("si-focused");
            $(".responsive-icon").css({
                "background-color": that.blueCol,
            });
            that.setRest();
            that.hide(".leaflet-control-layers-list");
            that.hide(".leaflet-control-layers");
            that.hide(".bar-top__tools");
            that.hide(".leaflet-control-compositions");
            if ($(window).width() <= 768) {
                that.hide(".search");
                that.hide(".eservicePanel");
                that.hide(".wyboryPanel");
                that.hide(".formPanel");
            }
            // that.states.onInfo = false;
        });

        $(document).on(
            "click tap",
            ".modePanel__body--btn-cancel",
            function () {
                that.showElements();
            }
        );

        $(document).on("click tap", "#drawEditCancel", function () {
            that.showElements();
        });
    }

    //!sip110 - left bar actions
    addSpecialClass() {
        $(".small-icon-baseLayers").addClass(
            "small-icon-baseLayers--responsive"
        );
        $(".small-icon-overlayLayers").addClass(
            "small-icon-overlayLayers--responsive"
        );
        $(".small-icon-compositions").addClass(
            "small-icon-compositions--responsive"
        );
    }
    //!----

    offEvents() {
        //!sip110 - left bar actions
        $(".small-icon-baseLayers--responsive").off("click tap");

        $(".small-icon-overlayLayers--responsive").off("click tap");

        $(".small-icon-compositions--responsive").off("click tap");
        //!----

        $(".small-icon-search").off("click tap");

        $(".small-icon-settings").off("click tap");

        $(".bar-top__tools-hide").off("click tap");

        $(".search-hide").off("click tap");

        $(".small-icon-forms").off("click tap");

        $(".small-icon-eservices").off("click tap");

        $(".small-icon-wybory").off("click tap");

        $(".formPanel__title--close-resp").off("click tap");

        $(".fa-angle-double-left").off("click tap");

        $(".fa-angle-double-right").off("click tap");

        //    $('.leaflet-control-layers-hide-base' ).off('click tap');

        $(".leaflet-control-layers-hide-overlays").off("click tap");

        $(".leaflet-control-compositions-hide").off("click tap");

        $(".priority-nav__btn").off("click tap");

        $(".priority-nav__btn--close").off("click tap");

        $(".si__legend").off("click tap");

        $(".si__streetView").off("click tap");

        $(".si__layerOpacity").off("click tap");

        //    $('.si__measure').off('click')

        $(".si__print").off("click tap");
    }
    makeFocused() {
        var that = this;
        that.show(".responsive-icon-d", "flex");
        that.show(".bar-bottom", "flex");
        that.hide(".modePanel-show");
        that.hide(".leaflet-control-layers");
        that.show(".bar-bottom", "flex");
        $(".responsive-icon").css({
            "background-color": that.blueCol,
        });
        $(".responsive-icon").removeClass("si-focused");
    }

    setSmallIcons() {
        if (this.topLogos) {
            var top;
            if ($(window).width() > 480) {
                top = 125;
                $(".composition-title-show").css("top", "125px!important");
            } else {
                /*else if ($(window).width() > 480) {
                   var top = 85;
                 }*/
                top = 105;
                $(".composition-title-show").css("top", "105px!important");
            }
        } else {
            top = 45;
        }
        $(".leaflet-control-zoom").css("top", "40px");
        $(".responsive-icon-d").each(function () {
            if (
                !$(this).hasClass("small-icon-composition-title") &&
                !$(this).hasClass("small-icon-modePanel") &&
                !$(this).hasClass("small-icon-forms")
            ) {
                $(this).css({
                    left: 0,
                    top: top,
                });
                top += 45;
            }
        });
    }

    hide(name) {
        $(name).css("display", "none");
    }

    show(name, display) {
        $(name).css({
            display: display,
        });
    }

    hideElements() {
        var that = this;
        that.hide(that.$titleContainer);
        that.hide(".leaflet-control-zoom");
        that.hide(".small-icon-composition-title");
        that.hide(".composition-title-show");
    }

    showElements() {
        var that = this;
        that.show(".leaflet-control-zoom", "block");
        if ($(".composition-title__name").text() != "") {
            that.hide(that.$titleContainer);
            that.show(".small-icon-composition-title", "flex");
            that.show(".composition-title-show", "block");
        }
    }

    setRest() {
        this.show(".responsive-icon-d", "flex");
        this.hide(".leaflet-top .leaflet-right");
    }

    setPositions() {
        $(".small-icon-baseLayers--responsive").css("top", "45px");
        this.setTheSameTop(
            ".small-icon-baseLayers--responsive",
            ".leaflet-control-layers-show-base"
        );
        this.setTheSameTop(
            ".small-icon-overlayLayers--responsive",
            ".leaflet-control-layers-show-overlays"
        );
        if (
            this.compositions.length != 0 &&
            this.compositions != null &&
            this.compositions != undefined
        ) {
            this.setTheSameTop(
                ".small-icon-compositions--responsive",
                ".leaflet-control-compositions-show"
            );
            //        this.setTheSameTop('.small-icon-composition-title', '.composition-title-show');
        }
    }

    setTheSameTop(get, set) {
        $(get).css({
            top: $(set).offset().top,
        });
    }

    setContainer(container) {
        if (this.topLogos) {
            $(container).css({
                position: "fixed",
                top: "120px",
                left: "42px",
            });
            if ($(window).width() <= 768 /*&& $(window).width() > 480*/) {
                $(container).css({
                    top: "100px",
                });
            }
            /*else if ($(window).width() <= 480) {
                   $(container).css({
                     'top': '105px',
                   });
                 }*/
        } else {

            if (container == '.searchn') {
                $(container).css({
                    position: "fixed",
                    top: "190px",
                    left: "42px",
                });
                // if ($(window).width() <= 768 && $(window).width() > 480) {} else if ($(window).width() <= 480) {
                if ($(window).width() <= 480) {
                    $(container).css({
                        top: "190px",
                    });
                }
            } else {
                $(container).css({
                    position: "fixed",
                    top: "40px",
                    left: "42px",
                });
                // if ($(window).width() <= 768 && $(window).width() > 480) {} else if ($(window).width() <= 480) {
                if ($(window).width() <= 480) {
                    $(container).css({
                        top: "40px",
                    });
                }
            }
        }
    }
}

export default Responsive;
