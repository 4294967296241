import $ from "jquery";

class TreeStructureManager {
  constructor(config) {
    this.treeStructure = config.treeStructure;
    this.layerList = config.layerList;
    this.bases = config.bases;
  }

  updateTreeStructure(treeStructure) {
    var that = this,
      treeStructureNew = [];
    $.each(treeStructure, function(i, v) {
      var k = 0;
      treeStructureNew[i] = {};
      for (var property in treeStructure[i]) {
        if (treeStructure[i].hasOwnProperty(property) || property != "layers") {
          treeStructureNew[i][property] = treeStructure[i][property];
        }
      }
      treeStructureNew[i].layers = [];
      $.each(v.layers, function(j, layer) {
        if (that.checkIfLayerInLayerList(layer.id.toString())) {
          treeStructureNew[i].layers[k] = treeStructure[i].layers[j];
          k++;
        }
      });
    });
    treeStructureNew = this.deleteEmptyGroups(treeStructureNew);
    return treeStructureNew;
  }

  getIdsList() {
    var idList = [];
    $.each(this.layerList, function(i, v) {
      idList.push(v.id.toString());
    });
    return idList;
  }

  checkIfLayerInLayerList(id) {
    var that = this;
    if (that.getIdsList().includes(id)) {
      return true;
    }
    return false;
  }

  deleteEmptyGroups(treeStructure) {
    var treeStructureNew = [],
      j = 0;
    for (var i = 0; i < treeStructure.length; i++) {
      // if ($.isEmptyObject(treeStructure[i].layers)) {} else {
      if (!$.isEmptyObject(treeStructure[i].layers)) {
        treeStructureNew[j] = treeStructure[i];
        j++;
      }
    }
    return treeStructureNew;
  }
}

export default TreeStructureManager;
