/* eslint-disable */

function DialogHandler(map, table, printProvider) {

  var that = this;

  this.layerName = 'Działka';

  this.printOptions = {
    scale: '',
    layout: '',
    projmiddles: [],
    customParams: {
      title: '',
      sygnatura: '',
      numer: '',
      obrebnazwa: ''
    },
    dpi: '',
  };

  this.features = [];

  this.printCallback = function (scale, layout, dpi) {
    that.setOptions(scale, layout, dpi);

    if (!scriptParams.def.generateDoc) {
      that.chooseTitle();
    } else {
      // if (scriptParams.addTool.generateDoc.actions.wyrysMode()) {
      //   that.createWyrys();
      // }
      // else {
      //   that.createParcelList();
      // }
      that.createParcelList(scriptParams.addTool.generateDoc.actions.createDocTitle());
    }

  };

  this.printCallbackMiddles = function (centers) {

    var middlesTable = [];

    that.printOptions.projmiddles = [];

    centers.eachLayer(function (layer) {
      var center = layer.getLatLng();
      middlesTable.push(center);
    });

    that.printOptions.projmiddles = middlesTable;
  };

  this.setOptions = function (scale, layout, dpi) {

    that.printOptions.scale = scale;
    that.printOptions.layout = layout;
    that.printOptions.dpi = dpi;

    if (that.printOptions.projmiddles[0] == undefined) {
      var centerMap = map.getCenter();
      // ze wzgledu na przesuniety srodek mapy
      var centerMapXY = map.latLngToLayerPoint(centerMap);
      centerMapXY.y = centerMapXY.y + 45;
      var center = map.layerPointToLatLng(centerMapXY);

      that.printOptions.projmiddles.push(center);
    }

  };


  this.chooseTitle = function () {
    //initUserRadioDialog('Tytuł wydruku', 'Wybierz tytuł wydruku:', radioTable, that.setDocumentSign) // user-dialog.js file
    initUserRadioDialog('Tytuł wydruku', 'Podaj tytuł wydruku:', null, that.createParcelList) // user-dialog.js file

  };

  this.setDocumentSign = function (documentTitle) {

    that.printOptions.customParams.title = documentTitle;

    initUserTextDialog('Sygnatura', 'Wpisz sygnaturę dokumentu:', that.createParcelList); // user-dialog.js file

  };

  this.createParcelList = function (documentTitle) { //function (sign) {
    //that.printOptions.customParams.sygnatura = sign;
    that.printOptions.customParams.sygnatura = "";
    that.printOptions.customParams.title = documentTitle;

    // if (table && table.integration) {
      // that.features = selectedParcels;
      that.features = window.selectedParcels;
      // that.features = table.integration.getSelectedFeaturesFromTable(that.layerName);

      var checkboxTable = [];

      // if (that.features.length > 1) { // group of parcels to display
      //
      //     $.each(that.features, function (index, feature) {
      //
      //         var checkbox = {
      //             label: feature.properties.label,
      //             value: feature.properties.ogc_fid
      //         };
      //
      //         checkboxTable.push(checkbox);
      //
      //     });
      //
      //     initUserCheckboxDialog('Wyrys', 'Wybierz działki do wyrysu:', checkboxTable, that.selectParcel);
      //
      // }
      // else {

      if (that.features && that.features.length) {

        // that.printOptions.customParams.numer = that.features[0].properties.numer;
        // that.printOptions.customParams.obrebnazwa = that.features[0].properties.obreb;
        // that.printOptions.customParams.dzialki = scriptParams.manager.selectedDatabaseIds;

        //dodanie podswietlenia
        var parcels = new L.tileLayer.wms("http://administracja.gison.pl/cgi-bin/mapserv?map=/home/vboxuser/mpzp/dzialki.map&dzialki=" + that.features.join(','), {
        // var parcels = new L.tileLayer.wms(vectorMapserver + 'dzialki.map&dzialki=' + that.printOptions.customParams.dzialki.join(','), {
          layers: 'Dzialki',
          format: 'image/png',
          transparent: true,
          srs: 'EPSG:900913',
          minZoom: 10,
          maxZoom: 20
        });

        map.addLayer(parcels);
        parcels.bringToFront();

        printProvider.print(that.printOptions);

        // scriptParams.addTool.generateDoc.actions.prepareParcels({
        //   printOptions: that.printOptions,
        // });

        // if (map.hasLayer(parcels)) {
        //
        //      map.removeLayer(parcels);
        //
        // };
        map.removeLayer(parcels);

      } else {

        that.printOptions.customParams.numer = '';
        that.printOptions.customParams.obrebnazwa = '';
        that.printOptions.customParams.dzialki = [];

        if (!scriptParams.def.generateDoc) {
          printProvider.print(that.printOptions);
        } else {
          scriptParams.addTool.generateDoc.actions.prepareParcels({
            printOptions: that.printOptions,
          });
        }

      };


      // };
    // } else {
    //   printProvider.print(that.printOptions);
    // }

  };

  this.createWyrys = function () {
    that.printOptions.customParams.numer = '';
    that.printOptions.customParams.obrebnazwa = '';
    that.printOptions.customParams.dzialki = [];
    that.printOptions.customParams.title = 'Dokument';
    that.printOptions.customParams.sygnatura = '';
    that.printOptions.customParams.title = '';

    printProvider.print(that.printOptions);
  }

  this.selectParcel = function (selectedIds) {

    if (selectedIds.length) {

      //dodanie podswietlenia
      var parcels = new L.tileLayer.wms(vectorMapserver + 'dzialki.map&dzialki=' + selectedIds.join(','), {
        layers: 'Dzialki',
        format: 'image/png',
        transparent: true,
        srs: 'EPSG:900913',
        minZoom: 10,
        maxZoom: 19
      });

      map.addLayer(parcels);
      parcels.bringToFront();

      that.printOptions.customParams.dzialki = selectedIds;

      printProvider.print(that.printOptions);

      if (map.hasLayer(parcels)) {

        map.removeLayer(parcels);

      };

    } else {

      printProvider.print(that.printOptions);

    };
  };

};

/***************************** DIALOG WITH TEXTBOX ****************************/

function initUserTextDialog(dialogTitle, textFieldLabel, submitCallbackFunction) {

  //doing callback function when user click submit
  function doCallbackFunction() {
    //get data from form input
    var textboxValue = $('#user-text-dialog').find('input[name="textfield"]').val();
    dialog.dialog('close')
    submitCallbackFunction(textboxValue);
  }

  var dialog;

  //check if DOM elem for dialog already exist
  if ($('body').find('#user-text-dialog').length === 0) {

    //build string html element
    var dialogContainer = '<div id="user-text-dialog" title="' + dialogTitle + '">' + '<form>' +
      '<fieldset>' +
      '<label for="textfield">' + textFieldLabel + '</label>' +
      '<input type="text" name="textfield" id="textfield" value="" class="text ui-widget-content ui-corner-all">' +
      '</fieldset>' +
      '</form>' +
      '</div>';
    //append it to body
    $('body').append(dialogContainer);

  }

  //init jQuery dialog widget
  dialog = $("#user-text-dialog").dialog({
    autoOpen: false,
    height: 250,
    width: 350,
    modal: true,
    buttons: {
      "Zatwierdz": doCallbackFunction,
    },
    close: function () {
      //do nothing
    }
  });

  //open
  dialog.dialog("open");
};


/***************************** DIALOG WITH CUSTOM RADIOBUTTONS ****************************/

function initUserRadioDialog(dialogTitle, radiobuttonsLabel, radiobuttonsTable, submitCallbackFunction) {

  //doing callback function when user click submit
  function doCallbackFunction() {
    //get data from form input
    //var checkedRadioValue = $('#user-radio-dialog input[type="text"]').val();
    //dialog.dialog('close')
    submitCallbackFunction("");
  }

  var dialog;

  $('body').find('#user-radio-dialog').remove();
  //build string html element
  var dialogContainer = '<div id="user-radio-dialog" title="' + dialogTitle + '">' +
    '<form>' +
    '<fieldset>' +
    '<p>' + radiobuttonsLabel + '</p>' +
    '<div class="radiobuttons-section"></div>' +
    '</fieldset>' +
    '</form>' +
    '</div>';
  //append it to body
  $('body').append(dialogContainer);

  //get elem into variable
  var dialogElem = $('body').children('#user-radio-dialog')

  //fill radio container
  var radioContainer = dialogElem.find('div.radiobuttons-section');

  //iterate through radiobuttons table

  //build elem and append it to radiobuttons container in form
  var elem = '<div class="single-radiobutton">' +
    '<label>' +
    '<input type="text" name="text" style="width: 300px;">' +
    '</label>' +
    '</div>';
  radioContainer.append(elem);


  doCallbackFunction();



};


/***************************** DIALOG WITH CUSTOM CHECKBOXES ****************************/

function initUserCheckboxDialog(dialogTitle, checkboxesLabel, checkboxesTable, submitCallbackFunction) {

  //doing callback function when user click submit
  function doCallbackFunction() {
    //get data from form input
    var checkedValues = $('#user-radio-dialog input[type="checkbox"]:checked');

    var values = [];

    $.each(checkedValues, function (index, checkbox) {

      var value = $(checkbox).val();

      values.push(value);

    });

    dialog.dialog('close');

    submitCallbackFunction(values);
  };

  var dialog;

  $('body').find('#user-radio-dialog').remove();
  //build string html element
  var dialogContainer = '<div id="user-radio-dialog" title="' + dialogTitle + '">' +
    '<form>' +
    '<fieldset>' +
    '<p>' + checkboxesLabel + '</p>' +
    '<div class="radiobuttons-section"></div>' +
    '</fieldset>' +
    '</form>' +
    '</div>';
  //append it to body
  $('body').append(dialogContainer);

  //get elem into variable
  var dialogElem = $('body').children('#user-radio-dialog')

  //fill radio container
  var radioContainer = dialogElem.find('div.radiobuttons-section');

  //iterate through radiobuttons table
  $.each(checkboxesTable, function (index, checkbox) {
    //build elem and append it to radiobuttons container in form
    var elem = '<div class="single-radiobutton">' +
      '<label>' +
      '<span>' + checkbox.label + '</span>' +
      '<input type="checkbox" value="' + checkbox.value + '">' +
      '</label>' +
      '</div>';
    radioContainer.append(elem);
  });


  //init jQuery dialog widget
  dialog = $("#user-radio-dialog").dialog({
    autoOpen: false,
    height: 300,
    width: 350,
    modal: true,
    buttons: {
      "Zatwierdz": doCallbackFunction,
    },
    close: function () {
      //do nothing
    }
  });

  //open
  dialog.dialog("open");

};

export default DialogHandler;
