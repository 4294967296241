import Tools from './Tools';
import L from 'leaflet';
import $ from 'jquery';
import './../vendor/jquery-ui';
// import "./../vendor/jqueryui-combobox";
import Wkt from 'wicket';
import Loading from './Loading';
import axios from 'axios';

var tools = new Tools();

class SearchNew {
     constructor(config, settings) {
          this.map = settings.map;
          this.config = config;
          this.tools = config.MapConf || {};
          this.storage = window.sessionStorage;
          this.bbox = config.MapConf.BBox || '';
          this.osmid = config.MapConf.OsmID;
          this.searchServices =
               typeof config.SearchServices === 'string'
                    ? JSON.parse(config.SearchServices)
                    : config.SearchServices;
          this.searchTypes = ['WyszukajAdres', 'WyszukajDzialke'];
          this.searchClass = 'search';
          this.$field = $('.searchn-text');
          this.$fieldSelect = $('.searchn-select');
          this.$search = this.$field.closest('.' + this.searchClass);
          this.$loadingAnim = this.$search.find('.searchn__input-loading-anim');
          this.$foundList = [];
          this.requestFlag = true;
          this.timer = 0;
          this.loading = new Loading(config, settings);
          this.layerListKeys = config.layerListKeys;
          this.activeLayersManager = settings.activeLayersManager;
          this.activeLayersIds = this.activeLayersManager.getActiveLayersIds();
          this.params = {
               textFilter: '',
               foundData: [],
          };
          this.searchItemsSource;
          this.obreby = config.Obreby;

          // this.selectedParcels = []
          window.selectedParcels = [];
          var that = this;
          this.keyUpCallbacks = {
               _this: that,
               arrowUp: this.arrowUp,
               arrowDown: this.arrowDown,
               enterPress: this.enterPress,
          };
          this.osmID = config.MapConf.OsmID;

          this.addSearchers();
          this.addEventsForAll();
     }

     addEventsForAll() {
          let that = this;

          $(document).on('click tap', 'li.sugg-new', function (e) {
               var latlng = that.getLatlngFromSugg($(this));

               //**********getinfo
               that.clickParams = {
                    latlng: latlng,
                    zoom: that.map.getZoom(),
                    //        xy: that.map.latLngToContainerPoint(latlng),
               };
               //**********getinfo end

               var $newActive = $(e.target).closest('li');
               $newActive.addClass('sugg-active');
               var text = $newActive.children('span').text();
               var namespace =
                    text.indexOf('LPIS') > -1
                         ? 'LPIS'
                         : text.indexOf('SWDE') > -1
                         ? 'PODGiK'
                         : 'gugik';
               let field = $(e.target).closest('ul').siblings('input');
               field.val(text);

               //if ($(this).hasClass('sugg-parcel')) {
               var geom = that.getGeomFromSugg($(this));
               //}
               if (latlng[0] && latlng[1]) {
                    if (geom) {
                         that.showGeomOnMap(geom, latlng, namespace, text);
                         that.addParcelToArr(text);
                    } else {
                         that.showPointOnMap(latlng);
                    }
               }
               that.closeSuggs(e);
          });

          $(document).on('click tap', 'body', function (event) {
               that.closeSuggs(event);
          });

          $(document).on('click', '#more-searchers', function () {
               $('.searchn__more--box').toggleClass(
                    'searchn__more--box-active'
               );
          });
     }

     addEvents(name, request) {
          const that = this;

          $(document).on('input', '#searchn-text-' + name, function (e) {
               //that.updateProperties(e.target);
               that.searchValue(e, name, request);
          });
     }

     activateSuggestionsList() {
          this.$foundList.removeClass('list-inactive').addClass('list-active');
     }

     disactivateSuggestionsList() {
          this.$foundList.removeClass('list-active').addClass('list-inactive');
     }

     animationOn() {
          this.$loadingAnim.css({
               opacity: 1,
          });
     }

     animationOff() {
          this.$loadingAnim.css({
               opacity: 0,
          });
     }

     addParcelToArr(text) {
          var parcel = text.split(' ')[0];
          // this.selectedParcels.push(parcel)
          window.selectedParcels.push(parcel);
     }

     addSearchers() {
          let html = ``;
          const searchPanel = document.querySelector('.searchn'),
               that = this;

          that.$foundList = that.$search.find('.searchn__input-found-list');
          if (
               this.searchServices.length > 3 ||
               (this.searchServices.length > 2 &&
                    that.tools.WyszukajDzialkeGUGiK)
          ) {
               if (that.tools.WyszukajDzialkeGUGiK) {
                    for (let i = 0; i < 2; i++) {
                         html += that.createHtmlSearcher(
                              that.searchServices[i].name,
                              that.searchServices[i].placeholder
                         );
                         that.addEvents(
                              that.searchServices[i].name,
                              that.searchServices[i].url
                         );
                    }
                    for (let i = 2; i < this.searchServices.length; i++) {
                         html += that.createHtmlSearcherHidden(
                              that.searchServices[i].name,
                              that.searchServices[i].placeholder
                         );
                         that.addEvents(
                              that.searchServices[i].name,
                              that.searchServices[i].url
                         );
                    }
               } else {
                    for (let i = 0; i < 3; i++) {
                         html += that.createHtmlSearcher(
                              that.searchServices[i].name,
                              that.searchServices[i].placeholder
                         );
                         that.addEvents(
                              that.searchServices[i].name,
                              that.searchServices[i].url
                         );
                    }
                    for (let i = 3; i < this.searchServices.length; i++) {
                         html += that.createHtmlSearcherHidden(
                              that.searchServices[i].name,
                              that.searchServices[i].placeholder
                         );
                         that.addEvents(
                              that.searchServices[i].name,
                              that.searchServices[i].url
                         );
                    }
               }
               let btn = `<div class="searchn__more--btn" id="more-searchers" title="więcej wyszukiwarek" data-toggle="tooltip"
                data-placement="bottom">
                <i class="fa fa-search"></i> <span>więcej</span>
            </div>`;
               let box = that.createHtmlSearcherBox(that.searchServices);
               searchPanel.innerHTML +=
                    html + `<div class="searchn__more">` + btn + box + `</div>`;
          } else {
               this.searchServices.forEach((v) => {
                    html += that.createHtmlSearcher(v.name, v.placeholder);
                    that.addEvents(v.name, v.url);
               });
               searchPanel.innerHTML += html;
          }
     }

     appendFoundedList(listHtml, request) {
          this.$foundList = this.$foundList.filter((x) =>
               $(x).hasClass('searchn__input-found-list-' + request)
          );
          this.$foundList = $('.searchn__input-found-list-' + request);
          this.$foundList.html(listHtml);
     }

     closeSuggs(event) {
          var $clicked = $(event.target);
          if (!$clicked.hasClass('found-list')) {
               if (this.$foundList.hasClass('list-active')) {
                    this.$foundList
                         .removeClass('list-active')
                         .addClass('list-inactive');
                    this.removeChildrenIfExist();
               }
          }
     }

     convertDataTableToHtml(dataTable, searchType, queryString) {
          var htmlString = '',
               html;

          if (searchType == 'adres' && dataTable.length == 1) {
               if (dataTable[0].display_name == ' ==nie znaleziono==') {
                    dataTable = [];
               }
          }

          if (dataTable.length != 0) {
               var length = dataTable.length,
                    that = this;
               for (var i = 0; i < length; i++) {
                    var name =
                         typeof dataTable[i].display_name == 'undefined' ||
                         undefined
                              ? dataTable[i].name
                              : dataTable[i].display_name;
                    if (searchType == 'adres' && this.bbox != '') {
                         var maxBounds = L.latLngBounds(
                              L.latLng(
                                   this.bbox.split(',')[1],
                                   this.bbox.split(',')[0]
                              ),
                              L.latLng(
                                   this.bbox.split(',')[3],
                                   this.bbox.split(',')[2]
                              )
                         );
                         if (maxBounds) {
                              if (
                                   maxBounds.contains(
                                        L.latLng(
                                             dataTable[i].lat,
                                             dataTable[i].lon
                                        )
                                   ) ||
                                   dataTable[i].lat == 0
                              ) {
                                   html =
                                        '<li class="sugg sugg-new sugg-address"><span data-index="' +
                                        i +
                                        '" data-lat="' +
                                        dataTable[i].lat +
                                        '" data-lon="' +
                                        dataTable[i].lon +
                                        '">' +
                                        name +
                                        '</span></li>';
                                   htmlString += html;
                              }
                         }
                    } else if (searchType == 'dzialka') {
                         html =
                              '<li class="sugg sugg-new sugg-parcel"><span data-index="' +
                              i +
                              '" data-lat="' +
                              dataTable[i].lat +
                              '" data-lon="' +
                              dataTable[i].lon +
                              '" data-geom="' +
                              dataTable[i].geom +
                              '">' +
                              dataTable[i].display_name +
                              '</span></li>';
                         htmlString += html;
                    } else {
                         html =
                              '<li class="sugg sugg-new"><span data-index="' +
                              i +
                              '" data-lat="' +
                              dataTable[i].lat +
                              '" data-lon="' +
                              dataTable[i].lon +
                              '" data-geom="' +
                              that.latlngToWKT(dataTable[i].geom) +
                              '">' +
                              name +
                              '</span></li>';
                         htmlString += html;
                    }
               }
          } else {
               htmlString =
                    '<li class="cant-be-active"><span>Nie znaleziono pasujących wyników</span></li>';
          }
          return htmlString;
     }

     convertWebServiceAddressToSuggObject(result) {
          result = JSON.parse(result.substring(1, result.length - 1));
          return result.geonames.map(function (geoname) {
               return {
                    lat: geoname.lat,
                    lon: geoname.lng,
                    display_name: geoname.fcodeName + ' ' + geoname.toponymName,
               };
          });
     }

     convertWebServiceToSuggObject(parcels) {
          return parcels.geonames.map(function (geoname) {
               return {
                    lat: geoname.lat,
                    lon: geoname.lng,
                    geom: geoname.geom,
                    display_name: geoname.fcodeName + ' ' + geoname.obreb,
               };
          });
     }

     createHtmlSearcher(name, placeholder) {
          return `<div id="field-search${name}" class="searchn__box searchn__input">
        <div class="searchn__input-wrapper">
            <input autocomplete="off" id="searchn-text-${name}" class="searchn__input-field search__input-field search-text searchn-text" data-searchn-type="${name}" data-request="${name}" type="text" placeholder="${placeholder}" />
            <ul class="searchn__input-found-list searchn__input-found-list-${name}"></ul>
        </div>
        <div class="searchn__input-button search__input-button" title="szukaj" data-toggle="tooltip" data-placement="bottom"><i class="fa fa-search"></i></div>
        <span class="searchn__input-loading-anim"><i class="fa fa-circle-o-notch fa-spin"></i></span>
    </div>`;
     }

     createHtmlSearcherHidden(name, placeholder) {
          return `<div id="field-search${name}" class="searchn__box--hidden searchn__box searchn__input">
        <div class="searchn__input-wrapper">
            <input autocomplete="off" id="searchn-text-${name}" class="searchn__input-field search__input-field search-text searchn-text" data-searchn-type="${name}" data-request="${name}" type="text" placeholder="${placeholder}" />
            <ul class="searchn__input-found-list searchn__input-found-list-${name}"></ul>
        </div>
        <div class="searchn__input-button search__input-button" title="szukaj" data-toggle="tooltip" data-placement="bottom"><i class="fa fa-search"></i></div>
        <span class="searchn__input-loading-anim"><i class="fa fa-circle-o-notch fa-spin"></i></span>
    </div>`;
     }

     createHtmlSearcherBox(searchServices) {
          let html = ``,
               that = this;
          if (
               searchServices.length > 3 ||
               (searchServices.length > 2 && that.tools.WyszukajDzialkeGUGiK)
          ) {
               for (let i = 2; i < searchServices.length; i++) {
                    html += that.createHtmlSearcher(
                         searchServices[i].name,
                         searchServices[i].placeholder
                    );
                    that.addEvents(
                         searchServices[i].name,
                         searchServices[i].url
                    );
               }
          } else {
               for (let i = 3; i < searchServices.length; i++) {
                    html += that.createHtmlSearcher(
                         searchServices[i].name,
                         searchServices[i].placeholder
                    );
                    that.addEvents(
                         searchServices[i].name,
                         searchServices[i].url
                    );
               }
          }
          return `<div class="searchn__more--box">` + html + `</div>`;
     }

     createParcelPopup(json) {
          return (
               `<div><u><b>` +
                    json.properties.layer +
                    `</b></u><br><b>Numer:  </b><span>` +
                    json.properties.numer +
                    `</span><br><b>Obręb </b><span>` +
                    json.properties.obreb +
                    (json.properties.pow
                         ? `</span><br><b>Powierzchnia:  </b><span>` +
                           json.properties.pow +
                           `m<sup>2</sup>`
                         : ` `) +
                    // `</span><br><b>ID: </b><span>` +
                    // json.properties.label.substr(
                    //      0,
                    //      json.properties.label.indexOf('(')
                    // ) +
                    `</span><br><b>ID: </b><span>` +
                    json.properties.id || '' + `</span></div>`
          );
     }

     encodeUrl(url) {
          return encodeURIComponent(url);
     }

     getBodyHtml(html) {
          var match = html.match(/<body>[\s\S]*?<\/body>/g);
          if (match && match[0]) {
               html = match[0].replace('<body>', '').replace('</body>', '');
          } else {
               return '';
          }
          return /\S/g.test(html) ? html : '';
     }

     getDataHtml(dataArr) {
          var html = '',
               htmls = [],
               dataHtml;
          dataArr.forEach((data) => {
               if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {
                    dataHtml = this.getBodyHtml(data);
               } else if (/<\/ServiceException/g.test(data)) {
                    dataHtml = '';
               } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                    dataHtml = '';
               } else if ($.trim(data) == '[]' || data == []) {
                    dataHtml = '';
               } else {
                    dataHtml = this.parseJsonToHtml(data);
               }
               var check = 0;
               $.each(htmls, function (i, v) {
                    if (dataHtml == v) {
                         check++;
                    }
               });
               if (check == 0) {
                    if (dataHtml != '' && dataHtml.length > 1) {
                         htmls.push(dataHtml);
                    }
               }
          });
          html = htmls.join('<hr>');
          return html;
     }

     getLatlngFromSugg($this) {
          var lat = $this.children('span').attr('data-lat'),
               lon = $this.children('span').attr('data-lon');
          return [lat, lon];
     }

     getGeomFromStr(geomStr) {
          var geomArr = geomStr.substring(9, geomStr.length - 2).split(',');
          var geomArrNew = [];
          $.each(geomArr, function (i, v) {
               geomArrNew[i] = v.split(' ');
               $.each(geomArrNew, function (j, w) {
                    if (
                         geomArrNew[j][1] &&
                         geomArrNew[j][1].indexOf(')') > -1
                    ) {
                         geomArrNew[j][1] = geomArrNew[j][1].slice(0, -1);
                    }
               });
          });
          return geomArrNew;
     }

     getGeomFromSugg($this) {
          let geomStr = $this.children('span').attr('data-geom');
          if (geomStr) {
               let geomArr = this.getGeomFromStr(geomStr);

               var wkt = new Wkt.Wkt();
               wkt.read(geomStr);
               geomArr = wkt.toJson();
               return geomArr;
          } else {
               return;
          }
     }

     getInfo(searcher) {
          this.activeLayersIds = this.activeLayersManager.getActiveLayersIds();
          let activeLayersIds = this.activeLayersIds,
               promises = [],
               layersUrls = [];

          var layerIDs = activeLayersIds;
          if (searcher) {
               if (searcher == 'gugik') {
               } else {
                    var layerID = this.getLayerIDs(
                         this.layerListKeys,
                         searcher
                    );
                    if (layerID) {
                         layerIDs.push(layerID[0]);
                    }
               }
          }

          layerIDs.forEach((activeLayerId) => {
               if (activeLayerId) {
                    //    activeLayersIds.forEach(activeLayerId => {
                    let layer = this.layerListKeys[activeLayerId];

                    // tylko w wersji dev
                    //            iflayer.proxy = false;

                    if (
                         layer.queryable &&
                         !layersUrls.includes(layer.queryUrl)
                    ) {
                         var request = layer.queryUrl
                              ? this.queryRequest(layer)
                              : this.wmsRequest(layer);
                         promises.push(request);
                    }

                    /* if (layer.link) {
                     if (layer.queryable) {
                       let linkPromise = this.linkPromise(layer);
                       promises.push(linkPromise);
                     }
                   } else {
                     if (layer.queryable && !layersUrls.includes(layer.queryUrl)) {
                       let request = this.getRequest(layer);
                       promises.push(request);
                     }
                     if (layer.queryUrl && !layersUrls.includes(layer.queryUrl)) {
                       layersUrls.push(layer.queryUrl);
                     }
                   }*/
               }
          });

          return promises;
     }

     getLayerIDs(layerList, phrase) {
          var layerIDs = [];
          $.each(layerList, (x, y) => {
               if (y.name.includes(phrase)) {
                    layerIDs.push(y.id);
               }
          });
          return layerIDs;
     }

     jsonpCallbackToJson(dataTable) {
          if (
               typeof dataTable === 'string' &&
               dataTable.indexOf('L.Control.Search.callJsonp') > -1
          ) {
               dataTable = dataTable
                    .replace('L.Control.Search.callJsonp(', '')
                    .replace(/]\)$/, ']');
               dataTable = JSON.parse(dataTable);
          }
          // else if (dataTable.charAt(0) == '(') {
          //     dataTable = dataTable.substring(1, dataTable.length - 1)
          //     dataTable = JSON.parse(dataTable);
          // }
          return dataTable;
     }

     latlngToWKT(geom) {
          if (typeof geom === 'string') {
               geom = JSON.parse(geom).geometry;
          }

          var lng,
               lat,
               coords = [];
          if (geom.type == 'Polygon') {
               var latlngs = geom.coordinates;
               for (var i = 0; i < latlngs.length; i++) {
                    var latlngs1 = latlngs[i];
                    if (latlngs1.length) {
                         for (var j = 0; j < latlngs1.length; j++) {
                              coords.push(
                                   latlngs1[j][0] + ' ' + latlngs1[j][1]
                              );
                              if (j === 0) {
                                   lng = latlngs1[j][0];
                                   lat = latlngs1[j][1];
                              }
                         }
                    } else {
                         coords.push(latlngs[i][0] + ' ' + latlngs[i][1]);
                         if (i === 0) {
                              lng = latlngs[i][0];
                              lat = latlngs[i][1];
                         }
                    }
               }
               return (
                    'POLYGON((' +
                    coords.join(',') +
                    ',' +
                    lng +
                    ' ' +
                    lat +
                    '))'
               );
          }
     }

     parseUrl(url) {
          return url
               .replace(/%osmid%/g, this.osmID)
               .replace(/%nazwa%/g, this.queryString)
               .replace(/%name%/g, this.queryString);
     }

     parseUrlInfo(url) {
          let lat = this.clickParams.latlng[0];
          let lng = this.clickParams.latlng[1];
          url = url
               .replace(/%lat%/g, lat)
               .replace(/%lon%|%lng%/g, lng)
               .replace(/%zoom%/g, this.clickParams.zoom);
          url = url + '&app=sip&username=' + localStorage['username'];
          return url;
     }

     parseJsonToHtml(data) {
          var html = '',
               that = this;
          try {
               var json = JSON.parse(data);
               if (json.popup) {
                    html = json.popup;
               } else {
                    if (json.length > 1) {
                         $.each(json, function (i, v) {
                              var json2 = JSON.parse(v);
                              html += '<br/>' + that.createParcelPopup(json2);
                         });
                    } else {
                         var json2 = JSON.parse(json);
                         html = this.createParcelPopup(json2);
                    }
               }
          } catch (err) {
               html = data;
          }
          return html;
     }

     queryRequest(layer) {
          var url =
               window.PROXY + this.encodeUrl(this.parseUrlInfo(layer.queryUrl));
          // var url = this.parseUrlInfo(layer.queryUrl);
          return fetch(url);
     }

     removeChildrenIfExist() {
          if (this.$foundList.children().length != 0) {
               this.$foundList.children().remove();
               this.$foundList
                    .removeClass('list-active')
                    .addClass('list-inactive');
          }
     }

     removeGwcServiceFromUrl(url) {
          return url.replace('gwc/service/', '');
     }

     removeParcelFromArr(text) {
          var parcel = text.split(' ')[0];
          // this.selectedParcels= this.selectedParcels.filter((elem)=>elem!=parcel)
          window.selectedParcels = window.selectedParcels.filter(
               (elem) => elem != parcel
          );
     }

     sendRequest(request, requestType, queryString) {
          const that = this;

          this.removeChildrenIfExist();
          this.disactivateSuggestionsList();
          this.animationOn();

          if (that.searchItemsSource) {
               that.searchItemsSource.cancel('Cancel previous request');
          }
          const CancelToken = axios.CancelToken;
          that.searchItemsSource = CancelToken.source();
          axios(this.parseUrl(request), {
               cancelToken: that.searchItemsSource.token,
          })
               .then(function (response) {
                    if (response && response.data) {
                         let data = response.data;
                         if (
                              requestType == 'adres' &&
                              that.tools.SearchServiceAdr != null
                         ) {
                              data =
                                   that.convertWebServiceAddressToSuggObject(
                                        data
                                   );
                              let html = that.convertDataTableToHtml(
                                   data,
                                   'adres',
                                   queryString
                              );
                              that.appendFoundedList(html, 'adres');
                              that.activateSuggestionsList();
                         } else if (requestType == 'dzialka') {
                              let data = JSON.parse(
                                   response.data.substring(
                                        1,
                                        response.data.length - 1
                                   )
                              );
                              data = that.convertWebServiceToSuggObject(data);
                              let html = that.convertDataTableToHtml(
                                   data,
                                   'dzialka',
                                   queryString
                              );
                              that.appendFoundedList(html, 'dzialka');

                              that.activateSuggestionsList();
                              that.animationOff();
                         } else {
                              data = that.jsonpCallbackToJson(data);
                              let html = that.convertDataTableToHtml(
                                   data,
                                   requestType,
                                   queryString
                              );

                              that.appendFoundedList(html, requestType);
                              that.activateSuggestionsList();
                         }
                    }
               })
               .catch(function (thrown) {
                    if (axios.isCancel(thrown)) {
                         console.log('Request canceled', thrown.message);
                    }
               });
     }

     searchValue(e, name, request) {
          const that = this;
          this.queryString = $(e.target).val();
          let inputField = e.target;
          let $field = $('#searchn-text-' + name);
          let requestType = $(inputField).attr('data-request');
          clearTimeout(this.timer);
          if (this.queryString.length >= 1) {
               this.timer = setTimeout(function () {
                    var queryTest = $field.val();
                    if (queryTest == that.queryString) {
                         that.sendRequest(
                              request,
                              requestType,
                              that.queryString
                         );
                    }
               }, 1000);
          } else {
               this.removeChildrenIfExist();
          }
     }

     showGeomOnMap(geom, latlng, namespace, text) {
          var latlngArr;
          if ($.isArray(latlng)) {
               latlngArr = latlng;
          } else {
               latlngArr = [latlng.lat.toString(), latlng.lng.toString()];
          }
          var that = this,
               zoom = that.map.getZoom() + 2,
               //  zoom = 18,
               id =
                    'hideGeom' +
                    latlngArr[0].replace('.', '') +
                    latlngArr[1].replace('.', '');
          // = this.fixGeom(geom);

          // this.map.setView(latlng, zoom, {});

          var html = '';
          var myStyle = {
               color: '#0080D4',
               weight: 5,
               opacity: 0.65,
          };

          var geojson = L.geoJson(geom, {
               style: myStyle,
               onEachFeature: function onEachFeature(feature, layer) {
                    layer.bindPopup(
                         html +
                              `<hr><button id="` +
                              id +
                              `">usuń zaznaczenie </button>`
                    );
                    that.map.fitBounds(layer.getBounds());
                    layer.bringToFront();
                    layer.on('click', function (e) {
                         that.clickParams = {
                              xy: e.containerPoint,
                              latlng: [e.latlng.lat, e.latlng.lng],
                              zoom: that.map.getZoom(),
                         };

                         that.loading.makeLoadingScreen(
                              '.leaflet-popup-content',
                              ''
                         );
                         var promises = that.getInfo(namespace);

                         Promise.all(promises)
                              .then((responses) => {
                                   var okResponses = responses.filter(
                                        (response) => {
                                             if (response.status == 200) {
                                                  return response;
                                             }
                                        }
                                   );
                                   return Promise.all(
                                        okResponses.map((res) => res.text())
                                   );
                              })
                              .then((texts) => {
                                   //      that.getInfoDone(texts);
                                   var html = $.trim(that.getDataHtml(texts));
                                   layer.bindPopup(
                                        html +
                                             `<hr><button id="` +
                                             id +
                                             `">usuń zaznaczenie </button>`
                                   );
                                   that.loading.removeLoadingScreen(
                                        '.leaflet-popup-content'
                                   );
                              });

                         $(document).on('click', '#' + id, function () {
                              that.map.removeLayer(layer);
                              that.removeParcelFromArr(text);
                         });
                    });
               },
          }).addTo(this.map);

          $(document).on(
               'click',
               '.leaflet-popup-content-wrapper, .leaflet-popup-content',
               function (e) {
                    e.stopPropagation();
               }
          );
     }

     showPointOnMap(latlng) {
          var that = this,
               zoom = 18,
               id =
                    'hideCircleMarker' +
                    latlng[0].replace('.', '') +
                    latlng[1].replace('.', '');
          this.map.setView(latlng, zoom, {});

          var html = '';
          var circle = L.circleMarker(latlng, {
               radius: 10,
               color: '#0080D4',
               fillOpacity: 0.8,
               clickable: false,
               className: 'searchPointer',
          }).bindPopup(
               html + `<hr><button id="` + id + `">usuń punkt</button>`
          );
          this.map.addLayer(circle);

          circle.openPopup().closePopup();

          var that = this;
          circle.on('click', function () {
               circle.openPopup();

               //**********getinfo
               that.loading.makeLoadingScreen('.leaflet-popup-content', '');

               var promises = that.getInfo('Adresy');

               Promise.all(promises)
                    .then((responses) => {
                         var okResponses = responses.filter((response) => {
                              if (response.status == 200) {
                                   return response;
                              }
                         });
                         return Promise.all(
                              okResponses.map((res) => res.text())
                         );
                    })
                    .then((texts) => {
                         //      that.getInfoDone(texts);
                         var html = $.trim(that.getDataHtml(texts));
                         circle.bindPopup(
                              html +
                                   `<hr><button id="` +
                                   id +
                                   `">usuń punkt </button>`
                         );
                         that.loading.removeLoadingScreen(
                              '.leaflet-popup-content'
                         );
                    });
               $(document).on('click', '#' + id, function () {
                    that.map.removeLayer(circle);
               });
          });
     }

     wmsRequest(layer) {
          var that = this,
               point = this.map.latLngToContainerPoint(that.clickParams.latlng),
               // point = that.clickParams.xy,
               layers = layer.options.layers
                    ? layer.options.layers.split(',')
                    : [],
               source = L.WMS.source(layer.url, layer.options);
          source._map = this.map;
          var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(
               source,
               point,
               layers
          );
          wmsParams.username = localStorage['username']
               ? localStorage['username']
               : '';
          var url = layer.url + L.Util.getParamString(wmsParams, layer.url);
          if (layer.proxy) {
               url =
                    window.PROXY +
                    this.encodeUrl(this.removeGwcServiceFromUrl(url));
          } else {
               url = this.removeGwcServiceFromUrl(url);
          }
          return fetch(url, {
               dataType: 'json',
          });
     }
}

export default SearchNew;
