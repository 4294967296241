import Tools from './Tools';
import $ from 'jquery';

var tools = new Tools();

// przyciski boczne
class ImgBtns {

    constructor(config) {
        this.btns = config.imgBtns || [];
        this.imgBtnsClass = 'imgBtns';
        this.imgBtnsProps = tools.createElPropertiesFromClass(this.imgBtnsClass);
        this.addBtns();
    }

    addBtns() {
        var that = this;
        this.btns.forEach(function(btn) {
            var $link = that.createLink(btn).append(that.createImg(btn));
            $(that.imgBtnsProps.selector).append($link);
        });
    }

    createLink(linkSettings) {
        var that = this;
        return $('<a>', {
            href: linkSettings.href,
            title: linkSettings.title,
            class: that.imgBtnsProps.class + '__link',
            target: '_blank',
            style: [
                'width: ' + linkSettings.width,
                'height: ' + linkSettings.height,
            ].join(';'),
        });
    }

    createImg(imgSettings) {
        var that = this;
        return $('<img>', {
            src: imgSettings.src,
            title: imgSettings.title,
            class: that.imgBtnsProps.class + '__img',
        });
    }

}

export default ImgBtns;