/* eslint-disable */

import L from 'leaflet';
import DialogHandler from '../vendor/user-dialog';
import { AddPrintView } from './print';
import './leaflet.print-src';

//require bootstrap, jquery, leaflet map initialized
(function ($) {
     $.fn.printPreview = function (
          panelContainerSelector,
          mapVariable,
          table,
          params,
          loading,
          token
     ) {
          var $mapContainer = $(this);
          //    var presetsIncluded = $.isArray(presetsTable) ? true : false;
          var formatSet = {};
          var formatPositionSet = {};
          var widthHeightMmSet = {};

          var counter = 1,
               middles = new L.layerGroup(),
               rectangles = new L.layerGroup(),
               crosses = new L.layerGroup();

          //namespace for functions to do/handle events inside plugin
          var Actions = {
               //getting user print properties from select box
               getPrintProperties: function () {
                    var format = $('#layout-styles')
                         .children('option:selected')
                         .text();
                    var position = $('#layout-orientation')
                         .children('option:selected')
                         .text();
                    var name = $('#layout-name')
                         .children('option:selected')
                         .text();
                    var fullName =
                         format + ' ' + position + (name ? ' ' + name : '');
                    var dpi = $('#dpis').children('option:selected').text();

                    return {
                         format: fullName,
                         scale: $('#scales').children('option:selected').val(),
                         widthmm: widthHeightMmSet[fullName].width,
                         heightmm: widthHeightMmSet[fullName].height,
                         name: name,
                         pagesize: format,
                         rotation: position == 'pionowy' ? true : false,
                         dpi: dpi,
                    };
               },
               //drawing new print preview
               drawPrintPreview: function (width, height) {
                    //displaying properly borders of preview
                    if (width > $mapContainer.outerWidth(false)) {
                         if (
                              !$('#printingPreview').hasClass(
                                   'no-horizontal-borders'
                              )
                         ) {
                              $('#printingPreview').addClass(
                                   'no-horizontal-borders'
                              );
                         }
                    } else {
                         if (
                              $('#printingPreview').hasClass(
                                   'no-horizontal-borders'
                              )
                         ) {
                              $('#printingPreview').removeClass(
                                   'no-horizontal-borders'
                              );
                         }
                    }
                    if (height > $mapContainer.outerHeight(false)) {
                         if (
                              !$('#printingPreview').hasClass(
                                   'no-vertical-borders'
                              )
                         ) {
                              $('#printingPreview').addClass(
                                   'no-vertical-borders'
                              );
                         }
                    } else {
                         if (
                              $('#printingPreview').hasClass(
                                   'no-vertical-borders'
                              )
                         ) {
                              $('#printingPreview').removeClass(
                                   'no-vertical-borders'
                              );
                         }
                    }

                    //set width and height of new preview
                    $('#printingPreview').css({
                         width: width + 'px',
                         height: height + 'px',
                    });
               },
               //re drawing existing print previews
               redrawPrintPreviews: function (width, height) {
                    rectangles.eachLayer(function (layer) {
                         var xDifference = width / 2;
                         var yDifference = height / 2;
                         var centerPointXY = map.latLngToContainerPoint(
                              layer.getBounds().getCenter()
                         );
                         var southWest = L.point(
                              centerPointXY.x - xDifference,
                              centerPointXY.y - yDifference
                         );
                         var northEast = L.point(
                              centerPointXY.x + xDifference,
                              centerPointXY.y + yDifference
                         );
                         var bounds = L.latLngBounds(
                              map.containerPointToLatLng(southWest),
                              map.containerPointToLatLng(northEast)
                         );

                         layer.setBounds(bounds);
                    });
               },
          };

          var gminaNazwa = location.pathname.substr(1);

          if (gminaNazwa.indexOf('_') > -1) {
               gminaNazwa = gminaNazwa.substring(0, gminaNazwa.indexOf('_'));
          }

          var printProvider = L.print.provider({
               method: 'GET',
               //url: 'http://localhost:8087/print/pdf',
               url: 'http://server4.gison.pl/8087/print/pdf',
               proxy:
                    ROOT +
                    (scriptParams.def.printingParams.proxy || 'print?url='),
               autoLoad: true,
               mapTitle: 'Wyrys',
               loading: loading,
               customParams: {
                    osmId: scriptParams.def.printingParams.osmId || 0,
                    title: scriptParams.def.printingParams.title || '',
                    address: scriptParams.def.printingParams.address || '',
                    gmina:
                         gminaNazwa ||
                         scriptParams.def.printingParams.gmina ||
                         0,
                    Token: token,
               },
               dpi: 190, //190  56  127 254 300
          });

          var printControl = L.control.print({
               provider: printProvider,
          });

          AddPrintView(mapVariable, printProvider);

          mapVariable.addControl(printControl);
          // var dialogHandler = new DialogHandler(mapVariable, table, printProvider, selectedParcels);
          var dialogHandler = new DialogHandler(
               mapVariable,
               table,
               printProvider
          );

          function loadPrintInfoDiv(response) {
               var firstFormat = '';
               var firstFormatPosition = '';
               response = JSON.parse(response.responseText);

               response.layouts.forEach(function (layout, i) {
                    var format = layout.pagesize;
                    var position = layout.rotation ? 'pionowy' : 'poziomy';
                    var name = layout.name;

                    var formatPosition = format + ' ' + position;
                    var fullName = formatPosition + (name ? ' ' + name : '');

                    if (i == 0) {
                         firstFormat = format;
                         firstFormatPosition = formatPosition;
                    }

                    if (!formatSet[format]) {
                         formatSet[format] = {
                              positions: {},
                         };
                    }
                    formatSet[format].positions[position] = true;

                    if (!formatPositionSet[formatPosition]) {
                         formatPositionSet[formatPosition] = {
                              names: {},
                         };
                    }
                    formatPositionSet[formatPosition].names[name] = true;

                    widthHeightMmSet[fullName] = {
                         width: layout.widthmm,
                         height: layout.heightmm,
                    };
               });

               var layoutStyles = createSelectOptionsObject(formatSet);
               var layoutPositions = createSelectOptionsObject(
                    formatSet[firstFormat].positions
               );
               var layoutNames = createSelectOptionsObject(
                    formatPositionSet[firstFormatPosition].names
               );

               var scales = response.scales.map(function (scale) {
                    return {
                         name: scale.name,
                         value: Number(scale.value),
                         default: scale.default || null,
                    };
               });

               var dpis = response.dpis;

               //assign plugin container html element with given selector to jquery variable variable
               var $printingContainer = $(panelContainerSelector);

               /* if (presetsIncluded) {
               var presetsString = '' +
                 '<div class="row">' +
                 '<div class="col-xs-3">' +
                 '<p class="section-label">Tryb</p>' +
                 '</div>' +
                 '<div class="col-xs-9">' +
                 '<select id="presets" class="print-select"></select>' +
                 '</div>' +
                 '</div>';
             } else {
               var presetsString = '';
             }*/

               if (!scriptParams.def.generateDoc) {
                    var printStateBtn =
                         '<button id="print-state" class="przycisk-map" data-toggle="collapse" href="#print-panel" data-parent="#printingPreviewContainer"><span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span> Wydruk </button>';
                    var printPanel =
                         '<div id="print-panel" class="panel-collapse collapse">';
               } else {
                    var closeBtn =
                         '<i id="print-close" class="fa fa-times" aria-hidden="true"></i>';
                    var printStateBtn =
                         '<button id="print-state" class="przycisk-map"href="#print-panel" data-parent="#printingPreviewContainer"><span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span> Wyrys </button>' +
                         closeBtn;
                    var printPanel = '<div id="print-panel">';
               }

               var select =
                    (params.opts.layoutStyles
                         ? '<div class="row"><div class="col-xs-3"><p class="section-label">Format</p></div><div class="col-xs-9"><select id="layout-styles" class="print-select"></select></div></div>'
                         : '') +
                    (params.opts.layoutPositions
                         ? '<div class="row"><div class="col-xs-3"><p class="section-label">Układ</p></div><div class="col-xs-9"><select id="layout-orientation" class="print-select"></select></div></div>'
                         : '') +
                    (params.opts.scales
                         ? '<div class="row"><div class="col-xs-3"><p class="section-label">Skala</p></div><div class="col-xs-9"><select id="scales" class="print-select"></select></div></div>'
                         : '') +
                    (params.opts.layoutNames
                         ? '<div class="row"><div class="col-xs-3"><p class="section-label">Rozmiar</p></div><div class="col-xs-9"><select id="layout-name" class="print-select"></select></div></div>'
                         : '') +
                    (params.opts.dpis
                         ? '<div class="row"><div class="col-xs-3"><p class="section-label">DPI</p></div><div class="col-xs-9"><select id="dpis" class="print-select"></select></div></div>'
                         : '');

               //build plugin html stringasd
               var htmlString =
                    '<div id="printingPreviewContainer" class="container">' +
                    /*printing preview*/
                    '<div class="panel">' +
                    '<div class="panel-heading">' +
                    '<div>' +
                    '<div">' +
                    printStateBtn +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    printPanel +
                    '<div class="panel-body">' +
                    '<form>' +
                    select +
                    //        presetsString +
                    '<div class="row">' +
                    '<div class="col-xs-12">' +
                    '<button id="print" class="overlay__button-2"> Generuj Wydruk </button>' +
                    '</div>' +
                    '</div>' +
                    '</form>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>';

               //var htmlString = `<div id="printingPreviewContainer" class="container"><div class="panel"><div class="panel-heading"><div><div><button id="print-state" class="przycisk-map" data-toggle="collapse" href="#print-panel" data-parent="#printingPreviewContainer"><i class="fa fa-caret-right" aria-hidden="true"></i>Wydruk</button></div></div></div><div id="print-panel" class="panel-collapse collapse"><div class="panel-body"><form><div class="row"><div class="col-xs-4"><p class="section-label">Format</p></div><div class="col-xs-8"><select id="layout-styles" class="print-select"></select></div></div><div class="row"><div class="col-xs-4"><p class="section-label">Układ</p></div><div class="col-xs-8"><select id="layout-orientation" class="print-select"></select></div></div><div class="row"><div class="col-xs-4"><p class="section-label">Skala</p></div><div class="col-xs-8"><select id="scales" class="print-select"></select></div></div><div class="row"><div class="col-xs-4"><p class="section-label">DPI</p></div><div class="col-xs-8"><select id="dpis" class="print-select"></select></div></div>` + presetsString + `<div class="row"><div class="col-xs-12"><button id="print" class="overlay__button-2">Generuj Wydruk</button></div></div></form></div></div></div></div>`;

               //init plugin panel
               $printingContainer.append(htmlString);

               addSelectOptions(
                    layoutStyles,
                    'layout-styles',
                    getDefaultValue(layoutStyles) || layoutStyles[0]
               );
               addSelectOptions(
                    layoutPositions,
                    'layout-orientation',
                    getDefaultValue(layoutPositions) || layoutPositions[0]
               );
               addSelectOptions(
                    layoutNames,
                    'layout-name',
                    getDefaultValue(layoutNames) || layoutNames[0]
               );
               addSelectOptions(
                    scales,
                    'scales',
                    getDefaultValue(scales) || scales[0]
               );
               addSelectOptions(dpis, 'dpis', getDefaultValue(dpis) || dpis[0]);

               /*   if (presetsIncluded) {
                 //fill select box with passed layer presets
                 $.each(presetsTable, function (index, preset) {
                   //build single option and append it to select control
                   var option = "<option data-value='" + JSON.stringify(preset.value) + "' data-name='" + preset.name + "'>" + preset.name + "</option>";
                   $printingContainer.find('#presets').append(option);
                 });
               }*/

               //add print preview background
               var preview = '<div id="printingPreview" class="off"></div>';
               $mapContainer.append(preview);
          }

          window.printInfoDeferredLog.done(function (response) {
               loadPrintInfoDiv(response);
          });

          window.printInfoDeferred.done(function (response) {
               loadPrintInfoDiv(response);
          });

          function getDefaultValue(array) {
               var toReturn = array[0];
               $.each(array, function (i, v) {
                    if (v.default) {
                         toReturn = v.value;
                    }
               });
               return toReturn;
          }

          function addSelectOptions(options, selectId, defaultValue) {
               var $printingContainer = $(panelContainerSelector);
               $printingContainer.find('#' + selectId).html('');
               $.each(options, function (index, layout) {
                    var selected =
                         layout.value == defaultValue
                              ? 'selected="selected"'
                              : '';
                    var option =
                         '<option ' +
                         selected +
                         '" value="' +
                         layout.value +
                         '">' +
                         layout.name +
                         '</option>';
                    $printingContainer.find('#' + selectId).append(option);
               });
          }

          // wygeneruj ponownie dokument mpzp
          if (scriptParams.manager && scriptParams.manager.states.regen) {
               window.printInfoDeferred.done(function (response) {
                    regenerateDoc(mapTools.getUrlValue('regen'));
               });
          }

          function regenerateDoc(regenGuid) {
               var openPrint =
                    scriptParams.addTool.generateDoc.actions.openPrint;
               resetState();

               $.ajax({
                    url: ROOT + 'dokument/summary/' + regenGuid,
               }).done(function (docResponse) {
                    scriptParams.addTool.regenGuid = docResponse.Guid;
                    addGenerateDocParams(docResponse);
                    openPrint();

                    restoreParcels(docResponse.Dzialki).done(function () {
                         restoreRectangles(JSON.parse(docResponse.PrintSpec));
                    });
               });
          }

          function addGenerateDocParams(docResponse) {
               scriptParams.addTool.generateDoc.radiosOptions.rodzaj.selected = docResponse.Rodzaj.toLowerCase();
               scriptParams.addTool.generateDoc.radiosOptions.podklad.selected = docResponse.Podklad.toLowerCase();

               var layout = docResponse.WyrysLayout.split(' ');
               $('#layout-styles').val(layout[0]);
               $('#layout-orientation').val(layout[1]);
               $('#scales').val(docResponse.WyrysScale);
          }

          function resetState() {
               scriptParams.highlighter.$tapSelectParcelBtn.removeClass(
                    'active'
               );
               $('[data-layer="info"]').addClass('active');
               $('#add-to-selected').closest('.input-field').remove();
          }

          function restoreParcels(parcels) {
               return scriptParams.highlighter.actions
                    .getGetDzId(parcels)
                    .done(function (parcelsResponse) {
                         addParcels(parcelsResponse.flist);
                    });
          }

          function addParcels(features) {
               features.forEach(function (jsonFeature) {
                    var feature = mapTools.extendGeojson(
                         JSON.parse(jsonFeature)
                    );
                    scriptParams.restore.actions.restoreFeature(feature);
               });
          }

          function restoreRectangles(spec) {
               spec.middles.forEach(function (middle) {
                    addPrintRectangle(middle);
               });
          }

          /************************* PLUGIN EVENTS LISTENERS **************************/
          //menu click listeners

          //activate 'printing state' with visible print preview on map
          var collapsingAnimationPrintFlag = false;
          $(document).off('click tap', '#print-state');
          $(document).on('click tap', '#print-state', function (e) {
               counter = 1;
               $('#count').html(counter);
               // $(".print-select").prop('disabled', false).css('background-color', 'white');

               map.removeLayer(middles);
               middles.clearLayers();

               map.removeLayer(rectangles);
               rectangles.clearLayers();

               crosses.eachLayer(function (layer) {
                    map.removeLayer(layer);
               });
               dialogHandler.printOptions.projmiddles = [];

               if (!scriptParams.def.generateDoc) {
                    if (collapsingAnimationPrintFlag === false) {
                         if (!$(this).hasClass('activated')) {
                              collapsingAnimationPrintFlag = true;
                              setTimeout(function () {
                                   collapsingAnimationPrintFlag = false;
                              }, 350);

                              $(this).addClass('activated');
                              $('#printingPreview')
                                   .removeClass('off')
                                   .addClass('on');
                              $('#print-state span')
                                   .removeClass('glyphicon-triangle-right')
                                   .addClass('glyphicon-triangle-bottom');

                              var printProp = Actions.getPrintProperties();
                              var pv = mapVariable.calcPrintView(
                                   printProp.scale,
                                   printProp.format
                              );

                              Actions.drawPrintPreview(pv.x, pv.y);
                         } else {
                              collapsingAnimationPrintFlag = true;
                              setTimeout(function () {
                                   collapsingAnimationPrintFlag = false;
                              }, 350);

                              $(this).removeClass('activated');
                              $('#printingPreview')
                                   .removeClass('on')
                                   .addClass('off');
                              $('#print-state span')
                                   .removeClass('glyphicon-triangle-bottom')
                                   .addClass('glyphicon-triangle-right');
                         }
                    }
               } else {
                    var $printingPanel = $('#printing-panel');
                    var $printingPreview = $('#printingPreview');

                    middles = new L.layerGroup();
                    rectangles = new L.layerGroup();

                    var printingPanelActive = !$printingPanel.hasClass(
                         'hidden'
                    );

                    if (printingPanelActive) {
                         $printingPreview.removeClass('off').addClass('on');

                         var printProp = Actions.getPrintProperties();
                         var pv = mapVariable.calcPrintView(
                              printProp.scale,
                              printProp.format
                         );

                         Actions.drawPrintPreview(pv.x, pv.y);
                    } else {
                         $printingPreview.removeClass('on').addClass('off');
                    }
               }
          });

          //map zoom handler
          // map.off('zoomend')
          map.on('zoomend', function () {
               //check if print panel is active

               var redrawPrintView = !scriptParams.def.generateDoc
                    ? $('#print-state').hasClass('activated')
                    : !$('#printing-panel').hasClass('hidden');

               if (redrawPrintView) {
                    //get selected printing properties
                    var printProp = Actions.getPrintProperties();
                    var pv = mapVariable.calcPrintView(
                         printProp.scale,
                         printProp.format
                    );

                    Actions.drawPrintPreview(pv.x, pv.y);
               }
          });

          //select option listner
          $(document).off(
               'change',
               '#printingPreviewContainer #print-panel select'
          );
          $(document).on(
               'change',
               '#printingPreviewContainer #print-panel select',
               function () {
                    adjustSelectOptions($(this).attr('id'), $(this).val());

                    var printProp = Actions.getPrintProperties();
                    var pv = mapVariable.calcPrintView(
                         printProp.scale,
                         printProp.format
                    );

                    Actions.drawPrintPreview(pv.x, pv.y);
                    Actions.redrawPrintPreviews(pv.x, pv.y);
               }
          );

          function createSelectOptionsObject(values) {
               var selectOptionsObject = [];
               $.each(values, function (key, value) {
                    selectOptionsObject.push({
                         name: key,
                         value: key,
                    });
               });
               return selectOptionsObject;
          }

          function adjustSelectOptions(type, value) {
               var layoutStylesId = 'layout-styles';
               var layoutPositionsId = 'layout-orientation';
               var layoutNamesId = 'layout-name';

               var $layoutStyles = $('#' + layoutStylesId);
               var $layoutPositions = $('#' + layoutPositionsId);
               var $layoutNames = $('#' + layoutNamesId);

               var format = $layoutStyles.val();

               if (type == layoutStylesId) {
                    $layoutPositions.empty();
                    $layoutNames.empty();

                    var layoutPositions = createSelectOptionsObject(
                         formatSet[format].positions
                    );
                    addSelectOptions(layoutPositions, layoutPositionsId);

                    var position = $layoutPositions.val();
                    var formatPosition = format + ' ' + position;

                    var layoutNames = createSelectOptionsObject(
                         formatPositionSet[formatPosition].names
                    );
                    addSelectOptions(layoutNames, layoutNamesId);
               } else if (type == layoutPositionsId) {
                    $layoutNames.empty();

                    var position = $layoutPositions.val();
                    var formatPosition = format + ' ' + position;

                    var layoutNames = createSelectOptionsObject(
                         formatPositionSet[formatPosition].names
                    );
                    addSelectOptions(layoutNames, layoutNamesId);
               }
          }

          //print button handler
          $(document).off('click tap', '#print');
          $(document).on('click tap', '#print', function (e) {
               e.preventDefault();

               if (!scriptParams.def.generateDoc) {
                    //        mstools.waitingBackground();
                    loading.makeLoadingScreen(
                         '#print-panel .panel-body',
                         'Generowanie wydruku...'
                    );
               }

               var printProp = Actions.getPrintProperties();

               dialogHandler.printCallback(printProp.scale, printProp.format, {
                    widthmm: printProp.widthmm,
                    heightmm: printProp.heightmm,
                    name: printProp.name,
                    pagesize: printProp.pagesize,
                    rotation: printProp.rotation,
                    dpi: printProp.dpi,
               });
               //      dialogHandler.printCallback(printProp.scale, printProp.format, printProp.dpi)

               var radios = $("#user-radio-dialog input[type='radio']");
               radios.eq(0).attr('checked', 'checked');

               middles = new L.layerGroup();

               //$('#print-state').click();
          });

          function addPrintRectangle(rectangleCenter) {
               if (counter == 1) {
               } else {
                    $('#count').html(counter);
               }
               counter = counter + 1;

               // $(".print-select").prop('disabled', 'disabled');
               // $(".print-select[disabled]").css('background-color', '#DCDAD1');

               var icon = L.icon({
                    /*iconUrl: ROOT + (scriptParams.def.printingParams.crossHref || './ContentMap/img/plus-empty.png'),
                iconSize: [25, 25],*/
                    iconUrl: 'https://png.icons8.com/metro/1600/plus-math.png',
                    iconSize: [10, 10],
               });

               var cross = L.marker(
                    [rectangleCenter.lat, rectangleCenter.lng],
                    {
                         icon: icon,
                         draggable: false,
                    }
               );

               crosses.addLayer(cross);
               crosses.addLayer(cross);

               mapVariable.removeLayer(middles);
               middles.addLayer(cross);
               middles.addTo(map);

               var printProp = Actions.getPrintProperties();
               var pv = mapVariable.calcPrintView(
                    printProp.scale,
                    printProp.format
               );

               var latLng = L.latLng(cross.getLatLng());
               var currentPoint = map.latLngToContainerPoint(latLng);
               //  var currentPoint = map.latLngToContainerPoint(getOffsetCenter(latLng));
               var width = pv.x;
               var height = pv.y;
               var xDifference = width / 2;
               var yDifference = height / 2;
               var southWest = L.point(
                    currentPoint.x - xDifference,
                    currentPoint.y - yDifference
               );
               var northEast = L.point(
                    currentPoint.x + xDifference,
                    currentPoint.y + yDifference
               );
               var bounds = L.latLngBounds(
                    map.containerPointToLatLng(southWest),
                    map.containerPointToLatLng(northEast)
               );

               var rectangle = L.rectangle(bounds, {
                    weight: 2,
                    opacity: 1,
                    // color: '#286090',
                    //dashArray: '3',
                    fillOpacity: 0.1,
                    // fillColor: '#286090'
                    draggable: true,
               }).addTo(map);

               rectangle.off('dragend');
               rectangle.on('dragend', function (e) {
                    var rectangleCenter = e.target.getBounds().getCenter();
                    cross.setLatLng(rectangleCenter);
                    dialogHandler.printCallbackMiddles(middles);
               });

               mapVariable.removeLayer(rectangles);
               rectangles.addLayer(rectangle);
               rectangles.addTo(map);
               dialogHandler.printCallbackMiddles(middles);
          }

          //add page button handler
          $(document).off('click tap', '#printadd');
          $(document).on('click tap', '#printadd', function (e) {
               e.preventDefault();
               var mapCenter = getOffsetCenter(map.getCenter());
               addPrintRectangle(mapCenter);
          });

          //add page button handler
          $(document).off('click tap', '#print-close');
          $(document).on('click tap', '#print-close', function (e) {
               var $printingPreview = $('#printingPreview');
               var $printingPanel = $('#printing-panel');

               $printingPreview.removeClass('on').addClass('off');
               $printingPanel.addClass('hidden');
               $printingPanel.click();

               middles.clearLayers();
               rectangles.clearLayers();
               crosses.eachLayer(function (layer) {
                    map.removeLayer(layer);
               });
          });

          /* if (presetsIncluded) {
           var userChanging = true;

           $(document).on('change', '#presets', function (e) {
             var selected = $(this).find(":selected");

             if (selected.index() === 0) {
               var tree = getLocalCookie(cookieNames().tree + cookieNames().suffix);
               var preset = presetJSON(tree);
               setLayersByList(preset);
             } else {
               var preset = selected.data("value");
               setLayersByList(preset);
             }
           });

           $(document).on('change', 'input[data-layer-name]', function (e) {

             if (userChanging === true) {
               setDefaultOption();

               var tree = getLocalCookie(cookieNames().tree);
               saveLocalCookie(tree, cookieNames().tree + cookieNames().suffix);

             }

           });

           function setLayersByList(preset) {
             userChanging = false;
             var treeLayers = $("input[data-layer-name]");

             for (var i = 0; i < treeLayers.length; i++) {
               var layerName = treeLayers.eq(i).data("layer-name");
               var layerGroup = treeLayers.eq(i).data("group-name");

               var checked = false;

               for (var j = 0; j < preset.length; j++) {
                 if (((preset[j].layerName === layerName) && (preset[j].layerGroup === layerGroup)) || ((preset[j].layerName === layerName) && (preset[j].layerGroup === undefined))) {
                   inputCheck(treeLayers.eq(i));
                   checked = true;
                   break;
                 }
               }
               if (!checked) {
                 inputUncheck(treeLayers.eq(i));
               }

             }
             userChanging = true;
           }

           function cookieNames() {
             var obj = {
               tree: "layertree_" + profilId + "_" + user,
               suffix: "_preset",
             };

             return obj;
           }

           function getLocalCookie(name) {
             return JSON.parse(localStorage.getItem(name));
           }

           // function getSessionCookie(name) {
           //   return JSON.parse(sessionStorage.getItem(name));
           // }

           function saveLocalCookie(cookie, name) {
             localStorage.setItem(name, JSON.stringify(cookie));
           }

           // function saveSessionCookie(cookie, name) {
           //   sessionStorage.setItem(name, JSON.stringify(cookie));
           // }

           function setDefaultOption() {
             $('#presets')[0].selectedIndex = 0;
           }

           function inputUncheck(checkbox) {
             if (checkbox.prop("checked")) {
               checkbox.click();
             }
           }

           function inputCheck(checkbox) {
             if (!(checkbox.prop("checked"))) {
               checkbox.click();
             }
           }

           function presetJSON(tree) {
             var arr = [];

             $.each(tree, function (treeKey, treeNode) {
               $.each(treeNode, function (treeNodeKey, treeEl) {
                 if (treeEl.checked) {
                   arr.push({
                     layerName: treeNodeKey,
                     layerGroup: treeKey,
                   });
                 }
               });
             });

             return arr;
           }
         }*/

          function getOffsetCenter(latlng) {
               var pointXY = map.latLngToLayerPoint(latlng);
               pointXY.y = pointXY.y + 45;
               var pointLatLng = map.layerPointToLatLng(pointXY);

               return pointLatLng;
          }

          function reduceFeatures(features, attr, splitIndex) {
               var arr = features.map((feature) => {
                    var param =
                         splitIndex != undefined
                              ? feature[attr].split(' ')[splitIndex]
                              : feature[attr];
                    return {
                         name: param,
                         value: param,
                    };
               });

               return uniqueArray(arr);
          }

          function uniqueArray(arrayOfObjects) {
               return arrayOfObjects.filter(
                    (object, index) =>
                         index ===
                         arrayOfObjects.findIndex(
                              (obj) =>
                                   JSON.stringify(obj) ===
                                   JSON.stringify(object)
                         )
               );
          }

          /*
            $(document).on( 'click tap', '#wypisM', function(e){
                e.preventDefault();

                mapVariable.generujWypisM();
            });
            $(document).on( 'click tap', '#wypisS', function(e){
                e.preventDefault();

                mapVariable.generujWypisS();
            });*/
     };
})(jQuery);
