import L from 'leaflet';
import $ from 'jquery';

//import '../vendor/jquery-ui';

class Wybory {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.storage = window.sessionStorage;
        this.searchClass = 'wyboryPopup__body';
        this.$field = $('.wyboryPopup__body-text');
        this.$search = this.$field.closest('.' + this.searchClass);
        this.$loadingAnim = this.$search.find('.wyboryPopup__body__input-loading-anim');
        this.$foundList = this.$search.find('.wyboryPopup__body__input-found-list');
        this.requestFlag = true;
        this.timer = 0;
        this.layerID = this.getLayerID(config.layerListKeys);
        this.greenCol = '#7DA83F';
        this.blueCol = '#0080D4';
        this.activeLayersManager = settings.activeLayersManager;
        this.layerListKeys = config.layerListKeys;
        this.params = {
            textFilter: '',
            foundData: [],
        };
        var that = this;
        this.keyUpCallbacks = {
            _this: that,
            arrowUp: this.arrowUp,
            arrowDown: this.arrowDown,
            enterPress: this.enterPress
        };
        // if (config.currentComposition && config.currentComposition.webname == 'wybory') {
            if (location.pathname.indexOf('wybory') > -1) {
            // if (config.ELokalSearch) {
                if (!(window.location.href.indexOf('?') > -1)) {
                    $('.wybory__background').addClass('wybory__background--visible');
                }
                var classNames = ['wyboryPanel', 'wyboryPanel__body', 'wyboryPanel__body--item', 'wyboryPanel__body--item-text', 'wyboryPanel__title--title', 'wyboryPanel__title'];
                this.removeDisabled(classNames);
                this.hide('wyboryPanel__body--item-btn-img--disabled');
                $('.wyboryPanel__body--item-btn-img').css('display', 'inline-block');
                this.hide('wyboryPanel__title-img--disabled');
                $('.wyboryPanel__title-img').css('display', 'inline-block');
                this.guid = config.WyborcaGuid;
                this.events();
            // }
            if ($(window).width() > 768) {
                $('.wyboryPanel').css({
                    'display': 'block',
                    'z-index': 12000
                });
            }
            if ($(window).width() <= 768 && !(window.location.href.indexOf('?') > -1)) {
                $('.small-icon-wybory').addClass('si-focused');
                $('.small-icon-wybory').css({
                    'display': 'flex',
                    'background-color': this.greenCol,
                });
            } else if ($(window).width() <= 768 && (window.location.href.indexOf('?') > -1)) {

                if (!(window.location.href.indexOf('&m=w') > -1)) {
                    $('.wyboryPanel').css({
                        'display': 'none'
                    });
                    $('.small-icon-wybory').css({
                        'display': 'flex',
                    });
                }
            }
        }
    }

    hide(item) {
        $('.' + item).css('display', 'none');
    }

    removeDisabled(classNames) {
        $.each(classNames, function(i, className) {
            $('.' + className).removeClass(className + '--disabled');
        })
    }

    getLayerID(layerList) {
        var layerID;
        $.each(layerList, (x, layer) => {
            if (layer.name.includes('Lokale')) {
                layerID = layer.id;
            }
        })
        return layerID;
    }

    events() {
        var that = this;

        $('.wybory__background').on('click tap', function() {
            if ($('.wybory__background').hasClass('wybory__background--visible')) {
                $('.wybory__background').removeClass('wybory__background--visible');
            }
        })

        $('.wyboryPanel__body').on('click tap', function() {
            $('.wyboryPopup').css('max-height', '120px');
            $('.modePanel__body--btn-cancel').click();
            $('.modePanel').addClass('modePanel--active');
            $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie wyszukiwania lokalu wyborczego');
            that.storage.setItem('mode', 'wybory');
            $('.wyboryPopup').addClass('overlay--active');
            $('#wyboryPopup-text-station').val('');
            $('#wyboryPopup-text-station').select();
        })

        $('.wyboryPopup').on('click tap', function() {
            if ($(window).width() > 768 || $(window).height() > 768) {
                $("#wyboryPopup").draggable({
                    cursor: 'grab',
                    cursorAt: {
                        left: 5,
                        top: 0,
                    },
                    opacity: 0.8,
                });
            }
        })

        $('.wyboryPanel__title--title').on('click tap', function() {
            $('.wyboryPanel__body').click();
        })

        $(document).on('input', '.wyboryPopup__body__input-field-station', function(e) {
            that.updateProperties(e.target);
            that.searchValue(e);
        });

        $(document).on('click tap', 'li.sugg', function() {
            var latlng = that.getLatlngFromSugg($(this));
            that.showPointOnMap(latlng);
        });

        $(document).on('click tap', 'body', function(event) {
            that.closeSuggs(event);
        });

        $('.wybory-close').on('click tap', function() {
            $('.modePanel').removeClass('modePanel--active');
            that.hide('small-icon-modePanel');
            that.storage.setItem('mode', 'none');
        })

        $('.modePanel__body--btn-cancel').on('click tap', function() {
            $('.modePanel').removeClass('modePanel--active');
            that.hide('small-icon-modePanel');
            that.storage.setItem('mode', 'none');
            $('.wybory-close').click();
        })

        $(window).on('keyup', function(e) {
            if (that.$foundList.hasClass('list-active')) {
                var key = e.keyCode;
                switch (key) {
                    case 38: //up arrow
                        that.keyUpCallbacks.arrowUp();
                        break;
                    case 40: //down arrow
                        that.keyUpCallbacks.arrowDown();
                        break;
                    case 13: //enter
                        that.keyUpCallbacks.enterPress(e);
                        break;
                }
            }
        })

    }

    updateProperties(target) {
        this.searchClass = 'wyboryPopup__body';
        this.$field = $(target).closest('.wyboryPopup__body-text');
        this.$search = this.$field.closest('.' + this.searchClass);
        this.$loadingAnim = this.$search.find('.wyboryPopup__body__input-loading-anim');
        this.$foundList = this.$search.find('.wyboryPopup__body__input-found-list');
    }

    searchValue(e) {
        var that = this,
            queryString = $(e.target).val();
        if (queryString.length != 0) {
            clearTimeout(this.timer);
            this.timer = setTimeout(function() {
                that.sendRequest(queryString);
            }, 300);
        } else {
            this.removeChildrenIfExist();
        }
    }

    removeChildrenIfExist() {
        if (this.$foundList.children().length != 0) {
            this.$foundList.children().remove();
            this.$foundList.removeClass('list-active').addClass('list-inactive');
        }
    }

    getStation(queryString) {
        var that = this;
        this.getUrl = window.SERVER + '/mapawyborcza-v8-1/Search/ZnajdzLokalWyborczy?nazwa=' + queryString + '&guid=' + that.guid;
        return $.ajax({
            url: that.getUrl,
            crossDomain: true,
            dataType: 'jsonp',
            type: 'POST',
            async: true,
            xhrFields: {
                withCredentials: true
            },
        });
    }

    sendRequest(queryString) {
        var that = this;
        if (this.requestFlag === true) {
            this.removeChildrenIfExist();
            this.disactivateSuggestionsList();
            this.requestFlag = false;
            this.animationOn();
            var requestType = 'getStation',
                promise = that[requestType](queryString);
            $.when(promise).then(
                function successCallback(data) {
                    var queryTest = that.$field.val();
                    if (queryTest == queryString) {
                        if (requestType == 'getStation') {
                            var html = that.convertDataTableToHtml(data);
                            that.appendFoundedList(html, 'getStation');
                        }
                        that.activateSuggestionsList();
                    }
                    that.animationOff();
                    that.requestFlag = true;
                },
                function errorCallback() {
                    alert('Błąd wyszukiwania.');
                    that.animationOff();
                    that.params.noFound = true;
                    that.requestFlag = true;
                });
        }
    }

    appendFoundedList(listHtml, request) {
        this.$foundList = $($.grep(this.$foundList, x => $(x).hasClass('wyboryPopup__body__input-found-list-' + request))[0])
        this.$foundList.html(listHtml);
    }

    activateSuggestionsList() {
        this.$foundList.removeClass('list-inactive').addClass('list-active');
    }

    disactivateSuggestionsList() {
        this.$foundList.removeClass('list-active').addClass('list-inactive');
    }

    animationOn() {
        this.$loadingAnim.css({
            opacity: 1
        });
    }

    animationOff() {
        this.$loadingAnim.css({
            opacity: 0
        });
    }

    convertWebServiceToSuggObject(parcels) {
        return parcels.geonames.map(function(geoname) {
            return {
                lat: geoname.lat,
                lon: geoname.lng,
                display_name: geoname.fcodeName + ' ' + geoname.obreb,
            }
        });
    }

    convertDataTableToHtml(dataTable) {
        var htmlString = '';
        if (dataTable.length != 0) {
            var length = dataTable.length;
            for (var i = 0; i < length; i++) {
                var name = (typeof dataTable[i].display_name == 'undefined' || undefined) ? dataTable[i].name : dataTable[i].display_name,
                    html = '<li class="sugg"><span data-index="' + i + '" data-lat="' + dataTable[i].lat + '" data-lon="' + dataTable[i].lon + '">' + name + '</span></li>';
                htmlString += html;
            }
        } else {
            htmlString = '<li class="cant-be-active"><span>Nie znaleziono pasujących wyników</span></li>';
        }
        return htmlString;
    }

    getLatlngFromSugg($this) {
        var lat = $this.children('span').attr('data-lat'),
            lon = $this.children('span').attr('data-lon');
        return [lat, lon];
    }

    showPointOnMap(latlng) {
        var that = this,
            zoom = 18;
        this.map.setView(latlng, zoom, {});
        var circle = L.circleMarker(latlng, {
            radius: 10,
            color: '#ff003b',
            fillOpacity: 0.8,
            clickable: false,
            className: 'searchPointer'
        });
        this.map.addLayer(circle);
        setTimeout(function removeCircleMarker() {
            $('.searchPointer').animate({
                opacity: 0
            }, 500, 'swing', function animationCompleteCallback() {
                that.map.removeLayer(circle);
            });
        }, 16000);
        that.clickParams = {
            latlng: latlng,
            zoom: that.map.getZoom(),
            xy: {
                x: 750,
                y: 480,
            }
        };
        var promises = that.getInfo();
        Promise.all(promises).then(responses => {
            var okResponses = responses.filter(response => {
                if (response.status == 200) {
                    return response;
                }
            });
            return Promise.all(okResponses.map(res => res.text()))
        }).then(texts => {
            that.getInfoDone(texts);
        });
    }

    closeSuggs(event) {
        var $clicked = $(event.target);
        if (!$clicked.hasClass('found-list')) {
            if (this.$foundList.hasClass('list-active')) {
                this.$foundList.removeClass('list-active').addClass('list-inactive');
                this.removeChildrenIfExist();
            }
        }
    }

    getInfo() {
        var promises = [],
            layersUrls = [],
            layer = this.layerListKeys[this.layerID];
        if (layer.queryable && !layersUrls.includes(layer.queryUrl)) {
            var request = layer.queryUrl ? this.queryRequest(layer) : this.wmsRequest(layer);
            promises.push(request);
        }
        if (layer.queryUrl && !layersUrls.includes(layer.queryUrl)) {
            layersUrls.push(layer.queryUrl);
        }
        return promises;
    }

    queryRequest(layer) {
        var url = window.PROXY + this.encodeUrl(this.parseUrl(layer.queryUrl));
        return fetch(url);
    }

    wmsRequest(layer) {
        var point = this.clickParams.xy,
            layers = (layer.options.layers) ? layer.options.layers.split(',') : [],
            source = L.WMS.source(layer.url, layer.options);
        source._map = this.map;
        var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(source, point, layers),
            url = layer.url + L.Util.getParamString(wmsParams, layer.url);
        url = window.PROXY + this.encodeUrl(this.removeGwcServiceFromUrl(url));
        return fetch(url);
    }

    getInfoDone(data) {
        var html = $.trim(this.getDataHtml(data));
        if (html) {
            this.openPopup(html);
            this.initImgGallery();
        }
    }

    removeGwcServiceFromUrl(url) {
        return url.replace('gwc/service/', '');
    }

    encodeUrl(url) {
        return encodeURIComponent(url);
    }

    getDataHtml(dataArr) {
        var html = '',
            dataHtml;
        dataArr.forEach(data => {
            if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {
                dataHtml = this.getBodyHtml(data);
            } else if (/<\/ServiceException/g.test(data)) {
                dataHtml = '';
            } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                dataHtml = '';
            } else {
                dataHtml = this.parseJsonToHtml(data);
            }
            html = html.concat(dataHtml);
        });
        return html;
    }

    parseUrl(url) {
        var that = this;
        if (this.clickParams.latlng instanceof Array) {
            url = url.replace(/%lat%/g, that.clickParams.latlng[0]).replace(/%lon%|%lng%/g, that.clickParams.latlng[1]).replace(/%zoom%/g, this.clickParams.zoom);
        } else {
            url = url.replace(/%lat%/g, that.clickParams.latlng.lat).replace(/%lon%|%lng%/g, that.clickParams.latlng.lng).replace(/%zoom%/g, this.clickParams.zoom);
        }
        return url;
    }

    parseJsonToHtml(data) {
        var html = '';
        try {
            var json = JSON.parse(data);
            if (json.popup) {
                html = json.popup;
            }
        } catch (err) {
            html = data;
        }
        return html;
    }

    openPopup(html) {
        return L.popup()
            .setLatLng(this.clickParams.latlng)
            .setContent(html)
            .openOn(this.map);
    }

    initImgGallery() {
        var photos = $("a[rel=photo]");
        photos.fancybox();
    }

    enterPress(e) {
        var that = this._this,
            selectedIndex = that.$foundList.children('li.sugg-active').index();
        if (selectedIndex != -1) {
            var lat = that.$foundList.children('li.sugg-active').children('span').attr('data-lat'),
                lon = that.$foundList.children('li.sugg-active').children('span').attr('data-lon'),
                latlng = L.latLng(parseFloat(lat), parseFloat(lon));
            that.showPointOnMap(latlng);
            that.closeSuggs(e)
        }
    }

    arrowUp() {
        var that = this._this;
        if (that.$field.is(':focus')) {
            var selectedIndex = that.$foundList.children('li.sugg-active').index();
            if (selectedIndex != 0) { //if can be active
                that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                var $newActive = that.$foundList.children('li').eq(selectedIndex - 1);
                $newActive.addClass('sugg-active');
                var text = $newActive.children('span').text();
                that.$field.val(text);
                that.setVisible($newActive, false);
            }
        }
    }

    arrowDown() {
        var that = this._this,
            $newActive, text;
        if (that.$field.is(':focus')) {
            var downArrowPressBool = true,
                selectedIndex = that.$foundList.children('li.sugg-active').index();
            if (selectedIndex == -1) {
                if (!that.$foundList.children('li').eq(0).hasClass('cant-be-active')) {
                    that.$foundList.children('li').eq(0).addClass('sugg-active');
                }
            } else {
                if (!that.$foundList.children('li').eq(selectedIndex + 1).hasClass('cant-be-active')) {
                    that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                    $newActive = that.$foundList.children('li').eq(selectedIndex + 1);
                    $newActive.addClass('sugg-active');
                    text = $newActive.children('span').text();
                    that.$field.val(text);
                    that.setVisible($newActive, downArrowPressBool);
                } else {
                    that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                    $newActive = that.$foundList.children('li').eq(0);
                    $newActive.addClass('sugg-active');
                    text = $newActive.children('span').text();
                    that.$field.val(text);
                    that.actions.setVisible($newActive, downArrowPressBool);
                }
            }
        }
    }

    setVisible($activeListElem, downArrowPressBool) {
        var that = this;
        try {
            var elemPositionTop = $activeListElem.position().top,
                elemHeight = $activeListElem.height(),
                scrollTop = that.$foundList.scrollTop(),
                containerHeight = that.$foundList.height(),
                diff = containerHeight - elemPositionTop;
            if (downArrowPressBool === true) {
                if (diff < elemHeight) {
                    that.$foundList.scrollTop(scrollTop + (2 * elemHeight));
                }
            } else {
                if (diff > containerHeight) {
                    that.$foundList.scrollTop(scrollTop - (2 * elemHeight));
                }
            }
        } catch (e) {
            that.$foundList.scrollTop(0);
        }
    }

}

export default Wybory;