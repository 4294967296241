import Tools from './Tools';
import $ from 'jquery';
var tools = new Tools();

class PageStyle {

    constructor(config) {
        this.pageStyleClass = tools.getPropValue(config, 'style.class');
        this.setPageStyle();
    }

    setPageStyle() {
        if (this.pageStyleClass) {
            $('body').addClass(this.class);
        }
    }

}

export default PageStyle;