import L from 'leaflet';
import $ from 'jquery';

class eService_addressInfo {

    constructor(config, settings, lp) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.lp = lp;
        this.storage = window.sessionStorage;
        this.states = settings.states;
        this.treeStructure = config.treeStructure;
        this.bases = config.bases;
        this.infoIcon = L.icon({
            iconUrl: './assets/images/icons/bt_info.png',
            iconSize: [30, 30],
        });
        this.infoCursor = L.marker([0, 0], {
            icon: this.infoIcon
        });
        this.layerIDs = this.getLayerIDs(config.layerListKeys);
        this.addServiceBtn();
        this.activeLayersManager = settings.activeLayersManager;
        this.layerListKeys = config.layerListKeys;
        this.events();
    }

    getLayerIDs(layerList) {
        var layerIDs = [];
        $.each(layerList, (x, y) => {
            if (y.name.includes('Adresy')) {
                layerIDs.push(y.id);
            }
        })
        return layerIDs;
    }

    events() {
        var that = this;
        this.map.on('click', function(e) {
            if (that.states.es_onInfo_ai) {
                that.clickParams = {
                    latlng: e.latlng,
                    zoom: that.map.getZoom(),
                    xy: e.containerPoint,
                };
                var promises = that.getInfo();
                Promise.all(promises).then(responses => {
                    var okResponses = responses.filter(response => {
                        if (response.status == 200) {
                            return response;
                        }
                    });
                    return Promise.all(okResponses.map(res => res.text()))
                }).then(texts => {
                    that.getInfoDone(texts);
                });
            }
        });

        that.map.on('mousemove', function(e) {
            that.infoCursor.setLatLng([e.latlng.lat, e.latlng.lng]);
        });

        $('.modePanel__body--btn-cancel').on('click', function() {
            $('.modePanel').removeClass('modePanel--active');
            $('.small-icon-modePanel').css('display', 'none');
            that.infoCursor.removeFrom(that.map);
            that.storage.setItem('mode', 'none');
            $('.eservice-close').click();
        })
    }

    addServiceBtn() {
        var that = this,
            serviceDiv = $('<div>', {
                html: `<div class="eservicePanel__body--item-text">Wyświetlanie informacji o punktach adresowych</div>
<div class="eservice-btn eservicePanel__body--item-btn" id=""><img class="eservicePanel__body--item-btn-img" src="assets/images/eservices/eservice_addressinfo.png"/></div>`,
                class: 'eservicePanel__body--item',
                id: 'eService__addressInfo'
            });

        $('.eservicePanel__body').append(serviceDiv);

        $('#eService__addressInfo').on('click', function() {
            $('.modePanel__body--btn-cancel').click();
            that.states.es_onInfo_ai = true;
            that.states.es_onInfo_ti = false;
            that.states.es_onInfo_pi = false;
            that.states.onInfo = false;
            that.emptyPopup();
            that.infoCursor.addTo(that.map);
            $('.modePanel').addClass('modePanel--active');
            $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie informacji o punktach adresowych');
            that.storage.setItem('mode', 'es-ia');
        })
    }

    emptyPopup() {
        $('.eservicePopup').removeClass('overlay--active');
        $('.eservicePopup__title').text('');
        $('.eservicePopup__body').html(``);
    }

    getInfo() {
        var activeLayersIds = this.layerIDs,
            promises = [],
            layersUrls = [];
        activeLayersIds.forEach(activeLayerId => {
            var layer = this.layerListKeys[activeLayerId];
            if (layer.queryable && !layersUrls.includes(layer.queryUrl)) {
                var request = layer.queryUrl ? this.queryRequest(layer) : this.wmsRequest(layer);
                promises.push(request);
            }
            if (layer.queryUrl && !layersUrls.includes(layer.queryUrl)) {
                layersUrls.push(layer.queryUrl);
            }
        });
        return promises;
    }

    queryRequest(layer) {
        var url = window.PROXY + this.encodeUrl(this.parseUrl(layer.queryUrl));
        return fetch(url);
    }

    wmsRequest(layer) {
        var point = this.clickParams.xy,
            layers = layer.options.layers.split(','),
            source = L.WMS.source(layer.url, layer.options);
        source._map = this.map;
        var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(source, point, layers),
            url = layer.url + L.Util.getParamString(wmsParams, layer.url);
        url = window.PROXY + this.encodeUrl(this.removeGwcServiceFromUrl(url));
        return fetch(url);
    }

    encodeUrl(url) {
        return encodeURIComponent(url);
    }

    parseUrl(url) {
        let {
            lat,
            lng
        } = this.clickParams.latlng;
        url = url.replace(/%lat%/g, lat)
            .replace(/%lon%|%lng%/g, lng)
            .replace(/%zoom%/g, this.clickParams.zoom);
        return url;
    }

    removeGwcServiceFromUrl(url) {
        return url.replace('gwc/service/', '');
    }

    getInfoDone(data) {
        var html = $.trim(this.getDataHtml(data));
        if (html) {
            this.openPopup(html);
        }
    }

    openPopup(html) {
        return L.popup()
            .setLatLng(this.clickParams.latlng)
            .setContent(html)
            .openOn(this.map);
    }

    parseJsonToHtml(data) {
        var html = '';
        try {
            var json = JSON.parse(data);
            if (json.popup) {
                html = json.popup;
            }
        } catch (err) {
            html = data;
        }
        return html;
    }

    getDataHtml(dataArr) {
        var html = '',
            dataHtml;
        dataArr.forEach(data => {
            if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {
                dataHtml = this.getBodyHtml(data);
            } else if (/<\/ServiceException/g.test(data)) {
                dataHtml = '';
            } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                dataHtml = '';
            } else {
                dataHtml = this.parseJsonToHtml(data);
            }
            html = html.concat(dataHtml);
        });
        return html;
    }

    getBodyHtml(html) {
        var match = html.match(/<body>[\s\S]*?<\/body>/g);
        if (match && match[0]) {
            html = match[0].replace('<body>', '').replace('</body>', '');
        } else {
            return '';
        }
        return (/\S/g.test(html)) ? html : '';
    }

}

export default eService_addressInfo;