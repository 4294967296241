import $ from 'jquery';

class ChangeFontSize {
     constructor(config) {
          this.tools = config.MapConf.tools || {};
          if (config.MapConf.ChangeFontSize) {
               this.sizes = [10, 12, 13];
               this.createFontSizeBar(this.sizes);
          }
     }

     createFontSizeBar(sizes) {
          var that = this,
               $container = $('.bar-top__fontSize');
          $.each(sizes, function (i, size) {
               $container.append(
                    `<span class="bar-top__fontSize--item bar-top__fontSize--item-` +
                         size +
                         `" title="zmień rozmiar czcionki" data-toggle="tooltip" data-placement="bottom"></span>`
               );
               var symbol = 'A';
               for (var j = 1; j <= i; j++) {
                    symbol += '<sup>+</sup>';
               }
               $('.bar-top__fontSize--item-' + size).append(symbol);
               if ($(window).width() > 767) {
                    $('.bar-top__fontSize--item-' + size)
                         .css({
                              'font-size': size,
                         })
                         .css({
                              'font-size': '+=5px',
                         })
                         .on('click tap', function () {
                              that.changeFontSize(size);
                         });
               } else if ($(window).width() > 479) {
                    $('.bar-top__fontSize--item-' + size)
                         .css({
                              'font-size': size,
                         })
                         .css({
                              'font-size': '+=30px',
                         })
                         .on('click tap', function () {
                              that.changeFontSize(size);
                         });
               } else {
                    $('.bar-top__fontSize--item-' + size)
                         .css({
                              'font-size': size,
                         })
                         .css({
                              'font-size': '+=20px',
                         })
                         .on('click tap', function () {
                              that.changeFontSize(size);
                         });
               }
          });
     }

     changeFontSize(size) {
          $('html').css({
               fontSize: size,
          });
          if (size > 10) {
               $('.leaflet-control-layers').addClass(
                    'leaflet-control-layers-fontChanged'
               );
               $('.leaflet-control-layers-overlays__body').addClass(
                    'leaflet-control-layers-overlays__body-fontChanged'
               );
          } else {
               $('.leaflet-control-layers').removeClass(
                    'leaflet-control-layers-fontChanged'
               );
               $('.leaflet-control-layers-overlays__body').removeClass(
                    'leaflet-control-layers-overlays__body-fontChanged'
               );
          }
     }
}

export default ChangeFontSize;
