// tlumaczenie strony
import $ from 'jquery';

class Tools {
    constructor() {}

    $elFromClass(className) {
        return $('.' + className);
    }

    createIcon(className) {
        var $icon = $('<i>', {
            class: 'fa ' + className,
            'aria-hidden': true,
        });
        return $icon;
    }

    createElPropertiesFromClass(className) {
        return {
            class: className,
            selector: '.' + className,
            $el: $('.' + className),
        };
    }

    // TODO: deep copy
    extend() {
        $.extend.apply(null, arguments);
    }

    joinSelectors(selectors) {
        return selectors.join(', ');
    }

    objectToArray(obj) {
        var arr = [];
        $.each(obj, function(key, value) {
            if (value) {
                arr.push(key);
            }
        })
        return arr;
    }

    arrayToObject(array, keyField) {
        return array.reduce((obj, item) => {
            obj[item[keyField]] = item;
            return obj
        }, {});
    }

    getPropValue(obj, key) {
        return key.split(".").reduce(function(o, x) {
            return (typeof o == "undefined" || o === null) ? o : o[x];
        }, obj);
    }

    createNestedProp(obj, props, value) {
        var propsValues = [];
        for (var i = 1; i < props.length; i++) {
            var newObj = {},
                newValue = (i === props.length - 1) ? value : {};
            newObj[props[i]] = newValue;
            propsValues.push(newObj);
        }
        var counter = 0;
        for (var j = propsValues.length - 1; j >= 1; j--) {
            propsValues[j - 1][props[props.length - 2 - counter]] = propsValues[j];
            counter++;
        }
        return propsValues[0];
    }

    rewriteObjectKeys(obj) {
        var that = this,
            newObj = {};
        $.each(obj, function(key, value) {
            var keyParts = key.split('_'),
                prop = keyParts[0];
            if (keyParts.length > 1) {
                var nestedObj = that.createNestedProp(newObj, keyParts, value);
                if (!newObj[prop]) {
                    newObj[prop] = nestedObj;
                } else {
                    that.extend(newObj[prop], nestedObj);
                }
            } else {
                newObj[key] = value;
            }
        });
        return newObj;
    }

    replaceUrlParams(url, options) {
        var newOptions = options,
            newUrl = url;
        if (url.indexOf('?') != -1) {
            var params = url.substring(url.indexOf('?') + 1).split('&');
            params = params.filter(param => param.includes('='));
            var paramsObj = {};
            params.forEach(param => {
                var keyValue = param.split('=');
                paramsObj[keyValue[0]] = keyValue[1];
                newUrl = newUrl.replace(param, '');
            });
            $.each(options, (key) => {
                var optionsKey = key.toLowerCase();
                $.each(paramsObj, (key2) => {
                    var paramsKey = key2.toLowerCase();
                    if (optionsKey == paramsKey) {
                        delete options[key];
                    }
                });
            });
            newOptions = {...options,
                ...paramsObj
            };
        }
        return {
            url: newUrl,
            options: newOptions,
        };
    }

    sortArgs(args) {
        return $.makeArray(args).sort();
    }
}

export default Tools;