import Tools from './Tools';
import $ from 'jquery';
import '../vendor/leaflet.print-src';
import '../vendor/printing_preview_tool';
import Loading from './Loading';
var tools = new Tools();

class Print {
	constructor(config, settings) {
		this.toolsClass = settings.toolsClass;
		this.storage = window.sessionStorage;
		this.printProps = tools.createElPropertiesFromClass(
			this.toolsClass + '__print'
		);
		this.map = settings.map;
		this.states = settings.states;
		this.states.onPrint = false;
		this.loading = new Loading(config, settings);
        this.table = [];
        // this.parcels = settings.search.selectedParcels;
        this.parcels = window.selectedParcels;
		this.params = {
			opts: {
				layoutStyles: true,
				layoutPositions: true,
				layoutNames: true,
				scales: true,
				dpis: true,
			},
		};
		// if (config.Guid != null) {
		//     $('#map').printPreview('.print-container', this.map, this.table, this.params, this.loading);
		//     this.events();
		// }
		this.preview(config, this.map);
	}

	events() {
		var that = this;

		$(document).off('click tap', this.printProps.selector);
		$(document).on('click tap', this.printProps.selector, function () {
			that.states.onPrint = !that.states.onPrint;
			that.states.onInfo = !that.states.onInfo;
			if (!$('.printPopup').hasClass('overlay--active')) {
				$('#printingPreview').removeClass('off');
				$('#printingPreview').addClass('on');
				if (!$('#print-panel').hasClass('in')) {
					$('#print-state').trigger('click');
				}
				$('#print-state').attr('aria-expanded', 'true');
				$('.modePanel').addClass('modePanel--active');
				$('.modePanel__body--communicate').text(
					'Uwaga! Jesteś w trybie generowania wydruku'
				);
				that.storage.setItem('mode', 'print');
				$('.printPopup').addClass('overlay--active');
			} else {
				$('#printingPreview').addClass('off');
				$('#printingPreview').removeClass('on');
				$('.modePanel').removeClass('modePanel--active');
				if ($('#print-panel').hasClass('in')) {
					$('#print-state').trigger('click');
				}
				if ($(window).width() < 767) {
					$('.responsive-icon-d').css('display', 'flex');
				}
				$('.printPopup').removeClass('overlay--active');
				that.storage.setItem('mode', 'none');
			}
		});

		$(document).off('click tap', '.printPopup-close');
		$(document).on('click tap', '.printPopup-close', function () {
			if (!$('#print-panel').hasClass('in')) {
				$('#print-state').trigger('click');
			}
			$('.modePanel').removeClass('modePanel--active');
			$('.small-icon-modePanel').css('display', 'none');
			$('#printingPreview').addClass('off');
			$('#printingPreview').removeClass('on');
			$(that.printProps.selector)
				.find('.tools__tool-icon')
				.removeClass('tools__tool-icon-focused');
			$(that.printProps.selector)
				.find('.tools__tool-icon')
				.parent()
				.removeClass('tools__tool-focused');
			$('.modePanel__body--btn-cancel').click();
			if ($(window).width() <= 768) {
				$('.responsive-icon-d').css('display', 'flex');
			}
			that.states.onPrint = false;
			that.states.onInfo = true;
		});

		$('.modePanel__body--btn-cancel').off('click tap');
		$('.modePanel__body--btn-cancel').on('click tap', function () {
			$('.tools__tool-icon-focused').click();
			$('.modePanel').removeClass('modePanel--active');
			$('.small-icon-modePanel').css('display', 'none');
			that.storage.setItem('mode', 'none');
		});

		$('.printPopup').off('click tap');
		$('.printPopup').on('click tap', function () {
			if ($(window).width() > 768 || $(window).height() > 768) {
				$(this).draggable({
					cursor: 'grab',
					cursorAt: {
						left: 5,
						top: 0,
					},
					opacity: 0.8,
				});
			}
		});
	}

	reduceFeatures(features, attr, splitIndex) {
		var arr = features.map((feature) => {
			var param =
				splitIndex != undefined
					? feature[attr].split(' ')[splitIndex]
					: feature[attr];
			return {
				name: param,
				value: param,
			};
		});
		return this.uniqueArray(arr);
	}

	uniqueArray(arrayOfObjects) {
		return arrayOfObjects.filter(
			(object, index) =>
				index ===
				arrayOfObjects.findIndex(
					(obj) => JSON.stringify(obj) === JSON.stringify(object)
				)
		);
	}

	preview(config, map, token) {
		if (token) {
			// $('#map').printPreview().remove()
		}
		if (config.Guid != null) {
			var params = {
				opts: {
					layoutStyles: true,
					layoutPositions: true,
					layoutNames: true,
					scales: true,
					dpis: true,
				},
            };
			$('#map').printPreview(
				'.print-container',
				map,
				[],
				params,
				this.loading,
				token
			);
			this.events();
		}
	}
}

export default Print;
