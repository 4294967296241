import $ from 'jquery';

class SideLinkButtons {
    constructor(config) {
        var that = this;
        if (config.buttons) {
            $('.sideLinkButtonPanel').addClass('sideLinkButtonPanel--visible')
        }
        this.buttons = JSON.parse(config.buttons)
        $.each(this.buttons, function(i, v) {
            if (v.link) {
                that.addLink(i, v.title, v.href, v.icon)
            } else {
                that.addLink(i, v.title, v.href, v.icon, v.id)
            }
        })
    }

    checkIcon(icon) {
        if (icon.indexOf('<i') > -1) {
            icon = icon.replace('<i class=', '').replace('>', '').replace('</i>', '').replace(/'/g, '').replace(/"/g, '')
        } else if(icon.indexOf('"') > -1) {
            icon = icon.replace(/"/g, '')
        } else if(icon.indexOf("'") > -1) {
            icon = icon.replace(/'/g, '')
        }
        return icon;
    }

    addLink(num, title, url, icon, id) {
        icon = this.checkIcon(icon)
        var htmlTemplateLink = `<div class="sideLinkButtonPanel__link-show` + ($(window).width() < 768 ? ` sideLinkButtonPanel__link-show-visible ` : ` `) + `sideLinkButtonPanel__link-show` + num + `">
<i class="fa fa-angle-double-left"></i>
<i class="` + icon + `"></i>
</div>
<div class="sideLinkButtonPanel__link sideLinkButtonPanel__link` + num + ($(window).width() < 768 ? ` sideLinkButtonPanel__link--hidden` : ``) + `">
<i class="fa fa-angle-double-right sideLinkButtonPanel__link-hide sideLinkButtonPanel__link-hide` + num + `"></i>
<a href="` + url + `" target="_blank" ` + (id ? `id="` + id + `"` : ``) + `>
<i class="` + icon + `">
</i>` + title + `</a></div>`

        $('.sideLinkButtonPanel').append(htmlTemplateLink)

        $('#formularzeEpuap').on('click', function(e) {
            e.preventDefault()

            if(window.location.href.includes('srem')) {
            var html = 
            // <img class="epuapPopup__container--img" src="http://www.sip.srem.pl/img/epuap2_logo.jpg" />

           ` <div class="epuapPopup__container--item">
                <a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/najczesciej-zalatwiane-sprawy/wypisy-i-wyrysy-z-miejscowego-planu-zagospodarowania-przestrzennego/UMSrem" target="_blank">                <i class="fas fa-file-signature"></i>
                Złóż wniosek o wydanie wypisu i wyrysu z MPZP</a>
            </div>
            <div class="epuapPopup__container--item">
                <a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/najczesciej-zalatwiane-sprawy/zaswiadczenia-o-przeznaczeniu-terenu-w-planie-miejscowym/UMSrem" target="_blank">                <i class="fas fa-file-signature"></i>
                Złóż wniosek o wydanie zaświadczenia o przeznaczeniu terenu w MPZP</a>
            </div>
            <div class="epuapPopup__container--item">
                <a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/nabycie-nieruchomosci-lub-lokalu-komunalnego/zaswiadczenie-z-rejestru-numerow-porzadkowych-nieruchomosci/UMSrem" target="_blank">                <i class="fas fa-file-signature"></i>
                Złóż wniosek o wydanie zaświadczenia z rejestru numerów porządkowych nieruchomości</a>
            </div>
            `
            } else if(window.location.href.includes('dobroszyce_mpzp')) {
                var html = 
                // <img class="epuapPopup__container--img" src="http://www.sip.srem.pl/img/epuap2_logo.jpg" />
               ` <div class="epuapPopup__container--item">
                    <a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/najczesciej-zalatwiane-sprawy/wypisy-i-wyrysy-z-miejscowego-planu-zagospodarowania-przestrzennego/UMDobroszyce" target="_blank">                <i class="fas fa-file-signature"></i>
                    Złóż wniosek o wydanie wypisu i wyrysu z MPZP</a>
                </div>
                <div class="epuapPopup__container--item">
                    <a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/najczesciej-zalatwiane-sprawy/zaswiadczenia-o-przeznaczeniu-terenu-w-planie-miejscowym/UMDobroszyce" target="_blank">                <i class="fas fa-file-signature"></i>
                    Złóż wniosek o wydanie zaświadczenia o przeznaczeniu terenu w MPZP</a>
                </div>
                <div class="epuapPopup__container--item">
                    <a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/uzyskanie-zaswiadczen-i-opinii-w-zakresie-planowania-przestrzennego/wydanie-zaswiadczenia-ze-studium-uwarunkowan-i-kierunkow-zagospodarowania-przestrzennego/911-17-77-340" target="_blank">                <i class="fas fa-file-signature"></i>
                    Złóż wniosek o wydanie zaświadczenia o przeznaczeniu terenu w Studium</a>
                </div>
                <div class="epuapPopup__container--item">
                    <a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/nabycie-nieruchomosci-lub-lokalu-komunalnego/zaswiadczenie-z-rejestru-numerow-porzadkowych-nieruchomosci/UMDobroszyce" target="_blank">                <i class="fas fa-file-signature"></i>
                    Złóż wniosek o wydanie zaświadczenia z rejestru numerów porządkowych nieruchomości</a>
                </div>
                `
            }
            $('.epuapPopup').addClass('overlay--active')
            $('.epuapPopup__container').html(html)
        })

        $(document).on('click', '.sideLinkButtonPanel__link-hide' + num, function() {
            /*  $('.sideLinkButtonPanel__link' + num).css({
                 'display': 'none'
             }) */
            $('.sideLinkButtonPanel__link' + num).addClass('sideLinkButtonPanel__link--hidden')
            $('.sideLinkButtonPanel__link-show' + num).css({
                'display': 'flex'
            })
        })

        $(document).on('click', '.sideLinkButtonPanel__link-show' + num, function() {
            /* $('.sideLinkButtonPanel__link' + num).css({
                'display': 'flex'
            }) */
            $('.sideLinkButtonPanel__link' + num).removeClass('sideLinkButtonPanel__link--hidden')
            $('.sideLinkButtonPanel__link-show' + num).css({
                'display': 'none'
            })
        })

    }

    createHtmlImgs(imgs) {
        var html = ``;
        $.each(imgs, function(i, img) {
            html += '<img class="top-logos__body--imgs-img" src=' + img + ' />'
        })
        return `<div class="top-logos__body--imgs">` + html + `</div>`;
    }

}

export default SideLinkButtons;