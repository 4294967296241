import Dictionary from './Dictionary';
import $ from 'jquery';

// TODO: poprawa skryptu, wieksza uniwersalnosc
// tlumaczenie strony
class Translate {

    constructor(config) {
        this.settings = {};
        this.params = {
            flagsImg: '../img/flags.png',
            name: 'langPanel',
            full: {
                pl: 'polski',
                cs: 'český',
                en: 'english',
            },
            sessionName: 'lang',
        }
        if (config.MapConf.JezykAng) {
            this.init({
                google: {
                    languages: [],
                },
                manually: {
                    //          languages: config.MapConf.languages,
                    //          languages: config.MapConf.Jezyki,
                    languages: ['pl', 'en'],
                    dictionary: new Dictionary().translations,
                }
            });
        }
    }

    addLangWrapper() {
        var $panel = $('<div>', {
            'class': this.params.name + ' ' + this.params.langPanelWrapper,
        });
        this.settings.$insertIn.append($panel);
    }

    addLangPanel(options) {
        var $panel = $('.' + this.params.langPanelWrapper);
        for (var i = 0; i < this.settings.languages.length; i++) {
            var $lang = $('<div>', {
                class: this.params.name + ' ' + this.params.langPanelFlag,
                // src: this.params.flagsImg,
                'data-lang': this.settings.languages[i],
                'data-mode': options.mode,
                'data-toggle': 'tooltip',
                'data-placement': 'auto bottom',
                title: this.params.full[this.settings.languages[i]],
            });
            $panel.prepend($lang);
        }
    }

    saveLangToSession(lang) {
        sessionStorage.setItem(this.params.sessionName, lang);
    }

    deleteLangSession() {
        delete sessionStorage[this.params.sessionName];
    }

    translatePageManually(from, to) {
        if (this.settings.dictionary) {
            // var $elsToTranslate = $('[data-translate]'),
               var $elsToShow = $('[data-translate-show]');
            $.each(this.settings.dictionary[to], function(key, text) {
                var $elToTranslate = $('[data-translate="' + key + '"]');
                if ($elToTranslate.length > 0) {
                    if ($elToTranslate[0].tagName === 'INPUT') {
                        $elToTranslate.attr('placeholder', text);
                    } else {
                        $elToTranslate.html(text);
                    }
                }
            });

            $elsToShow.each(function() {
                var $el = $(this);
                if ($el.data('translate-show') === to) {
                    $el.removeClass('hidden');
                } else {
                    $el.addClass('hidden');
                }
            });

            this.saveLangToSession(to);
        }
    }

    addListeners() {
        var that = this;

        $(document).on('click tap', '[data-mode="manually"]', function() {
            var $flag = $(this),
                newLang = $flag.data('lang');
            that.translatePageManually(that.settings.currentLang, newLang);
        });

    }

    sessionTranslate() {
        if (sessionStorage[this.params.sessionName]) {
            this.translatePageManually(this.settings.defaultLang, sessionStorage[this.params.sessionName]);
        }
    }

    init(options) {
        this.settings = {
            languages: options.manually.languages || [],
            $insertIn: $('#' + this.params.name),
            currentLang: 'pl',
            defaultLang: 'pl',
            dictionary: options.manually.dictionary,
        };
        this.params.langPanelWrapper = this.params.name + '-wrapper';
        this.params.langPanelFlag = this.params.name + '-flag';
        this.addLangWrapper();
        this.addLangPanel({
            mode: 'manually',
        });
        this.addListeners();
        this.sessionTranslate();
    }

}

export default Translate;