import Tools from './Tools';
import $ from 'jquery';

var tools = new Tools();

// pobierz opcje mapy
class CenterView {

    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.mapSettings = config.MapConf;
        this.mapSettings.center = [config.MapConf.Lat, config.MapConf.Lng];
        this.mapSettings.zoom = config.MapConf.ZoomMapy;
        this.centerViewProps = tools.createElPropertiesFromClass(this.toolsClass + '__centerView');
        this.events();
    }

    events() {
        var that = this;
        $(document).on('click tap', this.centerViewProps.selector, function() {
            that.centerView();
        });
    }

    centerView() {
        this.map.setView(this.mapSettings.center, this.mapSettings.zoom, {
            animate: true,
        });
    }

}

export default CenterView;