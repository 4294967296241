import $ from 'jquery';

class Loading {

    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.mapSettings = config.MapConf;
        this.spinner = `<div class="spin-animation" id="spin">
<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div><p class="spin-animation__text"></p>
</div>`;
    }

    makeLoadingScreen(containerSelector, text) {
        $(this.spinner).appendTo(containerSelector)
        $(".spin-animation__text").text(text);
    }

    removeLoadingScreen(containerSelector) {
        $(containerSelector + ' .spin-animation').remove();
    }

}

export default Loading;