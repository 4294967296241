import $ from 'jquery';

// pobierz opcje mapy
class GetObjectFromPermalink {

    //DANE Z PERMALINKU
    getObjectFromPermalink() {
        var url = window.location.href,
            obj = {};
        if (url.indexOf('?') > -1) {
            var attributesArray = url.substr(url.indexOf('?') + 1, url.length).split('&');
            $.each(attributesArray, function(index, attribute) {
                obj[attribute.split('=')[0]] = attribute.split('=')[1];
            })
        }
        //obj {zoom: 13, lat: ..., lng: ..., l: 43,76}
        return obj;
    }

}

export default GetObjectFromPermalink;