import L from "leaflet";
import "../vendor/jquery_form";
import changeFileInputsModal from "./changeFileInputsModal.js";


import $ from "jquery";

//metoda dla obsługi pola input-file w modalu



class FormCreator {
  constructor(config, settings) {
    if (config.Guid != null && config.zgloszenieGuid != null) {
      this.map = settings.map;
      this.editableLayer = this.map.editableLayer;
      this.editGeometryFirst = config.zgloszenieEdycjaGeom;

      this.storage = window.sessionStorage;
      if (config.zgloszenieGrupa) {
        this.grupa = config.zgloszenieGrupa;
      }
      if (config.zgloszenieGuid != null) {
        this.Guid = config.zgloszenieGuid;
        if ($(window).width() > 768) {
          $(".formPanel").css("display", "flex");
        } else {
          $(".formPanel-show").css("display", "flex");
        }
      } else {
        $(".formPanel-show").css("display", "none");
      }
      $(window).resize(function () {
        if (config.zgloszenieGuid != null) {
          if ($(window).width() > 768 && $(window).height() > 768) {
            $(".formPanel").css("display", "flex");
          } else if ($(window).width() > 768) {
            $(".formPanel").css("display", "flex");
          } else {
            $(".formPanel").css("display", "none");
            $(".formPanel-show").addClass("responsive-icon-d");
            $(".formPanel-show").css("display", "flex");
          }
        } else {
          $(".formPanel").css("display", "none");
          $(".formPanel-show").css("display", "none");
        }
      });
      this.states = settings.states;
      this.states.onForm = false;
      this.states.onGeomEdit = false;
      var url = config.zgloszenieGrupa
        ? window.SERVER +
        window.RejROOT +
        window.RejActionList +
        this.Guid +
        "?group=" +
        this.grupa
        : window.SERVER + window.RejROOT + window.RejActionList + this.Guid;
      this.zgloszeniaCreatorSettings = {
        notices: {
          fromServer: true,
          ajax: {
            url: url
          },
          noticeUrl: window.SERVER + window.RejROOT + window.RejNoticeUrlAction
        }
      };
      this.o = {
        notices: {
          fromServer: false,
          // wlasciwosci przy pobieraniu formularzy z ajaxa
          ajax: {},
          // wlasciwosci przy pobieraniu z konfiguratora
          list: [],
          //        noticeUrl: (window.proxyhost || '') + (window.serverroot || '') + 'Zgloszenie2/CreatePublic2/',
          noticeUrl: window.SERVER + window.RejROOT + window.RejNoticeUrlAction,
          successMsg: "ok"
        },
        texts: {
          btn: "Dodaj zgłoszenie",
          error: "Błąd przesyłania formularza",
          addTitle: "Dodaj zgłoszenie: ",
          drawAlertPoint: "<b>Kliknij</b> na mapie, aby dodać Punkt",
          drawAlertLinePoly:
            "<b>Kliknij dwukrotnie</b> na mapie, aby zakończyć rysowanie",
          noNotices: "Brak zgłoszeń do dodania",
          chooseNoticeType:
            "Dla której kategorii chcesz przesłać zgłoszenie?</br>Wskaż, poprzez kliknięcie na granatową ikonkę.",
          sendSuccess: "Zgłoszenie zostało zarejestrowane",
          ok: "OK",
          cancel: "Anuluj"
        },
        icons: {
          point: "fas fa-map-marker-alt fa-2x",
          line: "fas fa-arrows-alt-h fa-2x",
          poly: "fas fa-vector-square fa-2x",
          noGeom: "fab fa-wpforms fa-2x"
        }
      };
      this.values = {
        classNames: {
          popup: "formPopup__body",
          notices: "notices",
          map: "x-panel-body.olMap",
          validationErrors: "validation-summary-errors",
          drawAlert: "draw-alert"
        }
      };
      $.fn.tagName = function () {
        var tagName = this[0].tagName;
        if (tagName != undefined) {
          return tagName.toLowerCase();
        } else {
          return tagName;
        }
      };
      window.mstools.changeCheckboxes = function () {
        var inputs = $('.formPopup input[type="checkbox"]');

        $.each(inputs, function (i, input) {
          if (
            !$(input)
              .parent()
              .hasClass("radio-primary")
          ) {
            //!change div to label elem
            let label = $(
              '<label class="checkbox-primary"></label>'
            ).insertAfter(input);
            if (input.hasAttribute("id")) {
              label.attr("for", $(input).attr("id"));
            }
            //!---
          }
        });
      };
      window.mstools.changeRadiobtns = function () {
        var inputs = $('.formPopup input[type="radio"]');
        $.each(inputs, function (i, input) {
          if (
            !$(input)
              .parent()
              .hasClass("radio-primary")
          ) {
            $('<div class="radio-primary"></div>').insertAfter(
              $(input).attr("id")
            );
          }
        });
      };
      this.mstools = window.mstools;
      this.zgloszeniaCreator();
      this.markerHandler = new L.Draw.Marker(
        this.map,
        this.map.drawCtrl.options.marker
      );
      this.polylineHandler = new L.Draw.Polyline(
        this.map,
        this.map.drawCtrl.options.polyline
      );
      this.polygonHandler = new L.Draw.Polygon(
        this.map,
        this.map.drawCtrl.options.polygon
      );
    }
  }

  setContainerSize() {
    var len =
      window.location.pathname.indexOf("sadkowice") > -1
        ? this.o.notices.list.length + 1
        : this.o.notices.list.length,
      panel = $(".formPanel"),
      body = $(".formPanel__body");
    if (len < 1) {
      $('.formPanel').css('display', 'none')
    } else if (len < 2) {
      panel.css({
        height: "115px",
        "max-height": "115px",
        "min-height": "115px"
      });
      body.css({
        height: "65px",
        "max-height": "65px",
        "min-height": "65px"
      });
    } else {
      panel.css({
        height: "150px",
        "max-height": "150px",
        "min-height": "150px"
      });
      body.css({
        height: "100px",
        "max-height": "100px",
        "min-height": "100px"
      });
    }
  }

  zgloszeniaCreator() {
    var _that = this;
    $.extend(true, _that.o, _that.zgloszeniaCreatorSettings);
    _that.addNoticesToPanel();
  }

  addNoticesToPanel() {
    var _that = this;
    _that.events();
    _that.drawOlEvents();
    if (_that.o.notices.fromServer) {
      _that.getNoticesList();
    }
    setTimeout(function () {
      var notices = _that.createNoticeLinks();
      if ($(window).width > 768) {
        _that.setContainerSize();
      }
      $(".formPanel__body").html(notices);
      if (window.location.pathname.indexOf("sadkowice") > -1) {
        //                      if (true) {
        $(".formPanel__body .notices__list").append(
          `<li class="notices__item notices__item--link"><span class="notices__name"><a class="notices__name--link" href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/najczesciej-zalatwiane-sprawy/zaswiadczenia-o-przeznaczeniu-terenu-w-planie-miejscowym/UG_Sadkowice" target="_blank">Wniosek o pobieranie WMS/WFS</a></span><a href="https://epuap.gov.pl/wps/portal/strefa-klienta/katalog-spraw/budownictwo-i-mieszkania/najczesciej-zalatwiane-sprawy/zaswiadczenia-o-przeznaczeniu-terenu-w-planie-miejscowym/UG_Sadkowice" target="_blank" class="notices__geom-type"><i class="notices__icon fas fa-link fa-2x" title="Link"></i></a></li>`
        );
        _that.setContainerSize();
      }
    }, 2800);
  }

  addButton(containerClass, btnClass, text, action, handler, id, tooltip) {
    var container = $("." + containerClass);
    container.append(
      '<button data-btnid="' +
      id +
      '" class="' +
      containerClass +
      "--button " +
      btnClass +
      '" data-action="' +
      action +
      '" title="' +
      tooltip +
      '">' +
      text +
      "</button>"
    );
  }

  buttonClick($btn) {
    var _that = this,
      action = $btn.data("action");
    if (action == "submitForm") {
      _that.submitForm();
      //!  block button ok to second click after validation
      let formValid =
        typeof window.formValid !== undefined ? window.formValid : true;
      formValid ? $btn.prop("disabled", true) : $btn.prop("disabled", false);
      //!------------------------
    } else if (action == "cancel") {
      _that.closePopup();
    }
  }

  events() {

    var _that = this;
    $(document).off("click tap", "[data-action]");
    $(document).on("click tap", "[data-action]", function (e) {
      _that.buttonClick($(this), e);
    });

    $(document).on("click tap", ".modePanel-show", function () {
      $(".modePanel").addClass("modePanel--active");
      $(".modePanel-show").removeClass("modePanel-show--active");
    });

    $(document).off("click tap", ".modePanel__body--btn-cancel");

    $(document).on("click tap", ".modePanel__body--btn-cancel", function () {
      _that.cancel();
    });

    $(document).off(
      "click tap",
      "." + _that.values.classNames.notices + "__item"
    );

    $(document).on(
      "click tap",
      "." + _that.values.classNames.notices + "__item",
      function () {
        if (!$(this).hasClass("notices__item--link")) {
          var $this = $(this).find(
            "." + _that.values.classNames.notices + "__geom-type"
          );
          _that.values.guid = $this.data("notice-guid") || "";
          _that.values.geomType = $this.data("notice-geom-type") || "";
          _that.values.title = $this.data("notice-title") || "";
          _that.closePopup();
          _that.drawGeometry();
          if ($(window).width() <= 768) {
            $(".formPanel").css("display", "none");
            $(".formPanel-show").css("display", "flex");
            $(".formPanel-show").removeClass("si-focused");
          }
        }
      }
    );

    $(document).off(
      "click tap",
      "." + _that.values.classNames.drawAlert + "__btn--close"
    );

    $(document).on(
      "click tap",
      "." + _that.values.classNames.drawAlert + "__btn--close",
      function () {
        _that.deactivateDraw(_that.geomTypeOL(_that.values.geomType));
        _that.removeDrawAlert();
      }
    );

    _that.mstools.validateForm({
      form: "." + _that.values.classNames.popup + " form",
      msgContainer: "." + _that.values.classNames.validationErrors
    });

    function ajaxSubmit(e) {
      $(this).ajaxSubmit({
        beforeSubmit: function (arr) {
          for (var i = 0; i < arr.length; i++) {
            if (arr[i].name == 'opcja') {
              arr[i].name = 'opcje'
            }
          }
          var formValid =
            typeof window.formValid !== undefined ? window.formValid : true;
          return formValid;
        },
        data: {
          geom: _that.values.geojson,
        },
        success: function (data) {
          _that.onFormSubmit(data);
        },
        error: function () {
          _that.closePopup();
          _that.openFailPopup();
        }
      });
      e.preventDefault();
    }

    $(document).off(
      "submit",
      "." + _that.values.classNames.popup + " form",
      ajaxSubmit
    );

    $(document).on(
      "submit",
      "." + _that.values.classNames.popup + " form",
      ajaxSubmit
    );

    $(document).on("click tap", ".formPopup-close", function () {
      if ($(window).width() <= 768 || $(window).height() <= 768) {
        $(".responsive-icon-d").css("display", "flex");
        _that.hide(".modePanel-show");
        $(".bar-bottom").css("display", "flex");
        $(".leaflet-control-zoom").css("display", "block");
      }
    });

    $(document).on("click tap", ".drawEditPanel-hide", function () {
      $(".drawEditPanel").removeClass("drawEditPanel--active");
      $(".drawEditPanel-show").css("display", "flex");
    });

    $(document).on("click tap", ".drawEditPanel-show", function () {
      $(".drawEditPanel").addClass("drawEditPanel--active");
      $(".drawEditPanel-show").css("display", "none");
    });
  }

  hide(className) {
    $(className).css("display", "none");
  }

  cancel() {
    var _that = this;
    this.emptyPopup();
    this.markerHandler.disable();
    this.polylineHandler.disable();
    this.polygonHandler.disable();
    $("#drawEditCancel").click();
    $(".formPopup").removeClass("overlay--active");
    $(".modePanel").removeClass("modePanel--active");
    _that.hide(".small-icon-modePanel");
    $(".modePanel-show").removeClass("modePanel-show--active");
    $(".overlay").removeClass("overlay--active");
    window.sessionStorage.setItem("mode", "none");
    window.sessionStorage.setItem("formID", 0);
    if ($(window).width() <= 768 || $(window).height() <= 768) {
      $(".responsive-icon-d").css("display", "flex");
      _that.hide(".modePanel-show");
      $(".bar-bottom").css("display", "flex");
      $(".leaflet-control-zoom").css("display", "block");
    }
  }

  drawOlEvents() {
    var _that = this,
      geomOptions = [
        {
          geomType: "Point"
        },
        {
          geomType: "Path"
        },
        {
          geomType: "Polygon"
        }
      ];
    _that.values.featureLayers = {};
    _that.values.featureDrawers = {};
    for (var i = 0; i < geomOptions.length; i++) {
      _that.addGeomListener({
        geomType: geomOptions[i].geomType
      });
    }
  }

  activateDraw(geomType, guid) {
    var _that = this;
    _that.storage.setItem("formID", guid);
    _that.states.onForm = true;
    _that.states.onInfo = false;
    _that.map.doubleClickZoom.disable();
    if (geomType == "Point") {
      $(".modePanel").addClass("modePanel--active");
      _that.storage.setItem("mode", "selectonmap");
      $(".modePanel__body--communicate").text(
        "Uwaga! Jesteś w trybie wskazania punktu"
      );
      this.markerHandler.enable();
    } else if (geomType == "Path") {
      $(".modePanel").addClass("modePanel--active");
      _that.storage.setItem("mode", "selectonmap");
      $(".modePanel__body--communicate").text(
        "Uwaga! Jesteś w trybie rysowania"
      );
      this.polylineHandler.enable();
    } else if (geomType == "Polygon") {
      $(".modePanel").addClass("modePanel--active");
      _that.storage.setItem("mode", "selectonmap");
      $(".modePanel__body--communicate").text(
        "Uwaga! Jesteś w trybie rysowania"
      );
      this.polygonHandler.enable();
    } else if (geomType == "noGeom") {
      _that
        .getNoticeForm()
        .done(function (response) {
          _that.storage.setItem("mode", "none");
          // _that.openNoticePopup(response);
          if (response.indexOf("Wróć do aplikacji") > -1) {
            _that.openNoticePopup(
              `<div class="formPopup__body--alert"><i class="fas fa-exclamation-circle"></i> Zgłoszenie poza obszarem mapy</div>`
            );
          } else {
            _that.openNoticePopup(response);
          }
        })
        .fail(function () {
          _that.openFailPopup();
        });
    }
    $(".notices__list").off("click");
    $(".notices__list").on("click", function () {
      $(".modePanel__body--btn-cancel").click();
    });
  }

  getShapeType(layer) {
    if (layer instanceof L.Circle) {
      return "circle";
    }
    if (layer instanceof L.Marker) {
      return "marker";
    }
    if (layer instanceof L.Polyline && !(layer instanceof L.Polygon)) {
      return "polyline";
    }
    if (layer instanceof L.Polygon && !(layer instanceof L.Rectangle)) {
      return "polygon";
    }
    if (layer instanceof L.Rectangle) {
      return "rectangle";
    }
  }

  addTooltip(layer) {
    var _that = this;
    _that.tooltip = new L.tooltip({
      className: "leaflet-draw-tooltip leaflet-draw-tooltip-custom",
      offset: [0, 23],
      direction: "right",
      permanent: true
    });
    layer.bindTooltip(_that.tooltip).setTooltipContent("Edytuj lub zatwierdź");
  }

  removeTooltip(layer) {
    layer.unbindTooltip();
  }

  drawToForm(e) {
    var _that = this;
    $(".modePanel").removeClass("modePanel--active");
    _that.hide(".small-icon-modePanel");
    var type = e.layerType,
      layer = e.layer;
    if (type === "marker") {
      _that.values.geojson = _that.createGeoJson(layer, "Point");
    } else if (type === "polyline") {
      _that.values.geojson = _that.createGeoJson(layer, type);
      _that.polylineHandler.disable();
    } else if (type === "polygon") {
      _that.values.geojson = _that.createGeoJson(layer, type);
      _that.polygonHandler.disable();
    }
    _that
      .getNoticeForm()
      .done(function (response) {
        _that.storage.setItem("mode", "none");
        if (response.indexOf("Wróć do aplikacji") > -1) {
          _that.openNoticePopup(
            `<div class="formPopup__body--alert"><i class="fas fa-exclamation-circle"></i> Zgłoszenie poza obszarem mapy</div>`
          );
        } else {
          _that.openNoticePopup(response);
        }
      })
      .fail(function () {
        _that.openFailPopup();
      });
  }

  addGeomListener() {
    var _that = this;
    this.map.off("draw:created");
    if (_that.editGeometryFirst) {
      this.map.on("draw:created", function (e) {
        $(".formPanel-show").css({
          "background-color": "#0080D4"
        });
        _that.states.onForm = true;
        _that.states.onInfo = false;
        $(".modePanel__body--communicate").text(
          "Uwaga! Jesteś w trybie edycji geometrii"
        );
        $(".drawEditPanel").addClass("drawEditPanel--active");
        $(".modePanel").removeClass("modePanel--active");
        $(".modePanel-show").css("display", "none");
        var type = e.layerType,
          layer = e.layer;
        _that.editableLayer.addLayer(layer);
        if (type === "marker") {
          _that.markerHandler.disable();
        } else if (type === "polyline") {
          _that.polylineHandler.disable();
        } else if (type === "polygon") {
          _that.polygonHandler.disable();
        }
        _that.map.on("editable:enable", function () {
          _that.addTooltip(layer);
        });
        layer.enableEdit();
        _that.states.onGeomEdit = true;

        $(document).off("click tap", "#drawEditCancel");
        $(document).on("click tap", "#drawEditCancel", function () {
          _that.storage.setItem("mode", "none");
          _that.states.onGeomEdit = false;
          $(".drawEditPanel").removeClass("drawEditPanel--active");
          $(".drawEditPanel-show").css("display", "none");
          $(".modePanel__body--btn-ds").css("display", "none");
          $(".modePanel").removeClass("modePanel--active");
          layer.disableEdit();
          _that.map.doubleClickZoom.enable();
          layer.remove();
        });

        $(".formPanel a").off("click tap");
        $(".formPanel a").on("click tap", function () {
          if (_that.states.onGeomEdit) {
            $("#drawEditCancel").click();
          }
        });

        $(document).off("click tap", "#drawEditStop");
        $(document).on("click tap", "#drawEditStop", function (event) {
          event.stopPropagation();
          layer.disableEdit();
          _that.states.onGeomEdit = false;
          _that.map.doubleClickZoom.enable();
          _that.drawToForm(e);
          $(".modePanel__body--btn-cancel").click();
          $(".drawEditPanel").removeClass("drawEditPanel--active");
          $(".drawEditPanel-show").css("display", "none");
          _that.hide(".small-icon-modePanel");
          _that.storage.setItem("mode", "none");
          layer.remove();
        });
      });
    } else {
      this.map.on("draw:created", function (e) {
        $(".formPanel-show").css({
          "background-color": "#0080D4"
        });
        _that.states.onGeomEdit = false;
        _that.map.doubleClickZoom.enable();
        _that.states.onForm = true;
        _that.states.onInfo = false;
        _that.drawToForm(e);
        e.layer.remove();
      });
    }
  }

  closePopup() {
    var _that = this;
    this.emptyPopup();
    $(".formPopup").removeClass("overlay--active");
    _that.states.onForm = false;
    _that.states.onInfo = true;
  }

  emptyPopup() {
    $(".formPopup__title").text("");
    $(".formPopup__body").html("");
    $(".formPopup__optionsBtns").html("");
  }

  createDrawAlert(settings) {
    var _that = this,
      addText = _that.values.geomType == "Point" ? "Dodaj" : "Narysuj",
      geomText =
        "" +
        '<div class="' +
        _that.values.classNames.notices +
        '__name">' +
        addText +
        " <b>" +
        _that.geomTypePL(_that.values.geomType) +
        "</b> " +
        "dla: <b>" +
        _that.values.title +
        "</b>" +
        "</div>";
    _that.values.$drawAlert = $("<div>", {
      class: _that.values.classNames.drawAlert,
      html: geomText + settings.html
    });
    var $cancelBtn = $("<button>", {
      class: _that.values.classNames.drawAlert + "__btn--close",
      text: _that.o.texts.cancel
    }),
      $msg = _that.values.$drawAlert.append($cancelBtn);
    $("." + _that.values.classNames.map).append($msg);
  }

  createGeoJson(layer) {
    var json = layer.toGeoJSON();
    return this.trimGeoJSON(json);
  }
  createNoticeLink(settings) {
    var _that = this,
      $geom,
      $icon;
    if (settings) {
      var $li = $("<li>", {
        class: _that.values.classNames.notices + "__item",
        "data-notice-mode": settings.mode
      });
      var $name = $("<span>", {
        class: _that.values.classNames.notices + "__name",
        text: settings.title + ":"
      });
      $li.append($name);
      if (settings.mode == "draw") {
        for (var i = 0; i < settings.geomType.length; i++) {
          $geom = $("<a>", {
            class: _that.values.classNames.notices + "__geom-type",
            href: "javascript:void(null)",
            "data-notice-title": settings.title,
            "data-notice-guid": settings.guid,
            "data-notice-geom-type": settings.geomType[i]
          });
          $icon = $("<i>", {
            class:
              _that.values.classNames.notices +
              "__icon " +
              _that.geomTypeFA(settings.geomType[i]),
            title: _that.geomTypePL(settings.geomType[i])
          });
          //change color of fomr icon
          settings.wmsColor != "undefined"
            ? $icon.css("color", settings.wmsColor)
            : $icon.css("color", "");
          $li.append($geom.append($icon));
        }
      } else if (settings.mode == "select") {
        for (var i = 0; i < settings.geomType.length; i++) {
          $geom = $("<a>", {
            class: _that.values.classNames.notices + "__geom-type",
            href: "javascript:void(null)",
            "data-notice-title": settings.title,
            "data-notice-guid": settings.guid,
            "data-notice-geom-type": settings.geomType[i]
          });
          $icon = $("<i>", {
            class:
              _that.values.classNames.notices +
              "__icon " +
              _that.geomTypeFA(settings.geomType[i]),
            title: _that.geomTypePL(settings.geomType[i])
          });
          //change color of fomr icon
          settings.wmsColor != "undefined"
            ? $icon.css("color", settings.wmsColor)
            : $icon.css("color", "");
          $li.append($geom.append($icon));
        }
      } else if (settings.mode == "selectform") {
        $geom = $("<a>", {
          class: _that.values.classNames.notices + "__geom-type",
          href: "javascript:void(null)",
          "data-notice-title": settings.title,
          "data-notice-guid": settings.guid,
          "data-notice-geom-type": "noGeom"
        });
        $icon = $("<i>", {
          class:
            _that.values.classNames.notices +
            "__icon " +
            _that.geomTypeFA("noGeom"),
          title: _that.geomTypePL("noGeom")
        });
        //change color of fomr icon
        settings.wmsColor != "undefined"
          ? $icon.css("color", settings.wmsColor)
          : $icon.css("color", "");
        $li.append($geom.append($icon));
      } else {
        $geom = $("<a>", {
          class: _that.values.classNames.notices + "__geom-type",
          href: "javascript:void(null)",
          "data-notice-title": settings.title,
          "data-notice-guid": settings.guid,
          "data-notice-geom-type": "noGeom"
        });
        $icon = $("<i>", {
          class:
            _that.values.classNames.notices +
            "__icon " +
            _that.geomTypeFA("noGeom"),
          title: _that.geomTypePL("noGeom")
        });
        $li.append($geom.append($icon));
      }
      return $li;
    } else {
      return "";
    }
  }

  createNoticeLinks() {
    var _that = this,
      $ul = $("<ul>", {
        class: _that.values.classNames.notices + "__list"
      });
    for (var i = 0; i < _that.o.notices.list.length; i++) {
      $ul.append(_that.createNoticeLink(_that.o.notices.list[i]));
    }
    return _that.getHtmlFromJqObject($ul);
  }

  createPopup(settings) {
    this.emptyPopup();
    var _that = this,
      btnOk;
    settings = settings || {};
    settings.callbacks = settings.callbacks || {};
    if (settings.btns.indexOf("ok") != -1) {
      btnOk = {
        text: _that.o.texts.ok,
        cls: _that.values.classNames.popup + "__ok",
        handler: function () {
          _that.getCallback(settings.callbacks.ok)();
        }
      };
      _that.addButton(
        "formPopup__optionsBtns",
        "formPopup__optionsBtns--button formPopup__optionsBtns--button-green",
        btnOk.text,
        "submitForm",
        btnOk.handler,
        "",
        btnOk.text
      );
    }
    if (settings.btns.indexOf("close") != -1) {
      btnOk = {
        text: _that.o.texts.ok,
        cls: _that.values.classNames.popup + "__ok",
        handler: function () {
          _that.getCallback(settings.callbacks.cancel)();
        }
      };
      _that.addButton(
        "formPopup__optionsBtns",
        "formPopup__optionsBtns--button formPopup__optionsBtns--button-green",
        btnOk.text,
        "cancel",
        btnOk.handler,
        "",
        btnOk.text
      );
    }
    if (settings.btns.indexOf("cancel") != -1) {
      var btnCancel = {
        text: _that.o.texts.cancel,
        handler: function () {
          _that.closePopup();
          _that.getCallback(settings.callbacks.cancel)();
        }
      };
      _that.addButton(
        "formPopup__optionsBtns",
        "formPopup__optionsBtns--button",
        btnCancel.text,
        "cancel",
        btnCancel.handler,
        "",
        btnCancel.text
      );
    }
    $(".formPopup__title").html(settings.title);
    $(".formPopup__body").html(settings.html);
    _that.addCounterTextArea();
  }

  addCounterTextArea() {
    const textarea = $("textarea");
    textarea.each(index => {
      if ($(textarea[index]).attr("maxLength")) {
        $(`<span class="textarea-counter">Pozostało znaków:
                    ${getLeftText(
          getTextAreaMaxLength($(textarea[index])),
          getActualTextAreaLength($(textarea[index]))
        )}/${getTextAreaMaxLength(
          $(textarea[index])
        )}</span>`).insertAfter($(textarea[index]));

        $(textarea[index]).keyup(function () {
          $(this).next(
            ".textarea-counter"
          ).html(`Pozostało znaków: ${getLeftText(getTextAreaMaxLength($(textarea[index])), getActualTextAreaLength($(textarea[index])))}
                    /${getTextAreaMaxLength($(textarea[index]))}`);
        });
      }
    });

    function getTextAreaMaxLength(textarea) {
      const text_max = textarea.attr("maxLength");
      return text_max;
    }

    function getActualTextAreaLength(textarea) {
      const text_length = textarea.val().length;
      return text_length;
    }

    function getLeftText(textMax, textLeft) {
      return textMax - textLeft;
    }
  }

  createNoticeUrl() {
    var _that = this;
    return _that.o.notices.noticeUrl + _that.values.guid + "?view=partial";
  }

  createPopupTitle() {
    var _that = this;
    return (
      _that.o.texts.addTitle +
      _that.values.title +
      " (" +
      _that.geomTypePL(_that.values.geomType) +
      ")"
    );
  }

  deactivateDraw(geomType) {
    this.values.featureDrawers[geomType].deactivate();
  }

  drawGeometry() {
    var _that = this,
      geomType = _that.geomTypeOL(_that.values.geomType);
    _that.activateDraw(geomType, _that.values.guid);
  }

  geomTypeFA(geomType) {
    var _that = this;
    switch (geomType) {
      case "Point":
        return _that.o.icons.point;
      case "LineString":
        return _that.o.icons.line;
      case "Polygon":
        return _that.o.icons.poly;
      case "noGeom":
        return _that.o.icons.noGeom;
      default:
        return "";
    }
  }

  geomTypeOL(geomType) {
    switch (geomType) {
      case "Point":
        return "Point";
      case "LineString":
        return "Path";
      case "Polygon":
        return "Polygon";
      case "noGeom":
        return "noGeom";
      default:
        return "";
    }
  }

  geomTypePL(geomType) {
    switch (geomType) {
      case "Point":
        return "Punkt";
      case "LineString":
        return "Linia";
      case "Polygon":
        return "Poligon";
      default:
        return "";
    }
  }

  getCallback(callback) {
    return callback || function () { };
  }

  $getForm() {
    return $("." + this.values.classNames.popup + " form");
  }

  getPopupCallbacks(html) {
    var _that = this;
    switch (_that.htmlHasForm(html)) {
      case true:
        return {
          ok: function () {
            _that.submitForm();
          },
          cancel: function () {
            _that.removeLayer();
          }
        };
      default:
        return {
          close: function () {
            _that.closePopup();
          }
        };
    }
  }

  getPopupBtns(html) {
    var _that = this;
    switch (_that.htmlHasForm(html)) {
      case true:
        return ["ok", "cancel"];
      default:
        return ["close"];
    }
  }

  getHtmlFromJqObject($jqObject) {
    return $jqObject[0].outerHTML;
  }

  getNoticeForm() {
    var _that = this;
    return $.ajax({
      url: _that.createNoticeUrl(),
      dataType: "html",
      type: "GET",
      data: {
        geom: _that.values.geojson
      }
    });
  }

  getNoticesList() {
    var _that = this;
    $.ajax(_that.o.notices.ajax)
      .done(function (response) {
        _that.o.notices.list = response;
        if (_that.o.notices.list.length < 1) {
          $('.formPanel').css('display', 'none')
        }
      })
      .fail(function () {
        _that.o.notices.list = [];
        $('.formPanel').css('display', 'none')
      });
  }

  htmlHasForm(html) {
    return /<\s*form.*>/.test(html);
  }

  layerToGeoJSON(layer) {
    var formatGeoJSON = L.geoJSON(layer);
    return formatGeoJSON;
  }

  openFailPopup() {
    var _that = this,
      $error = $("<span>", {
        class: _that.values.classNames.notices + "__name",
        text: _that.o.texts.error
      });
    _that.createPopup({
      title: _that.createPopupTitle(),
      html: _that.getHtmlFromJqObject($error),
      btns: ["close"],
      callbacks: {
        ok: function () {
          _that.removeDrawAlert();
          _that.removeLayer();
          _that.closePopup();
        },
        cancel: function () {
          _that.removeDrawAlert();
          _that.removeLayer();
          _that.closePopup();
        }
      }
    });
    _that.states.onForm = true;
    _that.states.onInfo = false;
    _that.showPopup();
  }

  openInitPopup() {
    if ($(window).width() <= 768 || $(window).height() <= 768) {
      this.hide(".formPanel");
    }
    var _that = this;
    if (_that.o.notices.list.length == 0) {
      _that.openInitPopupNoNotices();
    } else if (
      _that.o.notices.list.length == 1 &&
      _that.o.notices.list[0].geomType.length == 1
    ) {
      _that.openInitPopupOneNotice();
    } else {
      _that.openInitPopupManyNotices();
    }
    _that.states.onForm = true;
    _that.states.onInfo = false;
  }

  openInitPopupManyNotices() {
    var _that = this,
      notices = _that.createNoticeLinks();
    _that.createPopup({
      title: _that.o.texts.chooseNoticeType,
      html: notices,
      btns: ["cancel"]
    });
    _that.showPopup();
    _that.states.onForm = true;
    _that.states.onInfo = false;
  }

  openInitPopupNoNotices() {
    var _that = this,
      $warning = $("<span>", {
        class: _that.values.classNames.notices + "__name",
        text: _that.o.texts.noNotices
      });
    _that.createPopup({
      title: _that.o.texts.noNotices,
      html: _that.getHtmlFromJqObject($warning),
      btns: ["cancel"]
    });
    _that.states.onForm = true;
    _that.states.onInfo = false;
  }

  openInitPopupOneNotice() {
    var _that = this,
      notice = _that.o.notices.list[0];
    _that.values.guid = notice.guid || "";
    _that.values.geomType = notice.geomType[0] || "";
    _that.values.title = notice.title || "";
    _that.drawGeometry();
    _that.states.onForm = true;
    _that.states.onInfo = false;
  }

  openNoticePopup(html) {
    var _that = this;
    _that.createPopup({
      title: _that.createPopupTitle(),
      html: html,
      btns: _that.getPopupBtns(html),
      callbacks: _that.getPopupCallbacks(html)
    });
    _that.showPopup();
    _that.updatePopupHtml(html);
    _that.updateFormAction();
    _that.mstools.changeFileInputs({
      inputs: "input[type='file']",
      btnClass: "przycisk"
    });
    _that.mstools.changeCheckboxes();
    _that.mstools.changeRadiobtns();
    _that.states.onForm = true;
    _that.states.onInfo = false;
  }

  openSuccessPopup() {
    var _that = this;
    this.closePopup();
    var $success = $("<span>", {
      class: _that.values.classNames.notices + "__name",
      text: _that.o.texts.sendSuccess
    });
    _that.createPopup({
      title: _that.createPopupTitle(),
      html: _that.getHtmlFromJqObject($success),
      btns: ["close"],
      callbacks: {
        ok: function () {
          _that.closePopup();
        }
      }
    });
    _that.showPopup();
    _that.states.onForm = true;
    _that.states.onInfo = false;
  }

  onFormSubmit(data) {
    this.sendNoticeCallback(data);
  }

  removeDrawAlert() {
    $("." + this.values.classNames.drawAlert).remove();
  }

  removeLayer() {
    var _that = this,
      lastFeatureindex = _that.values.noticeLayer.length - 1;
    _that.values.featureLayers[
      _that.geomTypeOL(_that.values.geomType)
    ].removeFeatures(_that.values.noticeLayer[lastFeatureindex]);
  }

  sendNoticeCallback(html) {
    var _that = this;
    if ($.trim(html) == _that.o.notices.successMsg) {
      _that.openSuccessPopup();
    } else {
      _that.closePopup();
      _that.createPopup({
        title: _that.createPopupTitle(),
        html: html,
        btns: _that.getPopupBtns(html),
        callbacks: _that.getPopupCallbacks(html)
      });
      _that.showPopup();
      _that.updatePopupHtml(html);
      _that.updateFormAction();
      _that.mstools.changeFileInputs({
        inputs: "input[type='file']",
        btnClass: "przycisk"
      });
      _that.mstools.changeCheckboxes();
      _that.mstools.changeRadiobtns();
    }
  }

  showPopup() {
    $(".formPopup").addClass("overlay--active");
    //metoda dla obsługi pola input-file w modalu
    if ($('#input-attachment').length) {
      changeFileInputsModal();
    }
  }


  submitForm() {
    var _that = this,
      $form = _that.$getForm();
    $form.submit();
  }

  trimGeoJSON(geojson) {
    $.each(geojson, function (key) {
      if (key != "geometry") {
        delete geojson[key];
      }
    });
    return JSON.stringify(geojson);
  }

  updateFormAction() {
    var _that = this,
      $form = _that.$getForm();
    $form.attr("action", _that.createNoticeUrl());
  }

  updatePopupHtml(html) {
    $("." + this.values.classNames.popup + " .x-window-body").html(html);
  }
}

export default FormCreator;
