import Tools from './Tools';
import $ from 'jquery';

var tools = new Tools();

// pobierz opcje mapy
class Legend {

    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.src = config.MapConf.options_legend_src;
        this.legendClass = 'legend';
        this.legendWindowClass = '.legend';
        this.legendWindowActiveClass = this.legendWindowClass + '--active';
        this.legendWindowCloseClass = this.legendWindowClass + '-close';
        this.legendIconProps = tools.createElPropertiesFromClass(this.toolsClass + '__' + this.legendClass);
        this.legendProps = tools.createElPropertiesFromClass(this.legendClass);
        this.legendContainerProps = tools.createElPropertiesFromClass(this.legendClass + '__imgContainer');
        if (config.MapConf.Legenda) {
            this.addLegendImg();
            this.events();
        }
    }

    events() {
        var that = this;

        $(document).on('click tap', this.legendIconProps.selector, function() {
            that.legendProps.$el.toggleClass('overlay--active');
        })

        $(this.legendWindowCloseClass).on("click tap", function() {
            $(that.legendIconProps.selector).find('.tools__tool-icon').removeClass('tools__tool-icon-focused')
            $(that.legendIconProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-focused')
        });

    }

    createLegendImg() {
        return $('<img>', {
            alt: 'legenda',
            src: this.src,
            class: this.legendClass + '__img',
        });
    }

    addLegendImg() {
        this.legendContainerProps.$el.append(this.createLegendImg());
    }

}

export default Legend;