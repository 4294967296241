import $ from "jquery";
//!dynamic set height for leaflet-control-layers-overlays
export function setHeightContainer() {
    //warswty tematyczne
    let layerControl = $(".leaflet-control-layers")[2],
        layerControlList = $(layerControl).find(
            ".leaflet-control-layers-overlays__body"
        );

    if ($(window).width() > 768 && $(window).height() > 768) {
        let parent = $(".leaflet-control-layers")[0].parentElement, //container with all left layers
            layerShowBase = $(".leaflet-control-layers")[0], //warstwy podkładowe
            layerControlCompositions = $(".leaflet-control-layers")[1], //kompozycje mapowe
            result =
                $(parent).outerHeight(true) -
                $(layerShowBase).outerHeight(true) -
                $(layerControlCompositions).outerHeight(true);

        $(layerControlList).css({
            "max-height": result - 84 + "px",
        });
    } else {
        $(layerControlList).css({
            "max-height": "",
        });
    }
}
//!
class HidePanel {
    constructor(config, settings) {
        this.map = settings.map;
        this.compositions = config.tematList;
        this.storage = window.sessionStorage;
        this.$container = $(
            "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left"
        );
        this.$baseContainer = $(
            "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(1)"
        );
        if (
            this.compositions.length != 0 &&
            this.compositions != null &&
            this.compositions != undefined
        ) {
            this.$compositionsContainer = $(
                "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(3)"
            );
            this.$overlayContainer = $(
                "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(5)"
            );
        } else {
            this.$overlayContainer = $(
                "#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(3)"
            );
        }
        this.$titleContainer = $(
            "#map > div.leaflet-control-container > div.leaflet-top.leaflet-right > .composition-title"
        );
        this.$printContainer = $(".printPopup");
        //! dynamic set height for leaflet-control-layers-overlays

        setHeightContainer();
        //!-----
        this.events();
        var that = this;

        //!sip110 - left bar actions
        $(window).resize(function () {
            if ($(window).width() > 768 && $(window).height() > 768) {
                that.offEventsHidePanel();
                that.events();
                $(".leaflet-control-layers-hide-base").click();
                $(".leaflet-control-layers-hide-overlays").click();
                $(".leaflet-control-compositions-hide").click();
                // that.setHeightContainer();
                that.resetResponsiveStyles(".leaflet-control-compositions");
                that.resetResponsiveStyles(".leaflet-control-layers");
                that.resetResponsiveStyles(".bar-top__search");
                that.resetResponsiveStyles(".bar-top__tools");
                that.resetResponsiveStyles(".leaflet-control-layers-list");
                $(".bar-top__tools").css({
                    width: "",
                });

                setTimeout(function () {
                    $(".small-icon-baseLayers").click();
                    $(".small-icon-overlayLayers").click();
                    $(".small-icon-compositions").click();
                }, 0);
            } else if ($(window).width() > 768 || $(window).height() <= 768) {
                //that.resetResponsiveStyles(".bar-top__search");
                //that.offEventsHidePanel();
            } else if ($(window).width() >= 768 && $(window).height() <= 768) {
                //todo tylko wysokość większa od 768
                // that.hide(".responsive-icon");
            }
        });
    }

    resetResponsiveStyles(elem) {
        $(elem).css({
            position: "",
            top: "",
            left: "",
            display: "",
        });
    }
    //!-------------
    events() {
        var that = this;
        //! left panel
        //todo event na zwijanie warstwy podkładowe

        $(".leaflet-control-container").on(
            "click tap",
            ".leaflet-control-layers-hide-base",
            function (e) {
                e.stopPropagation();
                that.hide(that.$baseContainer);
                that.show(".leaflet-control-scale", "block");
                that.show(".leaflet-control-layers-show-base", "flex");
                that.show(".small-icon-baseLayers", "flex");
                that.setPositions();
            }
        );

        //todo event na zwijanie warstwy podkąłdowe
        $(".leaflet-control-container").on(
            "click tap",
            ".leaflet-control-layers-hide-overlays",

            function () {
                that.hide(that.$overlayContainer);
                that.show(".leaflet-control-scale", "block");
                that.show(".leaflet-control-layers-show-overlays", "flex");
                that.show(".small-icon-overlayLayers", "flex");
                that.setPositions();
            }
        );

        //todo event na zwijanie warstwy kompozycje mapowe
        $(".leaflet-control-container").on(
            "click tap",
            ".leaflet-control-compositions-hide",
            function (e) {
                e.stopPropagation();
                that.hide(that.$compositionsContainer);
                that.show(".leaflet-control-scale", "block");
                that.show(".leaflet-control-compositions-show", "flex");
                that.show(".small-icon-compositions", "flex");
                that.setPositions();
            }
        );
        //! ----------

        //todo ustawienie przycisku powięlksz - pomniejsz
        $(".leaflet-control-container").on(
            "click tap",
            ".composition-title-hide",
            function (e) {
                e.stopPropagation();
                that.hide(that.$titleContainer);
                $(".small-icon-composition-title").addClass(
                    "responsive-icon-d"
                );
                that.show(".composition-title-show", "flex");
                that.show(".small-icon-composition-title", "flex");
                $(".leaflet-control-zoom").css("top", "auto");
                that.setPositions();
            }
        );
        //todo dotyczy panela formularza
        $(document).on("click tap", ".formPanel-hide", function () {
            that.hide(".formPanel");
            that.show(".formPanel-show", "flex");
        });

        $(document).on("click tap", ".eservicePanel-hide", function () {
            that.hide(".eservicePanel");
            that.show(".eservicePanel-show", "flex");
        });

        $(document).on("click tap", ".wyboryPanel-hide", function () {
            if (!(that.storage.getItem("mode") == "wybory")) {
                $(".wyboryPopup").removeClass("overlay--active");
            }
            that.hide(".wyboryPanel");
            that.show(".wyboryPanel-show", "flex");
        });

        $(document).on("click tap", ".printPopup-hide", function () {
            that.hide("#print-panel > div > form > div:nth-child(1)");
            that.hide("#print-panel > div > form > div:nth-child(2)");
            $(".printPopup").addClass("printPopup-hidden");
            $(".print-container").addClass("print-container-hidden");
            $("#print-panel > div > form").addClass("form-hidden");
            $("#print-panel > div > form > div:nth-child(3)")
                .addClass("div-hidden")
                .removeClass("row");
            $("#print-panel > div > form > div:nth-child(2)")
                .addClass("div-hidden")
                .removeClass("row");
            $("#print-panel > div > form > div:nth-child(1)")
                .addClass("div-hidden")
                .removeClass("row");
            $("#print-panel > div > form > div:nth-child(4)")
                .addClass("div-hidden")
                .removeClass("row");
            $("#print-panel > div > form > div:nth-child(5)")
                .addClass("div-hidden")
                .removeClass("row");
            $("#print-panel > div > form > div:nth-child(3) > div")
                .addClass("d-hidden")
                .removeClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(1) > div")
                .addClass("d-hidden")
                .removeClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(2) > div")
                .addClass("d-hidden")
                .removeClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(4) > div")
                .addClass("d-hidden")
                .removeClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(5) > div")
                .addClass("d-hidden")
                .removeClass("col-xs-12");
            $("#print").html('<i class="fas fa-play" aria-hidden="true"></i>');
            $("#print").addClass("button-small");
            that.show(".printPopup-show", "block");
            that.hide(".printPopup-hide");
        });

        $(document).on("click tap", ".printPopup-show", function () {
            that.show("#print-panel > div > form > div:nth-child(1)", "block");
            that.show("#print-panel > div > form > div:nth-child(2)", "block");
            that.show("#print-panel > div > form > div:nth-child(3)", "block");
            that.show("#print-panel > div > form > div:nth-child(4)", "block");
            $(".printPopup").removeClass("printPopup-hidden");
            $(".print-container").removeClass("print-container-hidden");
            $("#print-panel > div > form").removeClass("form-hidden");
            $("#print").html("Generuj wydruk");
            $("#print-panel > div > form > div:nth-child(3)")
                .removeClass("div-hidden")
                .addClass("row");
            $("#print-panel > div > form > div:nth-child(2)")
                .removeClass("div-hidden")
                .addClass("row");
            $("#print-panel > div > form > div:nth-child(1)")
                .removeClass("div-hidden")
                .addClass("row");
            $("#print-panel > div > form > div:nth-child(4)")
                .removeClass("div-hidden")
                .addClass("row");
            $("#print-panel > div > form > div:nth-child(5)")
                .removeClass("div-hidden")
                .addClass("row");
            $("#print-panel > div > form > div:nth-child(3) > div")
                .removeClass("d-hidden")
                .addClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(2) > div")
                .removeClass("d-hidden")
                .addClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(1) > div")
                .removeClass("d-hidden")
                .addClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(4) > div")
                .removeClass("d-hidden")
                .addClass("col-xs-12");
            $("#print-panel > div > form > div:nth-child(5) > div")
                .removeClass("d-hidden")
                .addClass("col-xs-12");
            $("#print").removeClass("button-small");
            that.hide(".printPopup-show");
            that.show(".printPopup-hide", "block");
        });
        //! left panel
        //todo event na małą ikonkę warstwy podkładowe
        $(document).on("click tap", ".small-icon-baseLayers", function () {
            //      that.hide('.leaflet-control-scale');
            $(".overlay").removeClass("overlay--active");
            that.hide(".leaflet-control-layers-show-base");
            if ($(window).width() > 768 && $(window).height() > 768) {
                that.show(that.$baseContainer, "block");
                that.hide(".small-icon-baseLayers");
            }
            that.setPositions();
        });

        //todo event na małą ikonkę warstwy tematyczne
        $(document).on("click tap", ".small-icon-overlayLayers", function () {
            $(".overlay").removeClass("overlay--active");
            that.hide(".leaflet-control-layers-show-overlays");
            if ($(window).width() > 768 && $(window).height() > 768) {
                that.show(that.$overlayContainer, "block");
                that.hide(".small-icon-overlayLayers");
            }
            //      that.hide('.leaflet-control-scale');
            that.setPositions();
            //! dynamic set height for leaflet-control-layers-overlays
            setHeightContainer();
            //! ------
        });

        //todo event na małą ikonkę kompozycje mapowe
        $(document).on("click tap", ".small-icon-compositions", function () {
            //      that.hide('leaflet-control-scale');
            $(".overlay").removeClass("overlay--active");
            that.hide(".leaflet-control-compositions-show");
            if ($(window).width() > 768 && $(window).height() > 768) {
                that.show(that.$compositionsContainer, "block");
                that.hide(".small-icon-compositions");
            }
            that.setPositions();
        });
        //!--------------
        $(document).on("click tap", ".formPanel-show", function () {
            $(".overlay").removeClass("overlay--active");
            if ($(window).width() > 768 && $(window).height() > 768) {
                that.show(".formPanel", "flex");
                that.hide(".formPanel-show");
            }
        });

        $(document).on("click tap", ".eservicePanel-show", function () {
            $(".overlay").removeClass("overlay--active");
            if ($(window).width() > 768 && $(window).height() > 768) {
                that.show(".eservicePanel", "flex");
                that.hide(".eservicePanel-show");
            }
        });

        $(document).on("click tap", ".wyboryPanel-show", function () {
            $(".overlay").removeClass("overlay--active");
            if ($(window).width() > 768 && $(window).height() > 768) {
                that.show(".wyboryPanel", "flex");
                that.hide(".wyboryPanel-show");
            }
        });

        $(document).on(
            "click tap",
            ".small-icon-composition-title",
            function () {
                that.show(that.$titleContainer, "flex");
                that.hide(".leaflet-control-composition-title-show");
                $(".small-icon-composition-title").removeClass(
                    "responsive-icon-d"
                );
                that.hide(".small-icon-composition-title");
                that.setPositions();
            }
        );

        $(document).on("click tap", ".modePanel-hide", function () {
            $(".modePanel").removeClass("modePanel--active");
            $(".modePanel-show").addClass("modePanel-show--active");
            that.show(".modePanel-show", "flex");
        });

        $(document).on("click tap", ".drawEditPanel-hide", function () {
            $(".drawEditPanel").removeClass("drawEditPanel--active");
            $(".drawEditPanel-show").addClass("drawEditPanel-show--active");
            that.show(".drawEditPanel-show", "flex");
        });
    }
    //!sip110 - left bar actions
    offEventsHidePanel() {
        $(".leaflet-control-layers-hide-base").off("click tap");
        $(".leaflet-control-layers-hide-overlays").off("click tap");
        $(".leaflet-control-compositions-hide");
        $(".small-icon-baseLayers").off("click tap");
        $(".small-icon-overlayLayers").off("click tap");
        $(".small-icon-compositions").off("click tap");
        $(".small-icon-baseLayers").off("click tap");
    }
    //!------
    //! lewy panel

    //todo ustawienie pozycji absolut dla ikonek

    setPositions() {
        if ($(window).width() > 768 && $(window).height() > 768) {
            this.setTheSameTop(
                ".small-icon-baseLayers",
                ".leaflet-control-layers-show-base"
            );
            this.setTheSameTop(
                ".small-icon-overlayLayers",
                ".leaflet-control-layers-show-overlays"
            );
            if (
                this.compositions.length != 0 &&
                this.compositions != null &&
                this.compositions != undefined
            ) {
                this.setTheSameTop(
                    ".small-icon-compositions",
                    ".leaflet-control-compositions-show"
                );
                //        this.setTheSameTop('.small-icon-composition-title', '.composition-title-show');
            }
        } else {
            //      this.setTheSameTop('.small-icon-composition-title', '.leaflet-control-composition-title-show');
        }
    }
    // adding the condition about licenseDate.js
    setTheSameTop(get, set) {
        if ($(get).length) {
            $(get).css({
                top: $(set).offset().top,
            });
        }
    }

    hide(item) {
        $(item).css("display", "none");
    }

    show(item, display) {
        $(item).css({
            display: display,
        });
    }
}
//!--------
export default HidePanel;
