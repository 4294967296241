import 'bootstrap';
import $ from 'jquery';

// pobierz opcje mapy
class FunctionsAfterLoad {

    constructor() {
        this.tooltips();
    }

    tooltips() {
        // aktywuj bootstrap tooltipy
        $('[data-toggle="tooltip"]').tooltip();
    }

}

export default FunctionsAfterLoad;