// przetworz zewnetrzne dane wektorowe na nasze
import L from 'leaflet';
import $ from 'jquery';

class LayersOuterParser {

    parseLayer(layers, groupLayer) {
        switch (groupLayer.dataGroup) {
            case 'budzet-obywatelski':
                return this.createLayersBudzetObywatelski(layers, groupLayer);
            default:
                return {};
        }
    }

    // budzet obywatelski Śremia
    createLayersBudzetObywatelski(layers, groupLayer) {
        var layerGroup = L.featureGroup();
        if ($.isArray(layers)) {
            layers.forEach(layer => {
                var marker = L.marker([layer.lat, layer.lon], {
                    icon: L.icon(groupLayer.icon),
                }).bindPopup(this.createPopupBudzetObywatelski(layer, groupLayer));
                layerGroup.addLayer(marker);
            });
        }
        return layerGroup;
    }

    createPopupBudzetObywatelski(layer, groupLayer) {
        return `
      <p>
        <b>BUDŻET OBYWATELSKI ŚREMU</b>
      </p>
      <p>
        <span>Nazwa projektu: </span><span class="h__c--main">${layer.name}</span>
      </p>
      <div class="h__d--f h__j-c--s-b">
        <a href="${layer.url}" target="_blank">
          <button class="overlay__button">Czytaj więcej &gt;&gt;</button>
        </a>
        <div class="img-container" style="width: 100px">
          <img src="${groupLayer.popupImg}">
        </div>
      </div>
    `
    }

}

export default LayersOuterParser;