/* eslint-disable */
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}

// lista klas elementu jquery
$.fn.classList = function () {
  return this[0].className.split(/\s+/);
}; // funkcja jquery wyswietlajaca tag


$.fn.tagName = function () {
  var tagName = this.prop("tagName");

  if (tagName != undefined) {
    return tagName.toLowerCase();
  } else {
    return tagName;
  }
};

var captchaKey = '6LcROQoTAAAAAPRIX5-4OD4CoMg-b5Sii0XupRIb';

/*zmien wyglad checkboxow
  wymagane parametry obiektu wejsciowego:
    checkboxes - czekboksy do zmiany
  opcjonalne parametry:
    labelCSS - style do label (domyslnie brak styli)
    inputsNewClass - nazwa klasy wlasnych inputow (domyslnie check-primary)

  np. commands
*/
var changeInputs = function changeInputs(settings) {
  // wymagane zmienne, patrz wyzej
  var s = {
    inputs: settings.inputs
  }; // opcjonalne zmienne, patrz wyzej

  var o = $.extend({
    labelCSS: {},
    inputsNewClass: "check-primary"
  }, settings, s);

  var init = function init() {
    var inputs = $(o.inputs.selector);
    inputs.each(function () {
      var $this = $(this);
      $this.addClass(o.inputsNewClass);
      var inputPrimaryWrapped = $this.parent().find('div').length;

      if (!inputPrimaryWrapped) {
        $this.wrap("<label></label>");
        $this.parent().append("<div></div>");
        $this.parent().css(o.labelCSS);
      }
    });
  }; // zainicjuj funkcje rowniez po stworzeniu popupa


  init();
  $("#popup").on("dialogopen", function () {
    init();
  });
};

/*zmien wyglad input file
  wymagane parametry obiektu wejsciowego:
    inputs - selektor inputow typu file, string

  opcjonalne parametry:
    btnClass - nadawana klasa przyciskowi inputa, string
    inputsNewClass - nadawana klasa inputowi, string
    textBtn - tekst przycisku, string
    textEmpty - tekst braku wybranego pliku, string

  np. commands
*/
var changeFileInputs = function changeFileInputs(settings) {
  // wymagane zmienne, patrz wyzej
  var s = {
    inputs: settings.inputs
  }; // opcjonalne zmienne, patrz wyzej

  var o = $.extend({
    btnClass: "przycisk2",
    inputsNewClass: "file-primary",
    textBtn: "Przeglądaj",
    textEmpty: "Nie wybrano pliku"
  }, settings, s); // zmien input file

  var init = function init() {
    var inputs = $(o.inputs.selector); // dodanie klasy dla file input w celu poprawnego wyswietlenia

    inputs.each(function () {
      var $this = $(this);

      if (!$this.hasClass(o.inputsNewClass)) {
        $this.addClass(o.inputsNewClass);
      }
    }); // utworz elementy zastepujace domyslny input file

    inputs.wrap("<label></label>");
    inputs.parent().append("<div class='" + o.btnClass + "'>" + o.textBtn + "</div>");
    inputs.parent().append("<span>" + o.textEmpty + "</span>"); // wyswietl nazwe wgranego pliku

    inputs.unbind().change(function () {
      var selectedFileText = $(this).parent().find("span");
      var filePath = $(this).prop("value");

      if (filePath.length > 0) {
        var fileNameStart = filePath.lastIndexOf("\\");
        var fileName = filePath.slice(-(filePath.length - fileNameStart - 1));
      } else {
        fileName = o.textEmpty;
      }

      selectedFileText.html(fileName);
    });
  }; // zainicjuj funkcje rowniez po stworzeniu popupa


  init();
  $("#popup").on("dialogopen", function () {
    init();
  });
};

/*
  np. rejestry/Zgloszenie2/Create
*/
function createDisplayForm(settings) {
  // wymagane zmienne, patrz wyzej
  var s = {
    $jsonForm: settings.$jsonForm,
    $displayForm: settings.$displayForm
  }; // opcjonalne zmienne, patrz wyzej

  var o = $.extend({}, settings, s);
  var jsonForm = JSON.parse(o.$jsonForm.val());
  var displayForm = o.$displayForm.val();

  if (!displayForm) {
    updateDisplayForm();
  }

  jsonForm.forEach(function (element) {
    var name = element.name;
    var $formElement = $("[name^=\"".concat(name, "\"], #").concat(name, "-other, #").concat(name, "-other-value"));
    var $otherFormElement = $("#".concat(name, "-other-value"));
    $formElement.on('input', function () {
      updateDisplayForm();
    });
    $otherFormElement.on('focus', function () {
      updateDisplayForm();
    });
  });

  function updateDisplayForm() {
    var jsonForm = JSON.parse(o.$jsonForm.val());
    var displayHtml = '';
    jsonForm.forEach(function (element) {
      displayHtml += createDisplayLabel('<label>' + (element.label || '') + '</label>');
      var fieldHtml = '';

      if (element.type == 'checkbox-group') {
        element.values.forEach(function (checkOptions) {
          var html1 = '<span>' + checkOptions.label + ': </span>';
          var html2 = '<span>' + (checkOptions.selected ? 'Tak' : 'Nie') + '</span>';
          fieldHtml += '<p>' + html1 + html2 + '</p>';
        });
        var $input = $("#".concat(element.name, "-other-value"));

        if ($input && $input.length) {
          // console.log($input);
          var html = $input.val() || ''; // console.log(html);
          // if (html) {

          var $check = $input.closest('.checkbox, .checkbox-inline').find("#".concat(element.name, "-other"));
          var html1 = '<span>' + html + ': </span>';
          var html2 = '<span>' + ($check.prop('checked') ? 'Tak' : 'Nie') + '</span>';
          fieldHtml += '<p>' + html1 + html2 + '</p>'; // }
        }
      } else if (element.type == 'radio-group') {
        element.values.forEach(function (checkOptions) {
          if (checkOptions.checked) {
            var _html = '<span>' + checkOptions.label + ': </span>';

            var _html2 = '<span>' + (checkOptions.checked ? 'Tak' : 'Nie') + '</span>';

            fieldHtml += '<p>' + _html + _html2 + '</p>';
          }
        });

        var _$input = $("#".concat(element.name, "-other-value"));

        if (_$input && _$input.length) {
          // console.log($input);
          var _html3 = _$input.val() || ''; // console.log(html);
          // if (html) {


          var _$check = _$input.closest('.radio, .radio-inline').find("#".concat(element.name, "-other"));

          var checked = _$check.prop('checked');

          if (checked) {
            var _html4 = '<span>' + _html3 + ': </span>';

            var _html5 = '<span>' + (_$check.prop('checked') ? 'Tak' : 'Nie') + '</span>';

            fieldHtml += '<p>' + _html4 + _html5 + '</p>';
          } // }

        }
      } else if (element.type == 'select') {
        var defaultSelected = false;
        element.values.forEach(function (selectOptions) {
          if (selectOptions.selected) {
            defaultSelected = true;
            fieldHtml += '<span>' + selectOptions.label + '</span>';
          }
        });

        if (!defaultSelected) {
          fieldHtml += '<span>' + element.values[0].label + '</span>';
        }
      } else {
        var _html6 = element.value || '';

        fieldHtml += _html6 ? '<span>' + _html6 + '</span>' : '';
      }

      displayHtml += createDisplayField(fieldHtml);
    });
    displayHtml = '<div class="display-group">' + displayHtml + '</div>';
    o.$displayForm.val(displayHtml);
  } // function isOtherInput(e, element) {
  //   return e.target && element.other && $(e.target)[0].id.includes('-other')
  // }


  function createDisplayLabel(text) {
    return '<div class="display-label">' + text + '</div>';
  }

  function createDisplayField(text) {
    return '<div class="display-field">' + text + '</div>';
  }
}

/*
  np. rejestry/Zgloszenie2/Create
*/
function initCaptcha(settings) {
  // wymagane zmienne, patrz wyzej
  // var s = {
  // };
  // opcjonalne zmienne, patrz wyzej
  var o = $.extend({
    el: '#captcha',
    key: this.captchaKey
  }, settings);
  var $captcha = $(o.el);

  if (window.grecaptcha && $captcha.length) {
    window.grecaptcha.render('captcha', {
      'sitekey': o.key
    });
  }
}

/*
  np. rejestry/Zgloszenie2/Create
*/
function submitFormAjax(settings) {
  // wymagane zmienne, patrz wyzej
  var s = {
    form: settings.form,
    options: settings.options
  }; // opcjonalne zmienne, patrz wyzej

  var o = $.extend({}, settings, s);
  $(document).on('submit', o.form, function () {
    // dokumentacja: http://malsup.com/jquery/form/
    $(this).ajaxSubmit(o.options);
    return false;
  });
}

/*
  np. rejestry/Zgloszenie2/Create
*/
function updateJsonForm(settings) {
  // wymagane zmienne, patrz wyzej
  var s = {
    $jsonForm: settings.$jsonForm,
    $htmlForm: settings.$htmlForm,
    renderedForm: settings.renderedForm
  }; // opcjonalne zmienne, patrz wyzej

  var o = $.extend({
    updateRenderFormOnLoad: true
  }, settings, s);
  var jsonForm = JSON.parse(o.$jsonForm.val());

  if (o.updateRenderFormOnLoad) {
    updateRenderForm();
  }

  jsonForm.forEach(function (element) {
    var name = element.name;
    var $formElement = $("[name^=\"".concat(name, "\"], #").concat(name, "-other-value"));
    var $otherFormElement = $("#".concat(name, "-other-value"));
    $formElement.on('input', function () {
      var $this = $(this);
      var jsonFormIndex = $this.closest('.form-group').index(); // usun break line i tagi html

      var elementValue = $this.val().replace(/\n/g, ' ').replace(/<\/?[^>]+(>|$)/g, "");
      var elementType = jsonForm[jsonFormIndex].type;
      var elementIndex = elementType === 'select' ? $this[0].selectedIndex : $this.closest('.checkbox, .radio, .checkbox-inline, .radio-inline').index();
      var multiValues = elementIndex !== -1 ? true : false;

      if (multiValues) {
        if (elementType === 'checkbox-group') {
          updateCheckbox();
        } else if (elementType === 'radio-group') {
          updateRadio();
        } else {
          updateSelect();
        }
      } else {
        jsonForm[jsonFormIndex].value = elementValue;
        $this[0].setAttribute('value', elementValue);

        if (elementType === 'textarea') {
          $this.html(elementValue);
        }
      }

      updateJsonFormObj();
      updateRenderForm();

      function updateSelect() {
        updateRadioSelect('selected');
        var $selectOptions = $formElement.find('option');

        for (var i = 0; i < $selectOptions.length; i++) {
          $selectOptions[i].removeAttribute('selected');
        }

        $this.find('option:selected')[0].setAttribute('selected', true);
      }

      function updateRadio() {
        var type = $this[0].type;
        updateRadioSelect('checked');

        for (var i = 0; i < $formElement.length; i++) {
          $formElement[i].removeAttribute('checked');
        }

        if (type == 'radio') {
          $this[0].setAttribute('checked', 'checked');
        } else if (type == 'text') {
          $this[0].setAttribute('value', elementValue);
        }
      }

      function updateRadioSelect(tickType) {
        for (var i = 0; i < jsonForm[jsonFormIndex].values.length; i++) {
          delete jsonForm[jsonFormIndex].values[i][tickType];
        }

        if (jsonForm[jsonFormIndex].values[elementIndex]) {
          jsonForm[jsonFormIndex].values[elementIndex][tickType] = true;
        }
      }

      function updateCheckbox() {
        var type = $this[0].type;

        if (type == 'checkbox') {
          var checked = $this[0].checked;
          var jsonCheck = elementIndex < jsonForm[jsonFormIndex].values.length;

          if (checked) {
            if (jsonCheck) {
              jsonForm[jsonFormIndex].values[elementIndex].selected = checked;
            }

            $this[0].setAttribute('checked', 'checked');
            $this[0].checked = true;
          } else {
            if (jsonCheck) {
              delete jsonForm[jsonFormIndex].values[elementIndex].selected;
            }

            $this[0].removeAttribute('checked');
          }
        } else if (type == 'text') {
          $this[0].setAttribute('value', elementValue);
        }
      }
    });
    $otherFormElement.on('focus', function (e) {
      var $input = $(e.target);

      if (name.includes('radio')) {
        var jsonFormIndex = $(this).closest('.form-group').index();

        for (var i = 0; i < jsonForm[jsonFormIndex].values.length; i++) {
          delete jsonForm[jsonFormIndex].values[i]['checked'];
        }

        for (var _i = 0; _i < $formElement.length; _i++) {
          $($formElement[_i]).prop('checked', false);

          $formElement[_i].removeAttribute('checked');
        }
      }

      if (name.includes('radio')) {
        var groupClassName = '.radio';
      } else if (name.includes('checkbox')) {
        groupClassName = '.checkbox';
      }

      var $groupInput = $input.closest(groupClassName).find("#".concat(name, "-other"));
      $groupInput.prop('checked', true);
      $groupInput[0].setAttribute('checked', 'checked');
      updateJsonFormObj();
      updateRenderForm();
    });
  });

  function updateJsonFormObj() {
    o.$jsonForm.val(JSON.stringify(jsonForm));
  }

  function updateRenderForm() {
    o.$htmlForm.val($(o.renderedForm).get(0).outerHTML);
  }
}

function _validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function _validateTel(tel) {
  var re = /^\d{9}$/;
  return re.test(String(tel));
}

/*
  np. rejestry/Shared/_LayoutPublic
*/

function validateForm(settings) {
  // wymagane zmienne, patrz wyzej
  var s = {
    form: settings.$form,
    msgContainer: settings.msgContainer
  }; // opcjonalne zmienne, patrz wyzej

  var o = $.extend({
    emailErrorMsg: 'Błędny adres email',
    textErrorMsg: 'Nie uzupełniono pola tekstowego',
    numberErrorMsg: 'Nie wpisano liczby',
    radioErrorMsg: 'Nie zaznaczono żadnej opcji w grupie jednokrotnego wyboru',
    checkboxErrorMsg: 'Nie zaznaczono pola wyboru',
    checkboxGroupErrorMsg: 'Nie zaznaczono żadnej opcji w grupie wielokrotnego wyboru',
    telErrorMsg: 'Błędny numer telefonu - musi składać się z 9 cyfr',
    selectErrorMsg: 'Nie wybrano żadnej opcji z listy wyboru',
    errorMsg: 'Nie uzupełniono wszystkich wymaganych pól',
    validationErrorClass: 'field-validation-error',
    validationErrorBorderClass: 'input-validation-error'
  }, settings, s);
  window.formValid = true;
  $(document).on('submit', o.form, function () {
    var $form = $(o.form);
    var $msgContainer = $(o.msgContainer);
    var $fieldsToCheck = $form.find('[required], input[type=email], input[type=tel]');
    $fieldsToCheck = $fieldsToCheck.filter(':not([id*="other-value"])');
    var errors = false;
    var currentName = '';
    $('.' + o.validationErrorClass).remove();
    $('.' + o.validationErrorBorderClass).removeClass(o.validationErrorBorderClass);
    $.each($fieldsToCheck, function (i, fieldToCheck) {
      var $fieldToCheck = $(fieldToCheck);
      var field = {
        type: $fieldToCheck.attr('type'),
        value: $fieldToCheck.val(),
        name: $fieldToCheck.attr('name'),
        tag: $fieldToCheck.tagName()
      };

      if (field.tag == 'input') {
        var inputRequired = $fieldToCheck.attr('required');

        if (inputRequired) {
          var validateInput = true;
        } else {
          if (field.value.length > 0) {
            validateInput = true;
          } else {
            validateInput = false;
          }
        }

        if (field.type == 'email') {
          if (!_validateEmail(field.value) && validateInput) {
            errors = addErrorMsg($fieldToCheck, o.emailErrorMsg);
          }
        } else if (field.type == 'number') {
          if (!field.value.length) {
            errors = addErrorMsg($fieldToCheck, o.numberErrorMsg);
          }
        } else if (field.type == 'radio') {
          if (!$('input[name="' + field.name + '"]:checked').length && currentName != field.name) {
            errors = addErrorMsg($fieldToCheck, o.radioErrorMsg);
          }
        } else if (field.type == 'checkbox') {
          if (!$('input[name="' + field.name + '"]:checked').length && currentName != field.name) {
            var checkboxErrorMsg = $('input[name="' + field.name + '"]').length == 1 ? o.checkboxErrorMsg : o.checkboxGroupErrorMsg;
            errors = addErrorMsg($fieldToCheck, checkboxErrorMsg);
          }
        } else if (field.type == 'tel') {
          if (!_validateTel(field.value) && validateInput) {
            errors = addErrorMsg($fieldToCheck, o.telErrorMsg);
          }
        } else {
          if (!field.value.length) {
            errors = addErrorMsg($fieldToCheck, o.textErrorMsg);
          }
        }
      } else if (field.tag == 'textarea') {
        if (!field.value.length) {
          errors = addErrorMsg($fieldToCheck, o.textErrorMsg);
        }
      } else if (field.tag == 'select') {
        if (!$('[name=' + field.name + '] option:selected').length) {
          errors = addErrorMsg($fieldToCheck, o.textErrorMsg);
        }
      }

      currentName = field.name;
    });

    if (errors) {
      window.formValid = false;
      $msgContainer.html(o.errorMsg);
      return false;
    } else {
      window.formValid = true;
    }
  });
  $(document).on('input', o.form + ' input, select, textarea', function () {
    var $msgContainer = $(o.msgContainer);
    $(this).removeClass(o.validationErrorBorderClass);

    if ($(this).attr('type') == 'radio') {
      getInputTextParent($(this), 'radio').parent().find('.' + o.validationErrorClass).remove();
    } else if ($(this).attr('type') == 'checkbox') {
      getInputTextParent($(this), 'checkbox').parent().find('.' + o.validationErrorClass).remove();
    } else {
      $(this).next('.' + o.validationErrorClass).remove();
    }

    $msgContainer.html('');
  });

  function addErrorMsg($fieldToCheck, err) {
    var errMsg = '<div class="' + o.validationErrorClass + '">' + err + '</div>';
    $fieldToCheck.addClass(o.validationErrorBorderClass);

    if ($fieldToCheck.attr('type') == 'radio') {
      getInputTextParent($fieldToCheck, 'radio').before(errMsg);
    } else if ($fieldToCheck.attr('type') == 'checkbox') {
      getInputTextParent($fieldToCheck, 'checkbox').before(errMsg);
    } else {
      $fieldToCheck.after(errMsg);
    }

    return true;
  }

  function getInputTextParent(input, type) {
    return input.closest(".".concat(type, "-group"));
  }
}

var Mstools = function Mstools() {
  _classCallCheck(this, Mstools);

  this.captchaKey = captchaKey; // metody

  this.changeInputs = changeInputs;
  this.changeFileInputs = changeFileInputs;
  this.createDisplayForm = createDisplayForm;
  this.initCaptcha = initCaptcha;
  this.submitFormAjax = submitFormAjax;
  this.updateJsonForm = updateJsonForm;
  this.validateForm = validateForm;
};

var gisonSip = new Mstools();

export default gisonSip;

