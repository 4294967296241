import $ from "jquery";

class Composition {
  constructor(config, settings) {
    this.profilName = config.profilName;
    this.map = settings.map;
    this.toolsClass = settings.toolsClass;
    this.tools = config.MapConf.tools || {};
    this.compositionsContainerClass = ".compositions__body";
    this.popupSelector = ".compositionPopup";
    this.treeStructure = config.treeStructure;
    this.bases = config.bases;
    this.storage = window.sessionStorage;
    this.allLayers = config.layerList;
    this.kompozycja = config.currentComposition || {};
    this.compositions = config.tematList;
    this.defaultCompositions = this.compositions;
    if (
      !/^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/i.test(
        window.location.pathname.substr(1)
      )
    ) {
      //    if (config.Guid != null) {
      this.addCompositions();
      this.highlightComposition();
    }
    if (this.compositions.length > 1) {
      $(".small-icon-compositions").addClass("responsive-icon-d");
      // if ($(window).width() <= 768 || $(window).height() <= 768) {
      //   $('.small-icon-compositions').css('display', 'flex');
      //   $('.composition-title').css('display', 'none');
      // }
      // $('.leaflet-control-compositions').css('display', 'block')
      $(".leaflet-control-compositions").removeClass(
        "leaflet-control-compositions--hidden"
      );
      if (this.compositions.length > 2) {
        $(".leaflet-control-compositions__body").css({
          "max-height": "214px"
        });
        $("leaflet-control-compositions").css({
          height: "270px"
        });
      }
    } else {
      // $('.leaflet-control-compositions').css('display', 'none')
      $(".leaflet-control-compositions").addClass(
        "leaflet-control-compositions--hidden"
      );
    }
    var that = this;
    $(window).resize(function() {
      if ($(window).width() <= 768 && $(window).height() <= 768) {
        if ($(".small-icon-compositions").hasClass("responsive-icon-d")) {
          //          $('.small-icon-compositions').css('display', 'flex');
        }
      } else if (that.compositions.length > 0) {
        $(".small-icon-composition-title").addClass("responsive-icon-d");
      } else {
        //        $('.small-icon-compositions').css('display', 'none');
      }
      if ($("leaflet-control-composition-title").css("display") == "flex") {
        $(".small-icon-composition-title").css("display", "none");
      } else {
        $(".small-icon-composition-title").css("display", "flex");
      }
    });

    $("#composition-title").on("wheel", function(e) {
      e = e || window.event;
      e.stopPropagation();
    });
  }

  highlightComposition() {
    var guid = this.kompozycja.webname;

    $('[data-compositionguid="' + guid + '"]').addClass(
      "compositions__body--item-btn-focused"
    );
  }

  addCompositions() {
    var that = this,
      len = this.defaultCompositions.length,
      compositionsArray = [];
    $.each(this.defaultCompositions, function(i, v) {
      if (v.kolejnosc > len) {
        len = v.kolejnosc;
      }
    });
    //    var compositionsArray = [];
    //UWZGLĘDNIENIE PARAMETRU 'kolejnosc'
    for (var i = 0; i <= len; i++) {
      // for (var i = 0; i <= 1; i++) {
      var compositions = $.grep(
        this.defaultCompositions,
        x => x.kolejnosc == i
      );
      if (compositions) {
        for (var j = 0; j < compositions.length; j++) {
          if (compositions[j].publ == true && compositions[j].webname != null) {
            compositionsArray.push(compositions[j]);
          }
        }
      } else {
        len++;
      }
    }
    for (var k = compositionsArray.length - 1; k >= 0; k--) {
      that.addComposition(compositionsArray[k]);
    }
  }

  addComposition(options) {
    var that = this;
    if (options.nazwanakafelku) {
      $(that.compositionsContainerClass).prepend(
        '<div class="compositions__body--item"><button class="compositions__body--item-btn" data-compositionGUID="' +
          options.webname +
          '">' +
          '<span class="btn-focused-changed">!</span>' +
          '</button><div class="compositions__body--item-btn-overlay" id="' +
          that.profilName +
          "--" +
          options.webname +
          '" data-compositionGUID-ov="' +
          options.webname +
          '"><p class="compositions__body--item-btn-overlay-text">' +
          options.nazwanakafelku +
          '</p></div><div class="compositions__body--item-name">' +
          options.nazwanakafelku +
          "</div></div>"
      );
    } else {
      $(that.compositionsContainerClass).prepend(
        '<div class="compositions__body--item"><button class="compositions__body--item-btn" data-compositionGUID="' +
          options.webname +
          '">' +
          '<span class="btn-focused-changed">!</span>' +
          '</button><div class="compositions__body--item-btn-overlay" id="' +
          that.profilName +
          "--" +
          options.webname +
          '" data-compositionGUID-ov="' +
          options.webname +
          '"><p class="compositions__body--item-btn-overlay-text"></p></div><div class="compositions__body--item-name"></div></div>'
      );
    }
    $('*[data-compositionGUID="' + options.webname + '"]').css(
      "background-image",
      'url("' + window.SERVER + window.ROOT + options.icon.substr(1) + '")'
    );
    $(document).off(
      "click tap",
      '*[data-compositionGUID="' + options.webname + '"]'
    );
    $(document).off(
      "click tap",
      '*[data-compositionGUID-ov="' + options.webname + '"]'
    );
    $(document).on(
      "click tap",
      '*[data-compositionGUID-ov="' + options.webname + '"]',
      function() {
        $(".small-icon-composition-title").addClass("responsive-icon-d");
        that.setMap(options);
      }
    );
  }

  setMap(options) {
    $(".composition-title").css({
      // 'display': 'block',
      "z-index": "12000"
    });
    window.location.href =
      window.location.origin + "/" + window.PROFILNAME + "_" + options.webname;
  }
}

export default Composition;
