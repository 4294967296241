import $ from "jquery";

class StartBackground {
  constructor(config) {
    if (config.MapConf.ArrowText) {
      this.set(".formPanel", config.MapConf.ArrowText);
      this.events();
    }
  }

  set(containerClass, text) {
    var right, bottom;
    if ($(window).width() > 768) {
      $(".start__background").addClass("start__background--visible");
      $(".start__background--content-text").html(text);
      right = $(containerClass).width();
      bottom = $(containerClass).height() + 120;
      $(".start__background--content").css({
        position: "fixed",
        right: right,
        bottom: bottom
      });
    } else {
      $(".start__background").addClass("start__background--visible");
      $(".start__background--content-text").html(text);
      right = $(containerClass).width();
      bottom = $(containerClass).height() + 40;
      $(".start__background--content").css({
        position: "fixed",
        right: right,
        "margin-right": "5px",
        bottom: bottom
      });
    }
  }

  events() {
    $(".start__background").on("click tap", function() {
      if ($(".start__background").hasClass("start__background--visible")) {
        $(".start__background").removeClass("start__background--visible");
      }
    });
  }

  hide(name) {
    $(name).css("display", "none");
  }
}

export default StartBackground;
