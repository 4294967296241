// biblioteki
import gisonSip from "./vendor/gison-sip";
import $ from "jquery";

import "../styles/style.scss";

import fancybox from "@fancyapps/fancybox";

// moduły js użytkownika
//import 'require';
import "./vendor/jquery-ui";
import "./vendor/jquery.ui.touch-punch.min";
import GetConfig from "./modules/GetConfig";
// import TileOnError from './modules/TileOnError';
import MapInit from "./modules/MapInit";
import PriorityNav from "./modules/PriorityNav2";
//import PriorityNav from './modules/priorityNav';
import Translate from "./modules/Translate";
import PageStyle from "./modules/PageStyle";
import MapTools from "./modules/MapTools";
import MapToolsFocus from "./modules/MapToolsFocus";
import Overlay from "./modules/Overlay";
import Print from "./modules/Print";
import Measure from "./modules/Measure";
import CenterView from "./modules/CenterView";
import FunctionsAfterLoad from "./modules/FunctionsAfterLoad";
import Legend from "./modules/Legend";
import SearchGugik from "./modules/SearchGugik";
import Search from "./modules/Search";
import SearchNew from "./modules/SearchNew";
import StreetView from "./modules/StreetView";
import MiniMap from "./modules/MiniMap";
import Geolocation from "./modules/Geolocation";
import CoordsSearch from "./modules/CoordsSearch";
import Coords from "./modules/Coords";
import MapInfo from "./modules/MapInfo";
import CheckLayers from "./modules/CheckLayers";
import Permalink from "./modules/Permalink";
import StartInfo from "./modules/StartInfo";
import FormCreator from "./modules/FormCreator";
import ChangeFontSize from "./modules/ChangeFontSize";
import Wcag from "./modules/Wcag";
import Help from "./modules/Help";
import ImgBtns from "./modules/ImgBtns";
import Counter from "./modules/Counter";
import UeLogos from "./modules/UeLogos";
import SideLinkButtons from "./modules/SideLinkButtons";
import StartingMenu from "./modules/startingMenu";
import News from "./modules/News";
import Twg from "./modules/Twg";
import HidePanel from "./modules/HidePanel";
import Responsive from "./modules/Responsive";
import Tools from "./modules/Tools";
import ActiveLayersManager from "./modules/ActiveLayersManager";
import TreeStructureManager from "./modules/TreeStructureManager";
import GetObjectFromPermalink from "./modules/GetObjectFromPermalink";
import WyboryBackgroundPosition from "./modules/WyboryBackground";
import StartBackground from "./modules/StartBackground";
import Eservices from "./modules/Eservices";
import Wybory from "./modules/Wybory";
import Login from "./modules/Login";
import NavigateMap from "./modules/NavigateMap";
import LayerOpacity from "./modules/LayerOpacity";
import SelectObject from "./modules/SelectObject";
import Loading from "./modules/Loading";

// import Stats from './modules/Stats';
import "unfetch/polyfill";

window.mstools = gisonSip;
var tools = new Tools();

window.$ = $;
// pobierz ustawienia
var getConfig = new GetConfig();
// dodaj overlay
new Overlay();

getConfig.promise.then(
    function (config) {
        //zmienne globalne
        window.scriptParams = {
            def: {
                printing: true,
                printingPresets: false,
                printingWmsLayersSpecial: false,
                printingDefaultLayersSpecial: false,
                printingParams: {
                    printView: "print/EditHtml"
                },
                generateDoc: false
            },
            settings: {
                wmsLayers: config.layerList
            }
        };
        window.printInfoDeferred = $.Deferred();

        var modules = {};
        // zainicjuj mape
        var additionalVectorGroups = config.additionalVectorGroups || [],
            additionalVectorLayers = config.additionalVectorLayers || [],
            treeStructureManager = new TreeStructureManager(config);

        config.layerList = config.layerList.concat(additionalVectorLayers) || [];
        config.treeStructure =
            config.treeStructure.concat(additionalVectorGroups) || [];

        config.treeStructure = treeStructureManager.updateTreeStructure(
            config.treeStructure
        );

        config.layerListKeys = tools.arrayToObject(config.layerList, "id");

        // var tileOnError = new TileOnError()

        var mapInit = new MapInit(config);
        mapInit.init(config);
        var activeLayersManager = new ActiveLayersManager(config),
            getObjectFromPermalink = new GetObjectFromPermalink(config),
            map = mapInit.map;
        window.map = map;

        var states = mapInit.states || {};

        window.states = states;

        // new Stats()

        // dodaj translatora
        new Translate(config);

        // dodaj styl strony
        new PageStyle(config);

        // dodaj narzedzia mapy
        var mapTools = new MapTools(config, {
            map: map
        });

        // var tileOnError = new TileOnError()

        // dodaj focus na wybrane narzędzia
        new MapToolsFocus(),
            // dodaj zwijanie paska narzędzi
            new PriorityNav();

        // dodaj narzedzie drukowania
        var print = new Print(config, {
            map: map,
            states: states,
            toolsClass: mapTools.toolsClass
        });

        // dodaj narzedzie pomiaru
        new Measure(config, {
            map: map,
            states: states,
            toolsClass: mapTools.toolsClass
        });

        // dodaj kreator formularzy
        new FormCreator(config, {
            map: map,
            states: states,
            toolsClass: mapTools.toolsClass
        });

        // dodaj narzedzie do kompozycji mapowych
        /*var composition = new Composition(config, {
          map: map,
          toolsClass: mapTools.toolsClass,
        });*/

        // dodaj narzedzie odpytywania warstw
        var mapInfo = new MapInfo(config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            states: states,
            activeLayersManager: activeLayersManager
        });

        // dodaj narzedzie street view
        new StreetView(config, {
            map: map,
            states: states,
            toolsClass: mapTools.toolsClass
        });

        // dodaj narzedzie mini mapy
        new MiniMap(config, {
            map: map,
            //      states: states,
            toolsClass: mapTools.toolsClass
        });

        // dodaj narzedzie wczytujące odpowiednie warstwy
        new CheckLayers(config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            additionalLayersAdded: mapInit.additionalLayersAdded,
            getObjectFromPermalink: getObjectFromPermalink
        });

        // dodaj narzedzie wysrodkowania widoku
        new CenterView(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        // dodaj animację ładowania
        new Loading(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        // dodaj narzedzie legendy
        new Legend(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        // dodaj narzedzie wyszukiwania
        // var search = new Search(config, {
        //     map: map,
        //     toolsClass: mapTools.toolsClass,
        //     activeLayersManager: activeLayersManager
        // });

        // dodaj narzedzie wyszukiwania
        var search = new SearchGugik(config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            activeLayersManager: activeLayersManager
        });


        // // dodaj narzedzie wyszukiwania
        var searchNew = new SearchNew(config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            activeLayersManager: activeLayersManager
        });

        // dodaj narzedzie wyszukiwania
        new CoordsSearch(config, {
            map: map,
            states: states,
            toolsClass: mapTools.toolsClass
        });

        // dodaj narzędzie wyświetlania współrzędnych
        new Coords(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        // dodaj początkowe mobilne menu
        new StartingMenu(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        // dodaj popup na początku
        new StartInfo(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        // dodaj euslugi
        new Eservices(
            config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            states: states,
            activeLayersManager: activeLayersManager
        },
            mapTools
        );

        // dodaj moduł wyborów
        new Wybory(
            config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            states: states,
            activeLayersManager: activeLayersManager
        },
            mapTools
        );

        new WyboryBackgroundPosition();

        // dodaj przyciemnioną strone i info po wejściu na portal
        new StartBackground(config);

        // dodaj opcję zmiany wielkości czcionki
        new ChangeFontSize(config);

        // dodaj aktualności
        new News(config, {
            map: map
        });

        // dodaj moduł Turystyki (Turystyka w Gminie)
        modules.Twg = new Twg(config, {
            map: map
        });

        // dodaj opcję zmiany kolorystyki na wcag
        new Wcag(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        // dodaj pomoc
        new Help(config);

        // dodaj panel ze zmianą przeźroczystości warstw
        new LayerOpacity(
            config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            states: states,
            activeLayersManager: activeLayersManager
        },
            mapTools
        );

        // dodaj możliwość przechodzenia po mapie w przód/tył
        new NavigateMap(
            config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            states: states,
            activeLayersManager: activeLayersManager
        },
            mapTools
        );

        // dodaj panel z zaznczaniem elementu warstwy
        new SelectObject(
            config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            states: states,
            activeLayersManager: activeLayersManager
        },
            mapTools
        );

        // dodaj loga na górze strony
        new UeLogos(config);

        // dodaj buttony z linkami
        new SideLinkButtons(config);

        // dodaj Licznik
        new Counter(config);

        // dodaj przyciski boczne
        new ImgBtns(config);

        // dodaj narzedzie permalink
        var permalink = new Permalink(config, {
            map: map,
            toolsClass: mapTools.toolsClass,
            states: states,
            activeLayersManager: activeLayersManager,
            getObjectFromPermalink: getObjectFromPermalink,
            modules: modules
        });

        // dodaj login
        new Login(config, {
            map: map,
            mapInit: mapInit,
            mapInfo: mapInfo,
            activeLayersManager: activeLayersManager,
            permalink: permalink,
            print: print,
            //search: search
        });

        // dodaj narzedzie geolokalizacji
        new Geolocation(config, {
            map: map,
            states: states,
            permalink: permalink,
            toolsClass: mapTools.toolsClass,
            activeLayersManager: activeLayersManager,
            getObjectFromPermalink: getObjectFromPermalink
        });

        new HidePanel(config, {
            map: map,
            toolsClass: mapTools.toolsClass
        });

        new Responsive(config, {
            map: map,
            states: states,
            toolsClass: mapTools.toolsClass
        });

        if (!(window.location.href.indexOf("m=") > -1)) {
            window.sessionStorage.setItem("mode", "none");
        }

        if (config.title != undefined) {
            $(".bar-top__title--name").html("<b>" + config.title + "</b>");
            $(".starting-panel--logo-title").html("Gmina " + config.title);
        }

        document.title = "System Informacji Przestrzennej - " + config.title;
        // dodaj narzedzie wysrodkowania widoku

        var html = $(".leaflet-control-attribution").html();
        $(".leaflet-control-attribution").html(
            html +
            ' | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        );

        new FunctionsAfterLoad();
    },
    function () {
        console.log("promise error");
        $("body").html(``);
        $("body").html(`<h1> PROMISE ERROR </h1>`);
    }
);