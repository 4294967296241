import $ from 'jquery';

class TapPointer {
    constructor(config, settings) {
        this.map = settings.map;
        this.animationOn = false;
        this.$pointer = '';
        this.buildElem();
        this.events();
    }

    events() {
        var that = this;
        that.map.on("click tap", function(e) {
            that.tap(e, false);
        })
    }

    buildElem() {
        var id = this.map._container.id;
        this.$mapContainer = $('#' + id);
        this.$mapContainer.append('<div class="tap-finish highlight-pointer" id="highlight-pointer"></div>');
        this.try = 0;
        this.$pointer = this.$mapContainer.find('#highlight-pointer');
    }

    changePosition(e) {
        var originalEvent = e.originalEvent,
            pageClickCoords = {
                x: originalEvent.pageX,
                y: originalEvent.pageY
            },
            mapPosition = {
                x: this.$mapContainer.offset().left,
                y: this.$mapContainer.offset().top,
            },
            newPosition = {
                left: pageClickCoords.x - mapPosition.x - 15,
                top: pageClickCoords.y - mapPosition.y - 15
            };
        this.$pointer.css({
            left: newPosition.left,
            top: newPosition.top
        });
    }

    tap(e, isContextOpen) {
        if (isContextOpen == false) {
            var that = this;
            if (this.animationOn == false) {
                this.animationOn = true;
                this.changePosition(e);
                this.$pointer.css({
                    transform: 'scale(1)'
                });
                that.$pointer
                    .removeClass('tap-finish')
                    .removeClass('tap-gone')
                    .addClass('tap-start');
                setTimeout(function() {
                    that.$pointer
                        .removeClass('tap-start')
                        .addClass('tap-finish');
                    that.animationOn = false;
                }, 50);
                setTimeout(function() {
                    that.$pointer
                        .addClass('tap-gone');
                }, 200)
            }
        }
    }
}

export default TapPointer