import $ from "jquery";
import { setHeightContainer } from "./HidePanel.js";
// logowanie
class Security {
    constructor() {
        window.SecurityManager = {
            salt: "kB90KMYxYtHoTb1ZL2Zv",
            username: "",
            // key: sessionStorage['SecurityManager.key'],
            key: "",
            ip: null,
            generateToken: function (username, password) {
                if (username && password) {
                    // If the user is providing credentials, then create a new key.
                    window.SecurityManager._logout();
                }
                // Set the key to a hash of the user's password + salt.
                var key = window.CryptoJS.enc.Base64.stringify(
                    window.CryptoJS.HmacSHA256(
                        [password, window.SecurityManager.salt].join(":"),
                        window.SecurityManager.salt
                    )
                );
                // Persist key pieces.
                if (username) {
                    //sessionStorage['SecurityManager.username'] = username;
                    localStorage["SecurityManager.username"] = username;
                    localStorage["username"] = username;

                    //sessionStorage['SecurityManager.key'] = key;
                    localStorage["SecurityManager.key"] = key;
                    localStorage["SecurityManager.profil"] = window.location.pathname.indexOf('_') > -1 ? window.location.pathname.substr(1).substr(0, window.location.pathname.indexOf('_') - 1) : window.location.pathname.substr(1);
                }
                return window.SecurityManager.sendUserKey(username, key);
            },
            sendUserKey: function (username, key) {
                // Set the username.
                window.SecurityManager.username = username;
                // Set the client IP address.
                // SecurityManager.ip = SecurityManager.ip || SecurityManager._getIp();
                // Get the (C# compatible) ticks to use as a timestamp. http://stackoverflow.com/a/7968483/2596404
                var ticks = new Date().getTime() * 10000 + 621355968000000000,
                    // Construct the hash body by concatenating the username, ip, and userAgent.
                    // var message = [SecurityManager.username, SecurityManager.ip, navigator.userAgent.replace(/ \.NET.+;/, ''), ticks].join(':');
                    // var message = [username, '_', navigator.userAgent.replace(/ \.NET.+;/, ''), ticks].join(':');
                    message = [username, "_", "SIP", ticks].join(":"),
                    // Hash the body, using the key.
                    hash = window.CryptoJS.HmacSHA256(message, key),
                    // Base64-encode the hash to get the resulting token.
                    token = window.CryptoJS.enc.Base64.stringify(hash),
                    // Include the username and timestamp on the end of the token, so the server can validate.
                    tokenId = [username, ticks].join(":"),
                    // Base64-encode the final resulting token.
                    tokenStr = window.CryptoJS.enc.Utf8.parse(
                        [token, tokenId].join(":")
                    ),
                    tokenEnc = window.CryptoJS.enc.Base64.stringify(tokenStr);
                window.SecurityManager._token = tokenEnc;
                return tokenEnc;
            },
            _logout: function () {
                window.SecurityManager.ip = null;
                // sessionStorage.removeItem('SecurityManager.username');
                localStorage.removeItem("SecurityManager.username");
                // SecurityManager.username = null;
                // sessionStorage.removeItem('SecurityManager.key');
                localStorage.removeItem("SecurityManager.key");
                localStorage.removeItem("SecurityManager.profil");
                // SecurityManager.key = null;
            },
            _getIp: function () {
                var result = "";
                $.ajax({
                    url: "http://ip-api.com/json",
                    method: "GET",
                    async: false,
                    success: function (response) {
                        result = response.query;
                    },
                });
                return result;
            },
            sendToken: function (token) {
                var profile = window.profileSettings;

                return $.ajax({
                    url:
                        profile.server +
                        profile.ROOT +
                        profile.layers.action +
                        profile.layersGuid,
                    dataType: "json",
                    data: {
                        token: token,
                    },
                });
            },
        };

        // jquery plugin do tworzenia formularza logowania
        (function ($) {
            $.fn.jsLogin = function (options) {
                var settings = $.extend(
                    {
                        userPlaceholder: "nazwa użytkownika",
                        passPlaceholder: "hasło",
                        btnText: "Zaloguj",
                        className: "js-login",
                    },
                    options
                ),
                    inputClassName = settings.className + "__input";
                if (!this.find("." + inputClassName).length) {
                    this.addClass(settings.className).addClass(
                        settings.className + "__container"
                    );
                    var $form = $("<form/>", {
                        action: "",
                        method: "get",
                        class: settings.className + "__form",
                    });
                    var $inputUser = $('<input type="text" placeholder="' + settings.userPlaceholder + '" autocomplete="off" class="' + settings.className + " " + inputClassName + " " + inputClassName + "--user" + '"/>')
                    // var $inputUser = $("<input/>", {
                    //     type: "text",
                    //     placeholder: settings.userPlaceholder,
                    //     autocomplete: "off",
                    //     class:
                    //         settings.className +
                    //         " " +
                    //         inputClassName +
                    //         " " +
                    //         inputClassName +
                    //         "--user",
                    // });
                    var $inputPass = $('<input type="password" placeholder="' + settings.passPlaceholder + '" autocomplete="off" class="' + settings.className + " " + inputClassName + " " + inputClassName + "--password" + '"/>')
                    // var $inputPass = $("<input/>", {
                    //     type: "password",
                    //     placeholder: settings.passPlaceholder,
                    //     autocomplete: "off",
                    //     class:
                    //         settings.className +
                    //         " " +
                    //         inputClassName +
                    //         " " +
                    //         inputClassName +
                    //         "--password",
                    // });
                    var $submit = $("<input/>", {
                        type: "submit",
                        value: settings.btnText,
                        class:
                            settings.className +
                            " " +
                            settings.className +
                            "__submit" +
                            " " +
                            "przycisk",
                    });
                    this.append(
                        $form
                            .append($inputUser)
                            .append($inputPass)
                            .append($submit)
                    );
                    setTimeout(function () {
                        var input = document.querySelector(
                            "." + inputClassName + "--user"
                        );
                        //input.focus();
                    }, 100);
                }
                return this;
            };

            $.fn.jsLoginDone = function (doneFunction, failFunction, options) {
                var settings = $.extend(
                    {
                        className: "js-login",
                        errorMsg: "Błędny login i/lub hasło",
                    },
                    options
                ),
                    $this = $(this),
                    $form = $this.find("form");

                $form.on("submit", function (e) {
                    e.preventDefault();
                    var $userInput = $(this).find(
                        "." + settings.className + "__input--user"
                    ),
                        $passwordInput = $(this).find(
                            "." + settings.className + "__input--password"
                        );

                    sendTokenFunc($userInput, $passwordInput);
                });

                function sendTokenFunc($userInput, $passwordInput) {
                    var userName = $userInput.val(),
                        password = $passwordInput.val(),
                        token = window.SecurityManager.generateToken(
                            userName,
                            password
                        );
                    window.SecurityManager._token = token;
                    var sendToken = window.SecurityManager.sendToken(token);
                    sendToken.done(function (data) {
                        if (typeof data == "object") {
                            doneFunction(data, token);
                        } else {
                            if (!$this.find(".errorMsg").length) {
                                $this.append(
                                    '<div class="errorMsg"><span style="color: red;">' +
                                    settings.errorMsg +
                                    "</span></div>"
                                );
                            }
                            failFunction();
                        }
                        //! dynamic set height for leaflet-control-layers-overlays
                        setTimeout(function () {
                            setHeightContainer();
                        }, 0);
                        //!---------
                    });
                    sendToken.fail(function (data) {
                        localStorage["username"] = '';
                        if (!$this.find(".errorMsg").length) {
                            $this.append(
                                '<div class="errorMsg"><span style="color: red;">' +
                                settings.errorMsg +
                                "</span></div>"
                            );
                        }
                        failFunction();
                    });
                }
                return this;
            };
        })($);
    }
}

export default Security;
