import $ from 'jquery';

class UeLogos {
     constructor(config) {
          if (config.mapConfig.MapConf.PasekNaGorze) {
               this.imgs = config.icons;
               this.text = config.mapConfig.MapConf.TekstNaPasku;

               this.addLogos(
                    this.createHtmlImgs(
                         this.getLeftImgs(this.sortArray(this.imgs, 'left'),'l')
                    ),
                    this.createHtmlImgs(
                         this.getRightImgs(this.sortArray(this.imgs, 'right')),'r'
                    ),
                    this.text
               );
               if (config.mapConfig.MapConf.KolorPaska) {
                    this.bgcolor = config.mapConfig.MapConf.KolorPaska;
                    $('.top-logos__body').css('background-color', this.bgcolor);
               }
          }
     }

     addLogos(imgLeftHtml, imgRightHtml, text) {
          $('.leaflet-top').addClass('leaflet-top__top-logos');
          // $('#map').addClass('map__top-logos')
          $('.bar-top').addClass('bar-top__top-logos');
          $('.modePanel').addClass('modePanel__top-logos');
          $('.small-icon-modePanel').addClass(
               'small-icon-modePanel__top-logos'
          );
          $('.drawEditPanel').addClass('drawEditPanel__top-logos');
          $('.small-icon-drawEditPanel').addClass(
               'small-icon-drawEditPanel__top-logos'
          );
          $('.counterPanel').addClass('counterPanel__top-logos');
          $('#overlayLayers').addClass('leaflet-control-layers__top-logos');
          $('.leaflet-control-layers-overlays__body').addClass(
               'leaflet-control-layers-overlays__body__top-logos'
          );
          $('.leaflet-control-layers-overlays__body-l').addClass(
               'leaflet-control-layers-overlays__body__top-logos-l'
          );
          $('.leaflet-control-layers-overlays__body-m').addClass(
               'leaflet-control-layers-overlays__body__top-logos-m'
          );
          $('.leaflet-control-layers-overlays__body-s').addClass(
               'leaflet-control-layers-overlays__body__top-logos-s'
          );
          $('.small-icon-composition-title').addClass(
               'small-icon-composition-title__top-logos'
          );
          /* var overlaysHeight = $('.leaflet-control-layers-overlays__body').css('max-height')
        $('.leaflet-control-layers-overlays__body').css('max-height', (parseInt(overlaysHeight, 10) - 80) + 'px') */
          $('.top-logos').addClass('top-logos__visible');
          var html =
               imgLeftHtml +
               (text != ``
                    ? `<div class="top-logos__body--text"><p>` +
                      text +
                      `</p></div>`
                    : ``) +
               imgRightHtml;
          $('.top-logos__body').append(html);
     }

     sortArray(array, side) {
          var that = this;
          if (side == 'right') {
               return [...array].sort(that.compareImgsRight);
          } else {
               return [...array].sort(that.compareImgsLeft);
          }
     }

     compareImgsLeft(a, b) {
          if (a.kolejnosc > b.kolejnosc) return 1;
          if (a.kolejnosc < b.kolejnosc) return -1;

          return 0;
     }

     compareImgsRight(b, a) {
          if (a.kolejnosc > b.kolejnosc) return 1;
          if (a.kolejnosc < b.kolejnosc) return -1;

          return 0;
     }

     getRightImgs(imgs) {
          var right = [],
               j = 0;
          $.each(imgs, function (i, img) {
               if (!img.odLewej) {
                    right[j] = img;
                    j++;
               }
          });
          return right;
     }

     getLeftImgs(imgs) {
          var left = [],
               j = 0;
          $.each(imgs, function (i, img) {
               if (img.odLewej) {
                    left[j] = img;
                    j++;
               }
          });
          return left;
     }

     createHtmlImgs(imgs,side) {
          var html = ``;
          $.each(imgs, function (i, img) {
               html +=
                    '<img class="top-logos__body--imgs-img" src="' +
                    window.SERVER +
                    window.ROOT +
                    'Content/imgs/icons/' +
                    img.guid +
                    img.format +
                    '" />';
          });
          var className = side == 'r' ? 'top-logos__body--imgs-right' : 'top-logos__body--imgs-left'
          return `<div class="top-logos__body--imgs `+ className+`">` + html + `</div>`;
     }
}

export default UeLogos;
