//import Tools from '././Tools';
//var tools = new Tools();
import L from 'leaflet';
import $ from 'jquery';

class eService_parcelsInfo {

    constructor(config, settings, lp) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.lp = lp;
        this.storage = window.sessionStorage;
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf || {};
        this.states = settings.states;
        this.treeStructure = config.treeStructure;
        this.bases = config.bases;
        this.layers = config.layerListKeys;
        this.activeLayersManager = settings.activeLayersManager;
        this.allOrder = this.activeLayersManager.getLayersOrder('all');
        this.infoIcon = L.icon({
            iconUrl: './assets/images/icons/bt_info.png',
            iconSize: [30, 30],
        });
        this.infoCursor = L.marker([0, 0], {
            icon: this.infoIcon
        });
        this.layerIDs = this.getLayerIDs(config.layerListKeys);
        this.parcelLayer = null;
        this.uncheck = false;
        this.addServiceBtn();
        this.activeLayersManager = settings.activeLayersManager;
        this.layerListKeys = config.layerListKeys;
        this.states.es_onInfo = false;
        this.states.es_onInfo_pi = false;
        this.events();
    }

    getLayerIDs(layerList) {
        var layerIDs = [];
        $.each(layerList, (x, y) => {
            if (y.name.includes('Działki')) {
                layerIDs.push(y.id);
            }
        })
        return layerIDs;
    }

    events() {
        var that = this;

        this.map.on('click', function(e) {
            if (that.states.es_onInfo_pi) {
                that.clickParams = {
                    latlng: e.latlng,
                    zoom: that.map.getZoom(),
                    xy: e.containerPoint,
                };
                var promises = that.getInfo();
                Promise.all(promises).then(responses => {
                    var okResponses = responses.filter(response => {
                        if (response.status == 200) {
                            return response;
                        }
                    });
                    return Promise.all(okResponses.map(res => res.text()))
                }).then(texts => {
                    that.getInfoDone(texts);
                });
            }
        });

        that.map.on('mousemove', function(e) {
            that.infoCursor.setLatLng([e.latlng.lat, e.latlng.lng]);
        });

        $('.modePanel__body--btn-cancel').on('click', function() {
            $('.modePanel').removeClass('modePanel--active');
            $('.small-icon-modePanel').css('display', 'none');
            that.storage.setItem('mode', 'none');
            that.states.es_onInfo = false;
            that.states.es_onInfo_pi = false;
            that.states.onInfo = true;
            that.infoCursor.removeFrom(that.map);
            if (that.parcelLayer) {
                that.unselectLayer();
            }
            $('.eservice-close').click();
        })
    }

    addServiceBtn() {
        var that = this;

        var serviceDiv = $('<div>', {
            html: `<div class="eservicePanel__body--item-text">Wyświetlanie informacji o działkach ewidencyjnych</div>
<div class="eservice-btn eservicePanel__body--item-btn" id=""><img class="eservicePanel__body--item-btn-img" src="assets/images/eservices/eservice_parcelsinfo.png"/></div>`,
            class: 'eservicePanel__body--item',
            id: 'eService__parcelsInfo'
        });

        $('.eservicePanel__body').append(serviceDiv);

        $('#eService__parcelsInfo').on('click', function() {
            $('.modePanel__body--btn-cancel').click();
            that.emptyPopup();
            that.states.onInfo = false;
            that.states.es_onInfo = true;
            that.states.es_onInfo_ai = false;
            that.states.es_onInfo_ti = false;
            that.states.es_onInfo_pi = true;
            $('.modePanel').addClass('modePanel--active');
            $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie informacji o działkach ewidencyjnych');
            that.storage.setItem('mode', 'es-ip');
            that.activateParcelLayer();
            that.infoCursor.addTo(that.map);
        })
    }

    emptyPopup() {
        $('.eservicePopup').removeClass('overlay--active');
        $('.eservicePopup__title').text('');
        $('.eservicePopup__body').html(``);
    }

    activateParcelLayer() {
        var that = this;
        var parcelLayers = $.grep(Object.values(that.layers), x => x.name.includes('Działki'));
        if (parcelLayers) {
            var parcelLayer = $.grep(Object.values(that.layers), x => x.name == 'Działki-SWDE').length > 0 ? $.grep(Object.values(that.layers), x => x.name == 'Działki-SWDE')[0] : ($.grep(Object.values(that.layers), x => x.name == 'Działki-LPIS').length > 0 ? $.grep(Object.values(that.layers), x => x.name == 'Działki-LPIS')[0] : parcelLayers[0]);
            this.parcelLayer = parcelLayer;
            if (parcelLayer) {
                that.selectLayer(parcelLayer, parcelLayer.id);
            }
        }
    }

    selectLayer(layer, layerID) {
        var order = this.allOrder,
            indexes = this.getAllIndexes(order, layerID),
            that = this;
        $('.leaflet-control-layers-selector').each(function(i) {
            if ($.inArray(i, indexes) > -1) {
                if ($(this)[0].checked == false) {
                    that.uncheck = true;
                    $(this).click();
                }
            }
        })
    }

    unselectLayer() {
        var order = this.allOrder,
            layerID = this.parcelLayer.id,
            indexes = this.getAllIndexes(order, layerID),
            that = this;
        $('.leaflet-control-layers-selector').each(function(i) {
            if ($.inArray(i, indexes) > -1) {
                if (that.uncheck) {
                    if ($(this)[0].checked == true) {
                        $(this).click();
                        that.uncheck = false;
                    }
                }
            }
        })
    }

    getAllIndexes(arr, val) {
        var indexes = [],
            i = -1;
        while ((i = arr.indexOf(val, i + 1)) != -1) {
            indexes.push(i);
        }
        return indexes;
    }

    getInfo() {
        var activeLayersIds = this.layerIDs,
            promises = [],
            layersUrls = [];
        activeLayersIds.forEach(activeLayerId => {
            var layer = this.layerListKeys[activeLayerId];
            if (layer.queryable && !layersUrls.includes(layer.queryUrl)) {
                var request = layer.queryUrl ? this.queryRequest(layer) : this.wmsRequest(layer);
                promises.push(request);
            }
            if (layer.queryUrl && !layersUrls.includes(layer.queryUrl)) {
                layersUrls.push(layer.queryUrl);
            }
        });
        return promises;
    }

    queryRequest(layer) {
        var url = window.PROXY + this.encodeUrl(this.parseUrl(layer.queryUrl));
        return fetch(url);
    }

    wmsRequest(layer) {
        var point = this.clickParams.xy,
            layers = layer.options.layers.split(','),
            source = L.WMS.source(layer.url, layer.options);
        source._map = this.map;
        var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(source, point, layers);
        var url = layer.url + L.Util.getParamString(wmsParams, layer.url);
        url = window.PROXY + this.encodeUrl(this.removeGwcServiceFromUrl(url));
        return fetch(url, {
            dataType: 'json'
        });
    }

    encodeUrl(url) {
        return encodeURIComponent(url);
    }

    parseUrl(url) {
        let {
            lat,
            lng
        } = this.clickParams.latlng;
        url = url.replace(/%lat%/g, lat)
            .replace(/%lon%|%lng%/g, lng)
            .replace(/%zoom%/g, this.clickParams.zoom);
        return url;
    }

    removeGwcServiceFromUrl(url) {
        return url.replace('gwc/service/', '');
    }

    getInfoDone(data) {
        var html = $.trim(this.getDataHtml(data));
        if (html) {
            this.openPopup(html);
        }
    }

    openPopup(html) {
        return L.popup()
            .setLatLng(this.clickParams.latlng)
            .setContent(html)
            .openOn(this.map);
    }

    parseJsonToHtml(data) {
        var html = '';
        try {
            var json = JSON.parse(data),
                json2 = JSON.parse(json);
            if (json.popup) {
                html = json.popup;
            }
            html = this.createPopup(json2)
        } catch (err) {
            html = data;
        }
        return html;
    }

    createPopup(json) {
        return `<div><u><b>` + json.properties.layer + `</b></u><br><b>Numer:  </b><span>` + json.properties.numer + `</span><br><b>Obręb </b><span>` + json.properties.obreb + `</span><br><b>ID: </b><span>` + json.properties.label.substr(0, json.properties.label.indexOf('(')) + `</span></div>`;
    }

    getDataHtml(dataArr) {
        var html = '',
            htmls = [],
            dataHtml
        dataArr.forEach((data) => {
            // if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {} else if (/<\/ServiceException/g.test(data)) {
            if (/<\/ServiceException/g.test(data)) {
                dataHtml = '';
            } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                dataHtml = '';
            } else {
                dataHtml = this.parseJsonToHtml(data);
            }
            var check = 0;
            $.each(htmls, function(i, v) {
                if (dataHtml == v) {
                    check++;
                }
            })
            if (check == 0) {
                if (dataHtml != '' && dataHtml.length > 1) {
                    htmls.push(dataHtml);
                }
            }
        });
        html = htmls.join('<hr>');
        return html;
    }

    getBodyHtml(html) {
        var match = html.match(/<body>[\s\S]*?<\/body>/g);
        if (match && match[0]) {
            html = match[0].replace('<body>', '').replace('</body>', '');
        } else {
            return '';
        }
        return (/\S/g.test(html)) ? html : '';
    }

}

export default eService_parcelsInfo;