import Tools from './Tools';
import L from 'leaflet';
import $ from 'jquery';

var tools = new Tools();

class Geolocation {

    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.states = settings.states;
        this.states.geolocation = false;
        this.geolcoationProps = tools.createElPropertiesFromClass(this.toolsClass + '__geolocation');
        this.Permalink = settings.permalink;
        this.permalinkOpt = true;
        this.events();
    }

    events() {
        var that = this,
            interval;

        $(document).on('click tap', this.geolcoationProps.selector, () => {
            //      this.geolocate();

            if (this.states.geolocation) {
                clearInterval(interval);
                that.states.geolocation = false;
            } else {
                that.states.geolocation = true;
                this.geolocate();
                interval = setInterval(function() {
                    that.geolocateUpdate();
                }, 15000)
            }
        });

        this.map.on('locationfound', this.onLocationFound.bind(this));
    }

    getRandomInRange(from, to, fixed) {
        return (Math.random() * (to - from) + from).toFixed(fixed) * 1;
        // .toFixed() returns string, so ' * 1' is a trick to convert to number
    }

    geolocate() {
        if (window.location.protocol == 'https:' && navigator.geolocation) {
            this.getHTMLGeolocation();
        } else {
            //      this.getJsonGeolocation();
        }
    }

    geolocateUpdate() {
        if (window.location.protocol == 'https:' && navigator.geolocation) {
            this.getHTMLGeolocation();
        } else {
            //      this.getJsonGeolocation(true);
        }
    }

    getHTMLGeolocation() {
        this.map.locate({
            setView: false
        });
    }

    onLocationFound(e) {
        var latng = e.latlng;
        var position = {
            coords: {
                latitude: latng.lat,
                longitude: latng.lng,
            }
        }
        this.addLocationPoint(position, true);
    }

    getJsonGeolocation() {
        $.getJSON('http://ip-api.com/json', (data, status) => {
            if (status === "success") {
                var position = {
                    coords: {
                        latitude: data.lat,
                        longitude: data.lon,
                        //            latitude: that.getRandomInRange(50.011118, 50.011120, 5),
                        //            longitude: that.getRandomInRange(20.083094, 20.084000, 5),
                    }
                }
                this.addLocationPoint(position, false);
            }
            // else {
            //   bootbox.alert('Geolokalizacja niedostępna');
            // }

        });
        /*var position = {
      coords: {
        latitude: that.getRandomInRange(50.011118, 50.011278, 5),
        longitude: that.getRandomInRange(20.083094, 20.083274, 5),
      }}
      this.addLocationPoint(position, false, update);
*/
    }

    addLocationPoint(position, isHttps) {
        var latlng = L.latLng(position.coords.latitude, position.coords.longitude);
        if (this.locationMarker) {
            //      this.map.removeLayer(this.locationMarker);
            this.locationMarker.setLatLng(latlng);
        } else {
            this.locationMarker = L.circleMarker(latlng, {
                radius: 7,
                fillColor: '#4285F4',
                color: '#fff',
                fillOpacity: 1,
                opacity: 1,
            })
            this.map.setView(latlng, 17);
            this.locationMarker.addTo(this.map).bindPopup(this.locationHtml(latlng, isHttps)).openPopup();
        }
    }

    copyLink() {
        var copyText = $("#geolocationPopup__body--link");
        copyText.select();
        document.execCommand("copy");
    }

    locationHtml(latlng, isHttps) {
        var that = this,
            httpsInfo = !isHttps ? '<p>Lokalizacja może być niedokładna ze względu na brak szyfrowanego połączenia (https).</p>' : '',
            html;
        if (this.permalinkOpt) {
            var permalink = this.Permalink.getPermalink()
            html = '<p><b>Tu jesteś!</b></p>' + '<p>Szerokość geograficzna: <b>' + latlng.lat.toFixed(4) + '&deg;</b></p>' + '<p>Długość geograficzna: <b>' + latlng.lng.toFixed(4) + '&deg;</b></p>' + httpsInfo + '<div class="geolocationPopup__body"><a class="geolocationPopup__body--link" target="_blank" href="' + permalink + '"><input type="text" class="geolocationPopup__body--link" id="geolocationPopup__body--link" value="' + permalink + '"></a>' + '<button class="overlay__button-2 geolocation__body--button" id="geolocationPopup__body--link-copy">Skopiuj link</button></div>';
            $(document).on('click tap', '#geolocationPopup__body--link-copy', function() {
                that.copyLink();
            })
        } else {
            html = '' + '<p><b>Tu jesteś!</b></p>' +
                '<p>Szerokość geograficzna: <b>' + latlng.lat.toFixed(4) + '&deg;</b></p>' +
                '<p>Długość geograficzna: <b>' + latlng.lng.toFixed(4) + '&deg;</b></p>' +
                httpsInfo
        }
        return html;
    }

}

export default Geolocation;