//import Tools from '././Tools';
//var tools = new Tools();
import L from 'leaflet';
import $ from 'jquery';

class eService_searchAddress {

    constructor(config, settings, lp) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf || {};
        this.storage = window.sessionStorage;
        this.treeStructure = config.treeStructure;
        this.bases = config.bases;
        this.lp = lp;
        this.bbox = config.MapConf.BBox || '';
        this.osmID = config.MapConf.OsmID;
        this.states = settings.states;
        this.addServiceBtn();
        this.searchClass = 'eservicePopup__body';
        this.$field = $('.eservicePopup__body__input-field-address');
        this.$search = this.$field.closest('.' + this.searchClass);
        this.$loadingAnim = this.$search.find('.eservicePopup__body__input-loading-anim');
        this.$foundList = this.$search.find('.eservicePopup__body__input-found-list');
        this.requestFlag = true;
        this.timer = 0;
        this.params = {
            textFilter: '',
            foundData: [],
        };
        var that = this;
        this.keyUpCallbacks = {
            _this: that,
            arrowUp: this.arrowUp,
            arrowDown: this.arrowDown,
            enterPress: this.enterPress
        };
        this.events();
    }

    events() {
        var that = this;

        $(document).on('input', '.eservicePopup__body__input-field-address', function(e) {
            that.updateProperties(e.target);
            that.searchValue(e);
        });

        $(document).on('click tap', 'li.sugg', function() {
            var latlng = that.getLatlngFromSugg($(this));
            that.showPointOnMap(latlng);
            var selectedIndex = $(this).index();
            if (selectedIndex != 0) {
                var $newActive = that.$foundList.children('li').eq(selectedIndex);
                $newActive.addClass('sugg-active');
                var text = $newActive.children('span').text();
                that.$field.val(text);
                that.setVisible($newActive, false);
            }
        });

        $(document).on('click tap', 'body', function(event) {
            that.closeSuggs(event);
        });

        $('.eservice-close').on('click', function() {
            $('.modePanel').removeClass('modePanel--active');
            $('.small-icon-modePanel').css('display', 'none');
            that.storage.setItem('mode', 'none');
        })

        $('.modePanel__body--btn-cancel').on('click', function() {
            $('.modePanel').removeClass('modePanel--active');
            $('.small-icon-modePanel').css('display', 'none');
            that.storage.setItem('mode', 'none');
            $('.eservice-close').click();
        })

        $(document).on('click', '.eservicePopup__body__input-button-address', function() {
            var selectedIndex = 1;
            if (selectedIndex != 0) {
                var $newActive = that.$foundList.children('li').eq(0)
                $newActive.addClass('sugg-active');
                var text = $newActive.children('span').text();
                that.$field.val(text);
                that.setVisible($newActive, false);
                if ($newActive) {
                    var latlng = that.getLatlngFromSugg($newActive);
                    that.showPointOnMap(latlng);
                }
            }
        })

        $(window).on('keyup', function(e) {
            if (that.$foundList.hasClass('list-active')) {
                var key = e.keyCode;
                switch (key) {
                    case 38:
                        that.keyUpCallbacks.arrowUp();
                        break;
                    case 40:
                        that.keyUpCallbacks.arrowDown();
                        break;
                    case 13:
                        that.keyUpCallbacks.enterPress(e);
                        break;
                }
            }
        })

        $('.eservicePopup').on('click', function() {
            if ($(window).width() > 768 || $(window).height() > 768) {
                $(this).draggable({
                    cursor: 'grab',
                    cursorAt: {
                        left: 5,
                        top: 0,
                    },
                    opacity: 0.8,
                });
            }
        })

    }

    addServiceBtn() {
        var that = this,
            serviceDiv = $('<div>', {
                html: `<div class="eservicePanel__body--item-text">Wskazywanie nieruchomości po podaniu ulicy lub nr porządkowego</div>
<div class="eservice-btn eservicePanel__body--item-btn" id=""><img class="eservicePanel__body--item-btn-img" src="assets/images/eservices/eservice_searchaddress.png"/></div>`,
                class: 'eservicePanel__body--item',
                id: 'eService__searchAddress'
            })
        $('.eservicePanel__body').append(serviceDiv);
        $('#eService__searchAddress').on('click', function() {
            $('.eservicePopup').css('max-height', '120px');
            $('.modePanel__body--btn-cancel').click();
            $('.modePanel').addClass('modePanel--active');
            $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie wyszukiwania adresu');
            that.states.es_onInfo = false;
            that.storage.setItem('mode', 'es-sa');
            that.emptyPopup();
            $('.eservicePopup').addClass('overlay--active');
            $('.eservicePopup__title').text('Wskazywanie nieruchomości po podaniu ulicy lub nr porządkowego');
            if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
                $('.eservicePopup__body').css({
                    'height': '150px',
                    'width': '520px'
                })
            }
            $('.eservicePopup__body').html(`<div id="field-searchAddress" class="eservicePopup__body__box eservicePopup__body__input">
<div class="eservicePopup__body__input-wrapper">
<input id="eservicePopup-text-address" class="eservicePopup__body__input-field-address eservicePopup__body__input-field eservicePopup__body-text" data-search-type="WyszukajAdres" data-request="getAddress" type="text" placeholder="Wyszukaj adres..." />
<ul class="eservicePopup__body__input-found-list eservicePopup__body__input-found-list-getAddress"></ul>
</div>
<div class="eservicePopup__body__input-button eservicePopup__body__input-button-address"><i class="fa fa-search"></i></div>
<span class="eservicePopup__body__input-loading-anim">
</span>
</div>`);
            $('#eservicePopup-text-address').select();
        })
    }

    emptyPopup() {
        $('.eservicePopup').removeClass('overlay--active')
        $('.eservicePopup__title').text('')
        if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
            $('.eservicePopup__body').css({
                'height': 'auto',
                'width': 'auto'
            })
        }
        $('.eservicePopup__body').html(``);
    }

    updateProperties(target) {
        this.searchClass = 'eservicePopup__body';
        this.$field = $(target).closest('.eservicePopup__body-text');
        this.$search = this.$field.closest('.' + this.searchClass);
        this.$loadingAnim = this.$search.find('.eservicePopup__body__input-loading-anim');
        this.$foundList = this.$search.find('.eservicePopup__body__input-found-list');
    }


    searchValue(e) {
        var that = this;
        this.queryString = $(e.target).val();
        var inputField = e.target;
        if (this.queryString.length != 0) {
            clearTimeout(this.timer);
            this.timer = setTimeout(function() {
                that.sendRequest(that.queryString, inputField);
            }, 300);
        } else {
            this.removeChildrenIfExist();
        }
    }

    removeChildrenIfExist() {
        if (this.$foundList.children().length != 0) {
            this.$foundList.children().remove();
            this.$foundList.removeClass('list-active').addClass('list-inactive');
        }
    }

    getAddress() {
        return (this.tools.SearchServiceAdr != null) ? this.getAddressGema(this.queryString) : this.getAddressOSM(this.queryString);
    }

    getAddressOSM(queryString) {
        this.getAddressUrl = (this.tools.BBox != null) ? 'https://nominatim.openstreetmap.org/search?format=json&q=' + queryString + '&bounded=1&viewbox=' + this.tools.BBox : 'https://nominatim.openstreetmap.org/search?format=json&q=' + queryString;
        return $.ajax({
            url: this.getAddressUrl,
            dataType: 'json',
            type: 'GET',
            async: true,
        });
    }

    getAddressGema() {
        this.getAddressUrl = this.tools.SearchServiceAdr;
        return $.ajax({
            url: this.parseUrl(this.getAddressUrl),
            dataType: 'jsonp',
            jsonpCallback: 'searchResults',
            type: 'GET',
            data: {
                osmid: this.osmid,
                maxRows: 20,
                charset: 'UTF8',
                callback: 'searchResults',
            },
        });
    }

    sendRequest(queryString) {
        var that = this;
        if (this.requestFlag === true) {
            this.removeChildrenIfExist();
            this.disactivateSuggestionsList();
            this.requestFlag = false;
            this.animationOn();
            var requestType = 'getAddress',
                promise = that[requestType](queryString);
            $.when(promise).then(
                function successCallback(data) {
                    var queryTest = that.$field.val();
                    if (queryTest == queryString) {
                        if (requestType == 'getAddress' && that.tools.SearchServiceAdr != null) {
                            data = that.convertWebServiceAddressToSuggObject(data);
                        }
                        if (requestType == 'getAddress') {
                            var html = that.convertDataTableToHtml(data, 'getAddress');
                            that.appendFoundedList(html, 'getAddress');
                        }
                        that.activateSuggestionsList();
                    }
                    that.animationOff();
                    that.requestFlag = true;
                },

                function errorCallback() {
                    alert('Błąd wyszukiwania.');
                    that.animationOff();
                    that.params.noFound = true;
                    that.requestFlag = true;
                });
        }
    }

    convertWebServiceAddressToSuggObject(parcels) {
        return parcels.geonames.map(function(geoname) {
            return {
                lat: geoname.lat,
                lon: geoname.lng,
                display_name: geoname.fcodeName + ' ' + geoname.toponymName,
            }
        });
    }

    appendFoundedList(listHtml, request) {
        this.$foundList = $($.grep(this.$foundList, x => $(x).hasClass('eservicePopup__body__input-found-list-' + request))[0])
        this.$foundList.html(listHtml);
    }

    activateSuggestionsList() {
        this.$foundList.removeClass('list-inactive').addClass('list-active');
    }

    disactivateSuggestionsList() {
        this.$foundList.removeClass('list-active').addClass('list-inactive');
    }

    animationOn() {
        this.$loadingAnim.css({
            opacity: 1
        });
    }

    animationOff() {
        this.$loadingAnim.css({
            opacity: 0
        });
    }

    convertWebServiceToSuggObject(parcels) {
        return parcels.geonames.map(function(geoname) {
            return {
                lat: geoname.lat,
                lon: geoname.lng,
                display_name: geoname.fcodeName + ' ' + geoname.obreb,
            }
        });
    }

    convertDataTableToHtml(dataTable, searchType) {
        var htmlString = '',
            html;
        if (dataTable.length != 0) {
            var length = dataTable.length;
            for (var i = 0; i < length; i++) {
                var name = (typeof dataTable[i].display_name == 'undefined' || undefined) ? dataTable[i].name : dataTable[i].display_name;
                if (searchType == 'getAddress' && this.bbox != '') {
                    var maxBounds = L.latLngBounds(L.latLng(this.bbox.split(',')[1], this.bbox.split(',')[0]), L.latLng(this.bbox.split(',')[3], this.bbox.split(',')[2]));
                    if (maxBounds) {
                        if (maxBounds.contains(L.latLng(dataTable[i].lat, dataTable[i].lon))) {
                            html = '<li class="sugg"><span data-index="' + i + '" data-lat="' + dataTable[i].lat + '" data-lon="' + dataTable[i].lon + '">' + name + '</span></li>';
                            htmlString += html;
                        }
                    }
                } else {
                    html = '<li class="sugg"><span data-index="' + i + '" data-lat="' + dataTable[i].lat + '" data-lon="' + dataTable[i].lon + '">' + name + '</span></li>';
                    htmlString += html;
                }
            }
        } else {
            htmlString = '<li class="cant-be-active"><span>Nie znaleziono pasujących wyników</span></li>';
        }
        return htmlString;
    }

    parseUrl(url) {
        return url.replace(/%osmid%/g, this.osmID)
            .replace(/%nazwa%/g, this.queryString);
    }

    getLatlngFromSugg($this) {
        var lat = $this.children('span').attr('data-lat'),
            lon = $this.children('span').attr('data-lon');
        return [lat, lon];
    }

    showPointOnMap(latlng) {
        var that = this,
            zoom = 18;
        this.map.setView(latlng, zoom, {});
        var circle = L.circleMarker(latlng, {
            radius: 10,
            color: '#ff003b',
            fillOpacity: 0.8,
            clickable: false,
            className: 'searchPointer'
        });
        this.map.addLayer(circle);
        setTimeout(function removeCircleMarker() {
            $('.searchPointer').animate({
                opacity: 0
            }, 500, 'swing', function animationCompleteCallback() {
                that.map.removeLayer(circle);
            });
        }, 10000);
    }

    closeSuggs(event) {
        var $clicked = $(event.target);
        if (!$clicked.hasClass('found-list')) {
            if (this.$foundList.hasClass('list-active')) {
                this.$foundList.removeClass('list-active').addClass('list-inactive');
                this.removeChildrenIfExist();
            }
        }
    }

    enterPress(e) {
        var that = this._this,
            selectedIndex = that.$foundList.children('li.sugg-active').index(),
            latlng;
        if (selectedIndex != -1) {
            var lat = that.$foundList.children('li.sugg-active').children('span').attr('data-lat'),
                lon = that.$foundList.children('li.sugg-active').children('span').attr('data-lon');
            latlng = L.latLng(parseFloat(lat), parseFloat(lon));
            that.showPointOnMap(latlng);
            that.closeSuggs(e)
        } else {
            selectedIndex = 1;
            if (selectedIndex != 0) {
                var $newActive = that.$foundList.children('li').eq(0)
                $newActive.addClass('sugg-active');
                var text = $newActive.children('span').text();
                that.$field.val(text);
                that.setVisible($newActive, false);
                if ($newActive) {
                    latlng = that.getLatlngFromSugg($newActive);
                    that.showPointOnMap(latlng);
                }
            }
        }
    }

    arrowUp() {
        var that = this._this;
        if (that.$field.is(':focus')) {
            var downArrowPressBool = false,
                selectedIndex = that.$foundList.children('li.sugg-active').index();
            if (selectedIndex != 0) { //if can be active
                that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                var $newActive = that.$foundList.children('li').eq(selectedIndex - 1);
                $newActive.addClass('sugg-active');
                var text = $newActive.children('span').text();
                that.$field.val(text);
                that.setVisible($newActive, downArrowPressBool);
            }
        }
    }

    arrowDown() {
        var that = this._this,
            $newActive, text;
       /*  if (that.$field.is(':focus')) {
            var downArrowPressBool = true,
                selectedIndex = that.$foundList.children('li.sugg-active').index();
            // if (selectedIndex == -1) {
            //     if (!that.$foundList.children('li').eq(0).hasClass('cant-be-active')) {}
            // } else {
            if (selectedIndex !== -1) {
                if (!that.$foundList.children('li').eq(selectedIndex + 1).hasClass('cant-be-active')) {
                    that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                    $newActive = that.$foundList.children('li').eq(selectedIndex + 1);
                    $newActive.addClass('sugg-active');
                    text = $newActive.children('span').text();
                    that.$field.val(text);
                    that.setVisible($newActive, downArrowPressBool);
                } else {
                    that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                    $newActive = that.$foundList.children('li').eq(0);
                    $newActive.addClass('sugg-active');
                    text = $newActive.children('span').text();
                    that.$field.val(text);
                    that.actions.setVisible($newActive, downArrowPressBool);
                }
            }
        } */
        if (that.$field.is(':focus')) {
            var downArrowPressBool = true,
              selectedIndex = that.$foundList.children('li.sugg-active').index();
            if (selectedIndex == -1) {
              if (!that.$foundList.children('li').eq(0).hasClass('cant-be-active')) {
                that.$foundList.children('li').eq(0).addClass('sugg-active');
              }
            } else {
              if (!that.$foundList.children('li').eq(selectedIndex + 1).hasClass('cant-be-active')) {
                that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                var $newActive = that.$foundList.children('li').eq(selectedIndex + 1);
                $newActive.addClass('sugg-active');
                var text = $newActive.children('span').text();
                that.$field.val(text);
                that.setVisible($newActive, downArrowPressBool);
              } else {
                that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
                $newActive = that.$foundList.children('li').eq(0);
                $newActive.addClass('sugg-active');
                text = $newActive.children('span').text();
                that.$field.val(text);
                that.actions.setVisible($newActive, downArrowPressBool);
              }
            }
          }
    }

    setVisible($activeListElem, downArrowPressBool) {
        var that = this;
        try {
            var elemPositionTop = $activeListElem.position().top,
                elemHeight = $activeListElem.height(),
                scrollTop = that.$foundList.scrollTop(),
                containerHeight = that.$foundList.height(),
                diff = containerHeight - elemPositionTop;
            if (downArrowPressBool === true) {
                if (diff < elemHeight) {
                    that.$foundList.scrollTop(scrollTop + (2 * elemHeight));
                }
            } else {
                if (diff > containerHeight) {
                    that.$foundList.scrollTop(scrollTop - (2 * elemHeight));
                }
            }
        } catch (e) {
            that.$foundList.scrollTop(0);
        }
    }

}

export default eService_searchAddress;