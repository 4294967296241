import $ from 'jquery';

class ControlScalePosition {

    constructor(config) {
        if (config.MapConf.Skala != null) {
            this.events();
            this.event = $.Event('mousemove');
            this.event.pageX = 300;
            this.event.pageY = 300;
            $('.map').trigger(this.event);
        }
    }

    events() {
        var that = this;
        $('.map').on('mousemove', function() {
            that.set();
        })
    }

    set() {
        var left = $('.bar-bottom').width();
        $('.leaflet-control-scale').css({
            'position': 'fixed',
            'left': left,
            'margin-left': '140px',
        })

        $('.map-control-scalebar').css({
            'position': 'fixed',
            'left': left,
            'margin-left': '10px',
        })
    }

}

export default ControlScalePosition