import $ from 'jquery';

class WyboryBackgroundPosition {

    constructor() {
        this.set();
    }

    set() {
        if ($(window).width() > 768) {
            var right = $('.wyboryPanel').width(),
                top = $('.panelsContainer').position().top - 120;
            $('.wybory__background--img').css({
                'position': 'fixed',
                'right': right,
                'top': top,
            })
        } else {
            $('.wybory__background').css({
                'display': 'none'
            })
        }
    }

}

export default WyboryBackgroundPosition