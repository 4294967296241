import $ from 'jquery';

class CheckLayers {

    constructor(config, settings) {
        if (config.Guid != null) {
            this.map = settings.map;
            this.toolsClass = settings.toolsClass;
            this.tools = config.MapConf.tools || {};
            this.mapTools = config.MapConf || {};
            this.treeStructure = config.treeStructure;
            this.bases = config.bases;
            this.allLayers = config.layerList;
            this.treeStructure = config.treeStructure;
            this.overlayOrder = this.getLayersOrder('overlay');
            this.basesOrder = this.getLayersOrder('base');
            this.allOrder = this.getLayersOrder('all');
            this.additionalLayersAdded = settings.additionalLayersAdded;
            if (this.treeStructure.length > 0) {
                this.selectBaseLayer(this.getBaseIdToSelect());
                this.toSelect = this.getOverlaysIdsToSelect();
                this.selectOverlayLayers(this.toSelect);
            }
            this.getObjectFromPermalink = settings.getObjectFromPermalink.getObjectFromPermalink;
            this.additionalLayersAdded.done(() => {
                this.toSelectAdditional = this.getAdditionalLayers(this.toSelect, this.getObjectFromPermalink().l);
                this.selectLayers(this.toSelectAdditional);
            });
        }
    }

    getAdditionalLayers(layersIds, idsFromPermalink) {
        if (idsFromPermalink) {
            idsFromPermalink = idsFromPermalink.split(',').map(id => Number(id));
            return layersIds.filter(id => id < 0 && idsFromPermalink.indexOf(id) != -1);
        } else {
            return layersIds.filter(id => id < 0);
        }
    }

    getOverlaysIdsToSelect() {
        var toSelect = [];
        $.each(this.treeStructure, function(i, group) {
            $.each(group.layers, function(i, layer) {
                if (layer.checked && !($.inArray(layer.id, toSelect) > -1)) {
                    toSelect.push(layer.id)
                }
            })
        });
        return toSelect;
    }

    getBaseIdToSelect() {
        var toSelect = 9999;
        $.each(this.bases.layers, function(i, layer) {
            if (layer.checked) {
                toSelect = layer.id;
            }
        })
        return toSelect;
    }

    getLayersOrder(type) {
        var layersOrder = [];
        if (type === 'overlay') {
            $.each(this.treeStructure, function(index, type) {
                $.each(type.layers, function(index, layer) {
                    layersOrder.push(layer.id);
                })
            })
        } else if (type == 'base') {
            $.each(this.bases.layers, function(index, layer) {
                layersOrder.push(layer.id);
            })
        } else if (type == 'all') {
            $.each(this.bases.layers, function(index, layer) {
                layersOrder.push(layer.id);
            })
            $.each(this.treeStructure, function(index, type) {
                $.each(type.layers, function(index, layer) {
                    layersOrder.push(layer.id);
                })
            })
        }
        return layersOrder;
    }

    selectOverlayLayers(obj) {
        this.unselectAllLayers();
        this.selectLayers(obj);
    }

    selectLayers(obj) {
        var that = this,
            activeLayers = that.map.layersControl.options.getActiveLayers() || [],
            layerToSelectNums = obj;
        $.each(that.allLayers, function(type, layer) {
            if ($.inArray(layer.id, layerToSelectNums) > -1) {
                var layerToSelect = $.grep(activeLayers, x => x._url === layer.url);
                if (layerToSelect.length > 1) {
                    that.selectLayer(layerToSelect.filter(x => x.options.layers === layer.options.layers)[0], layer.id);
                } else {
                    that.selectLayer(layerToSelect[0], layer.id);
                }
            }
        });
    }

    selectBaseLayer(id) {
        var order = this.basesOrder,
            index = $.inArray(parseInt(id), order),
            inputs = $('.leaflet-control-layers-base').find('.leaflet-control-layers-selector')
        for (var i = 0; i < inputs.length; i++) {
            if (i == index) {
                $(inputs[i]).click()
            }
        }
    }

    unselectAllLayers() {
        var that = this;
        $.each(this.treeStructure, function(i, group) {
            $.each(group.layers, function(i, treeLayer) {
                $.each(that.map._layers, function(i, layer) {
                    if (treeLayer.id == layer.id)
                        layer.remove();
                })
            })
        })
    }

    selectLayer(layer, layerID) {
        var order = this.allOrder,
            indexes = this.getAllIndexes(order, layerID);
        $('.leaflet-control-layers-selector').each(function(i) {
            if ($.inArray(i, indexes) > -1) {
                $(this).click()
            }
        })
    }

    getAllIndexes(arr, val) {
        var indexes = [],
            i = -1;
        while ((i = arr.indexOf(val, i + 1)) != -1) {
            indexes.push(i);
        }
        return indexes;
    }

    hasDuplicates(array) {
        var valuesSoFar = Object.create(null);
        for (var i = 0; i < array.length; ++i) {
            var value = array[i];
            if (value in valuesSoFar) {
                return true;
            }
            valuesSoFar[value] = true;
        }
        return false;
    }
}

export default CheckLayers;