import Tools from "./Tools";
import L from "leaflet";
import proj4 from "./Proj4";
import $ from "jquery";
//! data preparationed to change coords
import { coordsParam } from "./Coords";
//!----
const tools = new Tools();

//!change the coordinate system params when search window is active
export const coordsChangeSearchActive = (
    that = that || "",
    txt = "Wyszukaj po współrzędnych <br>" +
        "<small>(" +
        that.selected +
        ")</small>"
) => {
    if ($(".coordsSearchPopup").length) {
        let coordsSearchicon = $(".coordsSearchPopup");
        if (coordsSearchicon.hasClass("overlay--active")) {
            $(".coordsSearchPopup .overlay__title").html((i, e) => {
                e = txt;
                return e;
            });
        }
    }
};
//! -----
class CoordsSearch {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.states = settings.states;
        this.states.onCoordsSearch = false;
        this.coordsSearchWindowClass = ".coordsSearchPopup";
        this.coordsSearchWindowActiveClass =
            this.coordsSearchWindowClass + "--active";
        this.coordsSearchWindowCloseClass =
            this.coordsSearchWindowClass + "-close";
        this.coordsSearchProps = tools.createElPropertiesFromClass(
            this.toolsClass + "__coordsSearch"
        );
        this.events();
    }
    events() {
        var that = this;

        $(document).on(
            "click tap",
            this.coordsSearchProps.selector,
            function () {
                //!change the title for title with coords info
                $(".coordsSearchPopup .overlay__title").html(function (i, e) {
                    e =
                        "Wyszukaj po współrzędnych<br>" +
                        "<small>(" +
                        coordsParam.name +
                        ")</small>";
                    return e;
                });
                //!-----
                $(".coordsSearchPopup").toggleClass("overlay--active");
                that.states.onCoordsSearch = !that.states.onCoordsSearch;
            }
        );

        $(document).on(
            "click tap",
            this.coordsSearchWindowCloseClass,
            function () {
                $(that.coordsSearchProps.selector)
                    .find(".tools__tool-icon")
                    .removeClass("tools__tool-icon-focused");
                $(that.coordsSearchProps.selector)
                    .find(".tools__tool-icon")
                    .parent()
                    .removeClass("tools__tool-focused");
                that.states.onCoordsSearch = false;
            }
        );

        $("#coordsSearchPopup__body--form").submit(function () {
            return false;
        });

        $("#coordsSearchPopup__body--form-submit").on("click tap", function () {
            var coords = {
                lat: $("#coordsSearchPopup__body--form-lat")
                    .val()
                    .replace(",", "."),
                lng: $("#coordsSearchPopup__body--form-lng")
                    .val()
                    .replace(",", "."),
            };
            that.goToCoords(coords.lat, coords.lng);
        });

        $(".coordsSearchPopup").on("click tap", function () {
            if ($(window).width() > 768 || $(window).height() > 768) {
                $(this).draggable({
                    cursor: "grab",
                    cursorAt: {
                        left: 5,
                        top: 0,
                    },
                    opacity: 0.8,
                });
            }
        });
    }

    goToCoords(lat, lng) {
        //!validation and query result
        const regex = /\b(\d{1,3}\.\d{6,8})\b|\b(\d{6,8}\.\d{1,3})\b/g;
        let coordsjoin = [lat, lng].join(","),
            search = coordsjoin.match(regex),
            popupTitle = $(".overlay__title");

        if (search != null && search.length === 2) {
            if (popupTitle.hasClass("overlay__title--error")) {
                popupTitle.removeClass("overlay__title--error");
                coordsChangeSearchActive(
                    "",
                    "Wyszukaj po współrzędnych <br>" +
                        "<small>(" +
                        coordsParam.name +
                        ")</small>"
                );
            }
        } else {
            popupTitle.addClass("overlay__title--error");
            coordsChangeSearchActive(
                "",
                "Podaj wartość <br> w poprawnym formacie <br>" +
                    "<small>(" +
                    coordsParam.name +
                    ")</small>"
            );
            return;
        }
        var zoom = this.map.getZoom();

        //! convert coords
        const secondProjection = "+proj=longlat +datum=WGS84 +no_defs"; //!wgs84
        let firstProjection = coordsParam.param, //!input params
            convertCordinates = proj4(firstProjection, secondProjection, [
                parseFloat(lat),
                parseFloat(lng),
            ]);
        convertCordinates.reverse();

        let convertedLat = convertCordinates[0].toString(),
            convertedLng = convertCordinates[1].toString();

        // console.log(
        //     "dane wejściwoe",
        //     coordsParam.name + " : " + "lat: " + lat + " lng: " + lng
        // );
        // console.log(
        //     "wgs84: " + "lat: " + convertedLat + " lng: " + convertedLng
        // );

        this.map.setView([convertedLat, convertedLng], zoom);
        this.showPoint(lat, lng, convertedLat, convertedLng, coordsParam.name);
    }
    //!------------------------------

    showPoint(lat, lng, convertedLat, convertedLng, coordsName) {
        //when the object of cords will create , reset input placeholedr
        $("#coordsSearchPopup__body--form-lat").val("");
        $("#coordsSearchPopup__body--form-lng").val("");
        //------------
        var that = this,
            id =
                "point" +
                convertedLat.replace(".", "") +
                convertedLng.replace(".", ""),
            latlng = L.latLng(convertedLat, convertedLng),
            circle = L.circleMarker(latlng, {
                radius: 7,
                color: "#0080D4",
                fillOpacity: 0.8,
                clickable: false,
                className: "searchPointer",
            }).bindPopup(
                `<div class= "overlay__close"> 
                    <span class="overlay__title">Punkt utworzono <br> w układzie <br> ` +
                    coordsName +
                    `</span>
                </div>
                <div class = "coordsSearchPopup__body--form"> 
                <span> <b>Szerokość:</b> ` +
                    lat +
                    `</span><br><span><b>Długość:</b> ` +
                    lng +
                    `</span>
                </div>
                <br>
                <button style="width: 100%" id="` +
                    id +
                    `">usuń punkt</button>`
            );
        this.map.addLayer(circle);
        circle.openPopup().closePopup();

        circle.on("click", function () {
            circle.openPopup();

            $(document).on("click", "#" + id, function () {
                that.map.removeLayer(circle);
            });
        });
    }
}

export default CoordsSearch;
