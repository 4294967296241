import Tools from './Tools';
import L from 'leaflet';
import $ from 'jquery';
import './../vendor/leaflet-measure';
var tools = new Tools();

// pobierz opcje mapy
class Measure {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf || {};
        this.addMeasureControl();
        this.storage = window.sessionStorage;
        this.states = settings.states;
        this.states.onMeasure = false;
        this.measureWindowClass = 'leaflet-control-measure-interaction';
        this.measureWindowActiveClass = this.measureWindowClass + '--active';
        this.measureWindowCloseClass = this.measureWindowClass + '-close';
        this.measureIconProps = tools.createElPropertiesFromClass(this.toolsClass + '__measure');
        this.measureWindowProps = tools.createElPropertiesFromClass(this.measureWindowClass);
        this.measureWindowCloseProps = tools.createElPropertiesFromClass(this.measureWindowCloseClass);
        this.addMeasureCloseBtn();
        this.events();
        // $('.leaflet-control-measure').addClass('overlay__low')
    }

    events() {
        var that = this,
            toggleMeasureSelectors = tools.joinSelectors([
                this.measureIconProps.selector,
                this.measureWindowCloseProps.selector,
            ]);

        $('.modePanel__body--btn-cancel').on('click tap', function() {
            $('.leaflet-control-measure-interaction').removeClass('leaflet-control-measure-interaction--active');
            $('.small-icon-modePanel').css('display', 'none');
            $('.tools__tool-icon').removeClass('tools__tool-icon-focused')
            $('.tools__measure').removeClass('tools__tool-focused')
            that.states.onMeasure = false;
            that.states.onInfo = true;
        })

        $(toggleMeasureSelectors).on('click tap', function() {
            that.toggleMeasureWindow();
            that.states.onMeasure = !that.states.onMeasure;
            that.states.onInfo = !that.states.onInfo;
        });

        $('.' + this.measureWindowCloseProps.class).on('click tap', function() {
            var icon = $('.tools__measure').find('.tools__tool-icon');
            $(icon).removeClass('tools__tool-icon-focused')
            $(icon).parent().removeClass('tools__tool-focused')
            $('.small-icon-modePanel').css('display', 'none');
            that.states.onMeasure = false;
            that.states.onInfo = true;
        })
    }

    addMeasureCloseBtn() {
        var $btnContainer = this.measureWindowProps.$el.find('.startprompt').eq(0);
        $btnContainer.append(`<img class="overlay__close-icon ` + this.measureWindowCloseProps.class + `" src="assets/images/icons/cross.png" alt="zamknij" />`);
    }

    addMeasureControl() {
        if (this.tools.Pomiar) {
            L.control.measure({
                position: 'bottomleft',
            }).addTo(this.map);
        }
    }

    createMeasureCloseBtn() {
        var $closeIcon = tools.createIcon('fa-close overlay__close-icon')
            .addClass(this.measureWindowCloseProps.class);
        return $closeIcon;
    }

    toggleMeasureWindow() {
        if (this.measureWindowProps.$el.hasClass(this.measureWindowActiveClass)) {
            this.measureWindowProps.$el.removeClass(this.measureWindowActiveClass);
            $('.leaflet-control-measure').css('display', 'none')
            $('.modePanel').removeClass('modePanel--active');
            this.storage.setItem('mode', 'none');
        } else {
            // $('.tools__tool-icon-focused').click();
            if ($('.printPopup').hasClass('overlay-active')) {
                $('.printPopup').removeClass('overlay--active')
            }
            $('.leaflet-control-measure').css('display', 'flex')
            this.measureWindowProps.$el.addClass(this.measureWindowActiveClass);
            $('.modePanel').addClass('modePanel--active');
            $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie pomiaru');
            this.storage.setItem('mode', 'measure');
        }
    }
}

export default Measure;