import Tools from '../Tools';

import L from 'leaflet';
import $ from 'jquery';

var tools = new Tools();

class eService_terrainInfo {

    constructor(config, settings, lp) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.lp = lp;
        this.bbox = config.MapConf.BBox;
        this.storage = window.sessionStorage;
        this.states = settings.states;
        this.treeStructure = config.treeStructure;
        this.bases = config.bases;
        this.infoIcon = L.icon({
            iconUrl: './assets/images/icons/bt_info.png',
            iconSize: [30, 30],
        });
        this.infoCursor = L.marker([0, 0], {
            icon: this.infoIcon
        });
        this.terrainLayer = L.tileLayer.wms('https://administracja.gison.pl/wms/cgi-bin/mapserv?map=/home/mapserver/maps/nmt_getfeature.map&INFO_FORMAT=text/html', {
            layers: "nmt",
            attribution: null,
            zIndex: -1000000,
            id: 999,
            transparent: true,
        });
        this.layerID = 999;
        this.addServiceBtn();
        this.activeLayersManager = settings.activeLayersManager;
        this.layerListKeys = config.layerListKeys;
        this.layerListKeys['999'] = {
            id: 999,
            type: "WMS",
            name: "",
            url: "https://administracja.gison.pl/wms/cgi-bin/mapserv?map=/home/mapserver/maps/nmt_getfeature.map&INFO_FORMAT=text/html",
            isBaseLayer: false,
            queryable: true,
            queryUrl: null,
            options: {
                untiled: false,
                layers: "nmt",
                attribution: null,
                zIndex: 1,
                srs: 'EPSG:3857',
            }
        };
        this.states.es_onInfo = false;
        this.events();
    }

    addWmsLayer(layer) {
        return this.createLayerObject({
            layer: layer,
            leafletFunction: L.WMS.source,
            defaultOptions: this.extendWmsLayersOptions({
                identify: false,
            }),
        });
    }

    extendWmsLayersOptions(options) {
        var defaultOptions = {
            format: 'image/png',
            transparent: true,
        }
        tools.extend(options, defaultOptions);
    }

    createLayerObject(settings) {
        var layerConfig = settings.layer;
        layerConfig.options = layerConfig.options || {};
        var layer;
        if (typeof settings.defaultOptions === 'function') {
            settings.defaultOptions(layerConfig.options);
        }
        if ($.inArray(layerConfig.id, this.allOverlayLayersIDs) > -1) {
            $.each(Object.entries(this.map._layers), function(i, l) {
                if (l[1].id == layerConfig.id) {
                    layer = l[1];
                }
            });
        } else {
            layer = settings.leafletFunction(layerConfig.url, layerConfig.options);
            layer.id = layerConfig.id;
        }
        return layer;
    }

    events() {
        var that = this;

        this.map.on('click', function(e) {
            if (that.states.es_onInfo_ti) {
                that.clickParams = {
                    latlng: e.latlng,
                    zoom: that.map.getZoom(),
                    xy: e.containerPoint,
                };
                var promises = that.getInfo();
                Promise.all(promises).then(responses => {
                    var okResponses = responses.filter(response => {
                        if (response.status == 200) {
                            return response;
                        }
                    });
                    return Promise.all(okResponses.map(res => res.text()))
                }).then(texts => that.getInfoDone(texts));
            }
        });

        that.map.on('mousemove', function(e) {
            that.infoCursor.setLatLng([e.latlng.lat, e.latlng.lng]);
        });

        $('.modePanel__body--btn-cancel').on('click', function() {
            $('.modePanel').removeClass('modePanel--active');
            $('.small-icon-modePanel').css('display', 'none');
            that.states.es_onInfo_ti = false;
            that.infoCursor.removeFrom(that.map);
            that.storage.setItem('mode', 'none');
            $('.eservice-close').click();
        })
    }

    addServiceBtn() {
        var that = this,
            serviceDiv = $('<div>', {
                html: `<div class="eservicePanel__body--item-text">Wyświetlanie informacji o wysokości terenu</div>
<div class="eservice-btn eservicePanel__body--item-btn" id=""><img class="eservicePanel__body--item-btn-img" src="assets/images/eservices/eservice_terraininfo.png"/></div>`,
                class: 'eservicePanel__body--item',
                id: 'eService__terrainInfo'
            });

        $('.eservicePanel__body').append(serviceDiv);

        $('#eService__terrainInfo').on('click', function() {
            that.terrainLayer.addTo(that.map);
            $('.modePanel__body--btn-cancel').click();
            that.states.onInfo = false;
            that.states.es_onInfo_ai = false;
            that.states.es_onInfo_ti = true;
            that.states.es_onInfo_pi = false;
            that.emptyPopup();
            that.infoCursor.addTo(that.map);
            $('.modePanel').addClass('modePanel--active');
            $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie wyświetlania informacji o wysokości terenu');
            that.storage.setItem('mode', 'es-ti');
        })
    }

    emptyPopup() {
        $('.eservicePopup').removeClass('overlay--active');
        $('.eservicePopup__title').text('');
        $('.eservicePopup__body').html(``);
    }

    getInfo() {
        var promises = [],
            layersUrls = [],
            layer = this.layerListKeys[this.layerID];
        if (layer.queryable && !layersUrls.includes(layer.queryUrl)) {
            var request = layer.queryUrl != undefined ? this.queryRequest(layer) : this.wmsRequest(layer);
            promises.push(request);
        }
        if (layer.queryUrl && !layersUrls.includes(layer.queryUrl)) {
            layersUrls.push(layer.queryUrl);
        }
        return promises;
    }

    queryRequest(layer) {
        var url = window.PROXY + this.encodeUrl(this.parseUrl(layer.queryUrl));
        return fetch(url);
    }

    wmsRequest(layer) {
        var point = this.clickParams.xy,
            layers = layer.options.layers.split(','),
            source = L.WMS.source(layer.url, layer.options);
        source._map = this.map;
        var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(source, point, layers);
        return fetch(layer.url + L.Util.getParamString(wmsParams, layer.url), {});
    }

    encodeUrl(url) {
        return encodeURIComponent(url);
    }

    parseUrl(url) {
        let {
            lat,
            lng
        } = this.clickParams.latlng;
        url = url.replace(/%lat%/g, lat)
            .replace(/%lon%|%lng%/g, lng)
            .replace(/%zoom%/g, this.clickParams.zoom);
        return url;
    }

    removeGwcServiceFromUrl(url) {
        return url.replace('gwc/service/', '');
    }

    getInfoDone(data) {
        var html = $.trim(this.getDataHtml(data));
        html = html.replace('<b>', '');
        html = html.replace('</b>', '');
        if (html) {
            this.openPopup(html);
        }
    }

    openPopup(html) {
        return L.popup()
            .setLatLng(this.clickParams.latlng)
            .setContent(html)
            .openOn(this.map);
    }

    parseJsonToHtml(data) {
        var html = '';
        try {
            var json = JSON.parse(data);
            if (json.popup) {
                html = json.popup;
            }
        } catch (err) {
            html = data;
        }
        return html;
    }

    getDataHtml(dataArr) {
        var html = '',
            dataHtml;
        dataArr.forEach(data => {
            if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {
                dataHtml = this.getBodyHtml(data);
            } else if (/<\/ServiceException/g.test(data)) {
                dataHtml = '';
            } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                dataHtml = '';
            } else {
                dataHtml = this.parseJsonToHtml(data);
            }
            html = html.concat(dataHtml);
        });
        return html;
    }

    getBodyHtml(html) {
        var match = html.match(/<body>[\s\S]*?<\/body>/g);
        if (match && match[0]) {
            html = match[0].replace('<body>', '').replace('</body>', '');
        } else {
            return '';
        }
        return (/\S/g.test(html)) ? html : '';
    }

}

export default eService_terrainInfo;