import L from 'leaflet';
import Tools from './Tools';
import $ from 'jquery';
import getGisonURL from './../getGisonURL';
var tools = new Tools();

class MapTools {
     constructor(config, settings) {
          this.map = settings.map;
          this.tools = config.MapConf.tools || {};
          this.config = config;
          this.toolsClass = 'tools';
          this.toolsProps = tools.createElPropertiesFromClass(this.toolsClass);
          this.toolsToggleProps = tools.createElPropertiesFromClass(
               'leaflet-' + this.toolsClass + '-toggle'
          );
          this.toolsListProps = tools.createElPropertiesFromClass(
               this.toolsClass + '__list'
          );
          this.toolsToolProps = tools.createElPropertiesFromClass(
               this.toolsClass + '__tool'
          );
          this.toolsHtmlParams = this.addToolsHtmlParams();
          this.addTools();
          this.events();
     }

     events() {
          $(document).on('click tap', '.tools__tool', function () {
               $('.formPopup').removeClass('overlay--active');
          });
     }

     getTools() {
          return [
               {
                    class: 'print',
                    name: 'Wydruk',
                    text: 'Drukuj',
                    url: './assets/images/icons/white/bt_print.png',
               },
               {
                    class: 'measure',
                    name: 'Pomiar',
                    text: 'Zmierz',
                    deselect: true,
                    url: './assets/images/icons/white/bt_measure-length.png',
               },
               {
                    class: 'legend',
                    name: 'Legenda',
                    text: 'Legenda mapy',
                    deselect: true,
                    url: './assets/images/icons/white/bt_minimap.png',
               },
               {
                    class: 'permalink',
                    name: 'Permalink',
                    text: 'Link do mapy',
                    deselect: true,
                    url: './assets/images/icons/white/bt_permalink.png',
               },
               {
                    class: 'coordsSearch',
                    name: 'SearchCoords',
                    text: 'Szukaj po współrzędnych',
                    deselect: true,
                    url: './assets/images/icons/white/bt_xy.png',
               },
               {
                    class: 'centerView',
                    name: 'Wysrodkuj',
                    icon: 'fa-globe',
                    text: 'Widok początkowy',
                    url: './assets/images/icons/white/bt_zoom-extent.png',
               },
               {
                    class: 'streetView',
                    name: 'StreetView',
                    text: 'Street View',
                    deselect: true,
                    url: './assets/images/icons/white/bt_streetview.png',
               },
               {
                    class: 'geolocation',
                    name: 'Geolocation',
                    text: 'Geolokalizacja',
                    deselect: true,
                    url: './assets/images/icons/white/bt_geolocation.png',
               },
               {
                    class: 'miniMap',
                    name: 'MiniMap',
                    text: 'Minimapa',
                    deselect: true,
                    url: './assets/images/icons/white/bt_minimap.png',
               },
               {
                    class: 'layerOpacity',
                    name: 'LayerOpacity',
                    text: 'Przezroczystość warstw',
                    deselect: true,
                    url: './assets/images/icons/white/bt_opacity.png',
               },
               {
                    class: 'selectObject',
                    name: 'SelectObject',
                    text: 'Zaznaczanie',
                    deselect: true,
                    url: './assets/images/icons/white/bt_zaznaczenie.png',
               },
               {
                    class: 'navigateMapBack',
                    name: 'NavigateMapBack',
                    text: 'Cofnij',
                    deselect: true,
                    url: './assets/images/icons/white/bt_arrowleft.png',
               },
               {
                    class: 'navigateMapForward',
                    name: 'NavigateMapForward',
                    text: 'Do przodu',
                    deselect: true,
                    url: './assets/images/icons/white/bt_arrowright.png',
               }, //! sip-234 nowy elemnt
               {
                    class: 'spatialDocs',
                    name: 'ListaPlanow',
                    text: 'Lista planów',
                    deselect: true,
                    url: './assets/images/icons/white/bt_wykaz_planow.png',
               },
          ];
     }

     addToolsHtmlParams() {
          var that = this,
               allTools = this.getTools();

          //! sip-234 sztuczne dodanie przycisku do jsona
          // that.config.MapConf.ListaPlanow = true;
          //! ---

          let selectedTools = allTools.filter(function (tool) {
               tool = tool || {};
               var toolAdded = that.config.MapConf[tool.name];
               if (toolAdded) {
                    return tool;
               }
          });
          return selectedTools;
     }

     createToolHtml(tool) {
          //! sip-234 dodanie wywołania
          if (tool.name === 'ListaPlanow') {
               const ListPlanowHref = getGisonURL.call(this).listaPlanow;

               return `
          <li class="tools__tool content tools__${tool.class} si__${tool.class} center-content priority-nav__link" data-toggle="tooltip" title="${tool.text}">
          <a href="${ListPlanowHref}" target="_blank"><img class="tools__tool-icon" src="${tool.url}" aria-hidden="true"/></a>
              </li>
              `;
          } else {
               return `
          <li class="tools__tool content tools__${tool.class} si__${tool.class} center-content priority-nav__link" data-toggle="tooltip" title="${tool.text}">
          <img class="tools__tool-icon" src="${tool.url}" aria-hidden="true"/>
              </li>
              `;
          }
     }

     addTools() {
          var html = '';
          for (var i = 0; i < this.toolsHtmlParams.length; i++) {
               html += this.createToolHtml(this.toolsHtmlParams[i]);
          }
          this.toolsListProps.$el.append(html);
     }

     createToolsControl() {
          var that = this;
          L.Control.Tools = L.Control.extend({
               onAdd: function () {
                    that.$toolsIcon = tools
                         .createIcon(
                              'fa-cogs leaflet-tools-toggle leaflet-box no-print'
                         )
                         .attr({
                              title: 'Narzędzia',
                              'data-toggle': 'tooltip',
                              'data-placement': 'bottom',
                              'data-translate': 'tools',
                         });
                    return that.$toolsIcon[0];
               },
          });
     }

     toolsAdded() {
          return this.toolsHtmlParams.length ? true : false;
     }

     addToolsControl() {
          if (this.toolsAdded()) {
               L.control.tools = function (opts) {
                    return new L.Control.Tools(opts);
               };
               L.control
                    .tools({
                         position: 'topright',
                    })
                    .addTo(this.map);
          }
     }
}

export default MapTools;
