import StartInfo from "./StartInfo";
import $ from "jquery";

class LicenseDate {
    constructor(config, settings) {
        this.config = config;
        this.map = settings.map || {};
        this.states = settings.states || {};
        this.toolsClass = settings.toolsClass || {};
        this.validateLicenseDateParam();
        this.dateCurrent = new Date();
    }
    // if date param  != null set license date else return true in Mapinit.js
    validateLicenseDateParam() {
        if (this.config.MapConf.DataLicencji != null) {
            this.date = this.config.MapConf.DataLicencji.match(
                /([^/Date()])/g
            ).join("");
            this.dateLicense = new Date(parseFloat(this.date)) || null;
            this.dateLicense.setHours(23, 59, 0, 0) || null;
        } else {
            this.date = null;
        }
    }

    compareDates() {
        if (this.date === null) {
            return true;
        } else {
            const dateCurrent = this.dateCurrent,
                dateLicense = this.dateLicense;

            let dateCurrentDistance = dateCurrent.getTime(),
                dateLicenseDistance = dateLicense.getTime();

            if (dateCurrentDistance < dateLicenseDistance) {
                return true;
            } else if (dateCurrentDistance >= dateLicenseDistance) {
                this.config.MapConf.StartButton = null;
                this.config.MapConf.StartInfo = null;
                this.startPopupInfo();
                return false;
            }
        }
    }

    createPopupInfo() {
        $(".overlay__close").css("display", "none");
        $(".startPopup__body").addClass("startPopup__body--license");
        let month =
            this.dateLicense.getMonth() < 10
                ? "0" + parseInt(this.dateLicense.getMonth() + 1)
                : this.dateLicense.getMonth() + 1;
        let day =
            this.dateLicense.getDate() < 10
                ? "0" + parseInt(this.dateLicense.getDate())
                : this.dateLicense.getDate();

        let year = this.dateLicense.getFullYear();

        let popupInfo = `<p>Licencja wygasła: <b>${day}.${month}.${year}</b> roku. </br>
   Prosimy o kontakt</p>`;

        return popupInfo;
    }

    startPopupInfo() {
        let popup = new StartInfo(this.config, {
            map: this.map,
            toolsClass: this.toolsClass,
        });
        popup.showPopup(this.createPopupInfo(), null);
        $(document).off("keypress");
    }
}

export default LicenseDate;
