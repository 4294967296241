/*
Leaflet.draw, a plugin that adds drawing and editing tools to Leaflet powered maps.
(c) 2012-2013, Jacob Toye, Smartrak

https://github.com/Leaflet/Leaflet.draw
http://leafletjs.com
https://github.com/jacobtoye
*/
/* eslint-disable */
(function (t, e) {
  L.drawVersion = "0.2-dev", L.Draw = {}, L.drawLocal = {
    draw: {
      toolbar: {
        actions: {
          title: 'Anuluj rysowanie',
          text: 'Anuluj'
        },
        undo: {
          title: 'Usuń ostatnio wstawiony punkt',
          text: 'Usuń ostatni punkt'
        },
        buttons: {
          polyline: 'Rysuj linię',
          polygon: 'Rysuj powierzchnię',
          polyRectangle: 'Rysuj prostokąt',
          rectangle: 'Rysuj prostokąt',
          circle: 'Rysuj okrąg',
          marker: 'Dodaj punkt'
        }
      },
      handlers: {
        circle: {
          tooltip: {
            start: 'Kliknij i przeciągnij aby narysować okrąg'
          }
        },
        marker: {
          tooltip: {
            start: 'Kliknij na mapę aby dodać punkt'
          }
        },
        polygon: {
          tooltip: {
            start: 'Kliknij aby rozpocząć rysowanie powierzchni',
            cont: 'Kliknij aby kontynuować rysowanie powierzchni',
            end: 'Kliknij pierwszy wierzchołek aby zakończyć'
          }
        },
        polyline: {
          error: '<strong>Błąd:</strong> Linie nie mogą się przecinać!',
          tooltip: {
            start: 'Kliknij aby rozpocząć rysowanie linii',
            cont: 'Kliknij aby kontynuować rysowanie linii',
            end: 'Kliknij ostatni punkt aby zakończyć'
          }
        },
        rectangle: {
          tooltip: {
            start: 'Kliknij i przeciągnij aby narysować prostokąt'
          }
        },
        polyRectangle: {
          tooltip: {
            start: 'Kliknij aby rozpocząć rysowanie prostokąta',
            cont: 'Kliknij aby kontynuować rysowanie prostokąta',
            end: 'Kliknij pierwszy wierzchołek aby zakończyć'
          }
        },
        simpleshape: {
          tooltip: {
            end: 'Upuść klawisz myszy aby zakończyć'
          }
        }
      }
    },
    edit: {
      toolbar: {
        actions: {
          save: {
            title: 'Zapisz zmiany',
            text: 'Zapisz'
          },
          cancel: {
            title: 'Anuluj edycję nie zapisując zmian',
            text: 'Anuluj'
          }
        },
        buttons: {
          edit: 'Edytuj obiekt',
          editDisabled: 'Brak obiektów do edycji',
          remove: 'Usuń obiekt',
          removeDisabled: 'Brak obiektów do usunięcia'
        }
      },
      handlers: {
        edit: {
          tooltip: {
            text: 'Przesuń znacznik aby edytować',
          }
        },
        remove: {
          tooltip: {
            text: 'Wskaż obiekt do usunięcia'
          }
        }
      }
    }
  }, L.Draw.Feature = L.Handler.extend({
    includes: L.Mixin.Events,
    initialize: function (t, e) {
      this._map = t, this._container = t._container, this._overlayPane = t._panes.overlayPane, this._popupPane = t._panes.popupPane, e && e.shapeOptions && (e.shapeOptions = L.Util.extend({}, this.options.shapeOptions, e.shapeOptions)), L.Util.extend(this.options, e)
    },
    enable: function () {
//      console.log('feature enable')
      this._enabled || (this.fire("enabled", {
        handler: this.type
      }), this._map.fire("draw:drawstart", {
        layerType: this.type
      }), L.Handler.prototype.enable.call(this))
    },
    disable: function () {
//      console.log('feature disable')
      this._enabled && (this.fire("disabled", {
        handler: this.type
      }), this._map.fire("draw:drawstop", {
        layerType: this.type
      }), L.Handler.prototype.disable.call(this))
    },
    addHooks: function () {
//      console.log('feature addhooks')
      this._map && (L.DomUtil.disableTextSelection(), this._tooltip = new L.Tooltip(this._map), L.DomEvent.addListener(this._container, "keyup", this._cancelDrawing, this))
    },
    removeHooks: function () {
//      console.log('feature removehooks')
      this._map && (L.DomUtil.enableTextSelection(), this._tooltip.dispose(), this._tooltip = null, L.DomEvent.removeListener(this._container, "keyup", this._cancelDrawing))
    },
    _fireCreatedEvent: function (t) {
//      console.log('feature firecreated')
      this._map.fire("draw:created", {
        layer: t,
        layerType: this.type
      })
    },
    _cancelDrawing: function (t) {
//      console.log('feature canceldrawing')
      27 === t.keyCode && this.disable()
    }
  }), L.Draw.Polyline = L.Draw.Feature.extend({
    statics: {
      TYPE: "polyline"
    },
    Poly: L.Polyline,
    options: {
      allowIntersection: !0,
      drawError: {
        color: "#b00b00",
        message: "<strong>Error:</strong> shape edges cannot cross!",
        timeout: 2500
      },
      icon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon"
      }),
      guidelineDistance: 20,
      shapeOptions: {
        stroke: !0,
        color: "#f06eaa",
        weight: 4,
        opacity: .5,
        fill: !1,
        clickable: !0
      },
      zIndexOffset: 2e3
    },
    initialize: function (t, e) {
      e && e.drawError && (e.drawError = L.Util.extend({}, this.options.drawError, e.drawError)), this.type = L.Draw.Polyline.TYPE, L.Draw.Feature.prototype.initialize.call(this, t, e)
    },
    addHooks: function () {
      L.Draw.Feature.prototype.addHooks.call(this), this._map && (this._markers = [], this._markerGroup = new L.LayerGroup, this._map.addLayer(this._markerGroup), this._poly = new L.Polyline([], this.options.shapeOptions), this._tooltip.updateContent(this._getTooltipText()), this._mouseMarker || (this._mouseMarker = L.marker(this._map.getCenter(), {
        icon: L.divIcon({
          className: "leaflet-mouse-marker",
          iconAnchor: [20, 20],
          iconSize: [40, 40]
        }),
        opacity: 0,
        zIndexOffset: this.options.zIndexOffset
      })), this._mouseMarker.on("click", this._onClick, this).addTo(this._map), this._map.on("mousemove", this._onMouseMove, this).on("zoomend", this._onZoomEnd, this))
    },
    removeHooks: function () {
      L.Draw.Feature.prototype.removeHooks.call(this), this._clearHideErrorTimeout(), this._cleanUpShape(), this._map.removeLayer(this._markerGroup), delete this._markerGroup, delete this._markers, this._map.removeLayer(this._poly), delete this._poly, this._mouseMarker.off("click", this._onClick), this._map.removeLayer(this._mouseMarker), delete this._mouseMarker, this._clearGuides(), this._map.off("mousemove", this._onMouseMove).off("zoomend", this._onZoomEnd)
    },
    _finishShape: function () {
      var t = this._poly.newLatLngIntersects(this._poly.getLatLngs()[0], !0);
      return !this.options.allowIntersection && t || !this._shapeIsValid() ? (this._showErrorTooltip(), undefined) : (this._fireCreatedEvent(), this.disable(), undefined)
    },
    _shapeIsValid: function () {
      return !0
    },
    _onZoomEnd: function () {
      this._updateGuide()
    },
    _onMouseMove: function (t) {
      if (this._tooltip != null) {
        var e = t.layerPoint,
          i = t.latlng;
        this._currentLatLng = i, this._tooltip.updatePosition(i), this._updateGuide(e), this._mouseMarker.setLatLng(i), L.DomEvent.preventDefault(t.originalEvent)
      }
    },
    _onClick: function (t) {
      var e = t.target.getLatLng(),
        i = this._markers.length;
      return i > 0 && !this.options.allowIntersection && this._poly.newLatLngIntersects(e) ? (this._showErrorTooltip(), undefined) : (this._errorShown && this._hideErrorTooltip(), this._markers.push(this._createMarker(e)), this._poly.addLatLng(e), 2 === this._poly.getLatLngs().length && this._map.addLayer(this._poly), this._updateMarkerHandler(), this._vertexAdded(e), this._clearGuides(), undefined)
    },
    _updateMarkerHandler: function () {
      this._markers.length > 1 && this._markers[this._markers.length - 1].on("click", this._finishShape, this), this._markers.length > 2 && this._markers[this._markers.length - 2].off("click", this._finishShape)
    },
    _createMarker: function (t) {
      var e = new L.Marker(t, {
        icon: this.options.icon,
        zIndexOffset: 2 * this.options.zIndexOffset
      });
      return this._markerGroup.addLayer(e), e
    },
    _updateGuide: function (t) {
      t = t || this._map.latLngToLayerPoint(this._currentLatLng);
      if(this._markers) {
      var e = this._markers.length;
      e > 0 && (this._errorShown || this._tooltip.updateContent(this._getTooltipText()), this._clearGuides(), this._drawGuide(this._map.latLngToLayerPoint(this._markers[e - 1].getLatLng()), t))}
    },
    _drawGuide: function (t, e) {
      var i, o, a, s, n = Math.floor(Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2)));
      for (this._guidesContainer || (this._guidesContainer = L.DomUtil.create("div", "leaflet-draw-guides", this._overlayPane)), i = this.options.guidelineDistance; n > i; i += this.options.guidelineDistance) o = i / n, a = {
        x: Math.floor(t.x * (1 - o) + o * e.x),
        y: Math.floor(t.y * (1 - o) + o * e.y)
      }, s = L.DomUtil.create("div", "leaflet-draw-guide-dash", this._guidesContainer), s.style.backgroundColor = this._errorShown ? this.options.drawError.color : this.options.shapeOptions.color, L.DomUtil.setPosition(s, a)
    },
    _updateGuideColor: function (t) {
      if (this._guidesContainer)
        for (var e = 0, i = this._guidesContainer.childNodes.length; i > e; e++) this._guidesContainer.childNodes[e].style.backgroundColor = t
    },
    _clearGuides: function () {
      if (this._guidesContainer)
        for (; this._guidesContainer.firstChild;) this._guidesContainer.removeChild(this._guidesContainer.firstChild)
    },
    _getTooltipText: function () {
      var t, e, i;
      return 0 === this._markers.length ? t = {
//        text: "Click to start drawing line."
        text: L.drawLocal.draw.handlers.polyline.tooltip.start
      } : (e = this._measurementRunningTotal + this._currentLatLng.distanceTo(this._markers[this._markers.length - 1].getLatLng()), i = e > 1e3 ? (e / 1e3).toFixed(2) + " km" : Math.ceil(e) + " m", t = 1 === this._markers.length ? {
//        text: "Click to continue drawing line.",
        text: L.drawLocal.draw.handlers.polyline.tooltip.cont,
        subtext: i
      } : {
//        text: "Click last point to finish line.",
        text: L.drawLocal.draw.handlers.polyline.tooltip.end,
        subtext: i
      }), t
    },
    _showErrorTooltip: function () {
      this._errorShown = !0, this._tooltip.showAsError().updateContent({
        text: this.options.drawError.message
      }), this._updateGuideColor(this.options.drawError.color), this._poly.setStyle({
        color: this.options.drawError.color
      }), this._clearHideErrorTimeout(), this._hideErrorTimeout = setTimeout(L.Util.bind(this._hideErrorTooltip, this), this.options.drawError.timeout)
    },
    _hideErrorTooltip: function () {
      this._errorShown = !1, this._clearHideErrorTimeout(), this._tooltip.removeError().updateContent(this._getTooltipText()), this._updateGuideColor(this.options.shapeOptions.color), this._poly.setStyle({
        color: this.options.shapeOptions.color
      })
    },
    _clearHideErrorTimeout: function () {
      this._hideErrorTimeout && (clearTimeout(this._hideErrorTimeout), this._hideErrorTimeout = null)
    },
    _vertexAdded: function (t) {
      1 === this._markers.length ? this._measurementRunningTotal = 0 : this._measurementRunningTotal += t.distanceTo(this._markers[this._markers.length - 2].getLatLng())
    },
    _cleanUpShape: function () {
      this._markers.length > 0 && this._markers[this._markers.length - 1].off("click", this._finishShape)
    },
    _fireCreatedEvent: function () {
      var t = new this.Poly(this._poly.getLatLngs(), this.options.shapeOptions);
      L.Draw.Feature.prototype._fireCreatedEvent.call(this, t)
    }
  }), L.Draw.Polygon = L.Draw.Polyline.extend({
    statics: {
      TYPE: "polygon"
    },
    Poly: L.Polygon,
    options: {
      shapeOptions: {
        stroke: !0,
        color: "#f06eaa",
        weight: 4,
        opacity: .5,
        fill: !0,
        fillColor: null,
        fillOpacity: .2,
        clickable: !0
      }
    },
    initialize: function (t, e) {
      L.Draw.Polyline.prototype.initialize.call(this, t, e), this.type = L.Draw.Polygon.TYPE
    },
    _updateMarkerHandler: function () {
      1 === this._markers.length && this._markers[0].on("click", this._finishShape, this)
    },
    _getTooltipText: function () {
      var t;
//      return t = 0 === this._markers.length ? "Click to start drawing shape." : 3 > this._markers.length ? "Click to continue drawing shape." : "Click first point to close this shape.", {
      return t = 0 === this._markers.length ? L.drawLocal.draw.handlers.polygon.tooltip.start : 3 > this._markers.length ? L.drawLocal.draw.handlers.polygon.tooltip.cont : L.drawLocal.draw.handlers.polygon.tooltip.end, {
        text: t
      }
    },
    _shapeIsValid: function () {
      return this._markers.length >= 3
    },
    _vertexAdded: function () {},
    _cleanUpShape: function () {
      this._markers.length > 0 && this._markers[0].off("click", this._finishShape)
    }
  }), L.SimpleShape = {}, L.Draw.SimpleShape = L.Draw.Feature.extend({
    addHooks: function () {
      L.Draw.Feature.prototype.addHooks.call(this), this._map && (this._map.dragging.disable(), this._container.style.cursor = "crosshair", this._tooltip.updateContent({
        text: this._initialLabelText
      }), this._map.on("mousedown", this._onMouseDown, this).on("mousemove", this._onMouseMove, this))
    },
    removeHooks: function () {
      L.Draw.Feature.prototype.removeHooks.call(this), this._map && (this._map.dragging.enable(), this._container.style.cursor = "", this._map.off("mousedown", this._onMouseDown, this).off("mousemove", this._onMouseMove, this), L.DomEvent.off(e, "mouseup", this._onMouseUp), this._shape && (this._map.removeLayer(this._shape), delete this._shape)), this._isDrawing = !1
    },
    _onMouseDown: function (t) {
      this._isDrawing = !0, this._startLatLng = t.latlng, L.DomEvent.on(e, "mouseup", this._onMouseUp, this).preventDefault(t.originalEvent)
    },
    _onMouseMove: function (t) {
      var e = t.latlng;
      this._tooltip.updatePosition(e), this._isDrawing && (this._tooltip.updateContent({
        text: "Release mouse to finish drawing."
      }), this._drawShape(e))
    },
    _onMouseUp: function () {
      this._shape && this._fireCreatedEvent(), this.disable()
    }
  }), L.Draw.Rectangle = L.Draw.SimpleShape.extend({
    statics: {
      TYPE: "rectangle"
    },
    options: {
      shapeOptions: {
        stroke: !0,
        color: "#f06eaa",
        weight: 4,
        opacity: .5,
        fill: !0,
        fillColor: null,
        fillOpacity: .2,
        clickable: !0
      }
    },
    initialize: function (t, e) {
      this.type = L.Draw.Rectangle.TYPE, L.Draw.SimpleShape.prototype.initialize.call(this, t, e)
    },
    _initialLabelText: "Click and drag to draw rectangle.",
    _drawShape: function (t) {
      this._shape ? this._shape.setBounds(new L.LatLngBounds(this._startLatLng, t)) : (this._shape = new L.Rectangle(new L.LatLngBounds(this._startLatLng, t), this.options.shapeOptions), this._map.addLayer(this._shape))
    },
    _fireCreatedEvent: function () {
      var t = new L.Rectangle(this._shape.getBounds(), this.options.shapeOptions);
      L.Draw.SimpleShape.prototype._fireCreatedEvent.call(this, t)
    }
  }), L.Draw.Circle = L.Draw.SimpleShape.extend({
    statics: {
      TYPE: "circle"
    },
    options: {
      shapeOptions: {
        stroke: !0,
        color: "#f06eaa",
        weight: 4,
        opacity: .5,
        fill: !0,
        fillColor: null,
        fillOpacity: .2,
        clickable: !0
      }
    },
    initialize: function (t, e) {
      this.type = L.Draw.Circle.TYPE, L.Draw.SimpleShape.prototype.initialize.call(this, t, e)
    },
    _initialLabelText: "Click and drag to draw circle.",
    _drawShape: function (t) {
      this._shape ? this._shape.setRadius(this._startLatLng.distanceTo(t)) : (this._shape = new L.Circle(this._startLatLng, this._startLatLng.distanceTo(t), this.options.shapeOptions), this._map.addLayer(this._shape))
    },
    _fireCreatedEvent: function () {
      var t = new L.Circle(this._startLatLng, this._shape.getRadius(), this.options.shapeOptions);
      L.Draw.SimpleShape.prototype._fireCreatedEvent.call(this, t)
    }
  }), L.Draw.Marker = L.Draw.Feature.extend({
    statics: {
      TYPE: "marker"
    },
    options: {
      icon: new L.Icon.Default,
      zIndexOffset: 2e3
    },
    initialize: function (t, e) {
//      console.log('marker initialize')
      this.type = L.Draw.Marker.TYPE, L.Draw.Feature.prototype.initialize.call(this, t, e)
    },
    addHooks: function () {
//      console.log('marker addhooks')
      L.Draw.Feature.prototype.addHooks.call(this), this._map && (this._tooltip.updateContent({
        //        text: "Click map to place marker."
        text: L.drawLocal.draw.handlers.marker.tooltip.start
      }), this._map.on("mousemove", this._onMouseMove, this))
    },
    removeHooks: function () {
//      console.log('marker removehooks')
      L.Draw.Feature.prototype.removeHooks.call(this), this._map && (this._marker && (this._marker.off("click", this._onClick), this._map.off("click", this._onClick).removeLayer(this._marker), delete this._marker), this._map.off("mousemove", this._onMouseMove))
    },
    _onMouseMove: function (t) {
//      console.log('marker onmousemove')
      var e = t.latlng;
      if (this._tooltip != null) {
        this._tooltip.updatePosition(e), this._marker ? this._marker.setLatLng(e) : (this._marker = new L.Marker(e, {
          icon: this.options.icon,
          zIndexOffset: this.options.zIndexOffset
        }), this._marker.on("click", this._onClick, this), this._map.on("click", this._onClick, this).addLayer(this._marker))
      }
    },
    _onClick: function () {
//      console.log('marker onclick')
      if (window.sessionStorage.getItem("mode") == 'selectonmap') {
        this._fireCreatedEvent(), this.disable()
      }
    },
    _fireCreatedEvent: function () {
//      console.log('marker firecreated')
      if(this._marker) {
      var t = new L.Marker(this._marker.getLatLng(), {
        icon: this.options.icon
      });
      L.Draw.Feature.prototype._fireCreatedEvent.call(this, t)}
    }
  }), L.Edit = L.Edit || {}, L.Edit.Poly = L.Handler.extend({
    options: {
      icon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon"
      })
    },
    initialize: function (t, e) {
      this._poly = t, L.setOptions(this, e)
    },
    addHooks: function () {
      this._poly._map && (this._markerGroup || this._initMarkers(), this._poly._map.addLayer(this._markerGroup))
    },
    removeHooks: function () {
      this._poly._map && (this._poly._map.removeLayer(this._markerGroup), delete this._markerGroup, delete this._markers)
    },
    updateMarkers: function () {
      this._markerGroup.clearLayers(), this._initMarkers()
    },
    _initMarkers: function () {
      this._markerGroup || (this._markerGroup = new L.LayerGroup), this._markers = [];
      var t, e, i, o, a = this._poly._latlngs;
      for (t = 0, i = a.length; i > t; t++) o = this._createMarker(a[t], t), o.on("click", this._onMarkerClick, this), this._markers.push(o);
      var s, n;
      for (t = 0, e = i - 1; i > t; e = t++)(0 !== t || L.Polygon && this._poly instanceof L.Polygon) && (s = this._markers[e], n = this._markers[t], this._createMiddleMarker(s, n), this._updatePrevNext(s, n))
    },
    _createMarker: function (t, e) {
      var i = new L.Marker(t, {
        draggable: !0,
        icon: this.options.icon
      });
      return i._origLatLng = t, i._index = e, i.on("drag", this._onMarkerDrag, this), i.on("dragend", this._fireEdit, this), this._markerGroup.addLayer(i), i
    },
    _fireEdit: function () {
      this._poly.fire("edit")
    },
    _onMarkerDrag: function (t) {
      var e = t.target;
      L.extend(e._origLatLng, e._latlng), e._middleLeft && e._middleLeft.setLatLng(this._getMiddleLatLng(e._prev, e)), e._middleRight && e._middleRight.setLatLng(this._getMiddleLatLng(e, e._next)), this._poly.redraw()
    },
    _onMarkerClick: function (t) {
      if (!(3 > this._poly._latlngs.length)) {
        var e = t.target,
          i = e._index;
        this._markerGroup.removeLayer(e), this._markers.splice(i, 1), this._poly.spliceLatLngs(i, 1), this._updateIndexes(i, -1), this._updatePrevNext(e._prev, e._next), e._middleLeft && this._markerGroup.removeLayer(e._middleLeft), e._middleRight && this._markerGroup.removeLayer(e._middleRight), e._prev && e._next ? this._createMiddleMarker(e._prev, e._next) : e._prev ? e._next || (e._prev._middleRight = null) : e._next._middleLeft = null, this._poly.fire("edit")
      }
    },
    _updateIndexes: function (t, e) {
      this._markerGroup.eachLayer(function (i) {
        i._index > t && (i._index += e)
      })
    },
    _createMiddleMarker: function (t, e) {
      var i, o, a, s = this._getMiddleLatLng(t, e),
        n = this._createMarker(s);
      n.setOpacity(.6), t._middleRight = e._middleLeft = n, o = function () {
        var o = e._index;
        n._index = o, n.off("click", i).on("click", this._onMarkerClick, this), s.lat = n.getLatLng().lat, s.lng = n.getLatLng().lng, this._poly.spliceLatLngs(o, 0, s), this._markers.splice(o, 0, n), n.setOpacity(1), this._updateIndexes(o, 1), e._index++, this._updatePrevNext(t, n), this._updatePrevNext(n, e)
      }, a = function () {
        n.off("dragstart", o, this), n.off("dragend", a, this), this._createMiddleMarker(t, n), this._createMiddleMarker(n, e)
      }, i = function () {
        o.call(this), a.call(this), this._poly.fire("edit")
      }, n.on("click", i, this).on("dragstart", o, this).on("dragend", a, this), this._markerGroup.addLayer(n)
    },
    _updatePrevNext: function (t, e) {
      t && (t._next = e), e && (e._prev = t)
    },
    _getMiddleLatLng: function (t, e) {
      var i = this._poly._map,
        o = i.latLngToLayerPoint(t.getLatLng()),
        a = i.latLngToLayerPoint(e.getLatLng());
      return i.layerPointToLatLng(o._add(a)._divideBy(2))
    }
  }), L.Polyline.addInitHook(function () {
    L.Edit.Poly && (this.editing = new L.Edit.Poly(this), this.options.editable && this.editing.enable()), this.on("add", function () {
      this.editing && this.editing.enabled() && this.editing.addHooks()
    }), this.on("remove", function () {
      this.editing && this.editing.enabled() && this.editing.removeHooks()
    })
  }), L.Edit = L.Edit || {}, L.Edit.SimpleShape = L.Handler.extend({
    options: {
      moveIcon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-edit-move"
      }),
      resizeIcon: new L.DivIcon({
        iconSize: new L.Point(8, 8),
        className: "leaflet-div-icon leaflet-editing-icon leaflet-edit-resize"
      })
    },
    initialize: function (t, e) {
      this._shape = t, L.Util.setOptions(this, e)
    },
    addHooks: function () {
      this._shape._map && (this._map = this._shape._map, this._markerGroup || this._initMarkers(), this._map.addLayer(this._markerGroup))
    },
    removeHooks: function () {
      if (this._shape._map) {
        this._unbindMarker(this._moveMarker);
        for (var t = 0, e = this._resizeMarkers.length; e > t; t++) this._unbindMarker(this._resizeMarkers[t]);
        this._resizeMarkers = null, this._map.removeLayer(this._markerGroup), delete this._markerGroup
      }
      this._map = null
    },
    updateMarkers: function () {
      this._markerGroup.clearLayers(), this._initMarkers()
    },
    _initMarkers: function () {
      this._markerGroup || (this._markerGroup = new L.LayerGroup), this._createMoveMarker(), this._createResizeMarker()
    },
    _createMoveMarker: function () {},
    _createResizeMarker: function () {},
    _createMarker: function (t, e) {
      var i = new L.Marker(t, {
        draggable: !0,
        icon: e,
        zIndexOffset: 10
      });
      return this._bindMarker(i), this._markerGroup.addLayer(i), i
    },
    _bindMarker: function (t) {
      t.on("dragstart", this._onMarkerDragStart, this).on("drag", this._onMarkerDrag, this).on("dragend", this._onMarkerDragEnd, this)
    },
    _unbindMarker: function (t) {
      t.off("dragstart", this._onMarkerDragStart).off("drag", this._onMarkerDrag).off("dragend", this._onMarkerDragEnd)
    },
    _onMarkerDragStart: function (t) {
      var e = t.target;
      e.setOpacity(0)
    },
    _onMarkerDrag: function (t) {
      var e = t.target,
        i = e.getLatLng();
      e === this._moveMarker ? this._move(i) : this._resize(i), this._shape.redraw()
    },
    _onMarkerDragEnd: function (t) {
      var e = t.target;
      e.setOpacity(1), this._shape.fire("edit")
    },
    _move: function () {},
    _resize: function () {}
  }), L.Edit = L.Edit || {}, L.Edit.Rectangle = L.Edit.SimpleShape.extend({
    _createMoveMarker: function () {
      var t = this._shape.getBounds(),
        e = t.getCenter();
      this._moveMarker = this._createMarker(e, this.options.moveIcon)
    },
    _createResizeMarker: function () {
      var t = this._getCorners();
      this._resizeMarkers = [];
      for (var e = 0, i = t.length; i > e; e++) this._resizeMarkers.push(this._createMarker(t[e], this.options.resizeIcon)), this._resizeMarkers[e]._cornerIndex = e
    },
    _onMarkerDragStart: function (t) {
      L.Edit.SimpleShape.prototype._onMarkerDragStart.call(this, t);
      var e = this._getCorners(),
        i = t.target,
        o = i._cornerIndex;
      this._oppositeCorner = e[(o + 2) % 4], this._toggleCornerMarkers(0, o)
    },
    _onMarkerDragEnd: function (t) {
      var e, i, o = t.target;
      o === this._moveMarker && (e = this._shape.getBounds(), i = e.getCenter(), o.setLatLng(i)), this._toggleCornerMarkers(1), this._repositionCornerMarkers(), L.Edit.SimpleShape.prototype._onMarkerDragEnd.call(this, t)
    },
    _move: function (t) {
      for (var e, i = this._shape.getLatLngs(), o = this._shape.getBounds(), a = o.getCenter(), s = [], n = 0, r = i.length; r > n; n++) e = [i[n].lat - a.lat, i[n].lng - a.lng], s.push([t.lat + e[0], t.lng + e[1]]);
      this._shape.setLatLngs(s), this._repositionCornerMarkers()
    },
    _resize: function (t) {
      var e;
      this._shape.setBounds(L.latLngBounds(t, this._oppositeCorner)), e = this._shape.getBounds(), this._moveMarker.setLatLng(e.getCenter())
    },
    _getCorners: function () {
      var t = this._shape.getBounds(),
        e = t.getNorthWest(),
        i = t.getNorthEast(),
        o = t.getSouthEast(),
        a = t.getSouthWest();
      return [e, i, o, a]
    },
    _toggleCornerMarkers: function (t) {
      for (var e = 0, i = this._resizeMarkers.length; i > e; e++) this._resizeMarkers[e].setOpacity(t)
    },
    _repositionCornerMarkers: function () {
      for (var t = this._getCorners(), e = 0, i = this._resizeMarkers.length; i > e; e++) this._resizeMarkers[e].setLatLng(t[e])
    }
  }), L.Rectangle.addInitHook(function () {
    L.Edit.Rectangle && (this.editing = new L.Edit.Rectangle(this), this.options.editable && this.editing.enable())
  }), L.Edit = L.Edit || {}, L.Edit.Circle = L.Edit.SimpleShape.extend({
    _createMoveMarker: function () {
      var t = this._shape.getLatLng();
      this._moveMarker = this._createMarker(t, this.options.moveIcon)
    },
    _createResizeMarker: function () {
      var t = this._shape.getLatLng(),
        e = this._getResizeMarkerPoint(t);
      this._resizeMarkers = [], this._resizeMarkers.push(this._createMarker(e, this.options.resizeIcon))
    },
    _getResizeMarkerPoint: function (t) {
      var e = this._shape._radius * Math.cos(Math.PI / 4),
        i = this._map.project(t);
      return this._map.unproject([i.x + e, i.y - e])
    },
    _move: function (t) {
      var e = this._getResizeMarkerPoint(t);
      this._resizeMarkers[0].setLatLng(e), this._shape.setLatLng(t)
    },
    _resize: function (t) {
      var e = this._moveMarker.getLatLng(),
        i = e.distanceTo(t);
      this._shape.setRadius(i)
    }
  }), L.Circle.addInitHook(function () {
    L.Edit.Circle && (this.editing = new L.Edit.Circle(this), this.options.editable && this.editing.enable()), this.on("add", function () {
      this.editing && this.editing.enabled() && this.editing.addHooks()
    }), this.on("remove", function () {
      this.editing && this.editing.enabled() && this.editing.removeHooks()
    })
  }), L.LatLngUtil = {
    cloneLatLngs: function (t) {
      for (var e = [], i = 0, o = t.length; o > i; i++) e.push(this.cloneLatLng(t[i]));
      return e
    },
    cloneLatLng: function (t) {
      return L.latLng(t.lat, t.lng)
    }
  }, L.Util.extend(L.LineUtil, {
    segmentsIntersect: function (t, e, i, o) {
      return this._checkCounterclockwise(t, i, o) !== this._checkCounterclockwise(e, i, o) && this._checkCounterclockwise(t, e, i) !== this._checkCounterclockwise(t, e, o)
    },
    _checkCounterclockwise: function (t, e, i) {
      return (i.y - t.y) * (e.x - t.x) > (e.y - t.y) * (i.x - t.x)
    }
  }), L.Polyline.include({
    intersects: function () {
      var t, e, i, o = this._originalPoints,
        a = o ? o.length : 0;
      if (this._tooFewPointsForIntersection()) return !1;
      for (t = a - 1; t >= 3; t--)
        if (e = o[t - 1], i = o[t], this._lineSegmentsIntersectsRange(e, i, t - 2)) return !0;
      return !1
    },
    newLatLngIntersects: function (t, e) {
      return this._map ? this.newPointIntersects(this._map.latLngToLayerPoint(t), e) : !1
    },
    newPointIntersects: function (t, e) {
      var i = this._originalPoints,
        o = i ? i.length : 0,
        a = i ? i[o - 1] : null,
        s = o - 2;
      return this._tooFewPointsForIntersection(1) ? !1 : this._lineSegmentsIntersectsRange(a, t, s, e ? 1 : 0)
    },
    _tooFewPointsForIntersection: function (t) {
      var e = this._originalPoints,
        i = e ? e.length : 0;
      return i += t || 0, !this._originalPoints || 3 >= i
    },
    _lineSegmentsIntersectsRange: function (t, e, i, o) {
      var a, s, n = this._originalPoints;
      o = o || 0;
      for (var r = i; r > o; r--)
        if (a = n[r - 1], s = n[r], L.LineUtil.segmentsIntersect(t, e, a, s)) return !0;
      return !1
    }
  }), L.Polygon.include({
    intersects: function () {
      var t, e, i, o, a, s = this._originalPoints;
      return this._tooFewPointsForIntersection() ? !1 : (t = L.Polyline.prototype.intersects.call(this)) ? !0 : (e = s.length, i = s[0], o = s[e - 1], a = e - 2, this._lineSegmentsIntersectsRange(o, i, a, 1))
    }
  }), L.Control.Draw = L.Control.extend({
    options: {
      //            position: "topright",
      //      draw: {},
      //      edit: !1
    },
    initialize: function (t) {
      L.Control.prototype.initialize.call(this, t);
      var e, i;
      this._toolbars = {}, L.DrawToolbar && this.options.draw && (i = new L.DrawToolbar(this.options.draw), e = L.stamp(i), this._toolbars[e] = i, this._toolbars[e].on("enable", this._toolbarEnabled, this)), L.EditToolbar && this.options.edit && (i = new L.EditToolbar(this.options.edit), e = L.stamp(i), this._toolbars[e] = i, this._toolbars[e].on("enable", this._toolbarEnabled, this))
    },
    onAdd: function (t) {
      var e, i = L.DomUtil.create("div", "leaflet-draw"),
        o = !1,
        a = "leaflet-draw-toolbar-top";
      for (var s in this._toolbars) this._toolbars.hasOwnProperty(s) && (e = this._toolbars[s].addToolbar(t), o || (L.DomUtil.hasClass(e, a) || L.DomUtil.addClass(e.childNodes[0], a), o = !0), i.appendChild(e));
      return i
    },
    onRemove: function () {
      for (var t in this._toolbars) this._toolbars.hasOwnProperty(t) && this._toolbars[t].removeToolbar()
    },
    _toolbarEnabled: function (t) {
      var e = "" + L.stamp(t.target);
      for (var i in this._toolbars) this._toolbars.hasOwnProperty(i) && i !== e && this._toolbars[i].disable()
    }
  }), L.Map.mergeOptions({
    drawControl: !1
  }), L.Map.addInitHook(function () {
    this.options.drawControl && (this.drawControl = new L.Control.Draw, this.addControl(this.drawControl))
  }), L.Toolbar = L.Class.extend({
    includes: [L.Mixin.Events],
    initialize: function (t) {
      L.setOptions(this, t), this._modes = {}, this._actionButtons = [], this._activeMode = null
    },
    enabled: function () {
      return null !== this._activeMode
    },
    disable: function () {
      this.enabled() && this._activeMode.handler.disable()
    },
    removeToolbar: function () {
      for (var t in this._modes) this._modes.hasOwnProperty(t) && (this._disposeButton(this._modes[t].button, this._modes[t].handler.enable), this._modes[t].handler.disable(), this._modes[t].handler.off("enabled", this._handlerActivated).off("disabled", this._handlerDeactivated));
      this._modes = {};
      for (var e = 0, i = this._actionButtons.length; i > e; e++) this._disposeButton(this._actionButtons[e]);
      this._actionButtons = [], this._actionsContainer = null
    },
    _initModeHandler: function (t, e, i, o) {
      var a = t.type;
      this._modes[a] = {}, this._modes[a].handler = t, this._modes[a].button = this._createButton({
        title: this.options[a].title,
        className: o + "-" + a,
        container: e,
        callback: this._modes[a].handler.enable,
        context: this._modes[a].handler
      }), this._modes[a].buttonIndex = i, this._modes[a].handler.on("enabled", this._handlerActivated, this).on("disabled", this._handlerDeactivated, this)
    },
    _createButton: function (t) {
      var e = L.DomUtil.create("a", t.className || "", t.container);
      return e.href = "#", t.text && (e.innerHTML = t.text), t.title && (e.title = t.title), L.DomEvent.on(e, "click", L.DomEvent.stopPropagation).on(e, "mousedown", L.DomEvent.stopPropagation).on(e, "dblclick", L.DomEvent.stopPropagation).on(e, "click", L.DomEvent.preventDefault).on(e, "click", t.callback, t.context), e
    },
    _disposeButton: function (t, e) {
      L.DomEvent.off(t, "click", L.DomEvent.stopPropagation).off(t, "mousedown", L.DomEvent.stopPropagation).off(t, "dblclick", L.DomEvent.stopPropagation).off(t, "click", L.DomEvent.preventDefault).off(t, "click", e)
    },
    _handlerActivated: function (t) {
      this._activeMode && this._activeMode.handler.enabled() && this._activeMode.handler.disable(), this._activeMode = this._modes[t.handler], L.DomUtil.addClass(this._activeMode.button, "leaflet-draw-toolbar-button-enabled"), this._showActionsToolbar(), this.fire("enable")
    },
    _handlerDeactivated: function () {
      this._hideActionsToolbar(), L.DomUtil.removeClass(this._activeMode.button, "leaflet-draw-toolbar-button-enabled"), this._activeMode = null, this.fire("disable")
    },
    _createActions: function (t) {
      for (var e, i, o = L.DomUtil.create("ul", "leaflet-draw-actions"), a = 50, s = t.length, n = s * a + (s - 1), r = 0; s > r; r++) e = L.DomUtil.create("li", "", o), i = this._createButton({
        title: t[r].title,
        text: t[r].text,
        container: e,
        callback: t[r].callback,
        context: t[r].context
      }), this._actionButtons.push(i);
      return o.style.width = n + "px", o
    },
    _showActionsToolbar: function () {
      var t = this._activeMode.buttonIndex,
        e = this._lastButtonIndex,
        i = 26,
        o = 1,
        a = t * i + t * o - 1;
      this._actionsContainer.style.top = a + "px", 0 === t && (L.DomUtil.addClass(this._toolbarContainer, "leaflet-draw-toolbar-notop"), L.DomUtil.addClass(this._actionsContainer, "leaflet-draw-actions-top")), t === e && (L.DomUtil.addClass(this._toolbarContainer, "leaflet-draw-toolbar-nobottom"), L.DomUtil.addClass(this._actionsContainer, "leaflet-draw-actions-bottom")), this._actionsContainer.style.display = "block"
    },
    _hideActionsToolbar: function () {
      this._actionsContainer.style.display = "none", L.DomUtil.removeClass(this._toolbarContainer, "leaflet-draw-toolbar-notop"), L.DomUtil.removeClass(this._toolbarContainer, "leaflet-draw-toolbar-nobottom"), L.DomUtil.removeClass(this._actionsContainer, "leaflet-draw-actions-top"), L.DomUtil.removeClass(this._actionsContainer, "leaflet-draw-actions-bottom")
    }
  }), L.Tooltip = L.Class.extend({
    initialize: function (t) {
      this._map = t, this._popupPane = t._panes.popupPane, this._container = L.DomUtil.create("div", "leaflet-draw-tooltip", this._popupPane), this._singleLineLabel = !1
    },
    dispose: function () {
      this._popupPane.removeChild(this._container), this._container = null
    },
    updateContent: function (t) {
      return t.subtext = t.subtext || "", 0 !== t.subtext.length || this._singleLineLabel ? t.subtext.length > 0 && this._singleLineLabel && (L.DomUtil.removeClass(this._container, "leaflet-draw-tooltip-single"), this._singleLineLabel = !1) : (L.DomUtil.addClass(this._container, "leaflet-draw-tooltip-single"), this._singleLineLabel = !0), this._container.innerHTML = (t.subtext.length > 0 ? '<span class="leaflet-draw-tooltip-subtext">' + t.subtext + "</span>" + "<br />" : "") + "<span>" + t.text + "</span>", this
    },
    updatePosition: function (t) {
      var e = this._map.latLngToLayerPoint(t);
      return L.DomUtil.setPosition(this._container, e), this
    },
    showAsError: function () {
      return L.DomUtil.addClass(this._container, "leaflet-error-draw-tooltip"), this
    },
    removeError: function () {
      return L.DomUtil.removeClass(this._container, "leaflet-error-draw-tooltip"), this
    }
  }), L.DrawToolbar = L.Toolbar.extend({
    options: {
      polyline: {
        title: "Draw a polyline"
      },
      polygon: {
        title: "Draw a polygon"
      },
      rectangle: {
        title: "Draw a rectangle"
      },
      circle: {
        title: "Draw a circle"
      },
      marker: {
        title: "Add a marker"
      }
    },
    initialize: function (t) {
      L.Toolbar.prototype.initialize.call(this, t)
    },
    addToolbar: function (t) {
      var e = L.DomUtil.create("div", "leaflet-draw-section"),
        i = 0,
        o = "leaflet-draw-draw";
      return this._toolbarContainer = L.DomUtil.create("div", "leaflet-draw-toolbar leaflet-bar"), this.options.polyline && this._initModeHandler(new L.Draw.Polyline(t, this.options.polyline), this._toolbarContainer, i++, o), this.options.polygon && this._initModeHandler(new L.Draw.Polygon(t, this.options.polygon), this._toolbarContainer, i++, o), this.options.rectangle && this._initModeHandler(new L.Draw.Rectangle(t, this.options.rectangle), this._toolbarContainer, i++, o), this.options.circle && this._initModeHandler(new L.Draw.Circle(t, this.options.circle), this._toolbarContainer, i++, o), this.options.marker && this._initModeHandler(new L.Draw.Marker(t, this.options.marker), this._toolbarContainer, i++, o), this._lastButtonIndex = --i, this._actionsContainer = this._createActions([{
        title: "Cancel drawing",
        text: "Cancel",
        callback: this.disable,
        context: this
      }]), e.appendChild(this._toolbarContainer), e.appendChild(this._actionsContainer), e
    }
  }), L.EditToolbar = L.Toolbar.extend({
    options: {
      edit: {
        title: "Edit layers",
        selectedPathOptions: null
      },
      remove: {
        title: "Delete layers"
      },
      featureGroup: null
    },
    initialize: function (t) {
      L.Toolbar.prototype.initialize.call(this, t), this._selectedFeatureCount = 0
    },
    addToolbar: function (t) {
      var e = L.DomUtil.create("div", "leaflet-draw-section"),
        i = 0,
        o = "leaflet-draw-edit";
      return this._toolbarContainer = L.DomUtil.create("div", "leaflet-draw-toolbar leaflet-bar"), this._map = t, this.options.edit && this._initModeHandler(new L.EditToolbar.Edit(t, {
        featureGroup: this.options.featureGroup,
        selectedPathOptions: this.options.edit.selectedPathOptions
      }), this._toolbarContainer, i++, o), this.options.remove && this._initModeHandler(new L.EditToolbar.Delete(t, {
        featureGroup: this.options.featureGroup
      }), this._toolbarContainer, i++, o), this._lastButtonIndex = --i, this._actionsContainer = this._createActions([{
        title: "Save changes.",
        text: "Save",
        callback: this._save,
        context: this
      }, {
        title: "Cancel editing, discards all changes.",
        text: "Cancel",
        callback: this.disable,
        context: this
      }]), e.appendChild(this._toolbarContainer), e.appendChild(this._actionsContainer), e
    },
    disable: function () {
      this.enabled() && (this._activeMode.handler.revertLayers(), L.Toolbar.prototype.disable.call(this))
    },
    _save: function () {
      this._activeMode.handler.save(), this._activeMode.handler.disable()
    }
  }), L.EditToolbar.Edit = L.Handler.extend({
    statics: {
      TYPE: "edit"
    },
    includes: L.Mixin.Events,
    options: {
      selectedPathOptions: {
        color: "#fe57a1",
        opacity: .6,
        dashArray: "10, 10",
        fill: !0,
        fillColor: "#fe57a1",
        fillOpacity: .1
      }
    },
    initialize: function (t, e) {
      if (L.Handler.prototype.initialize.call(this, t), e.selectedPathOptions = e.selectedPathOptions || this.options.selectedPathOptions, L.Util.setOptions(this, e), this._featureGroup = this.options.featureGroup, !(this._featureGroup instanceof L.FeatureGroup)) throw Error("options.featureGroup must be a L.FeatureGroup");
      this._uneditedLayerProps = {}, this.type = L.EditToolbar.Edit.TYPE
    },
    enable: function () {
      this._enabled || (L.Handler.prototype.enable.call(this), this._featureGroup.on("layeradd", this._enableLayerEdit, this).on("layerremove", this._disableLayerEdit, this), this.fire("enabled", {
        handler: this.type
      }))
    },
    disable: function () {
      this._enabled && (this.fire("disabled", {
        handler: this.type
      }), this._featureGroup.off("layeradd", this._enableLayerEdit).off("layerremove", this._disableLayerEdit), L.Handler.prototype.disable.call(this))
    },
    addHooks: function () {
      this._map && (this._featureGroup.eachLayer(this._enableLayerEdit, this), this._tooltip = new L.Tooltip(this._map), this._tooltip.updateContent({
        text: "Drag handles, or marker to edit feature.",
        subtext: "Click cancel to undo changes."
      }), this._map.on("mousemove", this._onMouseMove, this))
    },
    removeHooks: function () {
      this._map && (this._featureGroup.eachLayer(this._disableLayerEdit, this), this._uneditedLayerProps = {}, this._tooltip.dispose(), this._tooltip = null, this._map.off("mousemove", this._onMouseMove))
    },
    revertLayers: function () {
      this._featureGroup.eachLayer(function (t) {
        this._revertLayer(t)
      }, this)
    },
    save: function () {
      this._map.fire("draw:edited")
    },
    _backupLayer: function (t) {
      var e = L.Util.stamp(t);
      this._uneditedLayerProps[e] || (this._uneditedLayerProps[e] = t instanceof L.Polyline || t instanceof L.Polygon || t instanceof L.Rectangle ? {
        latlngs: L.LatLngUtil.cloneLatLngs(t.getLatLngs())
      } : t instanceof L.Circle ? {
        latlng: L.LatLngUtil.cloneLatLng(t.getLatLng()),
        radius: t.getRadius()
      } : {
        latlng: L.LatLngUtil.cloneLatLng(t.getLatLng())
      })
    },
    _revertLayer: function (t) {
      var e = L.Util.stamp(t);
      this._uneditedLayerProps.hasOwnProperty(e) && (t instanceof L.Polyline || t instanceof L.Polygon || t instanceof L.Rectangle ? t.setLatLngs(this._uneditedLayerProps[e].latlngs) : t instanceof L.Circle ? (t.setLatLng(this._uneditedLayerProps[e].latlng), t.setRadius(this._uneditedLayerProps[e].radius)) : t.setLatLng(this._uneditedLayerProps[e].latlng))
    },
    _toggleMarkerHighlight: function (t) {
      var e = t._icon;
      e.style.display = "none", L.DomUtil.hasClass(e, "leaflet-edit-marker-selected") ? (L.DomUtil.removeClass(e, "leaflet-edit-marker-selected"), this._offsetMarker(e, -4)) : (L.DomUtil.addClass(e, "leaflet-edit-marker-selected"), this._offsetMarker(e, 4)), e.style.display = ""
    },
    _offsetMarker: function (t, e) {
      var i = parseInt(t.style.marginTop, 10) - e,
        o = parseInt(t.style.marginLeft, 10) - e;
      t.style.marginTop = i + "px", t.style.marginLeft = o + "px"
    },
    _enableLayerEdit: function (t) {
      var e = t.layer || t.target || t,
        i = L.Util.extend({}, this.options.selectedPathOptions);
      this._backupLayer(e), e instanceof L.Marker ? this._toggleMarkerHighlight(e) : (e.options.previousOptions = e.options, e instanceof L.Circle || e instanceof L.Polygon || e instanceof L.Rectangle || (i.fill = !1), e.setStyle(i)), e instanceof L.Marker ? e.dragging.enable() : e.editing.enable()
    },
    _disableLayerEdit: function (t) {
      var e = t.layer || t.target || t;
      e instanceof L.Marker ? this._toggleMarkerHighlight(e) : (e.setStyle(e.options.previousOptions), delete e.options.previousOptions), e instanceof L.Marker ? e.dragging.disable() : e.editing.disable()
    },
    _onMouseMove: function (t) {
      this._tooltip.updatePosition(t.latlng)
    }
  }), L.EditToolbar.Delete = L.Handler.extend({
    statics: {
      TYPE: "remove"
    },
    includes: L.Mixin.Events,
    initialize: function (t, e) {
      if (L.Handler.prototype.initialize.call(this, t), L.Util.setOptions(this, e), this._deletableLayers = this.options.featureGroup, !(this._deletableLayers instanceof L.FeatureGroup)) throw Error("options.featureGroup must be a L.FeatureGroup");
      this.type = L.EditToolbar.Delete.TYPE
    },
    enable: function () {
      this._enabled || (L.Handler.prototype.enable.call(this), this._deletableLayers.on("layeradd", this._enableLayerDelete, this).on("layerremove", this._disableLayerDelete, this), this.fire("enabled", {
        handler: this.type
      }))
    },
    disable: function () {
      this._enabled && (L.Handler.prototype.disable.call(this), this._deletableLayers.off("layeradd", this._enableLayerDelete).off("layerremove", this._disableLayerDelete), this.fire("disabled", {
        handler: this.type
      }))
    },
    addHooks: function () {
      this._map && (this._deletableLayers.eachLayer(this._enableLayerDelete, this), this._deletedLayers = new L.layerGroup, this._tooltip = new L.Tooltip(this._map), this._tooltip.updateContent({
        text: "Click on a feature to remove."
      }), this._map.on("mousemove", this._onMouseMove, this))
    },
    removeHooks: function () {
      this._map && (this._deletableLayers.eachLayer(this._disableLayerDelete, this), this._deletedLayers = null, this._tooltip.dispose(), this._tooltip = null, this._map.off("mousemove", this._onMouseMove))
    },
    revertLayers: function () {
      this._deletedLayers.eachLayer(function (t) {
        this._deletableLayers.addLayer(t)
      }, this)
    },
    save: function () {
      this._map.fire("draw:deleted", {
        layers: this._deletedLayers
      })
    },
    _enableLayerDelete: function (t) {
      var e = t.layer || t.target || t;
      e.on("click", this._removeLayer, this)
    },
    _disableLayerDelete: function (t) {
      var e = t.layer || t.target || t;
      e.off("click", this._removeLayer), this._deletedLayers.removeLayer(e)
    },
    _removeLayer: function (t) {
      var e = t.layer || t.target || t;
      this._deletableLayers.removeLayer(e), this._deletedLayers.addLayer(e)
    },
    _onMouseMove: function (t) {
      this._tooltip.updatePosition(t.latlng)
    }
  })
})(this, document);
