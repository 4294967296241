import Tools from './Tools';
import DefaultView from './DefaultView';
import $ from 'jquery';

var tools = new Tools();

// pobierz opcje mapy
class GetConfig {
     constructor() {
          this.promise = this.getConfig();
     }

     getData(settings) {
          var config = $.Deferred();
          $.ajax(settings.ajax)
               .done(function (response) {
                    config.resolve(response);
               })
               .fail(function () {
                    config.resolve({});
               });
          return config;
     }

     getConfig() {
          this.profile = window.profileSettings;

          //chwilowo
          if (this.profile.profilName != '') {
               this.profile.layersGuid = this.profile.profilName;
          } else if (window.window.location.pathname != '/') {
               this.profile.layersGuid =
                    window.window.location.pathname.substr(1);
          }

          // typeprofil = a1dd0ee8-6a92-11ea-8cb0-1b9d9dc9d0d5 albo zabierzow
          let typeprofil = this.profile.layersGuid;

          if (this.profile.layersGuid) {
               var layerConfig = this.getData({
                    ajax: {
                         url:
                              this.profile.server +
                              this.profile.ROOT +
                              this.profile.layers.action +
                              this.profile.layersGuid,

                         // url: this.profile.server + this.profile.ROOT + this.profile.layers.action + this.profile.layersGuid + "?nocache=cokolwiek",
                         dataType: 'json',
                    },
               });

               var getProfileScript = $.Deferred();
               if (
                    !/^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/i.test(
                         this.profile.layersGuid
                    )
               ) {
                    $.getJSON(
                         './assets/scripts/additionaldata/' +
                              this.profile.layersGuid +
                              '.json'
                    )
                         .done(function (data) {
                              getProfileScript.resolve(data);
                         })
                         .fail(function () {
                              getProfileScript.resolve({});
                         });
               } else {
                    getProfileScript.resolve({});
               }
               var configPromise = $.Deferred();
          } else {
               var defaultView = new DefaultView();
               layerConfig = defaultView.defaultConfig;
          }
          var that = this;

          $.when(layerConfig).done(function (layerConfig_response) {
               //! profile name condition
               // const regex = /(\w+|\d+)+-+(\w+|\d+)+-+(\w+|\d+)+-+(\w+|\d+)+-+(\w+|\d+)/g;
               const regex = /([\w\d]+-){2,4}[\w\d]+/g;
               /*
            typeprofil is a url fragent, it is a profil name

            regex.test(typeprofil):

            */
               const typeProfilCheck = regex.test(typeprofil);
               //! added public condition
               if (
                    layerConfig_response.publ != null &&
                    layerConfig_response.publ != 'undefined'
               ) {
                    /* if typeProfilCheck (validation) === true (like a1dd0ee8-6a92-11ea-8cb0-1b9d9dc9d0d5), publicComposition is set a public, \
                    else read the json param public
                 */

                    const publicComposition = typeProfilCheck
                         ? true
                         : layerConfig_response.publ;

                    // const publicComposition = false;

                    console.log('publicComposition', publicComposition);
                    console.log(
                         'ayerConfig_response.publ',
                         layerConfig_response.publ
                    );

                    //! temporary unactived
                    // if (!publicComposition) {
                    //      layerConfig_response = {};
                    //      $('.responsive-icon').remove();
                    //      $('.bar-bottom').remove();
                    //      $('#map').css('position', 'relative');
                    // }
               }

               //! ------------------

               var mapConfig =
                    layerConfig_response.mapConfig.Guid != null
                         ? that.getData({
                                ajax: {
                                     url:
                                          that.profile.server +
                                          that.profile.ROOT +
                                          that.profile.map.action +
                                          layerConfig_response.mapConfig.Guid,
                                     dataType: 'jsonp',
                                },
                           })
                         : {
                                Guid: null,
                                Name: null,
                                MapConf: {
                                     Wydruk: null,
                                     Zoom: null,
                                     Pomiar: null,
                                     Info: null,
                                     Legenda: null,
                                     Permalink: null,
                                     Warstwy: null,
                                     Skala: null,
                                     Wysrodkuj: null,
                                     Geolocation: null,
                                     MiniMap: null,
                                     LayerOpacity: null,
                                     TrasaKML: null,
                                     ArrowText: null,
                                     WyszukajDzialke: null,
                                     WyszukajAdres: null,
                                     StreetView: null,
                                     ChangeFontSize: null,
                                     ChangeContrast: null,
                                     Coords: null,
                                     SearchCoords: null,
                                     StartInfo:
                                          '<center>Skonfiguruj mapę!</center>',
                                     StartButton: null,
                                     Lng: 19.434814453125004,
                                     Lat: 52.05249047600099,
                                     ZoomMapy: 7,
                                     ZoomMax: 7,
                                     OsmID: null,
                                     StylStrony: null,
                                     Jezyki: null,
                                     JezykAng: null,
                                     SearchServiceAdr: null,
                                     SearchServiceDz: null,
                                     BBox: null,
                                     showMPZPWithSWDE: null,
                                },
                           };
               $.when(mapConfig, getProfileScript).done(function (
                    mapConfig_response,
                    getProfileScript_response
               ) {
                    if (
                         window.profileSettings.layers.hasOwnProperty('default')
                    ) {
                         $.merge(
                              layerConfig_response.layerList,
                              window.profileSettings.layers.default.layerList
                         );
                    }

                    var config = {};
                    tools.extend(
                         config,
                         mapConfig_response,
                         layerConfig_response,
                         getProfileScript_response
                    );

                    // if (window.location.href.includes('localhost')) {
                    // if (window.location.href.includes('sadkowice')) {
                    // config.sideLinkButtons = true;
                    // config.buttons = '[{"title": "Wnętrze kościoła w Lubani","href": "https://my.matterport.com/show/?m=CMBmyfGGmAJ","icon": "fas fa-camera"}, {"title": "Wnętrze kościoła z otoczeniem","href": "https://my.matterport.com/show/?m=Wb5XJ9TniZ3","icon": "fas fa-camera"}]'
                    // } else if (window.location.href.includes('localhost')) {
                    // } else
                    if (
                         window.location.href.includes(
                              'swiatniki_kanalizacja'
                         ) ||
                         window.location.href.includes(
                              '6a54577a-0200-11e9-8fc0-233725720e71'
                         )
                    ) {
                         config.counter = true;
                         // config.MapConf.PasekNaGorze = true;
                         // config.MapConf.SelectObject = true;
                         // config.ueLogosNazwa = 'swiatniki';
                         // config.sideLinkButtons = true;
                         // config.buttons = '[{"title": "Informacje o projekcie","href": "http://administracja.gison.pl/mpzp-public/swiatnikigorne/uchwaly/informacje%20o%20projekcie.pdf","icon": "fas fa-info-circle"}, {"title": "Zgłaszanie nieprawidłowości","href": "http://administracja.gison.pl/mpzp-public/swiatnikigorne/uchwaly/zg%C5%82aszanie%20nieprawid%C5%82owo%C5%9Bci.pdf","icon": "fas fa-exclamation-circle"}, {"title": "Jak przyłączyć się do kanalizacji","href": "http://administracja.gison.pl/mpzp-public/swiatnikigorne/uchwaly/jak%20przy%C5%82%C4%85czy%C4%87%20si%C4%99%20do%20kanalizacji.pdf","icon": "fas fa-question-circle"}, {"title": "Kontakt","href": "http://administracja.gison.pl/mpzp-public/swiatnikigorne/uchwaly/kontakt.pdf","icon": "fas fa-envelope"}]'
                         // } else if (window.location.href.includes('localhost')) {
                         // } else if (window.location.href.includes('szczawnica')) {
                         // config.sideLinkButtons = true;
                         // config.buttons = '[{"title": "SOPO - osuwiska","href": "http://geoportal.pgi.gov.pl/portal/page/portal/SOPO/Wyszukaj3","icon": "fas fa-map-marker-alt"}, {"title": "MAPA TURYSTYCZNA","href": "https://www.turystykawgminie.pl/mapa/szczawnica/","icon": "fas fa-map-marked-alt"}, {"title": "METADANE","href": "http://metadane.gison.pl:8080/geonetwork/srv/pol/main.home","icon": "fas fa-database"}]'
                         // } else if (window.location.href.includes('localhost')) {
                    } else if (window.location.href.includes('srem')) {
                         config.sideLinkButtons = true;
                         config.buttons =
                              '[{"title":"Przetwarzanie danych osobowych","href":"http://sip.srem.pl/img/dokumenty/przetwarzanie_danych.pdf","icon":"fas fa-user-shield","link":true},{"title":"Regulamin świadczenia usług drogą elektroniczną","href":"http://prawo.sejm.gov.pl/isap.nsf/download.xsp/WDU20021441204/U/D20021204Lj.pdf","icon":"fas fa-list","link":true},{"title":"Złóż wniosek o wypis/wyrys","href":" https://administracja.gison.pl/mpzp/WniosekPz/CreatePublic?_profilId=382","icon":"fas fa-file-signature","link":true},{"title":"Formularze ePUAP","href":"","icon":"fas fa-file-signature","link":false,"id":"formularzeEpuap"}]';
                         // }else if(window.location.href.includes('localhost')) {
                         // } else if (window.location.href.includes('srokowo') || window.location.href.includes('dzwierzuty')) {
                         // config.MapConf.PasekNaGorze = true;
                         // config.ueLogosNazwa = 'srokowo';
                    } else if (
                         window.location.href.includes('dobroszyce_mpzp')
                    ) {
                         config.sideLinkButtons = true;
                         config.buttons =
                              '[{"title":"Formularze ePUAP","href":"","icon":"fas fa-file-signature","link":false,"id":"formularzeEpuap"}]';
                    } else {
                         config.counter = false;
                         config.MapConf.PasekNaGorze = false;
                         config.news = false;
                         config.newsUrl = null;
                    }

                    config.bases = {
                         displayName: 'Podkładowe',
                         layers: [],
                    };
                    $.each(
                         layerConfig_response.treeStructure[0].layers,
                         function (i, layer) {
                              if (
                                   $.grep(
                                        layerConfig_response.layerList,
                                        (x) => x.id == layer.id
                                   )[0]
                              ) {
                                   $.grep(
                                        layerConfig_response.layerList,
                                        (x) => x.id == layer.id
                                   )[0].checked = layer.checked;
                                   config.bases.layers.push(
                                        $.grep(
                                             layerConfig_response.layerList,
                                             (x) => x.id == layer.id
                                        )[0]
                                   );
                              }
                         }
                    );
                    layerConfig_response.treeStructure.shift();
                    window.SERVER = window.profileSettings.server;
                    window.ROOT = window.profileSettings.ROOT;
                    window.PROXY = window.profileSettings.proxy;
                    window.RejROOT = window.profileSettings.forms.ROOT;
                    window.RejActionList =
                         window.profileSettings.forms.actionList;
                    window.RejNoticeUrlAction =
                         window.profileSettings.forms.noticeUrlAction;
                    window.PreparePrint =
                         window.profileSettings.print.actionPreparePrint;
                    window.PrintView =
                         window.profileSettings.print.actionPrintView;
                    window.PROFILNAME = layerConfig_response.profilName;
                    if (window.window.location.href.indexOf('uid') > -1) {
                         var guid = window.window.location.href.slice(
                              window.window.location.href.indexOf('uid=') + 4,
                              window.window.location.href.indexOf('uid=') + 40
                         );
                         //TODO: tematlist
                         config.currentComposition =
                              $.grep(
                                   config.tematList,
                                   (x) => x.guid == guid
                              )[0] == undefined
                                   ? $.grep(
                                          config.tematList,
                                          (x) => x.visible != 'false'
                                     )[0]
                                   : $.grep(
                                          config.tematList,
                                          (x) => x.guid == guid
                                     )[0];
                    } else if (window.window.location.pathname == '/') {
                         config.currentComposition = $.grep(
                              config.tematList,
                              (x) => x.visible != 'false'
                         )[0];
                    } else {
                         config.currentComposition = $.grep(
                              config.tematList,
                              (x) => x.visible != 'false'
                         )[0];
                    }
                    //chwilowo
                    // config.MapConf.ImportTrack = true;
                    // config.MapConf.LayerOpacity = false;
                    // config.MapConf.MiniMap = false;
                    // config.MapConf.Legenda = true;
                    // config.MapConf.ChangeContrast = true;
                    // config.MapConf.Geowindow.location = true;
                    // config.MapConf.ArrowText = 'Złóż wniosek';
                    // config.EAdres = false;
                    // config.EDzialka = false;
                    // config.ELatLng = true;
                    // config.MapConf.ArrowText = 'Dodaj zgłoszenie'
                    // config.EDzialkaInfo = true;
                    // config.MapConf.StartInfo = null
                    // config.MapConf.StartButton = null
                    // config.MapConf.Pomoc = true;
                    // config.MapConf.Login = true;
                    // config.MapConf.Warstwy = false;
                    // config.MapConf.BBox = "19.59549,50.05097,20.02121,50.19273";
                    // config.zgloszenieEdycjaGeom = true;
                    // config.zgloszenieGuid = "7a0057d1-98e7-47de-b0e2-aadabed69691";
                    // config.MapConf.Zoom = false;
                    config.MapConf.NavigateMapBack = true;
                    config.MapConf.NavigateMapForward = true;
                    if (
                         config.SearchServices == null &&
                         config.MapConf.WyszukajDzialke &&
                         config.MapConf.WyszukajAdres
                    ) {
                         config.SearchServices = [
                              {
                                   name: 'dzialka',
                                   url: 'https://app.gison.pl/websearch/Handler1.ashx?typ=dzialki_geom_swde&charset=UTF8&maxRows=20&nazwa=%nazwa%&osmid=%osmid%',
                                   placeholder: 'Wyszukaj działkę...',
                              },
                              {
                                   name: 'adres',
                                   url: 'https://app.gison.pl/websearch/Handler1.ashx?typ=adresygemaOL&dataType=jsonp&jsonpCallback=searchResults&maxRows=20&charset=UTF8&osmid=%osmid%&nazwa=%nazwa%',
                                   placeholder: 'Wyszukaj adres...',
                              },
                         ];
                    } else if (
                         config.SearchServices == null &&
                         config.MapConf.WyszukajAdres
                    ) {
                         config.SearchServices = [
                              {
                                   name: 'adres',
                                   url: 'https://app.gison.pl/websearch/Handler1.ashx?typ=adresygemaOL&dataType=jsonp&jsonpCallback=searchResults&maxRows=20&charset=UTF8&osmid=%osmid%&nazwa=%nazwa%',
                                   placeholder: 'Wyszukaj adres...',
                              },
                         ];
                    } else if (
                         config.SearchServices == null &&
                         config.MapConf.WyszukajDzialke
                    ) {
                         config.SearchServices = [
                              {
                                   name: 'dzialka',
                                   url: 'https://app.gison.pl/websearch/Handler1.ashx?typ=dzialki_geom_swde&charset=UTF8&maxRows=20&nazwa=%nazwa%&osmid=%osmid%',
                                   placeholder: 'Wyszukaj działkę...',
                              },
                         ];
                    }
                    /* if (window.location.href.includes("dobroszyce_cmentarz") || window.location.href.includes("localhost")) {
                     config.MapConf.SearchServices = [
                         {
                             name: "cmentarz",
                             // url: "https://administracja.gison.pl/cmentarz-v6/Pochowany/Find?tt=1&osmid=-2709310&name=%name%&_profilid=20",
                             url: "http://192.168.2.115/cmentarz_prod/Pochowany/Find?name=%name%&jsonpParam=json_callback&_profilid=27",
                             placeholder: "Wyszukaj pochowanego..."
                         },
 
                     ];
                 }*/
                    // config.MapConf.Geolocation = true;
                    // config.MapConf.showMPZPWithSWDE = true;
                    // config.MapConf.Coords = true;
                    // config.MapConf.SearchCoords = true;
                    /* config.icons = [{
                            guid: "b4058f03-bfef-420b-8ec3-5e203a42f170",
                            odLewej: true,
                            kolejnosc: 1,
                            format: ".png"
                        },
                        {
                            guid: "7dfe6f6c-405e-4cf6-96b3-e126634d6b6a",
                            odLewej: true,
                            kolejnosc: 2,
                            format: ".png"
                        },
                        {
                            guid: "81d36804-470a-4859-a7ee-c3a132070683",
                            odLewej: false,
                            kolejnosc: 3,
                            format: ".png"
                        }
                    ]
                    config.MapConf.TekstNaPasku = "Projekt realizowany ze środków funduszu Unii Europejskiej";
                    config.mapConfig.MapConf.TekstNaPasku = "Projekt realizowany ze środków funduszu Unii Europejskiej";
                    config.mapConfig.MapConf.PasekNaGorze = true
                    config.MapConf.PasekNaGorze = true */

                    if (
                         window.location.href.includes('dobroszyce_kultura') &&
                         !window.location.href.includes('map=true') &&
                         (navigator.userAgent.match(/Android/i) ||
                              navigator.userAgent.match(/webOS/i) ||
                              navigator.userAgent.match(/iPhone/i) ||
                              navigator.userAgent.match(/iPad/i) ||
                              navigator.userAgent.match(/iPod/i) ||
                              navigator.userAgent.match(/BlackBerry/i) ||
                              navigator.userAgent.match(/Windows Phone/i))
                    ) {
                         config.MapConf.StartInfo = null;
                         config.MapConf.StartButton = null;
                    }

                    localStorage['username'] = '';
                    // config.MapConf.StartInfo = null
                    // config.MapConf.StartButton = null
                    // config.MapConf.WyszukajDzialke = false
                    // config.MapConf.WyszukajAdres = false
                    // config.MapConf.WyszukajDzialkeGUGiK = true
                    // config.aktualnoscGuid = "34eb760a-cb60-42c5-b2ce-06a322c3d771"
                    configPromise.resolve(config);
               });
          });
          return configPromise;
     }
}

export default GetConfig;
