import $ from 'jquery';

class PriorityNav {
    constructor() {
        this.o = {
            selectors: {
                nav: '.priority-nav',
                btn: '.priority-nav__btn',
                visibleLinks: '.priority-nav__links',
                hiddenLinks: '.priority-nav__hidden-links',
            },
            breakpoint: {
                minWidth: 0,
                transitionTime: 0,
            }
        };
        this.vars = {
            breakWidths: [],
            numOfItems: 0,
            totalSpace: 0,
            availableSpace: 0,
            numOfVisibleItems: 0,
            requiredSpace: 0,
            prevBreakpoint: 0,
            currentBreakpoint: 0,
        };
        this.init()
    }

    applySettings(settings) {
        var that = this;
        $.extend(true, that.o, settings);
    }

    events() {
        var that = this;

        $(window).resize(function() {
            that.checkNav();
        });

        $(document).on('click', that.o.selectors.btn, function() {
            that.vars.$hiddenLinks.toggleClass('hidden');
        });

        $('.priority-nav__btn--icon').on('click tap', function() {
            if ($('.priority-nav__hidden-links').hasClass('hidden')) {
                $('.fa--toggle').removeClass('fa-angle-double-up')
                $('.fa--toggle').addClass('fa-angle-double-down')
            } else {
                $('.fa--toggle').removeClass('fa-angle-double-down')
                $('.fa--toggle').addClass('fa-angle-double-up')
            }
        })

    }

    init$els() {
        this.vars.$nav = $(this.o.selectors.nav);
        this.vars.$visibleLinks = $(this.o.selectors.visibleLinks);
        this.vars.$hiddenLinks = $(this.o.selectors.hiddenLinks);
        this.vars.$btn = $(this.o.selectors.btn);
        this.vars.$btnText = $(this.o.selectors.btnText);
        this.vars.$btnIcon = $(this.o.selectors.btnIcon);
    }

    initState() {
        var that = this;
        this.vars.$visibleLinks.children().outerWidth(function(i, w) {
            that.vars.totalSpace += w;
            that.vars.numOfItems += 1;
            that.vars.breakWidths.push(that.vars.totalSpace);
        });
    }

    updateCount() {
        var that = this;
        this.vars.$btn.attr("count", that.vars.numOfItems - that.vars.numOfVisibleItems);
    }

    toggleBtnVisibility() {
        if (this.vars.numOfVisibleItems === this.vars.numOfItems || this.vars.$btn.attr("count") < 1) {
            this.vars.$btn.addClass('hidden');
        } else {
            this.vars.$btn.removeClass('hidden');
        }
    }

    calcSpace() {
        this.vars.availableSpace = this.vars.$visibleLinks.outerWidth() - 10;
        this.vars.availableSpace = $(window).width() - 140;
        this.vars.numOfVisibleItems = this.vars.$visibleLinks.children().length;
        this.vars.requiredSpace = this.vars.breakWidths[this.vars.numOfVisibleItems - 1];
    }

    reduceVisibleItems() {
        this.vars.$visibleLinks.children().last().prependTo(this.vars.$hiddenLinks);
        this.vars.numOfVisibleItems -= 1;
        this.updateNav();
    }

    increaseVisibleItems() {
        var that = this;
        this.vars.$hiddenLinks.children().first().appendTo(that.vars.$visibleLinks);
        this.vars.numOfVisibleItems += 1;
    }

    tooManyItems() {
        return (this.vars.requiredSpace > this.vars.availableSpace);
    }

    tooLittleItems() {
        return (this.vars.availableSpace >= this.vars.breakWidths[this.vars.numOfVisibleItems - 1]);
    }

    passedBreakpoint() {
        return (
            (this.vars.prevBreakpoint > 0) &&
            (this.vars.prevBreakpoint < this.vars.currentBreakpoint) &&
            (this.vars.prevBreakpoint <= this.o.breakpoint.minWidth && this.vars.currentBreakpoint > this.o.breakpoint.minWidth)
        );
    }

    restartVisibleItems() {
        var that = this;
        this.vars.$hiddenLinks.children().appendTo(that.vars.$visibleLinks);
    }

    windowMinWidth(width) {
        return (window.matchMedia('(min-width: ' + width + 'px) ').matches);
    }

    updateNav() {
        this.calcSpace();
        if (this.windowMinWidth(this.o.breakpoint.minWidth)) {
            if (this.tooManyItems()) {
                this.reduceVisibleItems();
            } else if (this.tooLittleItems()) {
                this.increaseVisibleItems();
            }
            this.updateCount();
            this.toggleBtnVisibility();
        } else {
            this.restartVisibleItems();
        }
    }

    checkNav() {
        var that = this;
        this.vars.prevBreakpoint = this.vars.currentBreakpoint;
        this.vars.currentBreakpoint = $(window).width();

        if (this.passedBreakpoint()) {
            setTimeout(function() {
                that.updateNav();
            }, (that.o.breakpoint.transitionTime * 1000 + 1));
        } else {
            that.updateNav();
        }
    }

    init(settings) {
        settings = settings || {};
        this.applySettings(settings);
        this.init$els();
        this.initState();
        this.events();
        this.checkNav();
    }

}


export default PriorityNav