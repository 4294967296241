import $ from 'jquery';

class Wcag {
     constructor(config, settings) {
          this.map = settings.map;
          this.tools = config.MapConf.tools || {};
          this.layers = config.layerList;
          this.treeStructure = config.treeStructure;
          this.bases = config.bases;
          if (config.MapConf.ChangeContrast) {
               this.cssHref = '/assets/styles/wcag.css';
               if (config.MapConf.options_wcag_legendSrc != undefined) {
                    this.legendSrc = config.MapConf.options_legend_src;
                    this.legendSrcWcag =
                         config.MapConf.options_wcag_legendSrc || false;
               }
               this.currentMode = 'default';
               this.addWcagControl();
               this.hideWcagLayers();
               this.controlEvents();
          }
     }

     hideWcagLayers() {
          var $lists = $('.leaflet-control-layers-list');
          $.each($lists, function () {
               var labels = $(this).find('label').find('span'),
                    inputs = $(this).find('label').find('input');
               $.each(labels, function (i, label) {
                    if ($(label).text().includes('__WCAG')) {
                         $(inputs[i]).css('display', 'none');
                         $(inputs[i])
                              .siblings('.leaflet-control-layers-radiobutton')
                              .css('display', 'none');
                         $(label).css('display', 'none');
                    }
               });
          });
          var title = $.grep(
               $('.leaflet-control-layers-groupTitle'),
               (x) => $(x).text() == 'WCAG'
          )[0];
          $(title).css('display', 'none');
     }

     controlEvents() {
          var that = this,
               labels = $('.leaflet-control-container')
                    .find('label')
                    .find('span'),
               inputs = $('.leaflet-control-container')
                    .find('label')
                    .find('input');
          $(inputs).on('click tap', function (e) {
               var name = $(e.target).parent().find('span').text();
               if (that.currentMode == 'wcag') {
                    $.each(labels, function (i, label) {
                         if ($(label).text() == name + '__WCAG') {
                              $(inputs[i]).click();
                         }
                    });
               }
          });
     }

     //**********wcag panel
     events() {
          var that = this;
          $(document).on(
               'click tap',
               '.bar-top__wcag--item-default',
               function () {
                    if (!(that.currentMode == 'default')) {
                         that.currentMode = 'default';
                         that.restoreLayers();
                         that.addDefaultIcons();
                         that.restoreCross();
                         if (that.cssHref) {
                              that.removeCssFromHead();
                         }
                         if (that.legendSrcWcag) {
                              that.restoreLegend();
                         }
                    }
               }
          );

          $(document).on('click tap', '.bar-top__wcag--item-wcag', function () {
               if (!(that.currentMode == 'wcag')) {
                    that.currentMode = 'wcag';
                    that.addWcagLayers();
                    that.addWcagIcons();
                    that.replaceCross();
                    if (that.legendSrcWcag) {
                         that.addWcagLegend();
                    }
                    if (that.cssHref) {
                         that.addCssToHead(that.cssHref);
                    }
               }
          });
     }

     addWcagControl() {
          var html = ` <span class="bar-top__wcag--item bar-top__wcag--item-default" title="Kontrast domyślny" data-toggle="tooltip" data-placement="bottom">A</span><span class="bar-top__wcag--item bar-top__wcag--item-wcag" title="Kontrast czarno-żółty" data-toggle="tooltip" data-placement="bottom">A</span>`;
          $('.bar-top__wcag').append(html);
          this.events();
     }

     //**********wcag icons
     addWcagIcons() {
          var $icons = [],
               toolsIcons = $('.tools__tool-icon'),
               controlIcons = $('.leaflet-control-layers-title-img'),
               smallIcons = $('.small-icon').find('img'),
               topToolsIcons = $('.bar-top__tools--item').find('img'),
               topToolsIconsResp = $('.bar-top__tools--resp-item').find('img'),
               eservicesIcons = $('.eservicePanel__body--item-btn-img'),
               wyboryIcons = $('.wyboryPanel__body--item-btn-img'),
               wyboryIcon = $('.wyboryPanel__title-img'),
               navIcon = $('.priority-nav__btn--icon'),
               barBottomLogo = $('.bar-bottom__logo');
          $.merge($icons, toolsIcons);
          $.merge($icons, controlIcons);
          $.merge($icons, smallIcons);
          $.merge($icons, topToolsIcons);
          $.merge($icons, topToolsIconsResp);
          $.merge($icons, eservicesIcons);
          $.merge($icons, wyboryIcons);
          $.merge($icons, wyboryIcon);
          $.merge($icons, navIcon);
          $.merge($icons, barBottomLogo);
          $.each($icons, function (i, icon) {
               var src = $(icon).attr('src'),
                    srcWcag =
                         src.slice(0, src.indexOf('.png')) +
                         '_wcag' +
                         src.slice(src.indexOf('.png'));
               $(icon).attr('src', srcWcag);
          });
     }

     addDefaultIcons() {
          var $icons = [],
               toolsIcons = $('.tools__tool-icon'),
               controlIcons = $('.leaflet-control-layers-title-img'),
               smallIcons = $('.small-icon').find('img'),
               topToolsIcons = $('.bar-top__tools--item').find('img'),
               topToolsIconsResp = $('.bar-top__tools--resp-item').find('img'),
               eservicesIcons = $('.eservicePanel__body--item-btn-img'),
               wyboryIcons = $('.wyboryPanel__body--item-btn-img'),
               wyboryIcon = $('.wyboryPanel__title-img'),
               navIcon = $('.priority-nav__btn--icon'),
               barBottomLogo = $('.bar-bottom__logo');
          $.merge($icons, toolsIcons);
          $.merge($icons, controlIcons);
          $.merge($icons, smallIcons);
          $.merge($icons, topToolsIcons);
          $.merge($icons, topToolsIconsResp);
          $.merge($icons, eservicesIcons);
          $.merge($icons, wyboryIcons);
          $.merge($icons, wyboryIcon);
          $.merge($icons, navIcon);
          $.merge($icons, barBottomLogo);
          $.each($icons, function (i, icon) {
               var srcWcag = $(icon).attr('src'),
                    src =
                         srcWcag.slice(0, srcWcag.indexOf('_wcag')) +
                         srcWcag.slice(srcWcag.indexOf('.png'));
               $(icon).attr('src', src);
          });
     }

     replaceCross() {
          var crosses = $('.overlay__close-icon');
          $.each(crosses, function () {
               var src = $(this).attr('src');
               $(this).attr('src', src.replace('cross', 'cross_wcag'));
          });
     }

     restoreCross() {
          var crosses = $('.overlay__close-icon');
          $.each(crosses, function () {
               var src = $(this).attr('src');
               $(this).attr('src', src.replace('cross_wcag', 'cross'));
          });
     }
     //**********wcag layers
     getLayersWithWcag() {
          var layers = [],
               wcagLayersSearch = $.grep(
                    this.treeStructure,
                    (x) => x.displayName === 'WCAG'
               )[0];
          if (wcagLayersSearch != undefined) {
               var wcagLayers = wcagLayersSearch.layers;
               $.each(wcagLayers, function (i, layer) {
                    var layerName = layer.name.replace('__WCAG', '');
                    layers.push(layerName);
               });
          }
          return layers;
     }

     getBaseLayersWithWcag() {
          var layers = [],
               wcagLayers = $.grep(this.bases.layers, (x) =>
                    x.name.includes('WCAG')
               );
          $.each(wcagLayers, function (i, layer) {
               var layerName = layer.name.replace('__WCAG', '');
               layers.push(layerName);
          });
          return layers;
     }

     addWcagLayers() {
          var that = this,
               overlayLayers = this.getLayersWithWcag(),
               activeBaseLayer = this.getBaseLayer(this.getActiveBaseLayerId()),
               activeOverlayLayers = this.getOverlayLayers(
                    this.getActiveLayersIds()
               ),
               layers = [];
          layers[0] = activeBaseLayer;
          $.each(activeOverlayLayers, function (i, layer) {
               if ($.inArray(layer, overlayLayers) > -1) {
                    layers.push(layer);
               }
          });
          $.each(layers, function (i, layerName) {
               that.replaceLayerWithWcag(layerName);
          });
     }

     restoreLayers() {
          var that = this,
               overlayLayers = this.getLayersWithWcag(),
               activeBaseLayer = this.getBaseLayer(this.getActiveBaseLayerId()),
               activeOverlayLayers = this.getOverlayLayers(
                    this.getActiveLayersIds()
               ),
               layers = [];
          layers[0] = activeBaseLayer;
          $.each(activeOverlayLayers, function (i, layer) {
               if (layer != undefined) {
                    if (
                         $.inArray(
                              layer.toString().replace('__WCAG', ''),
                              overlayLayers
                         ) > -1
                    ) {
                         layers.push(layer.toString().replace('__WCAG', ''));
                    }
               }
          });
          $.each(layers, function (i, layerName) {
               that.restoreLayer(layerName);
          });
     }

     replaceLayerWithWcag(name) {
          this.replaceLayer(name, name + '__WCAG');
     }

     restoreLayer(name) {
          this.replaceLayer(name + '__WCAG', name.replace('__WCAG', ''));
     }

     replaceLayer(toRemove, toAdd) {
          var layerToRemoveID = this.getLayerID(toRemove),
               layerToAddID = this.getLayerID(toAdd),
               order = this.getLayersOrder('all'),
               indexToUncheck = $.inArray(layerToRemoveID, order),
               indexToCheck = $.inArray(layerToAddID, order),
               $inputs = $('.leaflet-control-layers-selector');

          var $inputsChecked = [];
          $.each($inputs, function (i, v) {
               $inputsChecked[i] = v.checked ? 1 : 0;
          });
          $('.leaflet-control-layers-selector').each(function (i) {
               if (i == indexToUncheck && $inputsChecked[i] === 1) {
                    $inputs[indexToUncheck].click();
               } else if (i == indexToCheck && $inputsChecked[i] === 0) {
                    $inputs[indexToCheck].click();
               }
          });
     }

     getLayerID(name) {
          var layerID;
          $.each(this.layers, function (i, layer) {
               if (layer.name == name) {
                    layerID = layer.id;
               }
          });
          return layerID;
     }

     getLayersOrder(type) {
          var layersOrder = [];
          if (type === 'overlay') {
               $.each(this.treeStructure, function (index, type) {
                    $.each(type.layers, function (index, layer) {
                         layersOrder.push(layer.id);
                    });
               });
          } else if (type == 'base') {
               $.each(this.bases.layers, function (index, layer) {
                    layersOrder.push(layer.id);
               });
          } else if (type == 'all') {
               $.each(this.bases.layers, function (index, layer) {
                    layersOrder.push(layer.id);
               });
               $.each(this.treeStructure, function (index, type) {
                    $.each(type.layers, function (index, layer) {
                         layersOrder.push(layer.id);
                    });
               });
          }
          return layersOrder;
     }

     getActiveLayersIds() {
          var id = [],
               order = this.getLayersOrder('overlay'),
               $inputs = $(
                    '#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(5)'
               ).find('input');
          $.each($inputs, function (i, input) {
               if (input.checked) {
                    id.push(order[i]);
               }
          });
          return id;
     }

     getActiveBaseLayerId() {
          var id,
               order = this.getLayersOrder('base'),
               $inputs = $('.leaflet-control-layers-base').find('input');
          $.each($inputs, function (i, input) {
               if (input.checked) {
                    id = order[i];
               }
          });
          return id;
     }

     getBaseLayer(id) {
          return $.grep(this.bases.layers, (x) => x.id == id)[0].name;
     }

     getOverlayLayer(id) {
          var layer;
          $.each(this.treeStructure, function (i, group) {
               if ($.grep(group.layers, (x) => x.id == id).length > 0) {
                    layer = $.grep(group.layers, (x) => x.id == id)[0].name;
               }
          });
          return layer;
     }

     getOverlayLayers(ids) {
          var that = this,
               overlays = [];
          $.each(ids, function (i, id) {
               overlays.push(that.getOverlayLayer(id));
          });
          return overlays;
     }

     //**********wcag css
     addCssToHead(href) {
          var $head = $('head'),
               link =
                    '<link rel="stylesheet" data-css="wcag" href="' +
                    href +
                    '">',
               $headlinklast = $head.find("link[rel='stylesheet']:last");
          if ($headlinklast.length) {
               $headlinklast.after(link);
          } else {
               $head.append(link);
          }
     }

     removeCssFromHead() {
          $('[data-css]').remove();
     }

     //**********wcag other
     addWcagLegend() {
          var that = this;
          $('.legend.overlay > .legend__imgContainer > img').attr(
               'src',
               that.legendSrcWcag
          );
     }

     restoreLegend() {
          var that = this;
          $('.legend.overlay > .legend__imgContainer > img').attr(
               'src',
               that.legendSrc
          );
     }
}

export default Wcag;
