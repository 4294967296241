"use strict";
/* eslint-disable */
if (!window.colorPicker) {
  window.colorPicker = (function () {

    var crp = {
      register: function () {
        crp.attachDOMReadyEvent(crp.init);
        crp.attachEvent(document, 'mousedown', crp.onDocumentMouseDown);
        crp.attachEvent(document, 'touchstart', crp.onDocumentTouchStart);
        crp.attachEvent(window, 'resize', crp.onWindowResize);
      },

      init: function () {
        if (crp.colorPicker.lookupClass) {
          crp.colorPicker.installByClassName(crp.colorPicker.lookupClass);
        }
      },

      tryInstallOnElements: function (elms, className) {
        var matchClass = new RegExp('(^|\\s)(' + className + ')(\\s*(\\{[^}]*\\})|\\s|$)', 'i');

        for (var i = 0; i < elms.length; i += 1) {
          if (elms[i].type !== undefined && elms[i].type.toLowerCase() == 'color') {
            if (crp.isColorAttrSupported) {
              continue;
            }
          }
          var m;
          if (!elms[i].colorPicker && elms[i].className && (m = elms[i].className.match(matchClass))) {
            var targetElm = elms[i],
              optsStr = null,
              dataOptions = crp.getDataAttr(targetElm, 'colorPicker');
            if (dataOptions !== null) {
              optsStr = dataOptions;
            } else if (m[4]) {
              optsStr = m[4];
            }

            var opts = {};
            if (optsStr) {
              try {
                opts = (new Function('return (' + optsStr + ')'))();
              } catch (eParseError) {
                crp.warn('Error parsing colorPicker options: ' + eParseError + ':\n' + optsStr);
              }
            }
            targetElm.colorPicker = new crp.colorPicker(targetElm, opts);
          }
        }
      },

      isColorAttrSupported: (function () {
        var elm = document.createElement('input');
        if (elm.setAttribute) {
          elm.setAttribute('type', 'color');
          if (elm.type.toLowerCase() == 'color') {
            return true;
          }
        }
        return false;
      })(),

      isCanvasSupported: (function () {
        var elm = document.createElement('canvas');
        return !!(elm.getContext && elm.getContext('2d'));
      })(),

      fetchElement: function (mixed) {
        return typeof mixed === 'string' ? document.getElementById(mixed) : mixed;
      },

      isElementType: function (elm, type) {
        return elm.nodeName.toLowerCase() === type.toLowerCase();
      },

      getDataAttr: function (el, name) {
        var attrName = 'data-' + name,
          attrValue = el.getAttribute(attrName);
        if (attrValue !== null) {
          return attrValue;
        }
        return null;
      },

      attachEvent: function (el, evnt, func) {
        if (el.addEventListener) {
          el.addEventListener(evnt, func, false);
        } else if (el.attachEvent) {
          el.attachEvent('on' + evnt, func);
        }
      },

      detachEvent: function (el, evnt, func) {
        if (el.removeEventListener) {
          el.removeEventListener(evnt, func, false);
        } else if (el.detachEvent) {
          el.detachEvent('on' + evnt, func);
        }
      },

      _attachedGroupEvents: {},

      attachGroupEvent: function (groupName, el, evnt, func) {
        if (!crp._attachedGroupEvents.hasOwnProperty(groupName)) {
          crp._attachedGroupEvents[groupName] = [];
        }
        crp._attachedGroupEvents[groupName].push([el, evnt, func]);
        crp.attachEvent(el, evnt, func);
      },

      detachGroupEvents: function (groupName) {
        if (crp._attachedGroupEvents.hasOwnProperty(groupName)) {
          for (var i = 0; i < crp._attachedGroupEvents[groupName].length; i += 1) {
            var evt = crp._attachedGroupEvents[groupName][i];
            crp.detachEvent(evt[0], evt[1], evt[2]);
          }
          delete crp._attachedGroupEvents[groupName];
        }
      },

      attachDOMReadyEvent: function (func) {
        var fired = false,
          fireOnce = function () {
            if (!fired) {
              fired = true;
              func();
            }
          };

        if (document.readyState === 'complete') {
          setTimeout(fireOnce, 1);
          return;
        }

        if (document.addEventListener) {
          document.addEventListener('DOMContentLoaded', fireOnce, false);

          window.addEventListener('load', fireOnce, false);

        } else if (document.attachEvent) {
          document.attachEvent('onreadystatechange', function () {
            if (document.readyState === 'complete') {
              document.detachEvent('onreadystatechange', arguments.callee);
              fireOnce();
            }
          })

          window.attachEvent('onload', fireOnce);

          if (document.documentElement.doScroll && window == window.top) {
            var tryScroll = function () {
              if (!document.body) {
                return;
              }
              try {
                document.documentElement.doScroll('left');
                fireOnce();
              } catch (e) {
                setTimeout(tryScroll, 1);
              }
            };
            tryScroll();
          }
        }
      },

      warn: function (msg) {
        if (window.console && window.console.warn) {
          window.console.warn(msg);
        }
      },

      preventDefault: function (e) {
        if (e.preventDefault) {
          e.preventDefault();
        }
        e.returnValue = false;
      },

      captureTarget: function (target) {
        if (target.setCapture) {
          crp._capturedTarget = target;
          crp._capturedTarget.setCapture();
        }
      },

      releaseTarget: function () {
        if (crp._capturedTarget) {
          crp._capturedTarget.releaseCapture();
          crp._capturedTarget = null;
        }
      },

      fireEvent: function (el, evnt) {
        if (!el) {
          return;
        }
        if (document.createEvent) {
          var ev = document.createEvent('HTMLEvents');
          ev.initEvent(evnt, true, true);
          el.dispatchEvent(ev);
        } else if (document.createEventObject) {
          var ev = document.createEventObject();
          el.fireEvent('on' + evnt, ev);
        } else if (el['on' + evnt]) {
          el['on' + evnt]();
        }
      },

      classNameToList: function (className) {
        return className.replace(/^\s+|\s+$/g, '').split(/\s+/);
      },

      hasClass: function (elm, className) {
        if (!className) {
          return false;
        }
        return -1 != (' ' + elm.className.replace(/\s+/g, ' ') + ' ').indexOf(' ' + className + ' ');
      },

      setClass: function (elm, className) {
        var classList = crp.classNameToList(className);
        for (var i = 0; i < classList.length; i += 1) {
          if (!crp.hasClass(elm, classList[i])) {
            elm.className += (elm.className ? ' ' : '') + classList[i];
          }
        }
      },

      unsetClass: function (elm, className) {
        var classList = crp.classNameToList(className);
        for (var i = 0; i < classList.length; i += 1) {
          var repl = new RegExp(
            '^\\s*' + classList[i] + '\\s*|' +
            '\\s*' + classList[i] + '\\s*$|' +
            '\\s+' + classList[i] + '(\\s+)',
            'g'
          );
          elm.className = elm.className.replace(repl, '$1');
        }
      },

      getStyle: function (elm) {
        return window.getComputedStyle ? window.getComputedStyle(elm) : elm.currentStyle;
      },

      setStyle: (function () {
        var helper = document.createElement('div'),
          getSupportedProp = function (names) {
            for (var i = 0; i < names.length; i += 1) {
              if (names[i] in helper.style) {
                return names[i];
              }
            }
          };
        var props = {
          borderRadius: getSupportedProp(['borderRadius', 'MozBorderRadius', 'webkitBorderRadius']),
          boxShadow: getSupportedProp(['boxShadow', 'MozBoxShadow', 'webkitBoxShadow'])
        };
        return function (elm, prop, value) {
          switch (prop.toLowerCase()) {
            case 'opacity':
              var alphaOpacity = Math.round(parseFloat(value) * 100);
              elm.style.opacity = value;
              elm.style.filter = 'alpha(opacity=' + alphaOpacity + ')';
              break;
            default:
              elm.style[props[prop]] = value;
              break;
          }
        };
      })(),

      setBorderRadius: function (elm, value) {
        crp.setStyle(elm, 'borderRadius', value || '0');
      },

      setBoxShadow: function (elm, value) {
        crp.setStyle(elm, 'boxShadow', value || 'none');
      },

      getElementPos: function (e, relativeToViewport) {
        var x = 0,
          y = 0,
          rect = e.getBoundingClientRect();
        x = rect.left;
        y = rect.top;
        if (!relativeToViewport) {
          var viewPos = crp.getViewPos();
          x += viewPos[0];
          y += viewPos[1];
        }
        return [x, y];
      },

      getElementSize: function (e) {
        return [e.offsetWidth, e.offsetHeight];
      },

      getAbsPointerPos: function (e) {
        if (!e) {
          e = window.event;
        }
        var x = 0,
          y = 0;
        if (typeof e.changedTouches !== 'undefined' && e.changedTouches.length) {
          x = e.changedTouches[0].clientX;
          y = e.changedTouches[0].clientY;
        } else if (typeof e.clientX === 'number') {
          x = e.clientX;
          y = e.clientY;
        }
        return {
          x: x,
          y: y
        };
      },

      getRelPointerPos: function (e) {
        if (!e) {
          e = window.event;
        }
        var target = e.target || e.srcElement,
          targetRect = target.getBoundingClientRect(),
          x = 0,
          y = 0;

        var clientX = 0,
          clientY = 0;
        if (typeof e.changedTouches !== 'undefined' && e.changedTouches.length) {
          clientX = e.changedTouches[0].clientX;
          clientY = e.changedTouches[0].clientY;
        } else if (typeof e.clientX === 'number') {
          clientX = e.clientX;
          clientY = e.clientY;
        }

        x = clientX - targetRect.left;
        y = clientY - targetRect.top;
        return {
          x: x,
          y: y
        };
      },

      getViewPos: function () {
        var doc = document.documentElement;
        return [
          (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
          (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
		];
      },

      getViewSize: function () {
        var doc = document.documentElement;
        return [
          (window.innerWidth || doc.clientWidth),
          (window.innerHeight || doc.clientHeight),
		];
      },

      redrawPosition: function () {
        if (crp.picker && crp.picker.owner) {
          var thisObj = crp.picker.owner,
            tp, vp;

          if (thisObj.fixed) {
            tp = crp.getElementPos(thisObj.targetElement, true);
            vp = [0, 0];
          } else {
            tp = crp.getElementPos(thisObj.targetElement);
            vp = crp.getViewPos();
          }

          var ts = crp.getElementSize(thisObj.targetElement),
            vs = crp.getViewSize(),
            ps = crp.getPickerOuterDims(thisObj),
            a, b, c;
          switch (thisObj.position.toLowerCase()) {
            case 'left':
              a = 1;
              b = 0;
              c = -1;
              break;
            case 'right':
              a = 1;
              b = 0;
              c = 1;
              break;
            case 'top':
              a = 0;
              b = 1;
              c = -1;
              break;
            default:
              a = 0;
              b = 1;
              c = 1;
              break;
          }
          var l = (ts[b] + ps[b]) / 2;

          if (!thisObj.smartPosition) {
            var pp = [
					tp[a],
					tp[b] + ts[b] - l + l * c
				];
          } else {
            var pp = [
					-vp[a] + tp[a] + ps[a] > vs[a] ?
              (-vp[a] + tp[a] + ts[a] / 2 > vs[a] / 2 && tp[a] + ts[a] - ps[a] >= 0 ? tp[a] + ts[a] - ps[a] : tp[a]) :
						tp[a],
					-vp[b] + tp[b] + ts[b] + ps[b] - l + l * c > vs[b] ?
              (-vp[b] + tp[b] + ts[b] / 2 > vs[b] / 2 && tp[b] + ts[b] - l - l * c >= 0 ? tp[b] + ts[b] - l - l * c : tp[b] + ts[b] - l + l * c) :
              (tp[b] + ts[b] - l + l * c >= 0 ? tp[b] + ts[b] - l + l * c : tp[b] + ts[b] - l - l * c)
				];
          }

          var x = pp[a],
            y = pp[b],
            positionValue = thisObj.fixed ? 'fixed' : 'absolute',
            contractShadow =
            (pp[0] + ps[0] > tp[0] || pp[0] < tp[0] + ts[0]) &&
            (pp[1] + ps[1] < tp[1] + ts[1]);

          crp._drawPosition(thisObj, x, y, positionValue, contractShadow);
        }
      },

      _drawPosition: function (thisObj, x, y, positionValue, contractShadow) {
        var vShadow = contractShadow ? 0 : thisObj.shadowBlur;

        crp.picker.wrap.style.position = positionValue;
        crp.picker.wrap.style.left = x + 'px';
        crp.picker.wrap.style.top = y + 'px';

        crp.setBoxShadow(
          crp.picker.boxS,
          thisObj.shadow ?
          new crp.BoxShadow(0, vShadow, thisObj.shadowBlur, 0, thisObj.shadowColor) :
          null);
      },

      getPickerDims: function (thisObj) {
        var displaySlider = !!crp.getSliderComponent(thisObj),
          dims = [
			2 * thisObj.insetWidth + 2 * thisObj.padding + thisObj.width +
            (displaySlider ? 2 * thisObj.insetWidth + crp.getPadToSliderPadding(thisObj) + thisObj.sliderSize : 0),
			2 * thisObj.insetWidth + 2 * thisObj.padding + thisObj.height +
            (thisObj.closable ? 2 * thisObj.insetWidth + thisObj.padding + thisObj.buttonHeight : 0)
		];
        return dims;
      },

      getPickerOuterDims: function (thisObj) {
        var dims = crp.getPickerDims(thisObj);
        return [
			dims[0] + 2 * thisObj.borderWidth,
			dims[1] + 2 * thisObj.borderWidth
		];
      },

      getPadToSliderPadding: function (thisObj) {
        return Math.max(thisObj.padding, 1.5 * (2 * thisObj.pointerBorderWidth + thisObj.pointerThickness));
      },

      getPadYComponent: function (thisObj) {
        switch (thisObj.mode.charAt(1).toLowerCase()) {
          case 'v':
            return 'v';
            break;
        }
        return 's';
      },

      getSliderComponent: function (thisObj) {
        if (thisObj.mode.length > 2) {
          switch (thisObj.mode.charAt(2).toLowerCase()) {
            case 's':
              return 's';
              break;
            case 'v':
              return 'v';
              break;
          }
        }
        return null;
      },

      onDocumentMouseDown: function (e) {
        if (!e) {
          e = window.event;
        }
        var target = e.target || e.srcElement;

        if (target._crpLinkedInstance) {
          if (target._crpLinkedInstance.showOnClick) {
            target._crpLinkedInstance.show();
          }
        } else if (target._crpControlName) {
          crp.onControlPointerStart(e, target, target._crpControlName, 'mouse');
        } else {
          if (crp.picker && crp.picker.owner) {
            crp.picker.owner.hide();
          }
        }
      },

      onDocumentTouchStart: function (e) {
        if (!e) {
          e = window.event;
        }
        var target = e.target || e.srcElement;

        if (target._crpLinkedInstance) {
          if (target._crpLinkedInstance.showOnClick) {
            target._crpLinkedInstance.show();
          }
        } else if (target._crpControlName) {
          crp.onControlPointerStart(e, target, target._crpControlName, 'touch');
        } else {
          if (crp.picker && crp.picker.owner) {
            crp.picker.owner.hide();
          }
        }
      },

      onWindowResize: function (e) {
        crp.redrawPosition();
      },

      onParentScroll: function (e) {
        if (crp.picker && crp.picker.owner) {
          crp.picker.owner.hide();
        }
      },

      _pointerMoveEvent: {
        mouse: 'mousemove',
        touch: 'touchmove'
      },

      _pointerEndEvent: {
        mouse: 'mouseup',
        touch: 'touchend'
      },

      _pointerOrigin: null,
      _capturedTarget: null,

      onControlPointerStart: function (e, target, controlName, pointerType) {
        var thisObj = target._crpInstance;

        crp.preventDefault(e);
        crp.captureTarget(target);

        var registerDragEvents = function (doc, offset) {
          crp.attachGroupEvent('drag', doc, crp._pointerMoveEvent[pointerType],
            crp.onDocumentPointerMove(e, target, controlName, pointerType, offset));
          crp.attachGroupEvent('drag', doc, crp._pointerEndEvent[pointerType],
            crp.onDocumentPointerEnd(e, target, controlName, pointerType));
        };

        registerDragEvents(document, [0, 0]);

        if (window.parent && window.frameElement) {
          var rect = window.frameElement.getBoundingClientRect(),
            ofs = [-rect.left, -rect.top];
          registerDragEvents(window.parent.window.document, ofs);
        }

        var abs = crp.getAbsPointerPos(e),
          rel = crp.getRelPointerPos(e);
        crp._pointerOrigin = {
          x: abs.x - rel.x,
          y: abs.y - rel.y
        };

        switch (controlName) {
          case 'pad':
            switch (crp.getSliderComponent(thisObj)) {
              case 's':
                if (thisObj.hsv[1] === 0) {
                  thisObj.fromHSV(null, 100, null);
                };
                break;
              case 'v':
                if (thisObj.hsv[2] === 0) {
                  thisObj.fromHSV(null, null, 100);
                };
                break;
            }
            crp.setPad(thisObj, e, 0, 0);
            break;

          case 'sld':
            crp.setSld(thisObj, e, 0);
            break;
        }
        crp.dispatchFineChange(thisObj);
      },

      onDocumentPointerMove: function (e, target, controlName, pointerType, offset) {
        return function (e) {
          var thisObj = target._crpInstance;
          switch (controlName) {
            case 'pad':
              if (!e) {
                e = window.event;
              }
              crp.setPad(thisObj, e, offset[0], offset[1]);
              crp.dispatchFineChange(thisObj);
              break;
            case 'sld':
              if (!e) {
                e = window.event;
              }
              crp.setSld(thisObj, e, offset[1]);
              crp.dispatchFineChange(thisObj);
              break;
          }
        }
      },

      onDocumentPointerEnd: function (e, target, controlName, pointerType) {
        return function (e) {
          var thisObj = target._crpInstance;
          crp.detachGroupEvents('drag');
          crp.releaseTarget();
          crp.dispatchChange(thisObj);
        };
      },

      dispatchChange: function (thisObj) {
        if (thisObj.valueElement) {
          if (crp.isElementType(thisObj.valueElement, 'input')) {
            crp.fireEvent(thisObj.valueElement, 'change');
          }
        }
      },

      dispatchFineChange: function (thisObj) {
        if (thisObj.onFineChange) {
          var callback;
          if (typeof thisObj.onFineChange === 'string') {
            callback = new Function(thisObj.onFineChange);
          } else {
            callback = thisObj.onFineChange;
          }
          callback.call(thisObj);
        }
      },

      setPad: function (thisObj, e, ofsX, ofsY) {
        var pointerAbs = crp.getAbsPointerPos(e),
          x = ofsX + pointerAbs.x - crp._pointerOrigin.x - thisObj.padding - thisObj.insetWidth,
          y = ofsY + pointerAbs.y - crp._pointerOrigin.y - thisObj.padding - thisObj.insetWidth,
          xVal = x * (360 / (thisObj.width - 1)),
          yVal = 100 - (y * (100 / (thisObj.height - 1)));
        switch (crp.getPadYComponent(thisObj)) {
          case 's':
            thisObj.fromHSV(xVal, yVal, null, crp.leaveSld);
            break;
          case 'v':
            thisObj.fromHSV(xVal, null, yVal, crp.leaveSld);
            break;
        }
      },

      setSld: function (thisObj, e, ofsY) {
        var pointerAbs = crp.getAbsPointerPos(e),
          y = ofsY + pointerAbs.y - crp._pointerOrigin.y - thisObj.padding - thisObj.insetWidth,
          yVal = 100 - (y * (100 / (thisObj.height - 1)));

        switch (crp.getSliderComponent(thisObj)) {
          case 's':
            thisObj.fromHSV(null, yVal, null, crp.leavePad);
            break;
          case 'v':
            thisObj.fromHSV(null, null, yVal, crp.leavePad);
            break;
        }
      },

      _vmlNS: 'crp_vml_',
      _vmlCSS: 'crp_vml_css_',
      _vmlReady: false,

      initVML: function () {
        if (!crp._vmlReady) {
          var doc = document;
          if (!doc.namespaces[crp._vmlNS]) {
            doc.namespaces.add(crp._vmlNS, 'urn:schemas-microsoft-com:vml');
          }
          if (!doc.styleSheets[crp._vmlCSS]) {
            var tags = ['shape', 'shapetype', 'group', 'background', 'path', 'formulas', 'handles', 'fill', 'stroke', 'shadow', 'textbox', 'textpath', 'imagedata', 'line', 'polyline', 'curve', 'rect', 'roundrect', 'oval', 'arc', 'image'],
              ss = doc.createStyleSheet();
            ss.owningElement.id = crp._vmlCSS;
            for (var i = 0; i < tags.length; i += 1) {
              ss.addRule(crp._vmlNS + '\\:' + tags[i], 'behavior:url(#default#VML);');
            }
          }
          crp._vmlReady = true;
        }
      },

      createPalette: function () {
        var paletteObj = {
          elm: null,
          draw: null
        };
        if (crp.isCanvasSupported) {
          var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d'),
            drawFunc = function (width, height, type) {
              canvas.width = width;
              canvas.height = height;

              ctx.clearRect(0, 0, canvas.width, canvas.height);

              var hGrad = ctx.createLinearGradient(0, 0, canvas.width, 0);
              hGrad.addColorStop(0 / 6, '#F00');
              hGrad.addColorStop(1 / 6, '#FF0');
              hGrad.addColorStop(2 / 6, '#0F0');
              hGrad.addColorStop(3 / 6, '#0FF');
              hGrad.addColorStop(4 / 6, '#00F');
              hGrad.addColorStop(5 / 6, '#F0F');
              hGrad.addColorStop(6 / 6, '#F00');

              ctx.fillStyle = hGrad;
              ctx.fillRect(0, 0, canvas.width, canvas.height);

              var vGrad = ctx.createLinearGradient(0, 0, 0, canvas.height);
              switch (type.toLowerCase()) {
                case 's':
                  vGrad.addColorStop(0, 'rgba(255,255,255,0)');
                  vGrad.addColorStop(1, 'rgba(255,255,255,1)');
                  break;
                case 'v':
                  vGrad.addColorStop(0, 'rgba(0,0,0,0)');
                  vGrad.addColorStop(1, 'rgba(0,0,0,1)');
                  break;
              }
              ctx.fillStyle = vGrad;
              ctx.fillRect(0, 0, canvas.width, canvas.height);
            };

          paletteObj.elm = canvas;
          paletteObj.draw = drawFunc;
        } else {
          crp.initVML();
          var vmlContainer = document.createElement('div');
          vmlContainer.style.position = 'relative';
          vmlContainer.style.overflow = 'hidden';

          var hGrad = document.createElement(crp._vmlNS + ':fill');
          hGrad.type = 'gradient';
          hGrad.method = 'linear';
          hGrad.angle = '90';
          hGrad.colors = '16.67% #F0F, 33.33% #00F, 50% #0FF, 66.67% #0F0, 83.33% #FF0'

          var hRect = document.createElement(crp._vmlNS + ':rect');
          hRect.style.position = 'absolute';
          hRect.style.left = -1 + 'px';
          hRect.style.top = -1 + 'px';
          hRect.stroked = false;
          hRect.appendChild(hGrad);
          vmlContainer.appendChild(hRect);

          var vGrad = document.createElement(crp._vmlNS + ':fill');
          vGrad.type = 'gradient';
          vGrad.method = 'linear';
          vGrad.angle = '180';
          vGrad.opacity = '0';

          var vRect = document.createElement(crp._vmlNS + ':rect');
          vRect.style.position = 'absolute';
          vRect.style.left = -1 + 'px';
          vRect.style.top = -1 + 'px';
          vRect.stroked = false;
          vRect.appendChild(vGrad);
          vmlContainer.appendChild(vRect);

          var drawFunc = function (width, height, type) {
            vmlContainer.style.width = width + 'px';
            vmlContainer.style.height = height + 'px';

            hRect.style.width =
              vRect.style.width =
              (width + 1) + 'px';
            hRect.style.height =
              vRect.style.height =
              (height + 1) + 'px';

            hGrad.color = '#F00';
            hGrad.color2 = '#F00';

            switch (type.toLowerCase()) {
              case 's':
                vGrad.color = vGrad.color2 = '#FFF';
                break;
              case 'v':
                vGrad.color = vGrad.color2 = '#000';
                break;
            }
          };
          paletteObj.elm = vmlContainer;
          paletteObj.draw = drawFunc;
        }
        return paletteObj;
      },

      createSliderGradient: function () {
        var sliderObj = {
          elm: null,
          draw: null
        };
        if (crp.isCanvasSupported) {
          var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d'),
            drawFunc = function (width, height, color1, color2) {
              canvas.width = width;
              canvas.height = height;

              ctx.clearRect(0, 0, canvas.width, canvas.height);

              var grad = ctx.createLinearGradient(0, 0, 0, canvas.height);
              grad.addColorStop(0, color1);
              grad.addColorStop(1, color2);

              ctx.fillStyle = grad;
              ctx.fillRect(0, 0, canvas.width, canvas.height);
            };
          sliderObj.elm = canvas;
          sliderObj.draw = drawFunc;
        } else {
          crp.initVML();

          var vmlContainer = document.createElement('div');
          vmlContainer.style.position = 'relative';
          vmlContainer.style.overflow = 'hidden';

          var grad = document.createElement(crp._vmlNS + ':fill');
          grad.type = 'gradient';
          grad.method = 'linear';
          grad.angle = '180';

          var rect = document.createElement(crp._vmlNS + ':rect');
          rect.style.position = 'absolute';
          rect.style.left = -1 + 'px';
          rect.style.top = -1 + 'px';
          rect.stroked = false;
          rect.appendChild(grad);
          vmlContainer.appendChild(rect);

          var drawFunc = function (width, height, color1, color2) {
            vmlContainer.style.width = width + 'px';
            vmlContainer.style.height = height + 'px';

            rect.style.width = (width + 1) + 'px';
            rect.style.height = (height + 1) + 'px';

            grad.color = color1;
            grad.color2 = color2;
          };
          sliderObj.elm = vmlContainer;
          sliderObj.draw = drawFunc;
        }
        return sliderObj;
      },

      leaveValue: 1 << 0,
      leaveStyle: 1 << 1,
      leavePad: 1 << 2,
      leaveSld: 1 << 3,

      BoxShadow: (function () {
        var BoxShadow = function (hShadow, vShadow, blur, spread, color, inset) {
          this.hShadow = hShadow;
          this.vShadow = vShadow;
          this.blur = blur;
          this.spread = spread;
          this.color = color;
          this.inset = !!inset;
        };

        BoxShadow.prototype.toString = function () {
          var vals = [
				Math.round(this.hShadow) + 'px',
				Math.round(this.vShadow) + 'px',
				Math.round(this.blur) + 'px',
				Math.round(this.spread) + 'px',
				this.color
			];
          if (this.inset) {
            vals.push('inset');
          }
          return vals.join(' ');
        };

        return BoxShadow;
      })(),

      colorPicker: function (targetElement, options) {
        this.value = null;
        this.valueElement = targetElement;
        this.styleElement = targetElement;
        this.required = true;
        this.refine = true;
        this.hash = false;
        this.uppercase = true;
        this.onFineChange = null;
        this.activeClass = 'colorPicker-active';
        this.overwriteImportant = false;
        this.minS = 0;
        this.maxS = 100;
        this.minV = 0;
        this.maxV = 100;
        this.hsv = [0, 0, 100];
        this.rgb = [255, 255, 255];

        this.width = 111;
        this.height = 101;
        this.showOnClick = true;
        this.mode = 'HSV';
        this.position = 'bottom';
        this.smartPosition = true;
        this.sliderSize = 16;
        this.crossSize = 8;
        this.closable = false;
        this.closeText = 'Close';
        this.buttonColor = '#000000';
        this.buttonHeight = 18;
        this.padding = 6;
        this.backgroundColor = '#FFFFFF';
        this.borderWidth = 1;
        this.borderColor = '#BBBBBB';
        this.borderRadius = 3;
        this.insetWidth = 1;
        this.insetColor = '#BBBBBB';
        this.shadow = true;
        this.shadowBlur = 15;
        this.shadowColor = 'rgba(0,0,0,0.2)';
        this.pointerColor = '#4C4C4C';
        this.pointerBorderColor = '#FFFFFF';
        this.pointerBorderWidth = 1;
        this.pointerThickness = 2;
        this.zIndex = 10000000;
        this.container = null;

        for (var opt in options) {
          if (options.hasOwnProperty(opt)) {
            this[opt] = options[opt];
          }
        }

        this.hide = function () {
          if (isPickerOwner()) {
            detachPicker();
          }
        };

        this.show = function () {
          drawPicker();
        };

        this.redraw = function () {
          if (isPickerOwner()) {
            drawPicker();
          }
        };

        this.importColor = function () {
          if (!this.valueElement) {
            this.exportColor();
          } else {
            if (crp.isElementType(this.valueElement, 'input')) {
              if (!this.refine) {
                if (!this.fromString(this.valueElement.value, crp.leaveValue)) {
                  if (this.styleElement) {
                    this.styleElement.style.backgroundImage = this.styleElement._crpOrigStyle.backgroundImage;
                    this.styleElement.style.backgroundColor = this.styleElement._crpOrigStyle.backgroundColor;
                    this.styleElement.style.color = this.styleElement._crpOrigStyle.color;
                  }
                  this.exportColor(crp.leaveValue | crp.leaveStyle);
                }
              } else if (!this.required && /^\s*$/.test(this.valueElement.value)) {
                this.valueElement.value = '';
                if (this.styleElement) {
                  this.styleElement.style.backgroundImage = this.styleElement._crpOrigStyle.backgroundImage;
                  this.styleElement.style.backgroundColor = this.styleElement._crpOrigStyle.backgroundColor;
                  this.styleElement.style.color = this.styleElement._crpOrigStyle.color;
                }
                this.exportColor(crp.leaveValue | crp.leaveStyle);
              } else if (this.fromString(this.valueElement.value)) {} else {
                this.exportColor();
              }
            } else {
              this.exportColor();
            }
          }
        };

        this.exportColor = function (flags) {
          if (!(flags & crp.leaveValue) && this.valueElement) {
            var value = this.toString();
            if (this.uppercase) {
              value = value.toUpperCase();
            }
            if (this.hash) {
              value = '#' + value;
            }
            if (crp.isElementType(this.valueElement, 'input')) {
              this.valueElement.value = value;
            } else {
              this.valueElement.innerHTML = value;
            }
          }
          if (!(flags & crp.leaveStyle)) {
            if (this.styleElement) {
              var bgColor = '#' + this.toString(),
                fgColor = this.isLight() ? '#000' : '#FFF';
              this.styleElement.style.backgroundImage = 'none';
              this.styleElement.style.backgroundColor = bgColor;
              this.styleElement.style.color = fgColor;
              if (this.overwriteImportant) {
                this.styleElement.setAttribute('style',
                  'background: ' + bgColor + ' !important; ' +
                  'color: ' + fgColor + ' !important;'
                );
              }
            }
          }
          if (!(flags & crp.leavePad) && isPickerOwner()) {
            redrawPad();
          }
          if (!(flags & crp.leaveSld) && isPickerOwner()) {
            redrawSld();
          }
        };

        this.fromHSV = function (h, s, v, flags) {
          if (h !== null) {
            if (isNaN(h)) {
              return false;
            }
            h = Math.max(0, Math.min(360, h));
          }
          if (s !== null) {
            if (isNaN(s)) {
              return false;
            }
            s = Math.max(0, Math.min(100, this.maxS, s), this.minS);
          }
          if (v !== null) {
            if (isNaN(v)) {
              return false;
            }
            v = Math.max(0, Math.min(100, this.maxV, v), this.minV);
          }

          this.rgb = HSV_RGB(
            h === null ? this.hsv[0] : (this.hsv[0] = h),
            s === null ? this.hsv[1] : (this.hsv[1] = s),
            v === null ? this.hsv[2] : (this.hsv[2] = v)
          );

          this.exportColor(flags);
        };

        this.fromRGB = function (r, g, b, flags) {
          if (r !== null) {
            if (isNaN(r)) {
              return false;
            }
            r = Math.max(0, Math.min(255, r));
          }
          if (g !== null) {
            if (isNaN(g)) {
              return false;
            }
            g = Math.max(0, Math.min(255, g));
          }
          if (b !== null) {
            if (isNaN(b)) {
              return false;
            }
            b = Math.max(0, Math.min(255, b));
          }

          var hsv = RGB_HSV(
            r === null ? this.rgb[0] : r,
            g === null ? this.rgb[1] : g,
            b === null ? this.rgb[2] : b
          );
          if (hsv[0] !== null) {
            this.hsv[0] = Math.max(0, Math.min(360, hsv[0]));
          }
          if (hsv[2] !== 0) {
            this.hsv[1] = hsv[1] === null ? null : Math.max(0, this.minS, Math.min(100, this.maxS, hsv[1]));
          }
          this.hsv[2] = hsv[2] === null ? null : Math.max(0, this.minV, Math.min(100, this.maxV, hsv[2]));

          var rgb = HSV_RGB(this.hsv[0], this.hsv[1], this.hsv[2]);
          this.rgb[0] = rgb[0];
          this.rgb[1] = rgb[1];
          this.rgb[2] = rgb[2];

          this.exportColor(flags);
        };

        this.fromString = function (str, flags) {
          var m;
          if (m = str.match(/^\W*([0-9A-F]{3}([0-9A-F]{3})?)\W*$/i)) {

            if (m[1].length === 6) {
              this.fromRGB(
                parseInt(m[1].substr(0, 2), 16),
                parseInt(m[1].substr(2, 2), 16),
                parseInt(m[1].substr(4, 2), 16),
                flags
              );
            } else {
              this.fromRGB(
                parseInt(m[1].charAt(0) + m[1].charAt(0), 16),
                parseInt(m[1].charAt(1) + m[1].charAt(1), 16),
                parseInt(m[1].charAt(2) + m[1].charAt(2), 16),
                flags
              );
            }
            return true;

          } else if (m = str.match(/^\W*rgba?\(([^)]*)\)\W*$/i)) {
            var params = m[1].split(',');
            var re = /^\s*(\d*)(\.\d+)?\s*$/;
            var mR, mG, mB;
            if (
              params.length >= 3 &&
              (mR = params[0].match(re)) &&
              (mG = params[1].match(re)) &&
              (mB = params[2].match(re))
            ) {
              var r = parseFloat((mR[1] || '0') + (mR[2] || ''));
              var g = parseFloat((mG[1] || '0') + (mG[2] || ''));
              var b = parseFloat((mB[1] || '0') + (mB[2] || ''));
              this.fromRGB(r, g, b, flags);
              return true;
            }
          }
          return false;
        };

        this.toString = function () {
          return (
            (0x100 | Math.round(this.rgb[0])).toString(16).substr(1) +
            (0x100 | Math.round(this.rgb[1])).toString(16).substr(1) +
            (0x100 | Math.round(this.rgb[2])).toString(16).substr(1)
          );
        };

        this.toHEXString = function () {
          return '#' + this.toString().toUpperCase();
        };

        this.toRGBString = function () {
          return ('rgb(' +
            Math.round(this.rgb[0]) + ',' +
            Math.round(this.rgb[1]) + ',' +
            Math.round(this.rgb[2]) + ')'
          );
        };

        this.isLight = function () {
          return (
            0.213 * this.rgb[0] +
            0.715 * this.rgb[1] +
            0.072 * this.rgb[2] >
            255 / 2
          );
        };

        this._processParentElementsInDOM = function () {
          if (this._linkedElementsProcessed) {
            return;
          }
          this._linkedElementsProcessed = true;

          var elm = this.targetElement;
          do {
            var currStyle = crp.getStyle(elm);
            if (currStyle && currStyle.position.toLowerCase() === 'fixed') {
              this.fixed = true;
            }
            if (elm !== this.targetElement) {
              if (!elm._crpEventsAttached) {
                crp.attachEvent(elm, 'scroll', crp.onParentScroll);
                elm._crpEventsAttached = true;
              }
            }
          } while ((elm = elm.parentNode) && !crp.isElementType(elm, 'body'));
        };

        function RGB_HSV(r, g, b) {
          r /= 255;
          g /= 255;
          b /= 255;
          var n = Math.min(Math.min(r, g), b);
          var v = Math.max(Math.max(r, g), b);
          var m = v - n;
          if (m === 0) {
            return [null, 0, 100 * v];
          }
          var h = r === n ? 3 + (b - g) / m : (g === n ? 5 + (r - b) / m : 1 + (g - r) / m);
          return [
				60 * (h === 6 ? 0 : h),
				100 * (m / v),
				100 * v
			];
        }

        function HSV_RGB(h, s, v) {
          var u = 255 * (v / 100);
          if (h === null) {
            return [u, u, u];
          }
          h /= 60;
          s /= 100;
          var i = Math.floor(h),
            f = i % 2 ? h - i : 1 - (h - i),
            m = u * (1 - s),
            n = u * (1 - s * f);
          switch (i) {
            case 6:
            case 0:
              return [u, n, m];
            case 1:
              return [n, u, m];
            case 2:
              return [m, u, n];
            case 3:
              return [m, n, u];
            case 4:
              return [n, m, u];
            case 5:
              return [u, m, n];
          }
        }

        function detachPicker() {
          crp.unsetClass(THIS.targetElement, THIS.activeClass);
          crp.picker.wrap.parentNode.removeChild(crp.picker.wrap);
          delete crp.picker.owner;
        }

        function drawPicker() {
          THIS._processParentElementsInDOM();

          if (!crp.picker) {
            crp.picker = {
              owner: null,
              wrap: document.createElement('div'),
              box: document.createElement('div'),
              boxS: document.createElement('div'),
              boxB: document.createElement('div'),
              pad: document.createElement('div'),
              padB: document.createElement('div'),
              padM: document.createElement('div'),
              padPal: crp.createPalette(),
              cross: document.createElement('div'),
              crossBY: document.createElement('div'),
              crossBX: document.createElement('div'),
              crossLY: document.createElement('div'),
              crossLX: document.createElement('div'),
              sld: document.createElement('div'),
              sldB: document.createElement('div'),
              sldM: document.createElement('div'),
              sldGrad: crp.createSliderGradient(),
              sldPtrS: document.createElement('div'),
              sldPtrIB: document.createElement('div'),
              sldPtrMB: document.createElement('div'),
              sldPtrOB: document.createElement('div'),
              btn: document.createElement('div'),
              btnT: document.createElement('span')
            };

            crp.picker.pad.appendChild(crp.picker.padPal.elm);
            crp.picker.padB.appendChild(crp.picker.pad);
            crp.picker.cross.appendChild(crp.picker.crossBY);
            crp.picker.cross.appendChild(crp.picker.crossBX);
            crp.picker.cross.appendChild(crp.picker.crossLY);
            crp.picker.cross.appendChild(crp.picker.crossLX);
            crp.picker.padB.appendChild(crp.picker.cross);
            crp.picker.box.appendChild(crp.picker.padB);
            crp.picker.box.appendChild(crp.picker.padM);

            crp.picker.sld.appendChild(crp.picker.sldGrad.elm);
            crp.picker.sldB.appendChild(crp.picker.sld);
            crp.picker.sldB.appendChild(crp.picker.sldPtrOB);
            crp.picker.sldPtrOB.appendChild(crp.picker.sldPtrMB);
            crp.picker.sldPtrMB.appendChild(crp.picker.sldPtrIB);
            crp.picker.sldPtrIB.appendChild(crp.picker.sldPtrS);
            crp.picker.box.appendChild(crp.picker.sldB);
            crp.picker.box.appendChild(crp.picker.sldM);

            crp.picker.btn.appendChild(crp.picker.btnT);
            crp.picker.box.appendChild(crp.picker.btn);

            crp.picker.boxB.appendChild(crp.picker.box);
            crp.picker.wrap.appendChild(crp.picker.boxS);
            crp.picker.wrap.appendChild(crp.picker.boxB);
          }

          var p = crp.picker,
            displaySlider = !!crp.getSliderComponent(THIS),
            dims = crp.getPickerDims(THIS),
            crossOuterSize = (2 * THIS.pointerBorderWidth + THIS.pointerThickness + 2 * THIS.crossSize),
            padToSliderPadding = crp.getPadToSliderPadding(THIS),
            borderRadius = Math.min(
              THIS.borderRadius,
              Math.round(THIS.padding * Math.PI)),
            padCursor = 'crosshair';

          p.wrap.style.clear = 'both';
          p.wrap.style.width = (dims[0] + 2 * THIS.borderWidth) + 'px';
          p.wrap.style.height = (dims[1] + 2 * THIS.borderWidth) + 'px';
          p.wrap.style.zIndex = THIS.zIndex;

          p.box.style.width = dims[0] + 'px';
          p.box.style.height = dims[1] + 'px';

          p.boxS.style.position = 'absolute';
          p.boxS.style.left = '0';
          p.boxS.style.top = '0';
          p.boxS.style.width = '100%';
          p.boxS.style.height = '100%';
          crp.setBorderRadius(p.boxS, borderRadius + 'px');

          p.boxB.style.position = 'relative';
          p.boxB.style.border = THIS.borderWidth + 'px solid';
          p.boxB.style.borderColor = THIS.borderColor;
          p.boxB.style.background = THIS.backgroundColor;
          crp.setBorderRadius(p.boxB, borderRadius + 'px');

          p.padM.style.background =
            p.sldM.style.background =
            '#FFF';
          crp.setStyle(p.padM, 'opacity', '0');
          crp.setStyle(p.sldM, 'opacity', '0');

          p.pad.style.position = 'relative';
          p.pad.style.width = THIS.width + 'px';
          p.pad.style.height = THIS.height + 'px';

          p.padPal.draw(THIS.width, THIS.height, crp.getPadYComponent(THIS));

          p.padB.style.position = 'absolute';
          p.padB.style.left = THIS.padding + 'px';
          p.padB.style.top = THIS.padding + 'px';
          p.padB.style.border = THIS.insetWidth + 'px solid';
          p.padB.style.borderColor = THIS.insetColor;

          p.padM._crpInstance = THIS;
          p.padM._crpControlName = 'pad';
          p.padM.style.position = 'absolute';
          p.padM.style.left = '0';
          p.padM.style.top = '0';
          p.padM.style.width = (THIS.padding + 2 * THIS.insetWidth + THIS.width + padToSliderPadding / 2) + 'px';
          p.padM.style.height = dims[1] + 'px';
          p.padM.style.cursor = padCursor;

          p.cross.style.position = 'absolute';
          p.cross.style.left =
            p.cross.style.top =
            '0';
          p.cross.style.width =
            p.cross.style.height =
            crossOuterSize + 'px';

          p.crossBY.style.position =
            p.crossBX.style.position =
            'absolute';
          p.crossBY.style.background =
            p.crossBX.style.background =
            THIS.pointerBorderColor;
          p.crossBY.style.width =
            p.crossBX.style.height =
            (2 * THIS.pointerBorderWidth + THIS.pointerThickness) + 'px';
          p.crossBY.style.height =
            p.crossBX.style.width =
            crossOuterSize + 'px';
          p.crossBY.style.left =
            p.crossBX.style.top =
            (Math.floor(crossOuterSize / 2) - Math.floor(THIS.pointerThickness / 2) - THIS.pointerBorderWidth) + 'px';
          p.crossBY.style.top =
            p.crossBX.style.left =
            '0';

          p.crossLY.style.position =
            p.crossLX.style.position =
            'absolute';
          p.crossLY.style.background =
            p.crossLX.style.background =
            THIS.pointerColor;
          p.crossLY.style.height =
            p.crossLX.style.width =
            (crossOuterSize - 2 * THIS.pointerBorderWidth) + 'px';
          p.crossLY.style.width =
            p.crossLX.style.height =
            THIS.pointerThickness + 'px';
          p.crossLY.style.left =
            p.crossLX.style.top =
            (Math.floor(crossOuterSize / 2) - Math.floor(THIS.pointerThickness / 2)) + 'px';
          p.crossLY.style.top =
            p.crossLX.style.left =
            THIS.pointerBorderWidth + 'px';

          p.sld.style.overflow = 'hidden';
          p.sld.style.width = THIS.sliderSize + 'px';
          p.sld.style.height = THIS.height + 'px';

          p.sldGrad.draw(THIS.sliderSize, THIS.height, '#000', '#000');

          p.sldB.style.display = displaySlider ? 'block' : 'none';
          p.sldB.style.position = 'absolute';
          p.sldB.style.right = THIS.padding + 'px';
          p.sldB.style.top = THIS.padding + 'px';
          p.sldB.style.border = THIS.insetWidth + 'px solid';
          p.sldB.style.borderColor = THIS.insetColor;

          p.sldM._crpInstance = THIS;
          p.sldM._crpControlName = 'sld';
          p.sldM.style.display = displaySlider ? 'block' : 'none';
          p.sldM.style.position = 'absolute';
          p.sldM.style.right = '0';
          p.sldM.style.top = '0';
          p.sldM.style.width = (THIS.sliderSize + padToSliderPadding / 2 + THIS.padding + 2 * THIS.insetWidth) + 'px';
          p.sldM.style.height = dims[1] + 'px';
          p.sldM.style.cursor = 'default';

          p.sldPtrIB.style.border =
            p.sldPtrOB.style.border =
            THIS.pointerBorderWidth + 'px solid ' + THIS.pointerBorderColor;

          p.sldPtrOB.style.position = 'absolute';
          p.sldPtrOB.style.left = -(2 * THIS.pointerBorderWidth + THIS.pointerThickness) + 'px';
          p.sldPtrOB.style.top = '0';

          p.sldPtrMB.style.border = THIS.pointerThickness + 'px solid ' + THIS.pointerColor;

          p.sldPtrS.style.width = THIS.sliderSize + 'px';
          p.sldPtrS.style.height = sliderPtrSpace + 'px';

          function setBtnBorder() {
            var insetColors = THIS.insetColor.split(/\s+/),
              outsetColor = insetColors.length < 2 ? insetColors[0] : insetColors[1] + ' ' + insetColors[0] + ' ' + insetColors[0] + ' ' + insetColors[1];
            p.btn.style.borderColor = outsetColor;
          }
          p.btn.style.display = THIS.closable ? 'block' : 'none';
          p.btn.style.position = 'absolute';
          p.btn.style.left = THIS.padding + 'px';
          p.btn.style.bottom = THIS.padding + 'px';
          p.btn.style.padding = '0 15px';
          p.btn.style.height = THIS.buttonHeight + 'px';
          p.btn.style.border = THIS.insetWidth + 'px solid';
          setBtnBorder();
          p.btn.style.color = THIS.buttonColor;
          p.btn.style.font = '12px sans-serif';
          p.btn.style.textAlign = 'center';
          try {
            p.btn.style.cursor = 'pointer';
          } catch (eOldIE) {
            p.btn.style.cursor = 'hand';
          }
          p.btn.onmousedown = function () {
            THIS.hide();
          };
          p.btnT.style.lineHeight = THIS.buttonHeight + 'px';
          p.btnT.innerHTML = '';
          p.btnT.appendChild(document.createTextNode(THIS.closeText));

          redrawPad();
          redrawSld();

          if (crp.picker.owner && crp.picker.owner !== THIS) {
            crp.unsetClass(crp.picker.owner.targetElement, THIS.activeClass);
          }

          crp.picker.owner = THIS;

          if (crp.isElementType(container, 'body')) {
            crp.redrawPosition();
          } else {
            crp._drawPosition(THIS, 0, 0, 'relative', false);
          }

          if (p.wrap.parentNode != container) {
            container.appendChild(p.wrap);
          }

          crp.setClass(THIS.targetElement, THIS.activeClass);
        }

        function redrawPad() {
          switch (crp.getPadYComponent(THIS)) {
            case 's':
              var yComponent = 1;
              break;
            case 'v':
              var yComponent = 2;
              break;
          }
          var x = Math.round((THIS.hsv[0] / 360) * (THIS.width - 1)),
            y = Math.round((1 - THIS.hsv[yComponent] / 100) * (THIS.height - 1)),
            crossOuterSize = (2 * THIS.pointerBorderWidth + THIS.pointerThickness + 2 * THIS.crossSize),
            ofs = -Math.floor(crossOuterSize / 2);
          crp.picker.cross.style.left = (x + ofs) + 'px';
          crp.picker.cross.style.top = (y + ofs) + 'px';

          switch (crp.getSliderComponent(THIS)) {
            case 's':
              var rgb1 = HSV_RGB(THIS.hsv[0], 100, THIS.hsv[2]),
                rgb2 = HSV_RGB(THIS.hsv[0], 0, THIS.hsv[2]),
                color1 = 'rgb(' +
                Math.round(rgb1[0]) + ',' +
                Math.round(rgb1[1]) + ',' +
                Math.round(rgb1[2]) + ')',
                color2 = 'rgb(' +
                Math.round(rgb2[0]) + ',' +
                Math.round(rgb2[1]) + ',' +
                Math.round(rgb2[2]) + ')';
              crp.picker.sldGrad.draw(THIS.sliderSize, THIS.height, color1, color2);
              break;
            case 'v':
              var rgb = HSV_RGB(THIS.hsv[0], THIS.hsv[1], 100),
                color1 = 'rgb(' +
                Math.round(rgb[0]) + ',' +
                Math.round(rgb[1]) + ',' +
                Math.round(rgb[2]) + ')',
                color2 = '#000';
              crp.picker.sldGrad.draw(THIS.sliderSize, THIS.height, color1, color2);
              break;
          }
        }

        function redrawSld() {
          var sldComponent = crp.getSliderComponent(THIS);
          if (sldComponent) {
            switch (sldComponent) {
              case 's':
                var yComponent = 1;
                break;
              case 'v':
                var yComponent = 2;
                break;
            }
            var y = Math.round((1 - THIS.hsv[yComponent] / 100) * (THIS.height - 1));
            crp.picker.sldPtrOB.style.top = (y - (2 * THIS.pointerBorderWidth + THIS.pointerThickness) - Math.floor(sliderPtrSpace / 2)) + 'px';
          }
        }

        function isPickerOwner() {
          return crp.picker && crp.picker.owner === THIS;
        }

        function blurValue() {
          THIS.importColor();
        }

        if (typeof targetElement === 'string') {
          var id = targetElement,
            elm = document.getElementById(id);
          if (elm) {
            this.targetElement = elm;
          } else {
            crp.warn('Could not find target element with ID \'' + id + '\'');
          }
        } else if (targetElement) {
          this.targetElement = targetElement;
        } else {
          crp.warn('Invalid target element: \'' + targetElement + '\'');
        }

        if (this.targetElement._crpLinkedInstance) {
          crp.warn('Cannot link colorPicker twice to the same element. Skipping.');
          return;
        }
        this.targetElement._crpLinkedInstance = this;

        this.valueElement = crp.fetchElement(this.valueElement);
        this.styleElement = crp.fetchElement(this.styleElement);

        var THIS = this,
          container =
          this.container ?
          crp.fetchElement(this.container) :
          document.getElementsByTagName('body')[0],
          sliderPtrSpace = 3;

        if (crp.isElementType(this.targetElement, 'button')) {
          if (this.targetElement.onclick) {
            var origCallback = this.targetElement.onclick;
            this.targetElement.onclick = function (evt) {
              origCallback.call(this, evt);
              return false;
            };
          } else {
            this.targetElement.onclick = function () {
              return false;
            };
          }
        }

        if (this.valueElement) {
          if (crp.isElementType(this.valueElement, 'input')) {
            var updateField = function () {
              THIS.fromString(THIS.valueElement.value, crp.leaveValue);
              crp.dispatchFineChange(THIS);
            };
            crp.attachEvent(this.valueElement, 'keyup', updateField);
            crp.attachEvent(this.valueElement, 'input', updateField);
            crp.attachEvent(this.valueElement, 'blur', blurValue);
            this.valueElement.setAttribute('autocomplete', 'off');
          }
        }

        if (this.styleElement) {
          this.styleElement._crpOrigStyle = {
            backgroundImage: this.styleElement.style.backgroundImage,
            backgroundColor: this.styleElement.style.backgroundColor,
            color: this.styleElement.style.color
          };
        }

        if (this.value) {
          this.fromString(this.value) || this.exportColor();
        } else {
          this.importColor();
        }
      }
    };

    crp.colorPicker.lookupClass = 'colorPicker';
    crp.colorPicker.installByClassName = function (className) {
      var inputElms = document.getElementsByTagName('input'),
        buttonElms = document.getElementsByTagName('button');
      crp.tryInstallOnElements(inputElms, className);
      crp.tryInstallOnElements(buttonElms, className);
    };

    crp.register();
    return crp.colorPicker;
  })();
}
