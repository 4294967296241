import L from 'leaflet';
import Tools from './Tools';
import $ from 'jquery';
var tools = new Tools();

class Permalink {

    constructor(config, settings) {
        this.map = settings.map;
        this.modules = settings.modules
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.permalinkWindowClass = '.permalinkPopup';
        this.permalinkWindowActiveClass = this.permalinkWindowClass + '--active';
        this.permalinkWindowCloseClass = this.permalinkWindowClass + '-close';
        this.permalinkProps = tools.createElPropertiesFromClass(this.toolsClass + '__permalink');
        this.storage = window.sessionStorage;
        this.treeStructure = config.treeStructure;
        this.bases = config.bases;
        this.allLayers = config.layerList;
        this.treeStructure = config.treeStructure;
        this.activeLayersManager = settings.activeLayersManager;
        this.states = settings.states;
        this.states.onPermalink = false;
        this.permalinkAttributes = {};
        this.events();
        this.overlayOrder = this.activeLayersManager.getLayersOrder('overlay');
        this.basesOrder = this.activeLayersManager.getLayersOrder('base');
        this.allOrder = this.activeLayersManager.getLayersOrder('all');
        this.getObjectFromPermalink =
            settings.getObjectFromPermalink.getObjectFromPermalink;
        var that = this;
        setTimeout(function() {
            that.viewFromPermalink();
        }, 1500)
    }

    events() {
        var that = this;

        $(document).off('click tap', this.permalinkProps.selector)
        $(document).on('click tap', this.permalinkProps.selector, function() {
            $(that.permalinkWindowClass).toggleClass('overlay--active');
            if ($('.coordsSearchPopup').hasClass('overlay--active')) {
                $('.tools__coordsSearch').click();
            }
            that.states.onPermalink = !that.states.onPermalink;
            that.states.onInfo = !that.states.onInfo;
            if (that.allLayers.length > 0) {
                that.addLinkToPopup();
            }
        });

        $(document).on('click tap', this.permalinkWindowCloseClass, function() {
            $(that.permalinkProps.selector).find('.tools__tool-icon').removeClass('tools__tool-icon-focused');
            $(that.permalinkProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-focused');
            that.states.onPermalink = false;
            that.states.onInfo = true;
        });

        $('.permalinkPopup').on('click tap', function() {
            if ($(window).width() > 768 || $(window).height() > 768) {
                $(this).draggable({
                    cursor: 'grab',
                    cursorAt: {
                        left: 5,
                        top: 0,
                    },
                    opacity: 0.8,
                });
            }
        })

    }

    updateOrders() {
        this.overlayOrder = this.activeLayersManager.getLayersOrder('overlay');
        this.basesOrder = this.activeLayersManager.getLayersOrder('base');
        this.allOrder = this.activeLayersManager.getLayersOrder('all');
    }

    copyLink() {
        var copyText = $("#permalinkPopup__body--link");
        copyText.select();
        document.execCommand("copy");
    }

    addLinkToPopup() {
        var that = this,
            permalink = this.getPermalink();
        $('.permalinkPopup__body--link').attr("href", permalink);
        $('.permalinkPopup__body--link').val(permalink);
        $('#permalinkPopup__body--link-copy').on('click tap', function() {
            that.copyLink();
        })
    }


    /*  addCenterParameterToPermalinkAttributes() {
         this.permalinkAttributes.center = this.map.getCenter().lat + ',' + this.map.getCenter().lng + ',' + 100;
     } */

    addMapPositionToPermalinkAttributes() {
        this.permalinkAttributes.zoom = this.map.getZoom();
        this.permalinkAttributes.lat = this.map.getCenter().lat;
        this.permalinkAttributes.lng = this.map.getCenter().lng;
    }

    addMapSelectedLayersToPermalinkAttributes() {
        var layersObject = this.getOverlayLayersObject(),
            layers = '';
        $.each(layersObject, function(layerID) {
            layers += layerID + ',';
        })
        this.permalinkAttributes['l'] = layers.slice(0, -1);
        this.permalinkAttributes['b'] = this.activeLayersManager.getActiveBaseLayerId();
    }

    addModeToPermalinkAttributes() {
        var mode = this.getMode();
        if (mode == 'selectonmap') {
            this.permalinkAttributes.m = 'sos' + this.storage.getItem('formID');
        } else if (mode == 'info') {
            this.permalinkAttributes.m = 'i';
        } else if (mode == 'print') {
            this.permalinkAttributes.m = 'p';
        } else if (mode == 'measure') {
            this.permalinkAttributes.m = 'm';
        } else if (mode == 'news') {
            if (this.storage.getItem('newsID') != 'none') {
                this.permalinkAttributes.m = 'n' + this.storage.getItem('newsID');
            } else {
                this.permalinkAttributes.m = 'n';
            }
        } else if (mode == 'streetview') {
            this.permalinkAttributes.m = 'v';
        } else if (mode == 'options') {
            this.permalinkAttributes.m = 'so' + this.storage.getItem('formID');
        } else if (mode == 'es-sp') {
            this.permalinkAttributes.m = 'es-sp';
        } else if (mode == 'es-sc') {
            this.permalinkAttributes.m = 'es-sc';
        } else if (mode == 'es-sa') {
            this.permalinkAttributes.m = 'es-sa';
        } else if (mode == 'es-ip') {
            this.permalinkAttributes.m = 'es-ip';
        } else if (mode == 'es-ia') {
            this.permalinkAttributes.m = 'es-ia';
        } else if (mode == 'es-ti') {
            this.permalinkAttributes.m = 'es-ti';
        } else if (mode == 'wybory') {
            this.permalinkAttributes.m = 'w';
        } else if (mode == 'turysta') {
            var modeOptions = JSON.parse(this.storage.getItem('modeOptions'));
            this.permalinkAttributes.m = 't';
            if (modeOptions) {
                this.permalinkAttributes.tid = modeOptions.id
                this.permalinkAttributes.tpart = modeOptions.part
                this.permalinkAttributes.tsection = modeOptions.section
                this.permalinkAttributes.tspecific = modeOptions.specific
                    // this.permalinkAttributes.menu = false
            }
        } else if (mode == 'none') {
            delete this.permalinkAttributes.m;
        }
    }

    updatePermalinkAttributes() {
        // if (true) {
        this.addMapPositionToPermalinkAttributes();
        this.addMapSelectedLayersToPermalinkAttributes();
        this.addModeToPermalinkAttributes();
        // } else {
        // this.addCenterParameterToPermalinkAttributes();
        // }
    }

    getPermalink() {
        this.updatePermalinkAttributes();
        var currentUrl = window.location.href,
            permalink = (currentUrl.indexOf('?') > -1) ? currentUrl.slice(0, currentUrl.indexOf('?') + 1) : currentUrl + '?';
        $.each(this.permalinkAttributes, function(key, value) {
            permalink += key + '=' + value + '&';
        })
        return permalink.slice(0, -1);
    }

    getMode() {
        return this.storage.getItem('mode');
    }

    getOverlayLayersObject() {
        var activeOverlayLayers = this.activeLayersManager.getActiveLayersIds(),
            layersObject = {};
        $.each(activeOverlayLayers, function(i, v) {
            layersObject[v] = 1;
        })
        return layersObject;
    }

    /*  getOverlayLayers(ids) {
         var that = this,
             overlays = [];
         $.each(ids, function(i, id) {
             overlays.push(that.getOverlayLayer(id))
         })
         return overlays;
     } */

    selectOverlayLayers(obj, configLogin) {
        var that = this,
            activeLayers = that.map.layersControl.options.getActiveLayers() || [],
            layerToSelectNums = obj.split(',');
        that.unselectAllLayers();
        var allLayers = configLogin ? configLogin.layerList : this.allLayers
        $.each(allLayers, function(type, layer) {
            if ($.inArray(layer.id.toString(), layerToSelectNums) > -1) {
                var layerToSelect = $.grep(activeLayers, x => x._url === layer.url);
                if (layerToSelect.length > 1) {
                    // that.selectLayer(layerToSelect.filter(x => x.options.layers === layer.options.layers)[0], layer.id);
                    that.selectLayer(layer.id);
                } else {
                    // that.selectLayer(layerToSelect[0], layer.id);
                    that.selectLayer(layer.id);
                }
            }
        })
    }

    selectBaseLayer(id) {
        var order = this.basesOrder,
            index = $.inArray(parseInt(id), order),
            inputs = $('.leaflet-control-layers-base').find('.leaflet-control-layers-selector');
        for (var i = 0; i < inputs.length; i++) {
            if (i == index) {
                $(inputs[i]).click();
            }
        }
    }

    unselectAllLayers() {
        var that = this;
        $.each(this.treeStructure, function(i, group) {
            $.each(group.layers, function(i, treeLayer) {
                $.each(that.map._layers, function(i, layer) {
                    if (treeLayer.id == layer.id)
                        layer.remove();
                })
            })
        })
    }

    selectLayer(layerID) {
        var order = this.allOrder,
            indexes = this.getAllIndexes(order, layerID);
        $('.leaflet-control-layers-selector').each(function(i) {
            if ($.inArray(i, indexes) > -1) {
                $(this).click();
            }
        })
    }

    getAllIndexes(arr, val) {
        var indexes = [],
            i = -1;
        while ((i = arr.indexOf(val, i + 1)) != -1) {
            indexes.push(i);
        }
        return indexes;
    }

    hasDuplicates(array) {
        var valuesSoFar = Object.create(null);
        for (var i = 0; i < array.length; ++i) {
            var value = array[i];
            if (value in valuesSoFar) {
                return true;
            }
            valuesSoFar[value] = true;
        }
        return false;
    }

    setMode(str, permalinkObj) {
        var formID, that = this;
        if (str.charAt(0) == 's') {
            if (str.charAt(2) == 's') {
                formID = str.slice(3);
                setTimeout(function() {
                    $('.formPanel a[data-notice-guid=' + formID + ']').click();
                }, 2000);
            } else {
                formID = str.slice(2);
                $('[data-btnid=' + formID + ']').click();
            }
        } else if (str.charAt(0) == 'p') {
            $('.tools__print').click();
        } else if (str.charAt(0) == 'm') {
            $('.tools__measure').click();
        } else if (str.charAt(0) == 'n') {
            // if ($('.newsPanel').hasClass('newsPanel--visible')) {} else {
            if (!($('.newsPanel').hasClass('newsPanel--visible'))) {
                $('.news-show').click()
            }
            if (str.charAt(1)) {
                var id = str.substring(1, str.length),
                    secClick = 600,
                    diff = ($(window).width() > 992 ? 200 : ($(window).width() > 768 ? 230 : 270))
                if (id > 3) {
                    var scrollTop = 10,
                        scrollingTime = 50;
                    for (var i = 1; i <= id; i++) {
                        scrollTop += diff;
                        $('.newsPanel__container').stop().animate({
                            scrollTop: scrollTop
                        }, scrollingTime * i);
                        secClick += scrollingTime;
                    }
                    setTimeout(function() {
                        $('#newsBtn-' + id).click()
                    }, secClick)
                } else {
                    $('#newsBtn-' + id).click()
                }
            }
        } else if (str.charAt(0) == 'v') {
            $('.tools__streetView').click();
        } else if (str.charAt(0) == 'i') {
            $('.tools__info').click();
        } else if (str.charAt(0) == 'w') {
            $('.wyboryPanel__body--item').click();
        } else if (str.charAt(0) == 'e' && str.charAt(1) == 's') {
            if (str.charAt(3) == 's' && str.charAt(4) == 'a') {
                $('#eService__searchAddress').click();
            } else if (str.charAt(3) == 's' && str.charAt(4) == 'p') {
                $('#eService__searchParcels').click();
            } else if (str.charAt(3) == 's' && str.charAt(4) == 'c') {
                $('#eService__searchCoords').click();
            } else if (str.charAt(3) == 'i' && str.charAt(4) == 'p') {
                $('#eService__parcelsInfo').click();
            } else if (str.charAt(3) == 'i' && str.charAt(4) == 'a') {
                $('#eService__addressInfo').click();
            } else if (str.charAt(3) == 't' && str.charAt(4) == 'i') {
                $('#eService__terrainInfo').click();
            }
        } else if (str.charAt(0) == 't') {

            if (permalinkObj.tid || permalinkObj.tpart || permalinkObj.tsection || permalinkObj.tspecific) {
                that.modules.Twg.showPanel()
                that.modules.Twg.gettwg(permalinkObj.tid, permalinkObj.tpart, permalinkObj.tsection, permalinkObj.tspecific)
            } else {
                $('.twg-show').click()
            }
        }
    }


    selectLayersPortal(layers) {
        //odznaczenie wszystkich warstw
        this.unselectAllLayers()
        this.selectBaseLayer(9999)

        //zamiana nazw na nasze (można podmieniać)
        $.each(layers, (i, layer) => {
            layer = layer.replace(/\s+/g, ' ').trim().toLowerCase()
            if (layer == 'budynki') {
                layers[i] = 'budynki-powiat'
            } else if (layer == 'dzialki') {
                layers[i] = 'działki-powiat'
            } else if (layer == 'adresy') {
                layers[i] = 'adresy-gema'
            } else if (layer == 'ulice') {
                layers[i] = 'ulice-gema'
            } else if (layer == 'topo') {
                layers[i] = 'geoportal-topo'
            } else if (layer == 'orto') {
                layers[i] = 'geoportal-orto'
            }
        })

        //zaznaczenie warstwy bazowej z permalinku
        $.each($('.leaflet-control-layers-base').find('.leaflet-control-layers-radio'), (i, div) => {
            $.each(layers, (i, layer) => {
                if ($(div).text().replace(/\s+/g, ' ').trim().toLowerCase() == layer) {
                    $(div).children('div').children('input').click()
                }
            })
        })

        //zaznaczenie warstw tematycznych z permalinku
        $.each($('.leaflet-control-layers-checkGroup'), (i, div) => {
            if ($(div).text().replace(/\s+/g, ' ').trim().toLowerCase() == 'granica gminy') {
                $(div).children('div').children('input').click()
            }
            $.each(layers, (i, layer) => {
                if ($(div).text().replace(/\s+/g, ' ').trim().toLowerCase() == layer) {
                    $(div).children('div').children('input').click()
                }
            })
        })
    }

    showMarkersPortal(markers) {
        var latlon,
            marker,
            markersGroup = L.layerGroup(),
            icon = L.icon({
                iconUrl: './assets/images/marker-icon.png',
                iconSize: [25, 41],
                iconAnchor: [13, 0],
                popupAnchor: [0, 0]
            });
        for (var i = 0; i < markers.length / 2 + 1; i += 2) {
            latlon = [markers[i], markers[i + 1]]
            marker = L.marker(latlon, {
                icon: icon
            }).bindPopup("szerokość: " + latlon[0] + ", <br>długość: " + latlon[1])
            marker.addTo(markersGroup);
        }
        markersGroup.addTo(this.map)
    }

    //ŁADOWANIE STANU MAPY Z PERMALINKU
    //lat,lng,zoom
    //b - base layer
    //l - overlays
    //m - tryb
    //center - parametr potrzebny do wywołania portalu gminnego z geoportalu, [center=N,E,r], gdzie N-szerokość geograficzna, E-długość geograficzna, r-promień największego koła mieszczącego się w zakresie mapy (wyrażony w metrach)
    //layers - parametr potrzebny do wywołania portalu gminnego z geoportalu, warstwy, które mają być widoczneL
    ////w permalinku: 'topo' - na sipie 'Geoportal-TOPO'
    ////w permalinku: 'orto' - na sipie 'Geoportal-ORTO'
    ////w permalinku: 'budynki' - na sipie 'budynki-powiat'
    ////w permalinku: 'dzialki' - na sipie 'działki-powiat'
    ////w permalinku: 'adresy' - na sipie 'adresy-GEMA'
    ////w permalinku: 'ulice' - na sipie 'ulice-GEMA'
    ////Poza tymi przypadkami można też wpisywać nazwy innych warstw (takie jak w drzewie warstw, wielkość liter nie ma znaczenia) np layers=mpzp - to też zadziała. Granice gminy wyświetlają się zawsze (to też można zmienić).
    //markers - parametr potrzebny do wywołania portalu gminnego z geoportalu; zawiera parzystą liczbę liczb rzeczywistych - par "szerkosoc,dlugosc" wskazujących na połozenie markerów mających się wyświetlać na mapie
    viewFromPermalink(configLogin) {
        var permalinkObject = this.getObjectFromPermalink();
        if ('center' in permalinkObject || 'layers' in permalinkObject) {
            if ('center' in permalinkObject) {
                var center = permalinkObject.center.split(','),
                    radius = center[2] || 1000,
                    circle = L.circle([center[0], center[1]], parseInt(radius), { 'color': 'transparent' }).addTo(this.map)
                this.map.fitBounds(circle.getBounds());
                circle.removeFrom(this.map)
            }
            if ('layers' in permalinkObject) {
                var layers = permalinkObject.layers.split(',')
                this.selectLayersPortal(layers)
            }
            if ('markers' in permalinkObject) {
                var markers = permalinkObject.markers.split(',')
                this.showMarkersPortal(markers)
            }
        } else {
            if (('lat' in permalinkObject) && ('lng' in permalinkObject) && ('zoom' in permalinkObject)) {
                this.map.setView([permalinkObject.lat, permalinkObject.lng], permalinkObject.zoom);
            }
            if (('b' in permalinkObject && permalinkObject.b !== "") && ('l' in permalinkObject && permalinkObject.l !== "")) {
                this.selectOverlayLayers(permalinkObject.l, configLogin);
                this.selectBaseLayer(permalinkObject.b);
            } else if (('b' in permalinkObject && permalinkObject.b !== "") && !('l' in permalinkObject && permalinkObject.l !== "")) {
                this.unselectAllLayers();
                this.selectBaseLayer(permalinkObject.b);
            } else if ('l' in permalinkObject && permalinkObject.l !== "") {
                this.selectOverlayLayers(permalinkObject.l, configLogin);
            }
            if ('m' in permalinkObject) {
                this.setMode(permalinkObject.m, permalinkObject);
            }
            if ('markers' in permalinkObject) {
                var markers = permalinkObject.markers.split(',')
                this.showMarkersPortal(markers)
            }
        }
    }

}

export default Permalink;