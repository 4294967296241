import $ from 'jquery';
import Loading from './Loading';

class StartingMenu {

    constructor(config, settings) {
        this.treeStructure = config.treeStructure,
            this.map = settings.map;
        this.cityBorder = '20070';
        this.loading = new Loading(config, settings)
        if (window.location.href.includes('dobroszyce_kultura') || window.location.href.includes('localhost')) {
            if (navigator.userAgent.match(/Android/i) ||
                navigator.userAgent.match(/webOS/i) ||
                navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i) ||
                navigator.userAgent.match(/BlackBerry/i) ||
                navigator.userAgent.match(/Windows Phone/i)) {

                $('.small-icon-menu').removeClass('hidden')
                this.events();
                this.profilId = 642;

                if (!window.location.href.includes('m=t') && !window.location.href.includes('m=n') && !window.location.href.includes('map=true')) {
                    $('.starting-panel').addClass('starting-panel--active')
                }

                if (window.location.href.includes('m=t')) {
                    $('.twgPanel__groups').addClass('hidden')
                }

                if (window.location.href.includes('map=true') || window.location.href.includes('m=n') || window.location.href.includes('m=t')) {
                    $('#map').css('display', 'block')
                    $('#mapOff').css('display', 'none')
                } else {
                    $('#map').css('display', 'none')
                    $('.top-logos__body').css('display', 'none')
                    $('.bar-top').css('display', 'none')
                    $('.bar-bottom').css('display', 'none')
                    $('.countPopup').css('display', 'none')
                    $('.responsive-icon-d, .twg-show, .news-show').css('display', 'none')
                    $('#mapOff').css('display', 'flex')
                    this.loading.makeLoadingScreen(".mapOff", "Poczekaj...")
                }

            }
        }
    }

    events() {
        const that = this;

        // After click on starting menu e.g. Organizacje, it go to specific href
        $(document).on('click tap', '.starting-panel--link', function() {

            if (!$(this).children('.starting-panel--link-title').attr('href')) {
                const specific = $(this).attr('data-id');
                const section = $(this).attr('data-section-layer');
                const layer = $(this).attr('data-layer');
                const map = $(this).attr('data-map');
                const mode = $(this).attr('data-mode');

                const options = that.createOptions(specific, section, layer, mode, map, that.cityBorder);
                const url = that.createUrl(options);

                window.location.href = `?${url}`;
            } else {
                window.location.href = $(this).children('.starting-panel--link-title').attr('href')
            }
        })
    }

    createOptions(specific, section, layer, mode, map, border, part = 0) {
        const that = this;
        if (map) {
            return ({
                map: true
            });
        } else if (mode == "n") {
            return ({
                m: mode
            });
        } else {
            return ({
                m: mode,
                tid: that.profilId,
                tpart: part,
                ...(section ? { tsection: section } : {}),
                ...(specific ? { tspecific: specific } : {}),
                ...(layer ? { l: layer } : {}),
                b: border
            });
        }
    }

    createUrl(options) {
        return Object
            .keys(options)
            .map(k => k + '=' + options[k])
            .join('&');
    }
}

export default StartingMenu;