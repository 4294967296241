import Tools from './Tools';
import $ from 'jquery';
var tools = new Tools();

// tlumaczenie strony
class Overlay {

    constructor() {
        this.overlayClass = 'overlay';
        this.closeOverlayBtnClass = this.overlayClass + '__close-icon';
        this.closeOverlayBtnSelector = '.' + this.closeOverlayBtnClass;
        this.overlayActiveClass = this.overlayClass + '--active';
        this.$overlay = tools.$elFromClass(this.overlayClass);
        this.events();
    }

    events() {
        var that = this;

        $(document).on('click tap', this.closeOverlayBtnSelector, function() {
            that.closeOverlay();
        });

    }

    closeOverlay() {
        this.$overlay.removeClass(this.overlayActiveClass);
    }

}

export default Overlay;