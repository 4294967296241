//import Tools from '././Tools';
//var tools = new Tools();
import L from 'leaflet';
import $ from 'jquery';

class eService_searchParcels {

  constructor(config, settings, lp) {
    this.map = settings.map;
    this.toolsClass = settings.toolsClass;
    this.tools = config.MapConf.tools || {};
    this.storage = window.sessionStorage;
    this.treeStructure = config.treeStructure;
    this.bases = config.bases;
    this.lp = lp;
    this.osmid = config.MapConf.OsmID;
    this.layers = config.layerListKeys;
    this.activeLayersManager = settings.activeLayersManager;
    this.allOrder = this.activeLayersManager.getLayersOrder('all');
    this.states = settings.states;
    this.addServiceBtn();
    this.searchClass = 'search';
    this.$field = $('.eservicePopup__body__input-field-parcels');
    this.$search = this.$field.closest('.' + this.searchClass);
    this.$loadingAnim = this.$search.find('.search__input-loading-anim');
    this.$foundList = this.$search.find('.search__input-found-list');
    this.requestFlag = true;
    this.timer = 0;
    this.parcelLayer = null;
    this.uncheck = false;
    this.params = {
      textFilter: '',
      foundData: [],
    };
    var that = this;
    this.keyUpCallbacks = {
      _this: that,
      arrowUp: this.arrowUp,
      arrowDown: this.arrowDown,
      enterPress: this.enterPress
    };
    this.events();
  }

  events() {
    var that = this;

    $(document).on('input', '.eservicePopup__body__input-field-parcels', function (e) {
      that.updateProperties(e.target);
      that.searchValue(e);
    });

    $(document).on('click tap', 'li.sugg', function () {
      var latlng = that.getLatlngFromSugg($(this));
      that.showPointOnMap(latlng);
      var selectedIndex = $(this).index();
      if (selectedIndex != 0) {
        var $newActive = that.$foundList.children('li').eq(selectedIndex);
        $newActive.addClass('sugg-active');
        var text = $newActive.children('span').text();
        that.$field.val(text);
        that.setVisible($newActive, false);
      }
    });

    $(document).on('click tap', 'body', function (event) {
      that.closeSuggs(event);
    });

    $('.eservice-close').on('click', function () {
      $('.modePanel').removeClass('modePanel--active');
      $('.small-icon-modePanel').css('display', 'none');
      if (that.parcelLayer) {
        that.unselectLayer();
      }
      that.storage.setItem('mode', 'none');
    })

    $('.modePanel__body--btn-cancel').on('click', function () {
      $('.modePanel').removeClass('modePanel--active');
      $('.small-icon-modePanel').css('display', 'none');
      that.storage.setItem('mode', 'none');
      $('.eservice-close').click();
    })

    $(document).on('click', '.eservicePopup__body__input-button-parcel', function () {
      var selectedIndex = 1;
      if (selectedIndex != 0) {
        var $newActive = that.$foundList.children('li').eq(0)
        $newActive.addClass('sugg-active');
        var text = $newActive.children('span').text();
        that.$field.val(text);
        that.setVisible($newActive, false);
        if ($newActive) {
          var latlng = that.getLatlngFromSugg($newActive);
          that.showPointOnMap(latlng);
        }
      }
    })


    $(window).on('keyup', function (e) {
      if (that.$foundList.hasClass('list-active')) {
        var key = e.keyCode;
        switch (key) {
          case 38: //up arrow
            that.keyUpCallbacks.arrowUp();
            break;
          case 40: //down arrow
            that.keyUpCallbacks.arrowDown();
            break;
          case 13: //enter
            that.keyUpCallbacks.enterPress(e);
            break;
        }
      }
    })

    $('.eservicePopup').on('click', function () {
      if ($(window).width() > 768 || $(window).height() > 768) {
        $(this).draggable({
          cursor: 'grab',
          cursorAt: {
            left: 5,
            top: 0,
          },
          opacity: 0.8,
        });
      }
    })

  }

  addServiceBtn() {
    var that = this;

    var serviceDiv = $('<div>', {
      html: `<div class="eservicePanel__body--item-text">Wskazywanie działki po podaniu jej numeru</div>
<div class="eservice-btn eservicePanel__body--item-btn" id=""><img class="eservicePanel__body--item-btn-img" src="assets/images/eservices/eservice_searchparcels.png"/></div>`,
      class: 'eservicePanel__body--item',
      id: 'eService__searchParcels'
    })

    $('.eservicePanel__body').append(serviceDiv);

    $('#eService__searchParcels').on('click', function () {
      $('.modePanel__body--btn-cancel').click();
      $('.eservicePopup').css('max-height', '120px');
      $('.modePanel').addClass('modePanel--active');
      $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie wyszukiwania działek');
      that.states.es_onInfo = false;
      that.storage.setItem('mode', 'es-sp');
      that.emptyPopup();
      $('.eservicePopup').addClass('overlay--active')
      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
        $('.eservicePopup__body').css({
          'height': '150px',
          'width': '520px'
        })
      }
      $('.eservicePopup__body').html(` <div id="field-searchParcels" class="eservicePopup__body__box eservicePopup__body__input">
<div class="eservicePopup__body__input-wrapper">
<input id="eservicePopup__body-text-parcels" class="eservicePopup__body__input-field-parcels eservicePopup__body__input-field search-text" data-search-type="WyszukajDzialke" data-request="getParcel" type="text" placeholder="Wyszukaj działkę..." />
<ul class="eservicePopup__body__input-found-list eservicePopup__body__input-found-list-getParcel"></ul>
</div>
<div class="eservicePopup__body__input-button eservicePopup__body__input-button-parcel"><i class="fa fa-search"></i></div>
<span class="eservicePopup__body__input-loading-anim">
</span>
</div>`);
      $('.eservicePopup__title').text('Wskazywanie działki po podaniu jej numeru');

      $('#eservicePopup__body-text-parcels').select();
      that.activateParcelLayer();

    })
  }

  emptyPopup() {
    $('.eservicePopup').removeClass('overlay--active')
    $('.eservicePopup__title').text('')
    //    $('.eservicePopup__body').html(``);
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      $('.eservicePopup__body').css({
        'height': 'auto',
        'width': 'auto'
      })
    }
    $('.eservicePopup__body').empty();
  }

  updateProperties(target) {
    this.searchClass = 'search';
    this.$field = $(target).closest('.search-text');
    this.$search = this.$field.closest('.' + this.searchClass);
    this.$loadingAnim = this.$search.find('.search__input-loading-anim');
    this.$foundList = this.$search.find('.search__input-found-list');
  }


  searchValue(e) {
    var that = this,
      queryString = $(e.target).val();
    if (queryString.length != 0) {
      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        that.sendRequest(queryString);
      }, 800);
    } else {
      this.removeChildrenIfExist();
    }
  }

  removeChildrenIfExist() {
    if (this.$foundList.children().length != 0) {
      this.$foundList.children().remove();
      this.$foundList.removeClass('list-active').addClass('list-inactive');
    }
  }

  activateParcelLayer() {
    var that = this,
      parcelLayers = $.grep(Object.values(that.layers), x => x.name.includes('Działki'));
    if (parcelLayers) {
      var parcelLayer = $.grep(Object.values(that.layers), x => x.name == 'Działki-SWDE').length > 0 ? $.grep(Object.values(that.layers), x => x.name == 'Działki-SWDE')[0] : ($.grep(Object.values(that.layers), x => x.name == 'Działki-LPIS').length > 0 ? $.grep(Object.values(that.layers), x => x.name == 'Działki-LPIS')[0] : parcelLayers[0]);
      this.parcelLayer = parcelLayer;
      if (parcelLayer) {
        that.selectLayer(parcelLayer, parcelLayer.id);
      }
    }
  }

  selectLayer(layer, layerID) {
    var order = this.allOrder,
      indexes = this.getAllIndexes(order, layerID),
      that = this;
    $('.leaflet-control-layers-selector').each(function (i) {
      if ($.inArray(i, indexes) > -1) {
        if ($(this)[0].checked == false) {
          that.uncheck = true;
          $(this).click();
        }
      }
    })
  }

  unselectLayer() {
    var order = this.allOrder,
      layerID = this.parcelLayer.id,
      indexes = this.getAllIndexes(order, layerID),
      that = this;
    $('.leaflet-control-layers-selector').each(function (i) {
      if ($.inArray(i, indexes) > -1) {
        if (that.uncheck) {
          if ($(this)[0].checked == true) {
            $(this).click();
            that.uncheck = false;
          }
        }
      }
    })
  }

  getAllIndexes(arr, val) {
    var indexes = [],
      i = -1;
    while ((i = arr.indexOf(val, i + 1)) != -1) {
      indexes.push(i);
    }
    return indexes;
  }

  getParcel(queryString) {
    var that = this;
    this.getParcelUrl = (that.tools.SearchServiceDz != null) ? that.tools.SearchServiceDz : "https://app.gison.pl/websearch/Handler1.ashx?callback=searchResults";
    return $.ajax({
      url: that.getParcelUrl,
      dataType: "jsonp",
      jsonpCallback: 'searchResults',
      data: {
        typ: 'dzialki_geom_swde',
        osmid: this.osmid,
        maxRows: 20,
        charset: 'UTF8',
        nazwa: queryString,
        callback: 'searchResults',
      },
    });
  }

  sendRequest(queryString) {
    var that = this;
    if (this.requestFlag === true) {
      this.removeChildrenIfExist();
      this.disactivateSuggestionsList();
      this.requestFlag = false;
      this.animationOn();
      var requestType = 'getParcel',
        promise = that[requestType](queryString);
      $.when(promise).then(
        function successCallback(data) {
          var queryTest = that.$field.val();
          if (queryTest == queryString) {
            if (requestType == 'getParcel') {
              data = that.convertWebServiceToSuggObject(data);
            }
            var html = that.convertDataTableToHtml(data, 'getParcel');
            that.appendFoundedList(html, 'getParcel');
            that.activateSuggestionsList();
          }
          that.animationOff();
          that.requestFlag = true;
        },
        function errorCallback() {
          alert('Błąd wyszukiwania.');
          that.animationOff();
          that.params.noFound = true;
          that.requestFlag = true;
        });
    }
  }

  appendFoundedList(listHtml) {
    this.$foundList = $('.eservicePopup__body__input-found-list-getParcel')
    this.$foundList.html(listHtml);
  }

  activateSuggestionsList() {
    this.$foundList.removeClass('list-inactive').addClass('list-active');
  }

  disactivateSuggestionsList() {
    this.$foundList.removeClass('list-active').addClass('list-inactive');
  }

  animationOn() {
    this.$loadingAnim.css({
      opacity: 1
    });
  }

  animationOff() {
    this.$loadingAnim.css({
      opacity: 0
    });
  }

  convertWebServiceToSuggObject(parcels) {
    return parcels.geonames.map(function (geoname) {
      return {
        lat: geoname.lat,
        lon: geoname.lng,
        display_name: geoname.fcodeName + ' ' + geoname.obreb,
      }
    });
  }

  convertDataTableToHtml(dataTable) {
    var htmlString = '';
    if (dataTable.length != 0) {
      var length = dataTable.length;
      for (var i = 0; i < length; i++) {
        var name = (typeof dataTable[i].display_name == 'undefined' || undefined) ? dataTable[i].name : dataTable[i].display_name;
        var html = '<li class="sugg"><span data-index="' + i + '" data-lat="' + dataTable[i].lat + '" data-lon="' + dataTable[i].lon + '">' + name + '</span></li>';
        htmlString += html;
      }
    } else {
      htmlString = '<li class="cant-be-active"><span>Nie znaleziono pasujących wyników</span></li>';
    }
    return htmlString;
  }

  getLatlngFromSugg($this) {
    var lat = $this.children('span').attr('data-lat'),
      lon = $this.children('span').attr('data-lon');
    return [lat, lon];
  }

  showPointOnMap(latlng) {
    var that = this,
      zoom = 18;
    this.map.setView(latlng, zoom, {});
    var circle = L.circleMarker(latlng, {
      radius: 10,
      color: '#0080D4',
      fillOpacity: 0.8,
      clickable: false,
      className: 'searchPointer'
    });
    this.map.addLayer(circle);
    setTimeout(function removeCircleMarker() {
      $('.searchPointer').animate({
        opacity: 0
      }, 3500, 'swing', function animationCompleteCallback() {
        that.map.removeLayer(circle);
      });
    }, 6000);
  }

  closeSuggs(event) {
    var $clicked = $(event.target);
    if (!$clicked.hasClass('found-list')) {
      if (this.$foundList.hasClass('list-active')) {
        this.$foundList.removeClass('list-active').addClass('list-inactive');
        this.removeChildrenIfExist();
      }
    }
  }

  enterPress(e) {
    var that = this._this,
      selectedIndex = that.$foundList.children('li.sugg-active').index();
    if (selectedIndex != -1) {
      var lat = that.$foundList.children('li.sugg-active').children('span').attr('data-lat'),
        lon = that.$foundList.children('li.sugg-active').children('span').attr('data-lon'),
        latlng = L.latLng(parseFloat(lat), parseFloat(lon));
      that.showPointOnMap(latlng);
      that.closeSuggs(e)
    } else {
      selectedIndex = 1;
      if (selectedIndex != 0) {
        var $newActive = that.$foundList.children('li').eq(0)
        $newActive.addClass('sugg-active');
        var text = $newActive.children('span').text();
        that.$field.val(text);
        that.setVisible($newActive, false);
        if ($newActive) {
          latlng = that.getLatlngFromSugg($newActive);
          that.showPointOnMap(latlng);
        }
      }
    }
  }

  arrowUp() {
    var that = this._this;
    if (that.$field.is(':focus')) {
      var downArrowPressBool = false,
        selectedIndex = that.$foundList.children('li.sugg-active').index();
      if (selectedIndex != 0) { //if can be active
        that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
        var $newActive = that.$foundList.children('li').eq(selectedIndex - 1);
        $newActive.addClass('sugg-active');
        var text = $newActive.children('span').text();
        that.$field.val(text);
        that.setVisible($newActive, downArrowPressBool);
      }
    }
  }

  arrowDown() {
    var that = this._this;
    if (that.$field.is(':focus')) {
      var downArrowPressBool = true,
        selectedIndex = that.$foundList.children('li.sugg-active').index();
      if (selectedIndex == -1) {
        if (!that.$foundList.children('li').eq(0).hasClass('cant-be-active')) {
          that.$foundList.children('li').eq(0).addClass('sugg-active');
        }
      } else {
        if (!that.$foundList.children('li').eq(selectedIndex + 1).hasClass('cant-be-active')) {
          that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
          var $newActive = that.$foundList.children('li').eq(selectedIndex + 1);
          $newActive.addClass('sugg-active');
          var text = $newActive.children('span').text();
          that.$field.val(text);
          that.setVisible($newActive, downArrowPressBool);
        } else {
          that.$foundList.children('li').eq(selectedIndex).removeClass('sugg-active');
          $newActive = that.$foundList.children('li').eq(0);
          $newActive.addClass('sugg-active');
          text = $newActive.children('span').text();
          that.$field.val(text);
          that.actions.setVisible($newActive, downArrowPressBool);
        }
      }
    }
  }

  setVisible($activeListElem, downArrowPressBool) {
    var that = this;
    try {
      var elemPositionTop = $activeListElem.position().top,
        elemHeight = $activeListElem.height(),
        scrollTop = that.$foundList.scrollTop(),
        containerHeight = that.$foundList.height(),
        diff = containerHeight - elemPositionTop;
      if (downArrowPressBool === true) {
        if (diff < elemHeight) {
          that.$foundList.scrollTop(scrollTop + (2 * elemHeight));
        }
      } else {
        if (diff > containerHeight) {
          that.$foundList.scrollTop(scrollTop - (2 * elemHeight));
        }
      }
    } catch (e) {
      that.$foundList.scrollTop(0);
    }
  }

}

export default eService_searchParcels;
