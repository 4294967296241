import '../vendor/leaflet-measure';
import $ from 'jquery';

class Counter {
    constructor(config) {
        if (config.counter) {
            if ($(window).width() > 768) {
                $('.counterPanel').addClass('counterPanel--active')
            } else {
                $('.counterPanel-show').addClass('counterPanel-show--active')
            }
            // this.getValues('inwestycje-v5/profil/LiczbaUmow', config.profilName)
            this.getValues('inwestycje/profil/LiczbaUmow', config.profilName)
            this.greenCol = '#7DA83F';
            this.blueCol = '#0080D4';
            this.events();
        }
    }

    count(total, part, title) {
        var that = this,
            procent = (part / total) * 100;
        $('#countTotal').append(total)
        $('#countPart').append(part)
        $('#countTitle').append(title)
        $('.pie-half-circle').css({
            'border-color': that.blueCol,
        })
        var rotate = 'rotate(' + procent.toFixed(0) * 3.6 + 'deg)',
            rotate2 = 'rotate(' + (procent + 1).toFixed(0) * 3.6 + 'deg)';
        this.rotate('.pie-left-side', rotate)
        if (procent <= 50) {
            this.hide('.pie-beginning')
            $('.pie-right-side').css({
                'border-color': '#ffffff',
            })
            this.rotate('.pie-right-side', rotate2)
        } else {
            $('.pie').css({
                clip: 'rect(auto, auto, auto, auto)'
            });
            this.rotate('.pie-beginning', rotate2)
            this.rotate('.pie-right-side', 'rotate(180deg)')
        }

    }


    events() {
        var that = this;

        $(document).on('click', '.counterPanel-hide', function() {
            $('.counterPanel').css({
                'display': 'none'
            })
            $('.counterPanel-show').css({
                'display': 'flex',
                'background-color': that.blueCol
            })
            $('.small-icon-counter').removeClass('si-focused')
        })

        $(document).on('click', '.counterPanel-show', function() {
            if ($(window).width() >= 768) {
                $('.counterPanel-show').css({
                    'display': 'none'
                })
                $('.counterPanel').css({
                    'display': 'flex'
                })
            }
        })

        if (($(window).width() <= 768 && $(window).height() <= 768) || $(window).width() <= 768 || $(window).height() <= 768) {

            $('.small-icon-counter').on('click tap', function() {
                if ($(this).hasClass('si-focused')) {
                    that.makeFocused();
                    that.hide('.bar-top__tools');
                    that.hide('.counterPanel')
                } else {
                    that.setRest();
                    $('.tools__tool-focused').click();
                    $('.responsive-icon').removeClass('si-focused');
                    $('.responsive-icon').css({
                        'background-color': that.blueCol,
                    });
                    that.show('.counterPanel', 'flex');
                    $(this).addClass('si-focused');
                    $(this).css({
                        'background-color': that.greenCol,
                    });
                    if ($(window).height() <= 768 && $(window).width() > 768) {
                        $(this).css('display', 'none');
                    }
                    if ($(window).width() <= 768) {
                        that.hide('.search');
                        that.hide('.wyboryPanel');
                        that.hide('.formPanel');
                    }
                    if ($(window).width() <= 768 || $(window).height() <= 768) {
                        that.hide('.leaflet-control-compositions');
                        that.hide('.leaflet-control-layers');
                        that.hide('.bar-top__tools');
                    }
                }
            })
        }

    }

    hide(name) {
        $(name).css('display', 'none');
    }

    show(name, display) {
        $(name).css({
            display: display
        })
    }

    rotate(element, rotate) {
        $(element).css({
            '-webkit-transform': rotate,
            '-moz-transform': rotate,
            '-ms-transform': rotate,
            '-o-transform': rotate,
            'transform': rotate,
        })
    }

    setRest() {
        this.show('.responsive-icon-d', 'flex');
        this.hide('.leaflet-top .leaflet-right')
    }

    makeFocused() {
        var that = this;
        that.show('.responsive-icon-d', 'flex');
        that.show('.bar-bottom', 'flex');
        that.hide('.modePanel-show');
        that.hide('.leaflet-control-layers');
        that.show('.bar-bottom', 'flex');
        $('.responsive-icon').css({
            'background-color': that.blueCol,
        })
        $('.responsive-icon').removeClass('si-focused');
    }

    getValues(url, profile) {
        var that = this;
        $.ajax({
            type: 'GET',
            dataType: 'json',
            url: window.PROXY + window.location.protocol + '//administracja.gison.pl' +
                '/' + url + '?profil=' + profile,
            //      url: window.PROXY + SERVER + '/' + url + '?profil=' + profile,
            success: function(response) {
                that.count(response.wszystkie, response.podpisane, 'Licznik podpisanych umów: ');
            }
        });
    }

}

export default Counter;