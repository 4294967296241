/* eslint-disable */

var printOptions;
var printProvider;

//wybrane dzialki
var selectDzLayer;
var selectDzId = [];


function remove(arr, item) {
  for (var i = arr.length; i--;) {
    if (arr[i] === item) {
      arr.splice(i, 1);
    }
  }
}


// function AddPrintControl() {
//     printProvider = L.print.provider({
//         method: 'POST',
//         //url: 'http://localhost:8087/print/pdf',
//         url: 'http://server4.gison.pl/8087/print/pdf',
//         //server4.gison.pl/8087/print/pdf
//         proxy: ROOT + (scriptParams.def.printingParams.proxy || 'print?url='),
//         autoLoad: true,
//         mapTitle: 'Wyrys',
//
//         customParams: {
//             osmId: scriptParams.def.printingParams.osmId || 0,
//             title: scriptParams.def.printingParams.title || '',
//             address: scriptParams.def.printingParams.address || '',
//             gmina: scriptParams.def.printingParams.gmina || 0,
//
//             //druk: "tlo_wydruku",
//             //layout: "A4 portrait"
//             //layout: ""
//             //filename: "Tarnow_Opolski"
//         },
//         dpi: 190 //190  56  127 254 300
//     });
//
//     var printControl = L.control.print({
//         provider: printProvider
//     });
//     map.addControl(printControl);
//
//
// }


//function AddPrintView() {
export const AddPrintView = function(map,printProvider) {

  map.calcPrintView = function (scale, layout) {

    var bounds = map.getBounds();
    //var inchesKm = L.print.Provider.INCHES_PER_METER * 1000;
    var sw = bounds.getSouthWest();
    var ne = bounds.getNorthEast();
    var halfLat = (sw.lat + ne.lat) / 2;
    var midLeft = L.latLng(halfLat, sw.lng);
    var midRight = L.latLng(halfLat, ne.lng);
    var mwidth = midLeft.distanceTo(midRight);
    var pxwidth = map.getSize().x;
    var l = printProvider._getLayoutByName(layout);
    var dpi = 72;

    if (l.widthmm && l.heightmm) {
      var mmToInch = 0.0393700787;
      var sWidth = l.widthmm * dpi * mmToInch;
      var sHeight = l.heightmm * dpi * mmToInch;
    } else {
      if (l.map) {
        var sWidth = l.map.width;
        var sHeight = l.map.height;
      }
    }

    if (l) {
      var x = Math.round(sWidth * 0.0254 * scale * pxwidth / dpi / mwidth);
      var y = Math.round(sHeight * 0.0254 * scale * pxwidth / dpi / mwidth);
      //alert(x);
      return {
        x: x,
        y: y
      }
    }
    return {
      x: 20,
      y: 20
    }
  }

  map.printView = function (scale, layout) {


    //var options = {
    printOptions = {
      scale: scale,
      layout: layout,
      customParams: {
        title: ""
      }
    }
    //printProvider.print(options);

    //initUserTextDialog('#print', 'Tytul okna dialogowego', 'Wpisz tytuł wydruku:', sumbitCallbackFunction);

    var radiobuttonsTable = [
      {
        label: 'WYRYS Z MIEJSCOWEGO PLANU ZAGOSPODAROWANIA PRZESTRZENNEGO',
        value: 'WYRYS Z MIEJSCOWEGO PLANU ZAGOSPODAROWANIA PRZESTRZENNEGO'
      },
      {
        label: 'WYRYS ZE STUDIUM',
        value: 'WYRYS ZE STUDIUM'
      }
    ]


    initUserRadioDialog('Tytuł wydruku', 'Wybierz tytuł wydruku:', radiobuttonsTable, sumbitCallbackFunction2)

  }

  var sumbitCallbackFunction1 = function (data) {

    if (data) {
      printOptions.customParams.title = data;
    }

    initUserTextDialog('Sygnatura', 'Wpisz sygnaturę dokumentu:', sumbitCallbackFunction2);

  }


  //wyrys wybor numeru dzialki:
  var sumbitCallbackFunction2 = function (data) {


    //printOptions.customParams.sygnatura = data;
    printOptions.customParams.sygnatura = "";

    if (data) {
      printOptions.customParams.title = data;
    }


    var warstwy = drawnItems.getLayers();
    var radiobuttonsTable = [];

    radiobuttonsTable.push({
      label: 'bez numeru',
      value: ''
    });

    for (var key in warstwy) {

      var l = warstwy[key];

      //var lab = l.label._content;
      var lab = "";
      if (l.label) {
        lab = l.label._content;
      } else {
        for (var i in l._layers) {
          if (l._layers.hasOwnProperty(i)) {
            lab = l._layers[i].label._content;
            break;
          }
        }
      }

      var obj = {
        label: lab,
        value: l._leaflet_id
      };
      radiobuttonsTable.push(obj);

    }

    if (radiobuttonsTable.length > 1) {
      initUserRadioDialog('Wyrys', 'Wybierz numer działki do wyrysu:', radiobuttonsTable, sumbitCallbackFunction3)
    } else {
      printPrepare(printOptions);
    }

  }

  var sumbitCallbackFunction3 = function (data) {

    if (data) {
      var l = drawnItems.getLayer(data);
      printOptions.customParams.numer = l.numer;
      printOptions.customParams.obrebnazwa = l.obreb;
    }

    printPrepare(printOptions);
  }

  }
