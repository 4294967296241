import $ from "jquery";

class StartInfo {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.extraSpace = 0;
        this.h = 0;
        this.StartButton = config.MapConf.StartButton;
        // config.MapConf.StartInfo = "asdasdasd";
        if (
            config.MapConf.StartInfo != "False" &&
            config.MapConf.StartInfo != "false" &&
            config.MapConf.StartInfo != "true" &&
            config.MapConf.StartInfo != "True" &&
            config.MapConf.StartInfo != null
        ) {
            // if (!sessionStorage.getItem('isStarterInfoShown')) {
            this.showPopup(config.MapConf.StartInfo, this.StartButton);
            // }
        }
        this.events();
    }

    events() {
        var that = this;

        // $(window).resize(function () {
        //     that.setHeight();
        // });
    }

    showPopup(info, button) {
        var that = this;

        //! create button from ancore
        const regexAncore = /href\s?=\s?.+http/g;
        const regexAncoreImg = /(<a.+<img.+<\/a>)/g;
        let test = regexAncore.test(info);
        let test1 = regexAncoreImg.test(info);
        if (test) {
            console.log(info)
            info = info.replace(/&lt;/g, "<");
            info = info.replace(/&gt;/g, ">");
            info = info.replace(/&quot;/g, "");
            if (test1) { } else {
                info = info.replace(
                    /(<a.+<\/a>)/g,
                    "<div class='startPopup__buttons--ancore'>$1</div>"
                );
            }
        }

        //! ---------
        $(".startPopup__background").css("display", "block");
        $(".startPopup__body").html(info);
        $(".startPopup").addClass("overlay--active");
        $(".startPopup").css({
            display: "block",
            // opacity: 0,
        });
        that.h = $(".startPopup__body").height();
        $(".startPopup__title").text("");
        $(".startPopup-close").on("click tap", function () {
            sessionStorage.setItem("isStarterInfoShown", "true");
            that.hide(".startPopup__background");
            $(".startPopup").removeClass("overlay--active");
            that.hide(".startPopup");
        });
        if (button != null) {
            $(".startPopup__buttons").addClass("startPopup__buttons--visible");
            $(".startPopup__buttons--btn").text(button);
            $(".startPopup__buttons--btn").on("click tap", function () {
                sessionStorage.setItem("isStarterInfoShown", "true");
                that.hide(".startPopup__background");
                $(".startPopup").removeClass("overlay--active");
                that.hide(".startPopup");
            });
            that.extraSpace += 55;
        }

        //NA SZTYWNO DLA SADKOWIC
        //            if (true) {
        //      console.log('add img')
        else if (window.location.href.includes("sadkowice")) {
            /*  var html = `
<div class="startPopup__body--imgs">
<div class="startPopup__imgs">
<img class="startPopup__imgs--img" src="./assets/images/logaunijne/sadkowice_logo1.png" />
<img class="startPopup__imgs--img" src="./assets/images/logaunijne/sadkowice_logo2.png" />
</div>
<div class="startPopup__imgs">
<img class="startPopup__imgs--img" src="./assets/images/logaunijne/sadkowice_logo3.png" />
<img class="startPopup__imgs--img" src="./assets/images/logaunijne/sadkowice_logo4.png" />
</div>
</div>
<p style="text-align:center;line-height:20px;">
<strong>
<span style="color:#404040;font-size:12px;line-height:17.1429px;">&quot;Wdrożenie zintegrowanych technologii informacyjno-komunikacyjnych w gminach powiatu rawskiego&quot;</span>
</strong><br>
<span style="color:#404040;font-size:12px;line-height:17.1429px;">
Projekt wsp&oacute;łfinansowany ze środk&oacute;w Europejskiego Funduszu Rozwoju Regionalnego w ramach Regionalnego Programu Operacyjnego Wojew&oacute;dztwa Ł&oacute;dzkiego na lata 2014-2020
<br>
Projekt partnerski gmin: Rawa Mazowiecka, Biała Rawska i Sadkowice
<br>
Umowa o dofinansowanie nr UDA-RPLD.07.01.02-10-0020/17-00</span></p>`;
            //    $(html).insertAfter('.startPopup__body')
            $(html).insertAfter('.startPopup__body p:last')
            that.h = $('.startPopup__body').height();
            that.extraSpace += 35; */
        }
        //    if (true) {
        /*  if (location.href.includes('swiatniki_kanalizacja')) {
      var html = `<div class="startPopup__body--imgs">
<div class="startPopup__imgs">
<img class="startPopup__imgs--img" src="./assets/images/swiatniki_logo1.png" />
<img class="startPopup__imgs--img" src="./assets/images/swiatniki_logo2.png" />
</div>
<div class="startPopup__imgs">
<img class="startPopup__imgs--img" src="./assets/images/swiatniki_logo3.png" />
</div>
</div>`

      $(html).insertAfter('.startPopup__body p:last')
      that.h = $('.startPopup__body').height();
      that.extraSpace += 35;
    }*/
        // that.setHeight();

        $(document).on("keypress", function (e) {
            var key = e.which;
            if (key == 13 && $(".startPopup").hasClass("overlay--active")) {
                sessionStorage.setItem("isStarterInfoShown", "true");
                that.hide(".startPopup__background");
                $(".startPopup").removeClass("overlay--active");
                that.hide(".startPopup");
                return false;
            }
        });
    }

    hide(name) {
        $(name).css("display", "none");
    }

    // setHeight() {
    //     var that = this,
    //         height =
    //             that.h + that.extraSpace + 60 > $(window).height() * 0.8
    //                 ? $(window).height() * 0.8
    //                 : that.h + that.extraSpace + 60;
    //     $(".startPopup").css({
    //         height: height + "px",
    //         opacity: 1,
    //     });
    //     $(".startPopup__body").css({
    //         height: height - 60 - that.extraSpace + "px",
    //         overflow: "auto",
    //     });

    // }
}

export default StartInfo;
