/* eslint-disable */

L.SVG.include({
  _resetTransformPath: function (layer) {
    layer._path.setAttributeNS(null, 'transform', '');
  },

  /**
   * Applies matrix transformation to SVG
   * @param {L.Path}         layer
   * @param {Array.<Number>} matrix
   */
  transformPath: function (layer, matrix) {
    layer._path.setAttributeNS(null, 'transform',
      'matrix(' + matrix.join(' ') + ')');
  }

});
