import $ from 'jquery';

class News {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.storage = window.sessionStorage;
        this.layerListKeys = config.layerListKeys;
        this.storedData = {};
        if (config.aktualnoscGuid) {
            this.countNews = 0;
            this.guid = config.aktualnoscGuid
            $('.newsContainer').addClass('newsContainer--active')
            this.getNews(config.aktualnoscGuid)
            this.events();
        }
        this.dateFrom = '0000-00-00';
        this.onSearch = false;
        this.onFilter = false;
        this.newNewsCount = 0;
        /* var that = this
        setInterval(function() {
            console.log(that.dateFrom + ' ' + that.dateTo)
        }, 1500) */
        var today = new Date();
        this.dateTo = today.getFullYear() + '-' + (parseInt(today.getMonth()) + 1) + '-' + today.getDate();
    }

    events() {
        var that = this;

        $(document).on('click tap', '.news-show', function() {
            if ($('.newsPanel').hasClass('newsPanel--visible')) {
                $('.newsPanel').removeClass('newsPanel--visible')
                $('.sideLinkButtonPanel').addClass('sideLinkButtonPanel--visible')
                $('.news-show').removeClass('news-show--panelVisible')
                $('.news-show').children('.fa').removeClass('fa-angle-double-right').addClass('fa-angle-double-left')
                that.storage.setItem('mode', 'none')
                that.storage.setItem('newsID', 'none')
            } else {

                if ($('.twgPanel').hasClass('twgPanel--visible')) {
                    $('.twg-show').click();
                }

                $('.newsPanel').addClass('newsPanel--visible')
                $('.news-show').addClass('news-show--panelVisible')
                $('.sideLinkButtonPanel').removeClass('sideLinkButtonPanel--visible')
                $('.news-show').children('.fa').removeClass('fa-angle-double-left').addClass('fa-angle-double-right')
                if ($(window).height() < 769 || $(window).width() < 1630) {
                    $('.newsPopup').removeClass('overlay--active')
                }
                that.storage.setItem('mode', 'news')
                that.storage.setItem('newsID', 'none')
            }
        })

        $('.newsPanel__container').scroll(function() {
            if ($('.newsPanel__container').scrollTop() == $(document).height() - $('.newsPanel__container').height()) {
                that.getNews()
            }
        });

        $(document).on('click tap', '.newsPanel-close', function() {
            if ((window.location.href.includes('dobroszyce_kultura') || window.location.href.includes('localhost')) && (navigator.userAgent.match(/Android/i) ||
                    navigator.userAgent.match(/webOS/i) ||
                    navigator.userAgent.match(/iPhone/i) ||
                    navigator.userAgent.match(/iPad/i) ||
                    navigator.userAgent.match(/iPod/i) ||
                    navigator.userAgent.match(/BlackBerry/i) ||
                    navigator.userAgent.match(/Windows Phone/i))) {
                $('.starting-panel').addClass('starting-panel--active')
            } else {
                $('.newsPanel').removeClass('newsPanel--visible')
                $('.news-show').removeClass('news-show--panelVisible')
                $('.sideLinkButtonPanel').addClass('sideLinkButtonPanel--visible')
                $('.news-show').children('.fa').removeClass('fa-angle-double-right').addClass('fa-angle-double-left')
                that.storage.setItem('mode', 'none')
            }
        })

        $(document).on('click', '.newsPopup-close', function() {
            if ($(window).height() < 769 || $(window).width() < 1630) {
                $('.newsPanel').addClass('newsPanel--visible')
                $('.news-show').addClass('news-show--panelVisible')
                $('.news-show').children('.fa').removeClass('fa-angle-double-left').addClass('fa-angle-double-right')
            }
            that.storage.setItem('newsID', 'none')
        })

        $(document).on('click', '#newsPanelSearch', function() {
            if ($('.newsPanel__search').hasClass('newsPanel__search--visible')) {
                that.hideSearch()
            } else {
                that.showSearch()
            }
        })
        $(document).on('click', '#newsPanelFilter', function() {
            if ($('.newsPanel__filter').hasClass('newsPanel__filter--visible')) {
                that.hideFilter()
            } else {
                that.showFilter()
            }
        })


        // filtrowanie po dacie
        $(document).on('input', '#newsPanelDateFrom', function() {
            that.onFilter = true;
            that.dateFrom = $(this).val();
            that.filterNewsByDate(that.dateFrom, that.dateTo)
        })

        $(document).on('input', '#newsPanelDateTo', function() {
            that.onFilter = true;
            that.dateTo = $(this).val();
            that.filterNewsByDate(that.dateFrom, that.dateTo)
        })

        $(document).on('click tap', '.newsPanel__filter--reset', function() {
            that.dateFrom = '0000-00-00';
            that.onFilter = false;
            $('#newsPanelDateFrom').val('rrrr-mm-dd')
            $('#newsPanelDateTo').val('rrrr-mm-dd')
            var today = new Date()
            that.dateTo = today.getFullYear() + '-' + (parseInt(today.getMonth()) + 1) + '-' + today.getDate()
            that.filterNewsByDate(that.dateFrom, that.dateTo)
        })


        // wyszukiwanie po treści
        $('#search-text-news').on('input', function(e) {
            that.searchText = $(e.target).val();
            if (that.searchText != '') {
                that.onSearch = true;
            } else {
                that.onSearch = false;
            }
            that.searchValue(that.searchText);
        });
    }

    setPanelHeight(data) {
        this.countNews = data.length
        if ($(window).width() > 992) {
            if (data.length < 2) {
                $('.newsPanel').css('height', '320px')
                $('.newsPanel__container--item').css('height', '200px')
            } else if (data.length < 3) {
                $('.newsPanel').css('height', '540px')
                $('.newsPanel__container--item').css('height', '200px')
            } else if (data.length < 4) {
                $('.newsPanel').css('height', '615px')
                $('.newsPanel__container--item').css('height', '200px')
            }
        } else if ($(window).width() > 600) {
            if (data.length < 2) {
                $('.newsPanel').css('height', '300px')
                $('.newsPanel__container--item').css('height', '220px')
            } else if (data.length < 3) {
                $('.newsPanel').css('height', '580px')
                $('.newsPanel__container--item').css('height', '220px')
            }
        }
    }

    showSearch() {
        $('#newsPanelSearch').addClass('newsPanel__btns--btn-active')
        var diff = 60,
            height = 0;
        if (this.countNews < 2) {
            diff = 60;
            height = parseFloat($('.newsPanel').css('height')) + diff;
            $('.newsPanel').css('height', height + 'px')
            $('.newsPanel__search').addClass('newsPanel__search--visible')
        } else {
            diff = this.countNews < 2 ? 90 : (this.countNews < 3 ? 80 : 60);
            height = parseFloat($('.newsPanel__container').css('height')) - diff;
            $('.newsPanel__container').css('height', height + 'px')
            $('.newsPanel__search').addClass('newsPanel__search--visible')
        }
    }


    hideSearch() {
        $('#newsPanelSearch').removeClass('newsPanel__btns--btn-active')
        var diff = 60,
            height = 0;
        if (this.countNews < 2) {
            diff = 60;
            height = parseFloat($('.newsPanel').css('height')) - diff;
            $('.newsPanel').css('height', height + 'px')
            $('.newsPanel__search').removeClass('newsPanel__search--visible')
        } else {
            diff = this.countNews < 2 ? 90 : (this.countNews < 3 ? 80 : 60);
            height = parseFloat($('.newsPanel__container').css('height')) + diff;
            $('.newsPanel__container').css('height', height + 'px')
            $('.newsPanel__search').removeClass('newsPanel__search--visible')
        }
    }

    showFilter() {
        $('#newsPanelFilter').addClass('newsPanel__btns--btn-active')
        var diff = 60,
            height = 0;
        if (this.countNews < 2) {
            diff = 60;
            height = parseFloat($('.newsPanel').css('height')) + diff;
            $('.newsPanel').css('height', height + 'px')
            $('.newsPanel__filter').addClass('newsPanel__filter--visible')
        } else {
            diff = this.countNews < 2 ? 90 : (this.countNews < 3 ? 80 : 60);
            height = parseFloat($('.newsPanel__container').css('height')) - diff;
            $('.newsPanel__container').css('height', height + 'px')
            $('.newsPanel__filter').addClass('newsPanel__filter--visible')
        }
    }

    hideFilter() {
        $('#newsPanelFilter').removeClass('newsPanel__btns--btn-active')
        var diff = 60,
            height = 0;
        if (this.countNews < 2) {
            diff = 60;
            height = parseFloat($('.newsPanel').css('height')) - diff;
            $('.newsPanel').css('height', height + 'px')
            $('.newsPanel__filter').removeClass('newsPanel__filter--visible')
        } else {
            diff = this.countNews < 2 ? 90 : (this.countNews < 3 ? 80 : 60);
            height = parseFloat($('.newsPanel__container').css('height')) + diff;
            $('.newsPanel__container').css('height', height + 'px')
            $('.newsPanel__filter').removeClass('newsPanel__filter--visible')
        }
    }

    checkNews(date) {
        var oneDay = 24 * 60 * 60 * 1000,
            today = new Date();
        date = new Date(date)
        var diffDays = Math.round(Math.abs((today.getTime() - date.getTime()) / (oneDay)));
        return diffDays <= 7;
    }

    getNews(guid) {
        var that = this;
        $.ajax({
            // url: 'http://www.mocky.io/v2/5c6d14a83700007e14fa31bd', // wszystkie
            // url: 'http://www.mocky.io/v2/5c7696bc320000ed1bf462a7', // dużo
            // url: 'http://www.mocky.io/v2/5c6d154f370000aa1dfa31c2', // 3
            // url: 'http://www.mocky.io/v2/5c6d15cd370000aa1dfa31c7', // 2
            // url: 'http://www.mocky.io/v2/5c6d16dc370000530afa31cc', // 1
            url: window.SERVER + window.RejROOT + 'Aktualnosc/GetSipList/' + guid,
            error: function() {
                $('.newsContainer').removeClass('newsContainer--active')
            },
            success: function(data) {
                data.reverse();
                that.storeData(data)
                if (data.length > 0) {
                    var arrayShow = [],
                        len = data.length > 3 ? 4 : data.length,
                        k = 1,
                        i = 1;
                    for (var j = 0; j < data.length; j++) {
                        arrayShow[j] = [];
                    }
                    for (var z = 0, w = 0; w < len; z++) {
                        arrayShow[0][w] = data[z];
                        w++;
                    }
                    $.each(data, function(l, news) {
                        if (l >= len) {
                            arrayShow[k][0] = news;
                            k++;
                        }
                    })
                    that.addNews(data, 0)
                    that.showNews(arrayShow[0], 0)
                    that.setPanelHeight(arrayShow[0])
                    $('.newsPanel__container').on('scroll', function() {
                        if ($(this).scrollTop() + $(this).innerHeight() + 10 >= $(this)[0].scrollHeight) {
                            if (arrayShow[i]) {
                                that.showNews(arrayShow[i], i + 3)
                                i++;
                                that.setPanelHeight(data)
                            }
                        }
                        // that.filterNewsByDate(that.dateFrom, that.dateTo)
                    })
                } else {
                    $('.newsContainer').removeClass('newsContainer--active')
                }
            }
        })
    }

    storeData(data) {
        var that = this;
        $.each(data, function(k, v) {
            that.storedData[v.id] = v.tytul + ' ' + v.opis
        })
    }

    storeDatas(data) {
        var that = this;
        $.each(data, function(k, v) {
            that.storedData[v.id] = that.storedData[v.id] + ' ' + data
        })
    }

    addNews(array, part) {
        var that = this;
        $.each(array, function(i, v) {
            that.addNewsItem(v.data, v.tytul, v.opis, v.wyroznione, v.id)
        })
        if (this.newNewsCount > 0) {
            $('.news-show__num').css('display', 'flex')
            $('.news-show__num').text(this.newNewsCount)
        }
    }

    showNews(array, part) {
        var that = this;
        $.each(array, function(i) {
            that.showNewsItem(part + i)
        })
    }

    getShortcut(html) {
        var span = document.createElement('span');
        span.innerHTML = html;
        return span.textContent.substr(0, 180) || span.innerText.substr(0, 180);
    }

    showNewsItem(id) {
        if ((this.onSearch && $('#newsItem-' + id).hasClass('newsPanel__container--item-searched')) ||
            (this.onFilter && $('#newsItem-' + id).hasClass('newsPanel__container--item-filtered')) ||
            (!this.onSearch && !this.onFilter)) {
            $('#newsItem-' + id).addClass('newsPanel__container--item-active')
        } else {
            $('#newsItem-' + id).removeClass('newsPanel__container--item-active')

        }
    }

    addNewsItem(date, title, content, isSelected, id) {
        var that = this,
            isNew = this.checkNews(date),
            // isNew = false,
            html = ` <div data-date="` + date + `" class="newsPanel__container--item newsPanel__container--item-active` + (isNew ? ' newsPanel__container--item-new' : '') + `" id="newsItem-` + id + `">
<div class="row newsPanel__container--item-date">` + date + `</div>
<div class="row newsPanel__container--item-title"><i class="fas fa-check fa-2x newsPanel__container--item-title-icon"></i><p>` + title /*+ ` ` + id*/ + `</p></div>
<div class="row newsPanel__container--item-content">` + that.getShortcut(content) + `...</div>
<div class="row newsPanel__container--item-more"><button class="newsPanel__container--item-moreBtn" id="newsBtn-` + id + `">Czytaj więcej         
<i class="fa fa-angle-double-right"></i>
</button></div>
</div>`
        if (isNew) {
            this.newNewsCount++;
        }
        $('.newsPanel__container').append(html);
        //    var contentAll = content + `<br>` + attachment;
        $('#newsBtn-' + id).on('click', function() {
            // $('.newsPopup__container--content').html(`<iframe id="myframe` + id + `" width="100%" height="100%" style="border:0"></iframe>`)

            var tryToGetContent = $.Deferred()

            $.ajax({
                url: window.SERVER + window.RejROOT + 'Aktualnosc/GetDescription/' + id + '?guid=' + that.guid,
                success: function(data) {
                    // that.storeDatas(data)
                    tryToGetContent.resolve(data)
                },
                error: function() {
                    tryToGetContent.resolve('')
                },
            })

            $.when(tryToGetContent).done(function(contentReturned) {
                content = contentReturned != '' ? contentReturned : content
                $('.newsPopup__container--content').html(``)
                $('.newsPopup').addClass('overlay--active')
                $('.newsPopup__container--top-title').text(title /* + ' ' + id*/ )
                $('.newsPopup__container--top-date').text(date)
                $('.newsPopup__container--content').html(content)
                var url = that.getShareUrl(id)
                url = 'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(url) + '&picture=' + encodeURIComponent('http://administracja.gison.pl/mapimage_v13/Home/GetPrint/190412_144602_300_2291_1877_9d02e611-d824-4516-a407-0609e2dffc7a.png') + '&quote=' + encodeURIComponent(title)
                $('.newsPopup__container--share').children('a').attr('href', url)
                $('.newsPopup').attr('id', id)
                that.storage.setItem('mode', 'news')
                that.storage.setItem('newsID', id)
                    //      $('.newsPopup__container--content').html(contentAll)
                if ($(window).height() < 769 || $(window).width() < 1630) {
                    $('.newsPanel').removeClass('newsPanel--visible')
                    $('.news-show').removeClass('news-show--panelVisible')
                    $('.news-show').children('.fa').removeClass('fa-angle-double-right').addClass('fa-angle-double-left')
                }
            })
        })
    }

    getPermalink(num) {
        var currentUrl = window.location.href,
            permalink = (currentUrl.indexOf('?') > -1) ? currentUrl.slice(0, currentUrl.indexOf('?') + 1) : currentUrl + '?';
        permalink += 'm=n' + num
        return permalink;
    }

    getShareUrl(id) {
        var url = window.location.href;
        if (url.indexOf('?') > -1) {
            if ((url.indexOf('&m=') > -1) || (url.indexOf('?m=') > -1)) {
                url = url.replace(/&m=n[0-9]*/, '&m=n' + id);
                url = url.replace(/\?m=n[0-9]*/, '?m=n' + id);
            } else {
                url = url + '&m=n' + id;
            }
        } else {
            url = url + '?m=n' + id;
        }
        return url;
    }

    filterNewsByDate(dateFrom, dateTo) {
        var that = this;
        $.each($('.newsPanel__container--item'), function(i, item) {
            var date = $(item).attr('data-date').toString();
            if (date > dateTo || date < dateFrom) {
                $(item).removeClass('newsPanel__container--item-active')

                $(item).removeClass('newsPanel__container--item-filtered')
            } else {
                if (!that.onSearch || (that.onSearch && $(this).hasClass('newsPanel__container--item-searched'))) {
                    $(item).addClass('newsPanel__container--item-active')
                }
                $(item).addClass('newsPanel__container--item-filtered')
            }
        })
    }

    searchValue(text) {
        var that = this;
        $.each(that.storedData, function(k, v) {
            if (v.toLowerCase().includes(text.toLowerCase())) {
                if (!that.onFilter || (that.onFilter && $('#newsItem-' + k).hasClass('newsPanel__container--item-filtered'))) {
                    $('#newsItem-' + k).addClass('newsPanel__container--item-active')
                }
                $('#newsItem-' + k).addClass('newsPanel__container--item-searched')
            } else {
                $('#newsItem-' + k).removeClass('newsPanel__container--item-active')
                $('#newsItem-' + k).removeClass('newsPanel__container--item-searched')
            }
        })
    }

}
export default News;