import Tools from './Tools';
import L from 'leaflet';
import $ from 'jquery';
import '../vendor/svg.js';
import '../vendor/path.transform.js';
import '../vendor/Path.Drag.js';
import '../vendor/Control.MiniMap.js';
var tools = new Tools();

class MiniMap {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.miniClass = 'leaflet-control-minimap-minimized';
        this.maxiClass = 'leaflet-control-minimap-maximized';
        this.miniMapProps = tools.createElPropertiesFromClass(this.toolsClass + '__miniMap');
        this.layerUrl = this.getBorderUrl(config.layerListKeys);
        if (config.MapConf.MiniMap) {
            if (location.href.includes('swiatniki') || location.href.includes('6a54577a-0200-11e9-8fc0-233725720e71') || location.href.includes('mszanadolnamiasto')) {
                config.mapConfig.MapConf.MinimapZoom = 11;
                } else if (location.href.includes('srokowo') || location.href.includes('dzwierzuty') || location.href.includes('bierzwnik') || location.href.includes('bogatynia') || location.href.includes('sulechow') || location.href.includes('wolow') || location.href.includes('czarnydunajec') || location.href.includes('darlowogmina') || location.href.includes('trzebnica') || location.href.includes('widuchowa') || location.href.includes('zarygmina') || location.href.includes('krakow') || location.href.includes('srem')) {
            // } else if (window.location.href.includes('localhost')) {
                config.mapConfig.MapConf.MinimapZoom = 9;
            }
            this.miniZoom = config.mapConfig.MapConf.MinimapZoom || 10;
            this.addMiniMap();
            $('.leaflet-control-minimap').addClass('leaflet-control-minimap-minimized');
            this.events();
        }
    }

    events() {
        var that = this;

        $(document).on('click tap', this.miniMapProps.selector, function() {
            if ($('.leaflet-control-minimap').hasClass(that.maxiClass)) {
                $('.leaflet-control-minimap').removeClass(that.maxiClass);
                $('.leaflet-control-minimap').addClass(that.miniClass);
            } else {
                $('.leaflet-control-minimap').removeClass(that.miniClass);
                $('.leaflet-control-minimap').addClass(that.maxiClass);
            }
        })

    }

    addMiniMap() {
        var that = this,
            layer;
        if (that.layerUrl != "") {
            layer = new L.tileLayer.wms(that.layerUrl, {
                layers: "granice_gmin",
                format: "image/png",
                transparent: true,
                srs: "EPSG:3857",
                attribution: null,
            });
        } else {
            layer = new L.TileLayer("http://server7.gison.pl/cgi-bin/tilecache.cgi/1.0.0/osm_pastele/{z}/{x}/{y}.png", {
                minZoom: 0,
                maxZoom: 13
            });
        }
        this.miniMap = new L.Control.MiniMap(layer, {
            position: 'bottomleft',
            width: 180,
            height: 180,
            zoomLevelOffset: -3,
            centerFixed: that.map.getCenter(),
            zoomLevelFixed: that.miniZoom || 10,
            aimingRectOptions: {
                color: '#0080D4'
            }
        }).addTo(this.map);
    }

    getBorderUrl(layerList) {
        var borderUrl = "";
        $.each(layerList, (x, layer) => {
            if (layer.name.includes('Granica gminy') || layer.name.includes('Granice gminy') || layer.name.includes('Granica Gminy') || layer.name.includes('Granice Gminy')) {
                borderUrl = layer.url;
            }
        })
        return borderUrl || "";
    }

}

export default MiniMap;