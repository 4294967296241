import Tools from "./Tools";
import Security from "./Security";
import $ from "jquery";

var tools = new Tools();

// logowanie
class Login {
    constructor(config, settings) {
        this.loginClass = "login";
        this.loginProps = tools.createElPropertiesFromClass(this.loginClass);
        this.map = settings.map;
        this.mapinit = settings.mapInit;
        this.mapinfo = settings.mapInfo;
        this.activeLayersManager = settings.activeLayersManager;
        this.permalink = settings.permalink;
        this.search = settings.search;
        this.print = settings.print;
        new Security();
        this.blankLayer = {
            id: 9999,
            type: "Tile",
            name: "Brak",
            url: "",
            isBaseLayer: true,
            queryable: true,
            queryUrl: null,
            options: {
                layers: null,
                format: "image/png",
                transparent: true,
                srs: "EPSG:3857",
                attribution: null,
                zIndex: -100,
                checked: false,
            },
        };
        this.blank = {
            id: 9999,
            name: "Brak",
            checked: false,
        };

        this.showLogin = config.MapConf.Login;
        if (this.showLogin) {
            $("#top-login").addClass("bar-top__tools--item-show");
            this.getSessionUser();
            this.createLoginForm();
            this.events();
        }
    }

    events() {
        $(document).on("click tap", "#top-login", () => {
            this.loginProps.$el.addClass("overlay--active");
        });

        $(document).on("click tap", "#log-out", () => {
            // delete sessionStorage["SecurityManager.username"];
            delete localStorage['SecurityManager.username'];
            // delete sessionStorage["SecurityManager.key"];
            delete localStorage['SecurityManager.key'];
            delete localStorage['SecurityManager.profil'];
            this.showLoginPanel();
            window.location.reload();
        });

        $(document).on("input", ".js-login__input", () => {
            $(".errorMsg").remove();
        });

        $(document).on("submit", ".js-login__form", () => {
            this.loginProps.$el.removeClass("overlay--active");
        });
    }

    createLoginForm() {
        $("#createLogin").jsLogin();
        $("#createLogin").jsLoginDone(
            (config, token) => {
                this.addLayers(config, token);
                // $(".js-login__panel-username").text(sessionStorage["SecurityManager.username"]);
                $('.js-login__panel-username').text(localStorage['SecurityManager.username']);
                this.showLoginPanel();
            },
            () => {
                // delete sessionStorage["SecurityManager.username"];
                delete localStorage['SecurityManager.username'];
                // delete sessionStorage["SecurityManager.key"];
                delete localStorage['SecurityManager.key'];
                delete localStorage['SecurityManager.profil'];
                this.loginProps.$el.addClass("overlay--active");
            }
        );
    }

    addLayers(config, token) {
        tools.extend(config, config.mapConfig);
        config.bases = {
            displayName: "Podkładowe",
            layers: [],
        };
        $.each(config.treeStructure[0].layers, function (i, layer) {
            $.grep(config.layerList, x => x.id == layer.id)[0].checked = layer.checked;
            config.bases.layers.push($.grep(config.layerList, x => x.id == layer.id)[0]);
        });
        config.treeStructure.shift();
        config.layerListKeys = {};
        // config.layerList.push(this.blankLayer);
        // config.bases.layers.push(this.blank);
        $.each(config.layerList, function (i, layer) {
            config.layerListKeys[layer.id] = layer;
        });
        this.mapinit.updateLayers(config);
        this.mapinit.events();
        this.activeLayersManager.init(config);
        this.mapinfo.updateLayerListKeys(config);
        //! sip 100 comement this line becouse in log-in version group lits are scrollTop.
        // this.mapinit.wrapOverlaysControl(config.tematList);
        //!---
        this.permalink.updateOrders();
        this.permalink.viewFromPermalink(config);
        this.search.updateLayerListKeys(config.layerListKeys)
        this.print.preview(config, this.map, token);
    }

    showLoginPanel() {
        $("#top-login").toggleClass("hidden");
        $(".js-login__panel").toggleClass("hidden");
    }

    getSessionUser() {
        // var username = sessionStorage["SecurityManager.username"],
        var username = localStorage['SecurityManager.username'],
            // key = sessionStorage["SecurityManager.key"];
            key = localStorage['SecurityManager.key'],
            secprofil = localStorage['SecurityManager.profil'],
            profilName = window.location.pathname.indexOf('_') > -1 ? window.location.pathname.substr(1).substr(0, window.location.pathname.indexOf('_') - 1) : window.location.pathname.substr(1)
        if (username && key && (secprofil == profilName)) {
            var token = window.SecurityManager.sendUserKey(username, key),
                sendToken = window.SecurityManager.sendToken(token);
            sendToken.done(config => {
                if (typeof config == "object") {
                    this.addLayers(config);
                    // $(".js-login__panel-username").text(sessionStorage["SecurityManager.username"]);
                    $('.js-login__panel-username').text(localStorage['SecurityManager.username']);
                    this.showLoginPanel();
                }
            });
        } else {
            delete localStorage['SecurityManager.username'];
            delete localStorage['SecurityManager.key'];
            delete localStorage['SecurityManager.profil'];
        }
    }
}

export default Login;
