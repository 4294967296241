import proj4 from "./Proj4";
import $ from "jquery";

//! object with params needed for transformation between systems
const transform = {
    wgs84: "+proj=longlat +datum=WGS84 +no_defs",
    puwg92:
        "+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    puwg2000_5:
        "+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    puwg2000_6:
        "+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    puwg2000_7:
        "+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    puwg2000_8:
        "+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    epsg_3857:
        "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs",
    utm:
        "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs",
    wkid:
        "+proj=utm +zone=33 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs",
};

class CoordinateSystem {
    constructor() {
        this.systems = {
            latlng: {
                names: ["WGS84", "WGS 84", "EPSG:4326", "EPSG 4326", "latlng"],
                conversion: "",
            },
            puwg92: {
                names: [
                    "PUWG 1992",
                    "PUWG1992",
                    "PUWG92",
                    "EPSG:2180",
                    "1992",
                    "92",
                    "polska 1992",
                    "polska 92",
                    "pl92",
                    "pl 92",
                    "pl1992",
                    "pl 1992",
                ],
                conversion: transform.puwg92,
            },
            puwg2000: {
                names: ["PUWG 2000", "EPSG 2177", "EPSG 2178", "EPSG 2179"],
                conversion: transform.puwg2000_6,
            },
            puwg2000_5: {
                names: ["PUWG 2000 strefa 5", "EPSG 2176"],
                conversion: transform.puwg2000_5,
            },
            puwg2000_6: {
                names: ["PUWG 2000 strefa 6", "EPSG 2177"],
                conversion: transform.puwg2000_6,
            },
            puwg2000_7: {
                names: ["PUWG 2000 strefa 7", "EPSG 2178"],
                conversion: transform.puwg2000_7,
            },
            puwg2000_8: {
                names: ["PUWG 2000 strefa 8", "EPSG 2179"],
                conversion: transform.puwg2000_8,
            },
            EPSG_3857: {
                names: [
                    "EPSG:900913",
                    "900913",
                    "ESRI:102113",
                    "ESRI:102100",
                    "EPSG:3785",
                    "EPSG:3587",
                    "EPSG 900913",
                    "ESRI 102113",
                    "ESRI 102100",
                    "EPSG 3785",
                    "EPSG 3587",
                    "EPSG 900913",
                    "ESRI 102113",
                    "ESRI 102100",
                    "EPSG 3785",
                    "EPSG 3587",
                ],
                conversion: transform.epsg_3857,
            },
            UTM: {
                names: ["utm", "7483"],
                conversion: transform.utm,
            },
            WKID: {
                names: ["wkid", "102100", "WKID (102100)"],
                conversion: transform.wkid,
            },
        };
    }

    getSystemId(name) {
        var id = "";
        $.each(this.systems, function (key, system) {
            $.each(system.names, function (i, n) {
                if (n.toUpperCase() == name.toUpperCase()) {
                    id = key;
                }
            });
        });
        return id;
    }

    getSystemConverter(id) {
        var converter = "";
        $.each(this.systems, function (key, system) {
            if (key == id) {
                converter = system.conversion;
            }
        });
        return converter;
    }

    getCoords(id, event) {
        var that = this,
            converter = that.getSystemConverter(id);
        if (id === "latlng") {
            return [event.latlng.lat, event.latlng.lng];
        } else {
            return that.convertLatlngTo(id, event.latlng, converter);
        }
    }

    convertLatlngTo(systemID, latlng, converter) {
        latlng = [latlng.lng, latlng.lat];
        return proj4(converter, latlng);
    }
}

export default CoordinateSystem;
export { transform };
