import Tools from './Tools';
import $ from 'jquery';
var tools = new Tools();

class LayerOpacity {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.states = settings.states;
        this.states.onOpacityChange = false;
        this.layerListKeys = config.layerListKeys;
        this.activeLayersManager = settings.activeLayersManager;
        // this.allOrder = this.activeLayersManager.getLayersOrder('all');
        // this.order = this.activeLayersManager.getLayersOrder('overlay');
        // this.active = this.activeLayersManager.getActiveLayersIds();
        this.lOpacityWindowClass = '.lOpacityPopup';
        // this.lOpacityWindowActiveClass = this.lOpacityWindowClass + '--active';
        this.lOpacityWindowCloseClass = this.lOpacityWindowClass + '-close';
        this.lOpacityProps = tools.createElPropertiesFromClass(this.toolsClass + '__layerOpacity');
        this.addedSliders = [];
        this.events();
    }

    events() {
        var that = this;

        $(document).on('click tap', this.lOpacityProps.selector, function() {
            if ($('.lOpacityPopup').hasClass('overlay--active')) {
                that.states.onOpacityChange = false;
                $('.lOpacityPopup').removeClass('overlay--active');
                $('.lOpacityPopup__tooltip').detach();
                $('.lOpacityPopup__title').removeClass('lOpacityPopup__title--tooltipActive');
            } else {
                that.states.onOpacityChange = true;
                that.updateSliders()
                $('.lOpacityPopup').addClass('overlay--active');
            }
            that.states.onInfo = !that.states.onInfo;
        });

        $(document).on('click tap', this.lOpacityWindowCloseClass, function() {
            $(that.lOpacityProps.selector).find('.tools__tool-icon').removeClass('tools__tool-icon-focused');
            $(that.lOpacityProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-focused');
            $('.lOpacityPopup__tooltip').detach();
            $('.lOpacityPopup__title').removeClass('lOpacityPopup__title--tooltipActive');
            that.states.onOpacityChange = false;
            that.states.onInfo = true;
        });

        $(document).on('click tap', '.lOpacityPopup__title--info', function() {
            if ($('.lOpacityPopup__title').hasClass('lOpacityPopup__title--tooltipActive')) {
                $('.lOpacityPopup__tooltip').detach()
                $('.lOpacityPopup__title').removeClass('lOpacityPopup__title--tooltipActive')
            } else {
                $('.lOpacityPopup__title').append(`<div class="lOpacityPopup__tooltip"><i class="fas fa-info-circle fa-2x lOpacityPopup__tooltip--icon"></i><div class="lOpacityPopup__tooltip--txt">Lista warstw dotyczy tylko tych, które wyświetlają się na mapie.</div></div>`)
                $('.lOpacityPopup__title').addClass('lOpacityPopup__title--tooltipActive')
            }
        })

        $(document).on('click tap', '.leaflet-control-layers-selector', function() {
            if (that.states.onOpacityChange) {
                that.updateSliders()
            }
        })

        $('.lOpacityPopup').on('click tap', function() {
            if ($(window).width() > 768 || $(window).height() > 768) {
                $(this).draggable({
                    cursor: 'grab',
                    cursorAt: {
                        left: 5,
                        top: 0,
                    },
                    opacity: 0.8,
                });
            }
        })

    }

    getLayerName(layerList, id) {
        var layerName;
        $.each(layerList, (x, layer) => {
            if (layer.id == id) {
                layerName = layer.name;
            }
        })
        return layerName || "";
    }

    getMapLayer(id) {
        var mapLayers = this.map._layers,
            layer;
        $.each(mapLayers, function(i, l) {
            if (l.id == id) {
                layer = l;
            }
        })
        return layer;
    }

    updateSliders() {
        var that = this,
            active = this.activeLayersManager.getActiveLayersIds();
        $.each(active, function(i, id) {
            if (!that.addedSliders.includes(id)) {
                that.addSliderDiv(id)
                that.addSlider(id)
            }
        })
        $.each(that.addedSliders, function(i, sliderId) {
            if (sliderId && !active.includes(sliderId)) {
                that.deleteSlider(sliderId)
            }
        })
        this.setContainerHeight()
    }

    setContainerHeight() {
        var popup = $('.lOpacityPopup'),
            body = $('.lOpacityPopup__body')
        if (this.addedSliders.length < 2) {
            popup.removeClass('lOpacityPopup-small lOpacityPopup-xsmall')
                .addClass('lOpacityPopup-xxsmall')
            body.removeClass('lOpacityPopup--small__body-small lOpacityPopup--small__body-xsmall')
                .addClass('lOpacityPopup--small__body-xxsmall')
        } else if (this.addedSliders.length < 3) {
            popup.removeClass('lOpacityPopup-xxsmall lOpacityPopup-small')
                .addClass('lOpacityPopup-xsmall')
            body.removeClass('lOpacityPopup--small__body-small lOpacityPopup__body-xxsmall')
                .addClass('lOpacityPopup--small__body-xsmall')
        } else if (this.addedSliders.length < 4) {
            popup.removeClass('lOpacityPopup-xsmall lOpacityPopup-xxsmall')
                .addClass('lOpacityPopup-small')
            body.removeClass('lOpacityPopup--small__body-xsmall lOpacityPopup--small__body-xxsmall')
                .addClass('lOpacityPopup--small__body-small')
        } else {
            popup.removeClass('lOpacityPopup-xxsmall lOpacityPopup-xsmall lOpacityPopup-small')
            body.removeClass('lOpacityPopup--small__body-xxsmall lOpacityPopup--small__body-xsmall lOpacityPopup--small__body-small')
        }
    }

    deleteSlider(id) {
        $("[data-id=" + id + "]").detach()
        this.addedSliders.splice(this.addedSliders.indexOf(id), 1);
    }

    addSliderDiv(id) {
        var name = this.getLayerName(this.layerListKeys, id),
            div = ` <div class="layerOpacityItem" data-id="` + id + `">
<div class="layerOpacityItem__name">` + name + `</div>
<div class="layerOpacityItem__slider" data-layerID="` + id + `"></div>
</div> `;
        $('.lOpacityPopup__body').append(div)
        if (name.length > 90 && $(window).width() > 768) {
            $("[data-id=" + id + "]").css({
                'min-height': '70px',
                'max-height': '70px',
                'line-height': '11px',
                //        color: '#b2a50c'
            })
        } else if (name.length > 90 && $(window).width() < 769) {
            $("[data-id=" + id + "]").css({
                'min-height': '35px',
                'max-height': '35px',
            })
        } else if (name.length > 60 && $(window).width() > 768) {
            $("[data-id=" + id + "]").css({
                'min-height': '65px',
                'max-height': '65px',
                'line-height': '11px',
                //        color: '#0cb285'
            })
        } else if (name.length > 60 && $(window).width() < 769) {
            $("[data-id=" + id + "]").css({
                'min-height': '35px',
                'max-height': '35px',
            })
        } else if (name.length > 40 && $(window).width() > 768) {
            $("[data-id=" + id + "]").css({
                'min-height': '55px',
                'max-height': '55px',
                //        color: '#2e6d06'
            })
        } else if (name.length > 20 && $(window).width() > 768) {
            $("[data-id=" + id + "]").css({
                'min-height': '40px',
                'max-height': '40px',
                //        color: '#8008cb'
            })
        }
        /*else if (name.length > 20 && $(window).width() > 768) {
             $("[data-id=" + id + "]").css({
               'min-height': '35px',
               'max-height': '35px',
             })
           } */
        else if (name.length > 20 && $(window).width() < 769) {
            $("[data-id=" + id + "]").css({
                'min-height': '20px',
                'max-height': '30px',
            })
        } else {
            $("[data-id=" + id + "]").css({
                'min-height': '20px',
                'max-height': '30px',
                //        color: '#e8126d'
            })
        }
    }

    addSlider(id) {
        var layer = this.getMapLayer(id),
            tooltip = $('<div class="layerOpacityItem__slider--tooltip" id="tooltip' + id + '" />').css({
                top: -15,
            }).hide();
        $("[data-layerID=" + id + "]").slider({
            animate: "fast",
            min: 0,
            max: 1,
            step: 0.01,
            value: 1,
            slide: function(event, ui) {
                var tooltipText = ui.value == 1 ? '100%' : (ui.value.toFixed(2) * 100).toString().substr(0, 2) + '%'
                tooltip.text(tooltipText);
                layer.setOpacity(ui.value)
            }
        }).find(".ui-slider-handle").append(tooltip).hover(function() {
            tooltip.show()
        }, function() {
            tooltip.hide()
        })
        this.addedSliders.push(id)
    }
}

export default LayerOpacity;