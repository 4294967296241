// tlumaczenie strony
class Dictionary {

    constructor() {

        this.translations = {
            en: {
                portal: 'Portal-en',
            },
            pl: {
                portal: 'Portal',
            }
        }
    }

}

export default Dictionary;