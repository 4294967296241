import L from 'leaflet';
import $ from 'jquery';
//import './proj4';
import './Proj4leaflet';
import Tools from './Tools';
import ControlScalePosition from './ControlScalePosition';
import '../vendor/leaflet.wms';
import LayersOuterParser from './LayersOuterParser';
import Composition from './Compositions';
import GetObjectFromPermalink from './GetObjectFromPermalink';
import CheckLayers from './CheckLayers';
import Loading from './Loading';
import '../vendor/leaflet.draw.js';
import '../vendor/Leaflet.Editable.js';
import '../vendor/L.Control.SwitchScaleControl.js';
import '../vendor/L.Control.Zoomslider.js';
import TapPointer from './TapPointer.js';
//import 'leaflet-draw-with-touch';
import LicenseDate from './LicenseDate';

var tools = new Tools();
var layersOuterParser = new LayersOuterParser();

// zainicjownaie mapy
class MapInit {
     constructor() {}

     init(config) {
          L.CRS['EPSG2180'] = new L.Proj.CRS(
               'EPSG:2180',
               '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
          );
          L.CRS['EPSG2177'] = new L.Proj.CRS(
               'EPSG:2177',
               '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
          );
          //! added to staff licenceDate.js
          this.config = config;
          //!-------------
          //! added public condition
          this.public = config.publ || {};
          //!----------
          this.tematList = config.tematList;
          this.layerList = config.layerList;
          this.treeStructure = config.treeStructure;
          // this.treeStructure = {};
          // this.updateTreeStructure();
          this.bases = config.bases || {
               displayName: 'Podkładowe',
               layers: [],
          };
          this.blankLayer = {
               id: 9999,
               type: 'Tile',
               name: 'Brak',
               url: '',
               isBaseLayer: true,
               queryable: true,
               queryUrl: null,
               options: {
                    layers: null,
                    format: 'image/png',
                    transparent: true,
                    srs: 'EPSG:3857',
                    attribution: null,
                    zIndex: -100,
                    checked: false,
               },
          };
          this.blank = {
               id: 9999,
               name: 'Brak',
               checked: false,
          };
          this.layerList.push(this.blankLayer);
          this.bases.layers.push(this.blank);
          this.vectorLayers = config.additionalVectorLayers || [];
          this.vectorLayersTree = config.additionalVectorGroups || [];

          var that = this;
          if (this.vectorLayers[0]) {
               for (var i = 0; i < this.vectorLayers.length; i++) {
                    config.layerList.push(that.vectorLayers[i]);
               }
               for (var j = 0; j < that.vectorLayersTree.length; j++) {
                    config.treeStructure.push(that.vectorLayersTree[j]);
               }
          }
          this.layerObjectIndexes = this.addLayerObjectIndexes();
          this.layerGroups = this.createLayerGroups();
          this.compositions = config.tematList;
          this.baseLayers = this.createBaseLayerGroup();
          // this.vectorLayers = this.createBaseLayerGroup();
          //this.kompozycja = config.currentComposition || {}; changet at this.nazwa = config.nazwa || {};
          this.nazwa = config.nazwa || {};
          this.mapSettings = {};
          tools.extend(this.mapSettings, config.MapConf.map);
          this.mapSettings.center = [config.MapConf.Lat, config.MapConf.Lng];
          this.mapSettings.zoom = config.MapConf.ZoomMapy;
          this.mapTools = config.MapConf || {};
          this.osmID = config.MapConf.OsmID;

          //standard version
          // this.showLayers = this.mapTools.Warstwy;

          //! public composition condition
          // if (this.public === true || this.public === {}) {
          //! LicenseDate.js condition
          let LicenseDateCheck = new LicenseDate(config, {}).compareDates();
          LicenseDateCheck
               ? this.licenseMapInitTrue()
               : this.licenseMapInitFalse();
          // }
          //! ---------------

          this.controlScalePosition = new ControlScalePosition(config);
          this.allOverlayLayersIDs = [];
          this.allOverlayLayers = [];
          /*pusty obiekt ze statusem wlaczenia / wylaczenia interaktywnych opcji
             wypelniany w poszczegolnych modulach*/
          this.states = {};
          this.extendMapOptions();
          this.createMap();
          this.fitCenterInBounds();
          this.allLayersGroup = new L.layerGroup();
          if (this.bases.layers.length > 0) {
               this.addBaseLayersGroup();
          }
          this.addEmptyLayersControl();
          if (this.treeStructure.length > 0) {
               this.addLayers();
          }
          this.addScaleControl();
          this.controlScalePosition.set();
          this.addZoomControl();
          if (
               this.compositions.length != 0 &&
               this.compositions != null &&
               this.compositions != undefined
          ) {
               this.addCompositionControl();
               this.addCompositionTitle();
          }
          // dodaj narzedzie do kompozycji mapowych
          new Composition(config, {
               map: this.map,
               toolsClass: this.mapTools.toolsClass,
          });

          this.loading = new Loading(config, {
               map: this.map,
               toolsClass: this.mapTools.toolsClass,
          });
          this.addSmallIcons();
          this.wrapOverlaysControl(this.tematList);
          this.addDrawControl();
          this.events();
          this.laysControlsHiddenEvent();
          this.setCompositionTitle();
          this.hideGroups();
          this.additionalLayersAdded = $.Deferred();
          this.tapPointer = new TapPointer(config, {
               map: this.map,
          });
          if (this.showLayers == false) {
               $('.leaflet-control-layers').css('display', 'none');
          }
          // var html = $('.leaflet-control-attribution').html()
          // $('.leaflet-control-attribution').html(html + ' | &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors')
     }

     //! LicenseDate.js conditions if
     licenseMapInitFalse() {
          this.showLayers = false;
          this.mapTools.WyszukajDzialke = false;
          this.mapTools.WyszukajAdres = false;
          this.mapTools.Login = false;
          this.config.EAdres = false;
          this.config.EDzialka = false;
          this.config.ELatLng = false;
          this.config.EDzialkaInfo = false;
          this.config.ELokalSearch = false;
          this.config.EAdresInfo = false;
          this.config.ETerenInfo = false;
          this.config.zgloszenieGuid = null;
          this.config.aktualnoscGuid = null;
          this.config.WyborcaGuid = null;
     }
     licenseMapInitTrue() {
          this.showLayers = this.mapTools.Warstwy;
     }
     //! -----------------------
     laysControlsHiddenEvent() {
          var that = this;
          $(document).on(
               'click tap',
               '.leaflet-control-layers-groupToggle',
               function () {
                    var group = $(this).attr('data-groupID');
                    that.toggleGroup(group);
               }
          );
     }

     events() {
          var that = this;
          that.positionScroll = $(
               '.leaflet-control-layers-overlays__body'
          ).scrollTop();

          $(document).on(
               'mouseover',
               '.leaflet-control-layers-overlays__body',
               function () {
                    that.positionScroll = $(
                         '.leaflet-control-layers-overlays__body'
                    ).scrollTop();
               }
          );

          $(document).on(
               'click tap',
               '.leaflet-control-layers-selector',
               function () {
                    that.checkSelectorsState();
                    that.checkAnglesState();
                    that.checkCustomCheckboxes();
                    that.checkCustomRadios();
                    $('.leaflet-control-layers-overlays__body').scrollTop(
                         that.positionScroll
                    );
               }
          );
          /*   that.map.on('layeradd', function(layer) {
            console.log(layer)
            $(this).on('tileerror', function(t, e, c) {
                console.log('tileerror')
                console.log(t)
                console.log(e)
                console.log(c)
            })
        });
 */
          this.preventExpanding();
     }

     reloadImg() {
          // reload image by changing its src
          var src = $(this).attr('src');
          var i = src.lastIndexOf('?');
          if (i > 0) {
               // remove previous cache string
               src = src.substring(0, i);
          }
          $(this).attr('src', src + '?nocache=' + Math.random() * 1000);
     }

     updateLayers(config) {
          this.clearLayers();
          this.initLayers(config);
     }

     initLayers(config) {
          this.layerList = config.layerList;
          this.treeStructure = config.treeStructure;
          this.bases = config.bases;
          this.layerList.push(this.blankLayer);
          this.bases.layers.push(this.blank);

          this.vectorLayers = config.additionalVectorLayers || [];
          this.vectorLayersTree = config.additionalVectorGroups || [];

          var that = this;
          if (this.vectorLayers[0]) {
               for (var i = 0; i < this.vectorLayers.length; i++) {
                    // $.each(that.vectorLayers, function(i, v) {
                    config.layerList.push(that.vectorLayers[i]);
                    // })
               }
               for (var j = 0; j < that.vectorLayersTree.length; j++) {
                    // $.each(that.vectorLayersTree, function(i, v) {
                    config.treeStructure.push(that.vectorLayersTree[j]);
                    // })
               }
          }

          this.layerObjectIndexes = this.addLayerObjectIndexes();
          this.layerGroups = this.createLayerGroups();
          this.baseLayers = this.createBaseLayerGroup();
          // this.vectorLayers = this.createBaseLayerGroup();
          var baseLayers = this.addLayersToMap(this.baseLayers[0].layers);
          $.each(baseLayers, (name, baselayer) =>
               this.map.baseLayersControl.addBaseLayer(baselayer, name)
          );
          var $layersForm = $(this.map.baseLayersControl._form);
          this.addCustomRadios($layersForm);
          for (var m = 0; m < this.layerGroups.length; m++) {
               var overlayLayers = this.addLayersToMap(
                    this.layerGroups[m].layers
               );
               this.addLayersControl(
                    this.layerGroups[m].displayName,
                    overlayLayers,
                    this.layerGroups[m].checkable,
                    this.layerGroups[m].expanded,
                    this.layerGroups[m].separator
               );
          }
          this.wrapOverlaysControl(this.tematList);
          this.hideGroups();

          var getObjectFromPermalink = new GetObjectFromPermalink(config);
          new CheckLayers(config, {
               map: this.map,
               toolsClass: this.mapTools.toolsClass,
               additionalLayersAdded: this.additionalLayersAdded,
               getObjectFromPermalink: getObjectFromPermalink,
          });
          // this.events()
          this.clearCompositions();
          // this.compositions = config.tematList;
          new Composition(config, {
               map: this.map,
               toolsClass: this.mapTools.toolsClass,
          });
          this.setCompositionTitle();
          /*      var overlayControlHeight = $('#overlayLayers').css('height').replace('px','')
                 var overlayControlHeightInside = $('.leaflet-control-layers-overlays__body').css('height').replace('px','')
                 console.log(overlayControlHeight)
                 console.log((overlayControlHeight - 60)+'px')
                 if (config.tematList.length > 1) {
                     $('#overlayLayers').css('height',(overlayControlHeight - 60)+'px')
                     $('.leaflet-control-layers-overlays__body').css('height',(overlayControlHeightInside - 60)+'px')
                     console.log('>1')
                     if (config.tematList.length > 2) {
                         // $('#overlayLayers').css('height')
                         console.log('>2')
                     }
                 } */
     }

     clearCompositions() {
          $('.compositions__body').html('');
     }

     clearLayers() {
          this.allLayersGroup.eachLayer((layer) => {
               this.map.baseLayersControl.removeLayer(layer);
               this.map.layersControl.removeLayer(layer);
               this.map.layersEmptyControl.removeLayer(layer);
               this.map.removeLayer(layer);
          });
          $('.leaflet-control-layers-overlays__body').empty();
          $('.leaflet-control-layers-overlays__body').detach();
     }

     checkCustomCheckboxes() {
          var that = this;
          $.each(
               $('.leaflet-control-layers-overlays__body').find(
                    '.leaflet-control-layers-selector'
               ),
               function (i, input) {
                    // if ($(input).attr('data-group')) {} else {
                    if (!$(input).attr('data-group')) {
                         var $layersForm = $(input).closest(
                                   '.leaflet-control-layers-overlays'
                              ),
                              id = $layersForm
                                   .siblings(
                                        '.leaflet-control-layers-groupTitle'
                                   )
                                   .find('.leaflet-control-layers-groupToggle')
                                   .attr('data-groupid');
                         that.addCustomCheckboxes($layersForm, id);
                         that.addLayersInfo($layersForm, id);
                    }
               }
          );
     }

     checkCustomRadios() {
          var that = this;
          $.each(
               $('.leaflet-control-layers-base').find(
                    '.leaflet-control-layers-selector'
               ),
               function (i, input) {
                    // if ($(input).attr('data-base')) {} else {
                    if (!$(input).attr('data-base')) {
                         var $layersForm = $(input).closest(
                              '.leaflet-control-layers-base'
                         );
                         that.addCustomRadios($layersForm);
                    }
               }
          );
     }

     preventExpanding() {
          $(document).on(
               'mouseover',
               'div.leaflet-top.leaflet-left > div',
               function () {
                    $(this).removeClass('leaflet-control-layers-expanded');
               }
          );
     }

     checkSelectorsState() {
          var $groupSelectors = $('.leaflet-control-layers-groupCheckbox');
          $.each($groupSelectors, function (i, groupSelector) {
               var group = $(groupSelector).attr('id'),
                    selectors = $('#' + group)
                         .closest(
                              '.leaflet-control-layers-overlays__body--item'
                         )
                         .find('.leaflet-control-layers-selector'),
                    check = true;
               $.each(selectors, function (j, selector) {
                    check = !$(selector).prop('checked') ? false : check;
               });
               if (check && !$(groupSelector).prop('checked')) {
                    $(groupSelector).prop('checked', true);
               } else if (!check && $(groupSelector).prop('checked')) {
                    $(groupSelector).prop('checked', false);
               }
          });
     }

     checkAnglesState() {
          var $groupSelectors = $('.leaflet-control-layers-groupCheckbox');
          $.each($groupSelectors, function (i, groupSelector) {
               var group = $(groupSelector).attr('id'),
                    selectors = $('#' + group)
                         .closest(
                              '.leaflet-control-layers-overlays__body--item'
                         )
                         .find('.leaflet-control-layers-selector');
               if (
                    $('[data-groupID="' + group + '"]').hasClass(
                         'fa-angle-down'
                    )
               ) {
                    $.each(selectors, function (j, selector) {
                         $(selector).siblings('span').css('display', 'none');
                         $(selector)
                              .siblings('.leaflet-control-layers-checkmark')
                              .css('display', 'none');
                         $(selector)
                              .siblings('.leaflet-control-layers-info--visible')
                              .addClass(
                                   'leaflet-control-layers-info--notVisible'
                              );
                         $(selector).css('display', 'none');
                    });
               } else {
                    $.each(selectors, function (j, selector) {
                         $(selector).css('display', 'inline-block');
                         $(selector)
                              .siblings('.leaflet-control-layers-checkmark')
                              .css('display', 'inline-block');
                         $(selector)
                              .siblings('.leaflet-control-layers-info--visible')
                              .removeClass(
                                   'leaflet-control-layers-info--notVisible'
                              );
                         $(selector)
                              .siblings('span')
                              .css('display', 'inline-block');
                    });
               }
          });
     }

     expandGroup(group) {
          var selectors = $('#' + group)
               .closest('.leaflet-control-layers-overlays__body--item')
               .find('.leaflet-control-layers-selector');
          $.each(selectors, function (j, selector) {
               $(selector).css('display', 'inline-block');
               $(selector)
                    .siblings('.leaflet-control-layers-checkmark')
                    .css('display', 'inline-block');
               $(selector)
                    .siblings('.leaflet-control-layers-info--visible')
                    .removeClass('leaflet-control-layers-info--notVisible');
               $(selector).siblings('span').css('display', 'inline-block');
               $('[data-groupID="' + group + '"]')
                    .removeClass('fa-angle-down')
                    .addClass('fa-angle-up');
               $(selector)
                    .closest('.leaflet-control-layers-overlays')
                    .css('height', 'auto');
          });
          this.checkSelectorsState();
     }

     toggleGroup(group) {
          var selectors = $('#' + group)
               .closest('.leaflet-control-layers-overlays__body--item')
               .find('.leaflet-control-layers-selector');
          $.each(selectors, function (j, selector) {
               if (
                    $(selector).css('display') == 'block' ||
                    $(selector).css('display') == 'inline-block'
               ) {
                    $(selector).siblings('span').css('display', 'none');
                    $(selector)
                         .siblings('.leaflet-control-layers-checkmark')
                         .css('display', 'none');
                    $(selector)
                         .siblings('.leaflet-control-layers-info--visible')
                         .addClass('leaflet-control-layers-info--notVisible');
                    $(selector).css('display', 'none');
                    $('[data-groupID="' + group + '"]')
                         .removeClass('fa-angle-up')
                         .addClass('fa-angle-down');
                    $(selector)
                         .closest('.leaflet-control-layers-overlays')
                         .css('height', '0px');
               } else {
                    $(selector).css('display', 'inline-block');
                    $(selector)
                         .siblings('.leaflet-control-layers-checkmark')
                         .css('display', 'inline-block');
                    $(selector)
                         .siblings('.leaflet-control-layers-info--visible')
                         .removeClass(
                              'leaflet-control-layers-info--notVisible'
                         );
                    $(selector).siblings('span').css('display', 'inline-block');
                    $('[data-groupID="' + group + '"]')
                         .removeClass('fa-angle-down')
                         .addClass('fa-angle-up');
                    $(selector)
                         .closest('.leaflet-control-layers-overlays')
                         .css('height', 'auto');
               }
          });
          this.checkSelectorsState();
     }

     getGroupName(name) {
          return name.replace(/\s/g, '').toLowerCase();
     }

     hideGroups() {
          var that = this;
          $.each(this.treeStructure, function (i, group) {
               if (!group.expanded) {
                    var groupName = that.getGroupName(group.displayName);
                    that.toggleGroup(groupName);
               }
          });
     }

     createLayerGroup(groupSettings) {
          var group = {
               id: groupSettings.id,
               displayName: groupSettings.displayName,
               checkable: groupSettings.checkable,
               expanded: groupSettings.expanded,
               layers: this.getGroupLayers(groupSettings.layers),
               separator: groupSettings.separator,
          };
          return group;
     }

     createBaseLayerGroup() {
          var layers = [];
          layers.push(this.createLayerGroup(this.bases));
          return layers;
     }

     createLayerGroups() {
          var groups = [];
          for (var i = 0; i < this.treeStructure.length; i++) {
               groups.push(this.createLayerGroup(this.treeStructure[i]));
          }
          return groups;
     }

     addLayerObjectIndexes() {
          var indexes = {};
          for (var i = 0; i < this.layerList.length; i++) {
               var layer = this.layerList[i];
               indexes[layer.id] = i;
          }
          return indexes;
     }

     getLayerObjectById(id) {
          var index = this.layerObjectIndexes[id];
          return this.layerList[index];
     }

     getGroupLayers(layersSettings) {
          var layers = [];
          for (var i = 0; i < layersSettings.length; i++) {
               if (layersSettings[i] != undefined) {
                    var layerId = layersSettings[i].id,
                         layerObject = this.getLayerObjectById(layerId);
                    if (layerObject) {
                         layers.push(layerObject);
                    }
               }
          }
          return layers;
     }

     countLoadingTime(layer) {
          var that = this;
          layer.on('loading', function () {
               that.time0 = performance.now();
               //that.loading.makeLoadingScreen(".countPopup__content", "Ładowanie warstw...")
          });
          layer.on('load', function () {
               clearTimeout(that.timeout);
               that.time1 = performance.now();
               $('.countPopup').addClass('countPopup--active');
               $('.countPopup__content').text(
                    'Ładowanie: ' +
                         (((that.time1 - that.time0) % 60000) / 1000).toFixed(
                              4
                         ) +
                         ' sekund.'
               );
               that.timeout = setTimeout(function () {
                    $('.countPopup').removeClass('countPopup--active');
               }, 3500);
          });
     }

     createLayerObject(settings) {
          var that = this,
               layerConfig = settings.layer;
          layerConfig.options = layerConfig.options || {};
          if (layerConfig.type == 'Tile') {
               layerConfig.options.maxNativeZoom = 19;
          }

          layerConfig.url = this.removeTileUrl(layerConfig.url);

          var layer;
          if (typeof settings.defaultOptions === 'function') {
               settings.defaultOptions(layerConfig.options);
          }
          if ($.inArray(layerConfig.id, this.allOverlayLayersIDs) > -1) {
               $.each(Object.entries(this.map._layers), function (i, l) {
                    if (l[1].id == layerConfig.id) {
                         layer = l[1];
                    }
               });
          } else {
               layer = settings.leafletFunction(
                    layerConfig.url,
                    layerConfig.options
               );
               layer.id = layerConfig.id;
               var maxZoom =
                    this.mapTools.ZoomMax != 0 ? this.mapTools.ZoomMax : 19;
               layer.options.maxZoom = maxZoom;
          }
          that.countLoadingTime(layer);
          return layer;
     }

     createVectorObject(settings) {
          var layers = {};
          for (var i = 0; i < settings.layers.length; i++) {
               var features = this.addVectorLayer(settings.layers[i]);
               var layerConfig = settings.layers[i],
                    layer = this.createFeatureGroup(
                         features,
                         layerConfig.styles
                    );
               layers[layerConfig.name] = layer;
          }
          return layers;
     }

     // sprawdzenie ile poziomow zagniezdzen ma tablica wspolrzednych
     reverseCoords(coords) {
          var coords2LevelDeep = coords[0];
          if ($.isArray(coords2LevelDeep)) {
               var coords3LevelDeep = coords2LevelDeep[0];
               if ($.isArray(coords3LevelDeep)) {
                    return this.switchMultiPolyCoords(coords);
               } else {
                    return this.switchPolyCoords(coords);
               }
          } else {
               return this.switchCoords(coords);
          }
     }

     createFeature(geojson, styles) {
          var geometry = geojson.geometry,
               type = geometry.type,
               reversedCoords = this.reverseCoords(geometry.coordinates),
               feature;
          if (type == 'Polygon') {
               feature = L.polygon(reversedCoords, styles);
          } else if (type == 'Polyline' || type == 'LineString') {
               feature = L.polyline(reversedCoords, styles);
          } else {
               feature = L.marker(reversedCoords, styles);
          }
          if (this.mapTools.Info) {
               feature.bindPopup('type: ' + type);
               feature.bindTooltip(type);
          }
          return feature;
     }

     createFeatureGroup(features, styles) {
          styles = styles || {};
          var featuresList = [];
          for (var i = 0; i < features.length; i++) {
               var geojson = JSON.parse(features[i]);
               var feature = this.createFeature(geojson, styles);
               featuresList.push(feature);
          }
          return L.featureGroup(featuresList).addTo(this.map);
     }

     addBaseLayer(layer) {
          return this.createLayerObject({
               layer: layer,
               leafletFunction: L.tileLayer,
          });
     }

     addWmsLayer(layer) {
          return this.createLayerObject({
               layer: layer,
               leafletFunction: L.WMS.source,
               defaultOptions: this.extendWmsLayersOptions({
                    identify: false,
                    tolerance: 100,
               }),
          });
     }

     addWmsSingleLayer(layer, isTiled) {
          var srsCode = layer.options.srs.replace(':', '').toUpperCase();
          layer.options.crs = L.CRS[srsCode];
          var maxZoom = this.mapTools.ZoomMax != 0 ? this.mapTools.ZoomMax : 19,
               tileSize = layer.url.indexOf('gwc') > -1 ? 256 : 512;
          tools.extend(layer.options, {
               INFO_FORMAT: 'text/html',
               identify: false,
               untiled: isTiled,
               maxZoom: maxZoom,
               tileSize: tileSize,
               tolerance: 100,
          });
          if (layer.url.indexOf('wms.qgiscloud.com/rafalw') > -1) {
               delete layer.options['INFO_FORMAT'];
          }
          layer.url = this.removeTileUrl(layer.url);
          var url = this.replaceTileUrl(layer.url),
               newParams = tools.replaceUrlParams(url, layer.options);
          url = newParams.url;
          layer.options = newParams.options;
          var source = L.WMS.source(url, layer.options);
          source.addSubLayer(layer.options.layers);
          return source.getLayer(layer.options.layers);
     }

     replaceTileUrl(url) {
          url = this.removeTileUrl(url);
          url = url.replace(/%osmid%/g, this.osmID);
          return url;
     }

     addVectorLayerAjax(layer) {
          return {
               promise: $.ajax({
                    url: window.PROXY + layer.ajax.url,
               }),
          };
     }

     addVectorLayer(layer) {
          if (layer.ajax) {
               return this.addVectorLayerAjax(layer);
          }
     }

     addVectorLayers() {
          return this.createVectorObject({
               layers: this.vectorLayers,
          });
     }

     addLayers() {
          // var that = this;
          for (var i = 0; i < this.layerGroups.length; i++) {
               this.addLayersGroup(this.layerGroups[i]);
          }

          //! wcześniejsze ustawienie wysokości dla warstw tematycznych
          // setTimeout(function () {
          //     that.setLayerControlHeight();
          // }, 4000);
     }

     addLayersToMap(layersSettings) {
          var layers = {},
               layerObj;
          for (var i = 0; i < layersSettings.length; i++) {
               var layer = layersSettings[i];
               if (
                    layer.name == 'Działki-LPIS' ||
                    layer.name == 'Działki-SWDE' ||
                    layer.name == 'Działki-Geoportal'
               ) {
                    layer.options.zIndex = 5;
               }
               if (layer.type == 'Tile') {
                    layerObj = this.addBaseLayer(layer);
               } else if (
                    layer.type == 'WMS' ||
                    layer.type == 'WMS Tiled' ||
                    layer.type == 'Single Tiled' ||
                    layer.type == 'WMS Single Tile'
               ) {
                    if (
                         $.grep(this.allOverlayLayersIDs, (x) => x == layer.id)
                              .length == 0
                    ) {
                         layerObj =
                              layer.type == 'Single Tiled' ||
                              layer.type == 'WMS Single Tile'
                                   ? this.addWmsSingleLayer(layer, true)
                                   : this.addWmsSingleLayer(layer, false);
                         layerObj.id = layer.id;
                         this.allOverlayLayersIDs.push(layer.id);
                         this.allOverlayLayers.push(layerObj);
                    } else {
                         layerObj = $.grep(
                              this.allOverlayLayers,
                              (x) => x.id == layer.id
                         )[0];
                    }
               } else if (layer.type == 'vector') {
                    layerObj = this.addVectorLayer(layer);
               }
               layers[layer.name] = layerObj;

               // Add icon for layer
               layers[layer.name].icon = layer.icon;
               this.allLayersGroup.addLayer(layerObj);
          }
          return layers;
     }

     countExpandedGroups() {
          var count = 0;
          $.each(this.layerGroups, function (i, group) {
               if (group.expanded) {
                    {
                         count++;
                    }
               }
          });
          return count;
     }

     //!  wcześniejsze ustawienie wysokości dla warstw tematycznych
     // setLayerControlHeight() {
     //     var count = this.countExpandedGroups(),
     //         height = $(window).height();
     //     if (count > 1 && $(window).width() < 769) {
     //         $("#overlayLayers").css({
     //             "max-height": height - 80,
     //             "min-height": height - 80,
     //         });
     //         $(".leaflet-control-layers-overlays__body").css({
     //             "max-height": height - 160,
     //             "min-height": height - 160,
     //             height: height - 160,
     //         });
     //     }
     // }

     addLayersGroup(group) {
          var that = this;
          if (group.layers.length) {
               var overlayLayers = this.addLayersToMap(group.layers),
                    promises = [],
                    layerNames = [];
               $.each(overlayLayers, function (layerName, overlayLayer) {
                    if (overlayLayer) {
                         if (overlayLayer.promise != undefined) {
                              promises.push(overlayLayer.promise);
                              layerNames.push(layerName);
                         }
                    }
               });
               if (!promises.length) {
                    this.addLayersControl(
                         group.displayName,
                         overlayLayers,
                         group.checkable,
                         group.expanded,
                         group.separator
                    );
               } else {
                    $.when.apply($, promises).done(function (...responses) {
                         var layersToAdd = [];
                         if (typeof responses[1] == 'string') {
                              layersToAdd[0] = responses;
                         } else {
                              layersToAdd = responses;
                         }
                         $.each(layersToAdd, function (i, data) {
                              if (layerNames[i]) {
                                   var groupLayer = that.getGroupLayerByName(
                                        layerNames[i],
                                        group
                                   );
                                   overlayLayers[layerNames[i]] =
                                        layersOuterParser.parseLayer(
                                             data[0],
                                             groupLayer
                                        );
                              }
                         });
                         that.addLayersControl(
                              group.displayName,
                              overlayLayers,
                              group.checkable,
                              group.expanded,
                              group.separator
                         );
                         if (!group.expanded) {
                              var groupName = that.getGroupName(
                                   group.displayName
                              );
                              that.toggleGroup(groupName);
                         } else {
                              that.checkSelectorsState();
                         }
                         that.additionalLayersAdded.resolve(true);
                    });
               }
          }
     }

     getGroupLayerByName(layerName, group) {
          for (var i = 0; i < group.layers.length; i++) {
               if (group.layers[i].name == layerName) {
                    return group.layers[i];
               }
          }
     }

     addBaseLayersGroup() {
          if (this.baseLayers) {
               var baseLayers = this.addLayersToMap(this.baseLayers[0].layers);
               this.addBaseLayersControl(baseLayers);
          }
     }

     addEmptyLayersControl() {
          if (this.layerList.length) {
               this.layersEmptyControl = L.control
                    .layers(
                         {},
                         {},
                         {
                              position: 'topleft',
                              autoZIndex: false,
                         }
                    )
                    .addTo(this.map);
               this.$layersControl = $(this.layersEmptyControl._container).attr(
                    'id',
                    'overlayLayers'
               );
               this.map.layersEmptyControl = this.layersEmptyControl;
               var $layersForm = $(this.layersEmptyControl._form),
                    hide = `<i class="fa fa-angle-double-left fa-2x leaflet-control-layers-hideShow leaflet-control-layers-hide-overlays"></i>`,
                    title =
                         '<h4 class="leaflet-control-layers-title"><img class="leaflet-control-layers-title-img" src="assets/images/icons/sip_04.png" /> Warstwy tematyczne</h4>';
               $layersForm.prepend(title);
               $layersForm.prepend(hide);
          }
     }

     addBaseLayersControl(layers) {
          var that = this,
               control = L.control
                    .layers(
                         layers,
                         {},
                         {
                              position: 'topleft',
                              autoZIndex: false,
                              getActiveLayers: function () {
                                   var active = [];
                                   $.each(
                                        that.map._layers,
                                        function (layerId, layer) {
                                             if (
                                                  layer._leaflet_id &&
                                                  that.map.hasLayer(layer)
                                             ) {
                                                  active.push(layer);
                                             }
                                        }
                                   );
                                   return active;
                              },
                         }
                    )
                    .addTo(this.map);
          this.moveBaseLayerControl('Warstwy podkładowe', control, layers);
          this.map.baseLayersControl = control;
     }

     addLayersControl(title, layers, isCheckable, isExpanded, hasSeparator) {
          var that = this,
               control = L.control
                    .layers({}, layers, {
                         position: 'topleft',
                         autoZIndex: false,
                         getActiveLayers: function () {
                              var active = [];
                              $.each(
                                   that.map._layers,
                                   function (layerId, layer) {
                                        if (
                                             layer._leaflet_id &&
                                             that.map.hasLayer(layer)
                                        ) {
                                             active.push(layer);
                                        }
                                   }
                              );
                              return active;
                         },
                    })
                    .addTo(this.map);
          this.moveLayerControl(
               title,
               control,
               isCheckable,
               hasSeparator,
               layers
          );
          this.map.layersControl = control;
     }

     removeTileUrl(url) {
          if (url) {
               url = url.replace('gwc/service/', '');
               return url;
          } else {
               return '';
          }
          // return url;
     }

     //todo create a inside contener in left bar
     moveBaseLayerControl(title, control, layers) {
          var $layersForm = $(control._form),
               hide = `<i class="fa fa-angle-double-left fa-2x leaflet-control-layers-hideShow leaflet-control-layers-hide-base"></i>`;
          title =
               '<h4 class="leaflet-control-layers-title"><img  class="leaflet-control-layers-title-img" src="assets/images/icons/sip_01.png" /> ' +
               title +
               '</h4>';
          this.addCustomRadios($layersForm);
          this.addCustomIcon($layersForm, layers);
          $layersForm.prepend(title);
          $layersForm.prepend(hide);
     }

     addCustomRadios($layersForm) {
          $.each($layersForm.find('input'), function (i, input) {
               $(input).attr('data-base', 'base');
               $(input)
                    .parent()
                    .wrap(
                         "<label class='leaflet-control-layers-radio'></label>"
                    );
               var name = $(input)
                    .siblings('span')
                    .text()
                    .replace(/\s/g, '')
                    .toLowerCase();
               $(
                    '<div class="leaflet-control-layers-radiobutton" data-layer="' +
                         name +
                         '"></div>'
               ).insertAfter(input);
               $('[data-layer="' + name + '"]').css(
                    'display',
                    $(input).css('display') == 'none' ? 'none' : 'inline-block'
               );
          });
          this.addBaseLayersInfo($layersForm);
     }

     addBaseLayersInfo($layersForm) {
          var that = this;
          $.each(
               $layersForm.find('.leaflet-control-layers-selector'),
               function (i, input) {
                    this.layerName = $(input).siblings('span').text();
                    $(input)
                         .parent()
                         .append(
                              '<div class="leaflet-control-layers-info" aria-describedby="name-hint" data-group-checkinfo="base' +
                                   i +
                                   '" data-layer-name="' +
                                   this.layerName +
                                   '"><i class="fas fa-info-circle"></i><div class="leaflet-control-layers-info-tooltip" id="name-hint" aria-hidden="false">informacja o warstwie</div></div>'
                         );
                    if ($(window).width() <= 768) {
                         $('.leaflet-control-layers-info').addClass(
                              'leaflet-control-layers-info--visible'
                         );
                    }
                    $(input)
                         .parent()
                         .on('mouseover', function () {
                              if (
                                   $.trim(
                                        $(this)
                                             .children(
                                                  '.leaflet-control-layers-info'
                                             )
                                             .attr('data-layer-name')
                                   ) != 'Brak'
                              ) {
                                   $(this)
                                        .children(
                                             '.leaflet-control-layers-info'
                                        )
                                        .addClass(
                                             'leaflet-control-layers-info--visible'
                                        );
                              }
                         });
                    $(input)
                         .parent()
                         .on('mouseout', function () {
                              if ($(window).width() > 768) {
                                   $(this)
                                        .children(
                                             '.leaflet-control-layers-info'
                                        )
                                        .removeClass(
                                             'leaflet-control-layers-info--visible'
                                        );
                              }
                         });
                    $('[data-group-checkinfo="base' + i + '"]').on(
                         'click',
                         function (e) {
                              e.preventDefault();
                              $('.layerInfoPopup').addClass('overlay--active');
                              var _that = this;
                              $.each(that.layerList, function (k, v) {
                                   if (
                                        $.trim(
                                             $(_that).attr('data-layer-name')
                                        ).replace(/<\/?[^>]+(>|$)/g, '') ==
                                        $.trim(v.name).replace(
                                             /<\/?[^>]+(>|$)/g,
                                             ''
                                        )
                                   ) {
                                        v.source
                                             ? $('.layerInfoPopup__body').html(
                                                    '<b>nazwa: </b>' +
                                                         v.name.replace(
                                                              /<\/?[^>]+(>|$)/g,
                                                              ' '
                                                         ) +
                                                         '<br><b>źródło: </b>' +
                                                         v.source
                                               )
                                             : $('.layerInfoPopup__body').html(
                                                    '<b>nazwa: </b>' +
                                                         v.name.replace(
                                                              /<\/?[^>]+(>|$)/g,
                                                              ' '
                                                         ) +
                                                         '<br><b class="alert">brak informacji o źródle</b>'
                                               );
                                        $('.layerInfoPopup__legend')
                                             .children('img')
                                             .css('display', 'none')
                                             .attr('src', '#');
                                        if (v.legend) {
                                             $('.layerInfoPopup__legend')
                                                  .children('img')
                                                  .attr(
                                                       'src',
                                                       window.SERVER +
                                                            window.ROOT +
                                                            v.legend.replace(
                                                                 /\\/g,
                                                                 '/'
                                                            )
                                                  )
                                                  .css('display', 'initial');
                                        }

                                        $('.layerInfoPopup').draggable({
                                             cursor: 'grab',
                                             cursorAt: {
                                                  left: 5,
                                                  top: 0,
                                             },
                                             opacity: 0.8,
                                        });
                                   }
                              });
                         }
                    );
               }
          );
     }

     addCustomCheckboxes($layersForm, id) {
          $.each(
               $layersForm.find('.leaflet-control-layers-selector'),
               function (i, input) {
                    $(input).attr('data-group', id);
                    $(input)
                         .parent()
                         .wrap(
                              "<label class='leaflet-control-layers-checkGroup'></label>"
                         );
                    $(
                         '<div class="leaflet-control-layers-checkmark" data-group-check="' +
                              id +
                              '"></div>'
                    ).insertAfter(input);
                    $('[data-group-check="' + id + '"]').css(
                         'display',
                         $(input).css('display') == 'none'
                              ? 'none'
                              : 'inline-block'
                    );
               }
          );
     }

     addCustomIcon($layersForm, layer) {
          $.each(
               $layersForm.find('.leaflet-control-layers-selector'),
               function (i, input) {
                    const layerName = $(input).siblings('span').text().trim();
                    if (layer[layerName] && layer[layerName].icon) {
                         $(input)
                              .siblings('.leaflet-control-layers-checkmark')
                              .after(
                                   `<img src="${
                                        window.SERVER +
                                        window.ROOT +
                                        layer[layerName].icon
                                   }"></img>`
                              );
                         $(input)
                              .siblings('.leaflet-control-layers-radiobutton')
                              .after(
                                   `<img src="${
                                        window.SERVER +
                                        window.ROOT +
                                        layer[layerName].icon
                                   }"></img>`
                              );
                    }
               }
          );
     }

     addLayersInfo($layersForm, id) {
          var that = this;
          $.each(
               $layersForm.find('.leaflet-control-layers-selector'),
               function (i, input) {
                    this.layerName = $(input).siblings('span').text();
                    $(input)
                         .parent()
                         .append(
                              '<div class="leaflet-control-layers-info" aria-describedby="name-hint" data-group-checkinfo="' +
                                   id +
                                   '" data-layer-name="' +
                                   this.layerName +
                                   '"><i class="fas fa-info-circle"></i><div class="leaflet-control-layers-info-tooltip" id="name-hint" aria-hidden="false">informacja o warstwie</div></div>'
                         );
                    if ($(window).width() <= 768) {
                         $('.leaflet-control-layers-info').addClass(
                              'leaflet-control-layers-info--visible'
                         );
                    }
                    $(input)
                         .parent()
                         .on('mouseover', function () {
                              $(this)
                                   .children('.leaflet-control-layers-info')
                                   .addClass(
                                        'leaflet-control-layers-info--visible'
                                   );
                         });
                    $(input)
                         .parent()
                         .on('mouseout', function () {
                              $(this)
                                   .children('.leaflet-control-layers-info')
                                   .removeClass(
                                        'leaflet-control-layers-info--visible'
                                   );
                         });
                    $("[data-group-checkinfo='" + id + "']").on(
                         'click',
                         function (e) {
                              e.preventDefault();
                              $('.layerInfoPopup').addClass('overlay--active');
                              var _that = this;
                              $.each(that.layerList, function (k, v) {
                                   if (
                                        $.trim(
                                             $(_that).attr('data-layer-name')
                                        ).replace(/<\/?[^>]+(>|$)/g, '') ==
                                        $.trim(v.name).replace(
                                             /<\/?[^>]+(>|$)/g,
                                             ''
                                        )
                                   ) {
                                        v.source
                                             ? $('.layerInfoPopup__body').html(
                                                    '<b>nazwa: </b>' +
                                                         v.name.replace(
                                                              /<\/?[^>]+(>|$)/g,
                                                              ' '
                                                         ) +
                                                         '<br><b>źródło: </b>' +
                                                         v.source
                                               )
                                             : $('.layerInfoPopup__body').html(
                                                    '<b>nazwa: </b>' +
                                                         v.name.replace(
                                                              /<\/?[^>]+(>|$)/g,
                                                              ' '
                                                         ) +
                                                         '<br><b class="alert">brak informacji o źródle</b>'
                                               );

                                        $('.layerInfoPopup__legend')
                                             .children('img')
                                             .css('display', 'none')
                                             .attr('src', '#');
                                        if (v.legend) {
                                             $('.layerInfoPopup__legend')
                                                  .children('img')
                                                  .attr(
                                                       'src',
                                                       window.SERVER +
                                                            window.ROOT +
                                                            v.legend.replace(
                                                                 /\\/g,
                                                                 '/'
                                                            )
                                                  )
                                                  .css('display', 'initial');
                                        }

                                        $('.layerInfoPopup').draggable({
                                             cursor: 'grab',
                                             cursorAt: {
                                                  left: 5,
                                                  top: 0,
                                             },
                                             opacity: 0.8,
                                        });
                                   }
                              });
                         }
                    );
               }
          );
     }

     moveLayerControl(title, control, isCheckable, separator, layers) {
          var that = this,
               $layersForm = $(control._form),
               $layersContainer = $(control._container),
               id = title
                    .replace(/\s/g, '')
                    .replace(/\(/, '')
                    .replace(/\)/, '')
                    .replace(/\./, '')
                    .replace(/,/, '')
                    .replace('/', '_')
                    .toLowerCase(),
               groupTitle = isCheckable
                    ? '<i class="fa fa-angle-up leaflet-control-layers-groupToggle" data-groupID="' +
                      id +
                      '"></i><label for="' +
                      id +
                      '" class="leaflet-control-layers-groupTitle" style="display:inline-block"><input type="checkbox" name="group" class="leaflet-control-layers-groupCheckbox" id="' +
                      id +
                      '" checked><div class="leaflet-control-layers-groupCheckmark" id="check-' +
                      id +
                      '"></div><div>' +
                      title +
                      '</div></label>'
                    : '<i class="fa fa-angle-up leaflet-control-layers-groupToggle" data-groupID="' +
                      id +
                      '"></i><label for="' +
                      id +
                      '" class="leaflet-control-layers-groupTitle" style="display:inline-block"><input type="checkbox" name="group" class="leaflet-control-layers-groupCheckbox" id="' +
                      id +
                      '" style="display:none"><div class="leaflet-control-layers-groupCheckmark" id="check-' +
                      id +
                      '" style="display:none"></div> <div>' +
                      title +
                      '</div></label>';
          this.addCustomCheckboxes($layersForm, id);
          this.addCustomIcon($layersForm, layers);
          this.addLayersInfo($layersForm, id);
          $layersForm.addClass('leaflet-control-layers-overlays__body--item');
          $layersForm.prepend(
               '<div class="leaflet-control-layers-groupTitle">' +
                    groupTitle +
                    '</div>'
          );
          if (separator) {
               $layersForm.append(
                    '<div class="leaflet-control-layers-groupSeparator"></div>'
               );
          }
          if ($('.leaflet-control-layers-overlays__body').length == 0) {
               this.$layersControl.append($layersForm);
          } else {
               $('.leaflet-control-layers-overlays__body').append($layersForm);
          }
          $('#' + id).on('click tap', function () {
               var state = $('#' + id).prop('checked'),
                    selectors = $('#' + id)
                         .closest(
                              '.leaflet-control-layers-overlays__body--item'
                         )
                         .find('.leaflet-control-layers-selector');
               if (state) {
                    $.each(selectors, function (j, selector) {
                         if ($(selector).css('display') == 'none') {
                              $(selector).css('display', 'inline-block');
                              $(selector)
                                   .siblings('span')
                                   .css('display', 'inline-block');
                              $(selector)
                                   .siblings(
                                        '.leaflet-control-layers-checkmark'
                                   )
                                   .css('display', 'inline-block');
                              $(selector)
                                   .siblings(
                                        '.leaflet-control-layers-info--visible'
                                   )
                                   .removeClass(
                                        'leaflet-control-layers-info--notVisible'
                                   );
                              $('[data-groupID="' + id + '"]')
                                   .removeClass('fa-angle-down')
                                   .addClass('fa-angle-up');
                              that.expandGroup(id);
                         }
                    });
               }
               $.each($layersForm.find('input'), function (i, v) {
                    if (
                         (state && !$(v).prop('checked')) ||
                         (!state && $(v).prop('checked'))
                    ) {
                         $(v).click();
                    }
               });
          });
          $layersContainer.remove();
     }

     hasDuplicates(array) {
          var valuesSoFar = Object.create(null);
          for (var i = 0; i < array.length; ++i) {
               var value = array[i];
               if (value in valuesSoFar) {
                    return true;
               }
               valuesSoFar[value] = true;
          }
          return false;
     }

     wrapOverlaysControl(tematList) {
          $('.leaflet-control-layers-overlays__body--item').wrapAll(
               "<div class='leaflet-control-layers-overlays__body' />"
          );
          if (tematList.length > 1) {
               $('.leaflet-control-layers-overlays__body').addClass(
                    'leaflet-control-layers-overlays__body-m'
               );
               if (tematList.length > 2) {
                    $('.leaflet-control-layers-overlays__body').removeClass(
                         'leaflet-control-layers-overlays__body-m'
                    );
                    $('.leaflet-control-layers-overlays__body').addClass(
                         'leaflet-control-layers-overlays__body-s'
                    );
               }
          } else {
               $('.leaflet-control-compositions').addClass(
                    '.leaflet-control-compositions--hidden'
               );
               $('.leaflet-control-layers-overlays__body').addClass(
                    'leaflet-control-layers-overlays__body-l'
               );
          }
     }
     //!  Nowa skala w select
     addScaleControl() {
          let that = this;
          if (this.mapTools.Skala) {
               L.control
                    .scale({
                         position: 'topright',
                    })
                    .addTo(this.map);

               const setRangeofScales = (maxZoom) => {
                    let scales = [];
                    switch (maxZoom) {
                         case 14:
                              scales = [25000, 50000, 100000, 200000, 500000];
                              break;
                         case 15:
                              scales = [
                                   10000, 25000, 50000, 100000, 200000, 500000,
                              ];
                              break;
                         case 16:
                              scales = [
                                   5000, 10000, 25000, 50000, 100000, 200000,
                                   500000,
                              ];
                              break;
                         case 17:
                              scales = [
                                   2000, 5000, 10000, 25000, 50000, 100000,
                                   200000, 500000,
                              ];
                              break;
                         case 18:
                              scales = [
                                   1000, 2000, 5000, 10000, 25000, 50000,
                                   100000, 200000, 500000,
                              ];
                              break;
                         case 19:
                         case 20:
                              scales = [
                                   500, 1000, 2000, 5000, 10000, 25000, 50000,
                                   100000, 200000, 500000,
                              ];
                              break;
                         case 21:
                              scales = [
                                   150, 500, 1000, 2000, 5000, 10000, 25000,
                                   50000, 100000, 200000, 500000,
                              ];
                              break;
                         case 22:
                              scales = [
                                   100, 150, 500, 1000, 2000, 5000, 10000,
                                   25000, 50000, 100000, 200000, 500000,
                              ];
                              break;
                         default:
                              scales = [
                                   100, 150, 500, 1000, 2000, 5000, 10000,
                                   25000, 50000, 100000, 200000, 500000,
                              ];
                    }

                    return scales;
               };

               this.map.addControl(
                    new L.Control.SwitchScaleControl({
                         dropdownDirection: 'upward',
                         position: 'topright',
                         scales: setRangeofScales(that.mapSettings.maxZoom),
                    })
               );

               //! end Nowa skala w select
          }
     }

     addZoomControl() {
          if (this.mapTools.Zoom) {
               L.control
                    .zoom({
                         position: 'topright',
                         zoomInTitle: 'Przybliż',
                         zoomOutTitle: 'Oddal',
                    })
                    .addTo(this.map);
               $('.leaflet-control-zoom-in')
                    .attr('data-toggle', 'tooltip')
                    .attr('data-placement', 'left');
               $('.leaflet-control-zoom-out')
                    .attr('data-toggle', 'tooltip')
                    .attr('data-placement', 'left');
          }
          if (this.mapTools.ZoomSlider) {
               this.map.addControl(window.zoomsliderControl);
          }
     }

     createMap() {
          this.mapSettings.minZoom = this.mapSettings.zoom - 2;
          this.mapSettings.maxZoom =
               this.mapTools.ZoomMax != 0 ? this.mapTools.ZoomMax : 19;
          this.mapSettings.zoomSnap = 0.05;
          this.map = L.map('map', this.mapSettings);
     }

     fitCenterInBounds() {
          if (this.mapTools.BBox) {
               var that = this,
                    BBox = this.mapTools.BBox,
                    maxBounds = L.latLngBounds(
                         L.latLng(BBox.split(',')[1], BBox.split(',')[0]),
                         L.latLng(BBox.split(',')[3], BBox.split(',')[2])
                    );
               if (maxBounds) {
                    var fitBounds = this.map.getBounds();
                    this.map.on('moveend', () => {
                         var mapBounds = that.map.getBounds(),
                              mapCenter = mapBounds.getCenter(),
                              centerInsideBounds =
                                   maxBounds.contains(mapCenter);
                         if (centerInsideBounds) {
                              fitBounds = mapBounds;
                         } else {
                              try {
                                   that.map.panInsideBounds(fitBounds);
                              } catch (er) {
                                   console.log(er);
                              }
                         }
                    });
               }
          }
     }

     extendMapOptions() {
          var defaultOptions = {
               zoomControl: false,
               editable: true,
               editOptions: {},
          };
          tools.extend(this.mapSettings, defaultOptions);
     }

     extendWmsLayersOptions(options) {
          var defaultOptions = {
               format: 'image/png',
               transparent: true,
          };
          tools.extend(options, defaultOptions);
     }

     switchCoords(coords) {
          return [coords[1], coords[0]];
     }

     switchPolyCoords(featureCoords) {
          var that = this;
          return featureCoords.map(function (coords) {
               return that.switchCoords(coords);
          });
     }

     switchMultiPolyCoords(multiCoords) {
          var that = this;
          return multiCoords.map(function (featureCoords) {
               return that.switchPolyCoords(featureCoords);
          });
     }

     //this.kompozycja.nazwa changed  at this.nazwa

     setCompositionTitle() {
          var that = this;
          if (this.mapTools.Default) {
               $('.composition-title').css('display', 'none');
          }
          var nameCheck = this.nazwa;
          if (/([^\n]*\n+)+$/i.test(nameCheck)) {
               //!usuwa html - nie wyświetlają się same zdjęcia w composition-title__name
               // while (nameCheck.indexOf("<") > -1) {
               //     nameCheck =
               //         nameCheck.substr(0, nameCheck.indexOf("<")) +
               //         nameCheck.substr(nameCheck.indexOf(">") + 1);
               // }
               while (nameCheck.indexOf(' ') > -1) {
                    nameCheck = nameCheck.replace(' ', '');
               }
               while (nameCheck.indexOf('&nbsp;') > -1) {
                    nameCheck = nameCheck.replace('&nbsp;', '');
               }
               nameCheck = nameCheck.replace(/\s/g, '');
               nameCheck = $.trim(nameCheck);
               nameCheck = nameCheck.replace('p', '');
               if (nameCheck == '') {
                    this.nazwa = '';
               }
          }

          if ($.parseHTML(this.nazwa).length == 0) {
               $('.composition-title').css('display', 'none');
          } else {
               $('.composition-title__name').html(that.nazwa);
          }
          // if (true) {
          if (window.location.href.includes('sadkowice')) {
               /*   $('.composition-title__name').html(`<img class="composition-title__name--img" src="./assets/images/sadkowice_herb.png" />
<div class="composition-title__name--text">` + that.kompozycja.nazwa + `</div>`)
            $('.composition-title__name').css({
                    'display': 'flex',
                    'text-align': 'left'
                }) */
               // } else if (true) {
               //   }  else if (window.location.href.includes('localhost')) {
               // } else if (window.location.href.includes("srem")) {
               //     $(".small-icon-composition-title").addClass("responsive-icon-d");
               //     $(".leaflet-control-composition-title").css("display", "flex");
               //     $(".composition-title").addClass("composition-title--srem");
               //     $(".composition-title__name")
               //         .html(`<img class="composition-title__name--img composition-title__name--img-srem" src='./assets/images/logaunijne/srem_logo.png' />
               //            <div class="composition-title__name--text"></div>`);
               /*  $('.composition-title').html(`<img class="composition-title__img composition-title__img--srem" src='./assets/images/logaunijne/srem_logo.png' />`)
                 $('.composition-title__name').css({
                         'display': 'flex',
                         'text-align': 'left'
                     }) */
               // } else if (true) {
          } else if (location.href.includes('rzezawa')) {
               var url =
                    'http://administracja.gison.pl/turysta/PunktPoi/GetPublicPopupXY?profilid=344&lng=20.518743395805362&lat=49.990634021416696&zoom=19';
               url = window.PROXY + encodeURIComponent(url);
               $.ajax(url, {
                    method: 'GET',
                    success: function (data) {
                         var popup = L.popup()
                              .setLatLng([
                                   49.990634021416696, 20.518743395805362,
                              ])
                              .setContent(data.popup)
                              .openOn(that.map);
                    },
               });
          }
     }

     addCompositionControl() {
          var that = this;
          $(
               `<div class="leaflet-control-compositions leaflet-control leaflet-control-layers no-print">
<h4 class="leaflet-control-compositions__title" style="font-size:` +
                    $('.leaflet-control-layers-title').css('font-size') +
                    `">
<img  class="leaflet-control-layers-title-img" src="assets/images/icons/sip_02.png" /> Kompozycje mapowe
</h4>
<i class="fa fa-angle-double-left fa-2x leaflet-control-compositions-hideShow leaflet-control-compositions-hide"></i><div class="leaflet-control-compositions__body compositions__body" id="composition-control"></div>
<div>`
          ).insertAfter(
               '#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(1)'
          );

          $('#composition-control').on('mouseover', function (e) {
               e.stopPropagation();
               that.map.dragging.disable();
          });
          $('#composition-control').on('wheel', function (e) {
               e.stopPropagation();
          });
          $('#composition-control').on('mouseout', function () {
               that.map.dragging.enable();
          });

          if (
               /^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/i.test(
                    location.pathname.substr(1)
               )
          ) {
               // if (true) {
               $('.leaflet-control-compositions').css('display', 'none');
               $('.leaflet-control-compositions').addClass(
                    'leaflet-control-compositions--hidden'
               );
          }
     }

     addCompositionTitle() {
          var that = this;
          $(`<div id="composition-title" class="leaflet-control leaflet-control-composition-title composition-title no-print">
<i class="fa fa-angle-double-right fa-2x leaflet-control-composition-title-hide composition-title-hide"></i>
<div class="composition-title__name"></div></div>`).insertBefore(
               '#map > div.leaflet-control-container > div.leaflet-top.leaflet-right > div:nth-child(1)'
          );
          $('.composition-title__name').on('mouseover', function () {
               that.map.dragging.disable();
          });
          $('.composition-title__name').on('mouseout', function () {
               that.map.dragging.enable();
          });
     }
     // todo added liltle icons  in left bar
     addSmallIcons() {
          if (this.showLayers) {
               $(
                    `<div class="small-icon leaflet-control-layers-show-base"></div>`
               ).insertAfter(
                    '#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(1)'
               );
               if (
                    this.compositions.length != 0 &&
                    this.compositions != null &&
                    this.compositions != undefined
               ) {
                    $(
                         `<div class="small-icon leaflet-control-compositions-show"></div>`
                    ).insertAfter(
                         '#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(3)'
                    );
                    $(
                         `<div class="small-icon leaflet-control-layers-show-overlays"></div>`
                    ).insertAfter(
                         '#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(5)'
                    );
                    $(
                         `<div class="small-icon leaflet-control-composition-title-show composition-title-show"></div>`
                    ).insertAfter(
                         '#map > div.leaflet-control-container > div.leaflet-top.leaflet-right > div.composition-title'
                    );
               } else {
                    $(
                         `<div class="small-icon leaflet-control-layers-show-overlays"></div>`
                    ).insertAfter(
                         '#map > div.leaflet-control-container > div.leaflet-top.leaflet-left > div:nth-child(3)'
                    );
               }
          } else {
               $('.small-icon-baseLayers').remove();
               $('.small-icon-compositions').remove();
               $('.small-icon-overlayLayers').remove();
          }
     }

     addDrawControl() {
          var editableLayers = new L.FeatureGroup();
          this.map.addLayer(editableLayers);
          var MyCustomMarker = L.Icon.extend({
                    options: {
                         shadowUrl: null,
                         iconAnchor: new L.Point(12, 38),
                         iconSize: new L.Point(24, 34),
                         iconUrl: './assets/images/marker-icon.png',
                    },
               }),
               options = {
                    position: 'topleft',
                    draw: {
                         polyline: {
                              shapeOptions: {
                                   color: '#0080D4',
                                   weight: 5,
                                   editing: {
                                        className: '',
                                   },
                              },
                         },
                         polygon: {
                              allowIntersection: false,
                              drawError: {
                                   color: '#e1e100',
                                   message: '<strong>błąd!<strong>',
                              },
                              shapeOptions: {
                                   color: '#0080D4',
                                   editing: {
                                        className: '',
                                   },
                              },
                         },
                         circle: false,
                         rectangle: false,
                         marker: {
                              icon: new MyCustomMarker(),
                              shapeOptions: {
                                   editing: {
                                        className: '',
                                   },
                              },
                         },
                    },
                    edit: {
                         featureGroup: editableLayers,
                         remove: false,
                    },
               },
               drawControl = new L.Control.Draw(options);
          this.map.addControl(drawControl);
          this.map.drawCtrl = drawControl;
          this.map.editableLayer = editableLayers;
     }
}

export default MapInit;
