import $ from 'jquery';

class eService_searchCoords {

  constructor(config, settings, lp) {
    this.map = settings.map;
    this.toolsClass = settings.toolsClass;
    this.tools = config.MapConf.tools || {};
    this.lp = lp;
    this.storage = window.sessionStorage;
    this.states = settings.states;
    this.treeStructure = config.treeStructure;
    this.bases = config.bases;
    this.states = settings.states;
    this.addServiceBtn();
    this.events();
  }

  events() {
    var that = this;

    $(document).on('submit', '#eservicePopup__body--form', function () {
      return false;
    })

    $(document).on('click', '#eservicePopup__body--form-submit', function () {
      var coords = {
        lat: $('#eservicePopup__body--form-lat').val(),
        lng: $('#eservicePopup__body--form-lng').val()
      }
      that.goToCoords(coords.lat, coords.lng)
    })

    $('.eservice-close').on('click', function () {
      that.emptyPopup()
      $('.modePanel').removeClass('modePanel--active');
      $('.small-icon-modePanel').css('display', 'none');
      that.storage.setItem('mode', 'none');
    })

    $('.modePanel__body--btn-cancel').on('click', function () {
      $('.modePanel').removeClass('modePanel--active');
      $('.small-icon-modePanel').css('display', 'none');
      that.storage.setItem('mode', 'none');
      $('.eservice-close').click();
    })

    $('.eservicePopup').on('click', function () {
      if ($(window).width() > 768 || $(window).height() > 768) {
        $(this).draggable({
          cursor: 'grab',
          cursorAt: {
            left: 5,
            top: 0,
          },
          opacity: 0.8,
        });
      }
    })
  }

  addServiceBtn() {
    var that = this;

    var serviceDiv = $('<div>', {
      html: `<div class="eservicePanel__body--item-text">Wskazywanie miejsca po podaniu współrzędnych</div>
<div class="eservice-btn eservicePanel__body--item-btn" id=""><img class="eservicePanel__body--item-btn-img" src="assets/images/eservices/eservice_searchcoords.png"/></div>`,
      class: 'eservicePanel__body--item',
      id: 'eService__searchCoords'
    })

    $('.eservicePanel__body').append(serviceDiv);
    $('#eService__searchCoords').on('click', function () {
      $('.modePanel__body--btn-cancel').click();
      $('.eservicePopup').css('max-height', '230px');
      $('.modePanel').addClass('modePanel--active');
      $('.modePanel__body--communicate').text('Uwaga! Jesteś w trybie wyszukiwania miejsca');
      that.states.es_onInfo = false;
      that.storage.setItem('mode', 'es-sc');
      that.emptyPopup();
      $('.eservicePopup').addClass('overlay--active');
      $('.eservicePopup__title').text('Wskazywanie miejsca po podaniu współrzędnych');
      if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
        $('.eservicePopup__body').css({
          'height': '164px',
          'width': '375px'
        })
      }
      $('.eservicePopup__body').html(`<form id="eservicePopup__body--form" class="eservicePopup__body--form">
Szerokość:
<input type="text" name="lat" id="eservicePopup__body--form-lat"> Długość:
<input type="text" name="lng" id="eservicePopup__body--form-lng">
<button class="overlay__button-2" id="eservicePopup__body--form-submit">Idź</button>
</form>`);
      $('#eservicePopup__body--form-lat').select();
    })

  }

  emptyPopup() {
    $('.eservicePopup').removeClass('overlay--active')
    $('.eservicePopup__title').text('')
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      $('.eservicePopup__body').css({
        'height': 'auto',
        'width': 'auto'
      })
    }
    $('.eservicePopup__body').html(``);
  }

  goToCoords(lat, lng) {
    var zoom = this.map.getZoom();
    this.map.setView([lat, lng], zoom);
  }
}

export default eService_searchCoords;
