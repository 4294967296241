import $ from 'jquery';

class ActiveLayersManager {

    constructor(config) {
        this.init(config);
    }

    init(config) {
        this.treeStructure = config.treeStructure;
        this.bases = config.bases;
        this.overlayOrder = this.getLayersOrder('overlay');
        this.basesOrder = this.getLayersOrder('base');
        this.allOrder = this.getLayersOrder('all');
    }

    getActiveBaseLayerId() {
        var id,
            order = this.basesOrder,
            $inputs = $('.leaflet-control-layers-base').find('input');
        $.each($inputs, function(i, input) {
            if (input.checked) {
                id = order[i];
            }
        })
        return id;
    }

    getActiveLayersIds() {
        var id = [],
            order = this.overlayOrder,
            $allInputs = $('#overlayLayers').find('input'),
            $inputs = [];

        $.each($allInputs, function(j, input) {
            if (!$(input).hasClass('leaflet-control-layers-groupCheckbox')) {
                $inputs.push(input)
            }
        })
        $.each($inputs, function(i, input) {
            if (input.checked && id.indexOf(order[i]) == -1) {
                id.push(order[i]);
            }
        });
        return id;
    }

    getLayersOrder(type) {
        var layersOrder = [];
        if (type === 'overlay') {
            $.each(this.treeStructure, function(index, type) {
                $.each(type.layers, function(index, layer) {
                    layersOrder.push(layer.id);
                })
            })
        } else if (type == 'base') {
            $.each(this.bases.layers, function(index, layer) {
                layersOrder.push(layer.id);
            })
        } else if (type == 'all') {
            $.each(this.bases.layers, function(index, layer) {
                layersOrder.push(layer.id);
            })
            $.each(this.treeStructure, function(index, type) {
                $.each(type.layers, function(index, layer) {
                    layersOrder.push(layer.id);
                })
            })
        }
        return layersOrder;
    }

}

export default ActiveLayersManager;