import $ from 'jquery';

class DefaultView {

    constructor() {
        $('.small-icon').css('display', 'none')
        $('.bar-bottom').css('display', 'none')

        this.defaultConfig = {
            layerList: [{
                id: 66,
                type: "Tile",
                name: "OSM",
                url: "http://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
                isBaseLayer: true,
                queryable: true,
                queryUrl: null,
                options: {
                    layers: null,
                    format: "image/png",
                    transparent: true,
                    srs: "EPSG:3857",
                    attribution: null,
                    zIndex: 1,
                    checked: true
                }
            }],
            treeStructure: [{
                id: 791,
                displayName: "Warstwy podkładowe",
                expanded: true,
                checkable: false,
                checked: false,
                layers: [{
                    id: 66,
                    name: "OSM",
                    checked: true
                }]
            }],
            mapConfig: {
                Guid: null,
                Name: null,
                MapConf: {
                    Wydruk: null,
                    Zoom: null,
                    Pomiar: null,
                    Info: null,
                    Legenda: null,
                    Permalink: null,
                    Warstwy: null,
                    Skala: null,
                    Wysrodkuj: null,
                    WyszukajDzialke: null,
                    WyszukajAdres: null,
                    StreetView: null,
                    ChangeFontSize: null,
                    ChangeContrast: null,
                    Coords: null,
                    SearchCoords: null,
                    Lng: 19.434814453125004,
                    Lat: 52.05249047600099,
                    ZoomMapy: 7,
                    OsmID: null,
                    StylStrony: null,
                    Jezyki: null,
                    JezykAng: null,
                    SearchServiceAdr: null,
                    SearchServiceDz: null,
                    StartInfo: '<center>Skonfiguruj mapę!</center>',
                    StartButton: null,
                    BBox: null,
                    Default: true,
                }
            },
            tematList: [{
                temaiid: 0,
                guid: "",
                webname: null,
                nazwa: "",
                publ: true,
                visible: "true",
                kolejnosc: 0
            }, ],
            title: null
        }
    }

}

export default DefaultView;