import $ from 'jquery';

class MapToolsFocus {

    constructor() {
        this.toolsFocusOption = {
            print: true,
            zoom: false,
            measure: true,
            selectArea: false,
            info: true,
            legend: true,
            permalink: true,
            layers: false,
            scale: false,
            centerView: false,
            multiSelect: false,
            searchParcels: false,
            searchAddress: false,
            coordsSearch: true,
            streetView: true,
            geolocation: true,
            miniMap: true,
            importTrack: false,
            layerOpacity: true,
            selectObject: true
        }
        this.events();
    }

    events() {
        var that = this;

        $('.tools__list').on('click tap', function(event) {
            var $target = $(event.target),
                toolIcon = $target.is('img') ? $target : $target.find('.tools__tool-icon'),
                toolItem = $target.is('li') ? $target : $target.closest('li');
            if (toolItem.attr('class')) {
                var tool = that.getToolFromClassName(toolItem.attr('class'));
                that.toolsFocusOption[tool] ? that.toggleIcon(toolIcon, tool) : that.clickFocusedTools();
            }
        })

        $('.priority-nav__hidden-links').on('click tap', function(event) {
            var $target = $(event.target),
                toolIcon = $target.is('img') ? $target : $target.find('.tools__tool-icon'),
                toolItem = $target.is('li') ? $target : $target.closest('li');
            if (toolItem.attr('class')) {
                var tool = that.getToolFromClassName(toolItem.attr('class'));
                that.toolsFocusOption[tool] ? that.toggleIcon(toolIcon, tool) : that.clickFocusedTools();
            }
        })

    }

    toggleIcon(icon, tool) {
        var that = this;
        if ($(icon).hasClass('tools__tool-icon-focused')) {
            $(icon).removeClass('tools__tool-icon-focused');
            $(icon).parent().removeClass('tools__tool-focused');
        } else {
            if (tool != 'permalink') {
                that.clickFocusedTools();
            }
            $(icon).addClass('tools__tool-icon-focused');
            $(icon).parent().addClass('tools__tool-focused');
        }
    }

    clickFocusedTools() {
        $.each($('.tools__tool-icon-focused'), function() {
            $(this).trigger('click');
        })
    }

    getToolFromClassName(classname) {
        var tool = "";
        $.each(this.toolsFocusOption, function(k) {
            if (classname.includes(k.toString())) {
                tool = k;
            }
        })
        return tool;
    }

}

export default MapToolsFocus;