import Tools from './Tools';
import L from 'leaflet';
import $ from 'jquery';
import "./../vendor/jquery-ui";
// import "./../vendor/jqueryui-combobox";
import Wkt from 'wicket';
import Loading from './Loading';
import axios from 'axios';


var tools = new Tools();

class SearchGugik {
     constructor(config, settings) {
          this.map = settings.map;
          this.tools = config.MapConf || {};
          this.storage = window.sessionStorage;
          this.bbox = config.MapConf.BBox || '';
          this.osmid = config.MapConf.OsmID;
          this.searchTypes = [
               'WyszukajDzialkeGUGiK',
          ];
          this.searchClass = 'search';
          this.$field = $('.search-text');
          this.$fieldSelect = $('.search-select');
          this.$search = this.$field.closest('.' + this.searchClass);
          this.$loadingAnim = this.$search.find('.search__input-loading-anim');
          this.$loadingAnimGugik = $('.search__input-loading-anim-gugik');
          this.$foundList = this.$search.find('.search__input-found-list');
          this.requestFlag = true;
          this.timer = 0;
          this.loading = new Loading(config, settings);
          this.layerListKeys = config.layerListKeys;
          this.activeLayersManager = settings.activeLayersManager;
          this.activeLayersIds = this.activeLayersManager.getActiveLayersIds();
          this.params = {
               textFilter: '',
               foundData: [],
          };
          this.searchItemsSource;
          this.obreby = config.Obreby

          // this.selectedParcels = []
          window.selectedParcels = [];
          var that = this;
          this.keyUpCallbacks = {
               _this: that,
               arrowUp: this.arrowUp,
               arrowDown: this.arrowDown,
               enterPress: this.enterPress,
          };
          this.osmID = config.MapConf.OsmID;
          this.showSearch();
          this.events();

          //todo resize search panel
          $(window).resize(function () {
               if (
                    that.tools.WyszukajAdres ||
                    that.tools.WyszukajDzialke ||
                    that.tools.WyszukajDzialkeGUGiK
               ) {
                    if ($(window).width() <= 768 && $(window).height() <= 768) {
                         $('.small-icon-search').addClass('responsive-icon-d');
                         $('.small-icon-search').css('display', 'flex');
                    } else if ($(window).width() <= 768) {
                         $('.small-icon-search').addClass('responsive-icon-d');
                    } else {
                         $('.small-icon-search').removeClass(
                              'responsive-icon-d'
                         );
                         $('.small-icon-search').css('display', 'none');
                    }
               }
          });
     }

     events() {
          var that = this;

          $('.ui-menu-item').on('click tap', function (e) {
               $("#search-text-parcelsGugik").trigger("input")
                    .val(function (i, val) { return val });
          });

          this.$fieldSelect.on('change', function (e) {
               $("#search-text-parcelsGugik").trigger("input")
                    .val(function (i, val) { return val });
          });

          this.$field.on('input', function (e) {
               that.updateProperties(e.target);
               that.searchValue(e);
          });

          $(document).on('click tap', 'li.sugg-parcelGugik', function (e) {
               var latlng = that.getLatlngFromSugg($(this));
               //**********getinfo
               that.clickParams = {
                    latlng: latlng,
                    zoom: that.map.getZoom(),
                    //        xy: that.map.latLngToContainerPoint(latlng),
               };
               //**********getinfo end

               var $newActive = $(e.target).closest('li');
               $newActive.addClass('sugg-active');
               var text = $newActive.children('span').text();

               that.$field.val(text);

               var geom = that.getGeomFromSugg($(this));

               that.showGeomOnMapGugik(geom, latlng);
          });

          $(document).on('click tap', 'body', function (event) {
               that.closeSuggs(event);
          });

          $(document).on('click tap', '.search__input-button', function () {
               var $newActive = that.$foundList.children('li').eq(0);
               $newActive.addClass('sugg-active');
               var text = $newActive.children('span').text();

               that.$field.val(text);
               that.setVisible($newActive, false);
               if ($newActive.length) {
                    var latlng = that.getLatlngFromSugg($newActive);
                    if (
                         $newActive.hasClass('sugg-parcel') ||
                         $newActive.hasClass('sugg-parcelGugik')
                    ) {
                         var geom = that.getGeomFromSugg($newActive);
                    }

               }
          });

          $(document).on('click tap', '.search__input-info', function () {
               $('.infoSearchPopup')
                    .addClass('overlay--active')
                    .css('top', '190px');
          });

          $(window).on('keyup', function (e) {
               if (that.$foundList.hasClass('list-active')) {
                    //if suggestions are displayed
                    var key = e.keyCode;
                    switch (key) {
                         case 38: //up arrow
                              that.keyUpCallbacks.arrowUp();
                              break;
                         case 40: //down arrow
                              that.keyUpCallbacks.arrowDown();
                              break;
                         case 13: //enter
                              that.keyUpCallbacks.enterPress(e);
                              break;
                    }
               }
          });

          $('.infoSearchPopup').on('click tap', function () {
               if ($(window).width() > 768 || $(window).height() > 768) {
                    $(this).draggable({
                         cursor: 'grab',
                         cursorAt: {
                              left: 5,
                              top: 0,
                         },
                         opacity: 0.8,
                    });
               }
          });
     }

     updateProperties(target) {
          this.searchClass = 'search';
          this.$field = $(target).closest('.search-text');
          this.$search = this.$field.closest('.' + this.searchClass);
          this.$loadingAnim = $(target).closest('.search__box').find('.search__input-loading-anim');
          this.$foundList = this.$search.find('.search__input-found-list');
     }

     showSearch() {
          if (this.tools.WyszukajDzialkeGUGiK) {
               if ($(window).width() > 768 && $(window).height() > 768) {
                    this.$search.removeClass(this.searchClass + '--inactive');
                    $('.bar-top').css('justify-content', 'space-between');
               } else if (
                    $(window).width() <= 768 &&
                    $(window).height() <= 768
               ) {
                    $('.small-icon-search').addClass('responsive-icon-d');
                    $('.small-icon-search').css('display', 'flex');
               } else if ($(window).width() <= 768) {
                    $('.small-icon-search').addClass('responsive-icon-d');
               }
               for (var i = 0; i < this.searchTypes.length; i++) {
                    var searchType = this.searchTypes[i];
                    if (this.tools[searchType]) {
                         this.$search
                              .find('input[data-search-type="' + searchType + '"]')
                              .closest('.search__box')
                              .css('display', 'flex');
                    } else {
                         this.$search
                              .find('input[data-search-type="' + searchType + '"]'
                              )
                              .closest('.search__box')
                              .css('display', 'none');
                    }
               }
          }

          if (this.tools.WyszukajDzialkeGUGiK && this.obreby) {
               $.each(this.obreby, function (i, v) {
                    $('#select-parcelsGugik').append(`<option value="` + v.teryt + `">` + v.nazwa + `</option>`)
               })
               this.createWidget()
               $("#select-parcelsGugik").combobox();
               $("#toggle").on("click", function () {
                    $("#combobox").toggle();
               });
               $('.search__input-loading-anim-gugik').css('display', 'block')
          }

     }


     createWidget() {
          $.widget("custom.combobox", {
               _create: function () {
                    this.wrapper = $("<span>")
                         .addClass("custom-combobox")
                         .insertAfter(this.element);

                    this.element.hide();
                    this._createAutocomplete();
                    this._createShowAllButton();
               },

               _createAutocomplete: function () {
                    var selected = this.element.children(":selected"),
                         value = selected.val() ? selected.text() : "";

                    this.input = $("<input>")
                         .appendTo(this.wrapper)
                         .val('')
                         .attr("placeholder", "Wybierz obręb")
                         .attr("title", "")
                         .addClass("custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left")
                         .autocomplete({
                              delay: 0,
                              minLength: 0,
                              source: $.proxy(this, "_source")
                         })
                         .tooltip({
                              classes: {
                                   "ui-tooltip": "ui-state-highlight"
                              }
                         });
                    this._on(this.input, {
                         autocompleteselect: function (event, ui) {
                              ui.item.option.selected = true;
                              this._trigger("select", event, {
                                   item: ui.item.option
                              });
                              $("#search-text-parcelsGugik").trigger("input")
                                   .val(function (i, val) { return val });
                         },

                         autocompletechange: "_removeIfInvalid"
                    });
               },

               _createShowAllButton: function () {
                    var input = this.input,
                         wasOpen = false;

                    $("<a>")
                         .attr("tabIndex", -1)
                         .attr("role", "button")
                         .appendTo(this.wrapper)
                         .removeClass("ui-corner-all")
                         .addClass("custom-combobox-toggle ui-corner-right search__input-button-style")
                         .append("<i class='fa fa-chevron-down'></i>")
                         .on("mousedown", function () {
                              wasOpen = input.autocomplete("widget").is(":visible");
                         })
                         .on("click", function () {
                              input.trigger("focus");

                              // Close if already visible
                              if (wasOpen) {
                                   return;
                              }

                              // Pass empty string as value to search for, displaying all results
                              input.autocomplete("search", "");
                         });
               },

               _source: function (request, response) {
                    var matcher = new RegExp($.ui.autocomplete.escapeRegex(request.term), "i");
                    response(this.element.children("option").map(function () {
                         var text = $(this).text();
                         if (this.value && (!request.term || matcher.test(text)))
                              return {
                                   label: text,
                                   value: text,
                                   option: this
                              };
                    }));
               },

               _removeIfInvalid: function (event, ui) {

                    // Selected an item, nothing to do
                    if (ui.item) {
                         return;
                    }

                    // Search for a match (case-insensitive)
                    var value = this.input.val(),
                         valueLowerCase = value.toLowerCase(),
                         valid = false;
                    this.element.children("option").each(function () {
                         if ($(this).text().toLowerCase() === valueLowerCase) {
                              this.selected = valid = true;
                              return false;
                         }
                    });

                    // Found a match, nothing to do
                    if (valid) {
                         return;
                    }

                    // Remove invalid value
                    this.input
                         .val("")
                         .attr("title", value + " nie pasuje do żadnego elementu listy")
                         .tooltip("open");
                    this.element.val("");
                    this._delay(function () {
                         this.input.tooltip("close").attr("title", "");
                    }, 2500);
                    this.input.autocomplete("instance").term = "";
               },

               _destroy: function () {
                    this.wrapper.remove();
                    this.element.show();
               }
          });
     }

     searchValue(e) {
          var that = this;
          this.queryString = $(e.target).val();
          var inputField = e.target;
          clearTimeout(this.timer);
          if (this.queryString.length >= 1) {
               this.timer = setTimeout(function () {
                    var queryTest = that.$field.val();
                    if (queryTest == that.queryString) {
                         that.sendRequest(that.queryString, inputField);
                    }
               }, 1000);
          } else {
               this.removeChildrenIfExist();
          }
     }

     removeChildrenIfExist() {
          if (this.$foundList.children().length != 0) {
               this.$foundList.children().remove();
               this.$foundList
                    .removeClass('list-active')
                    .addClass('list-inactive');
          }
     }



     getParcelGugik(queryString) {
          var that = this;
          this.getParcelUrl = 'https://uldk.gugik.gov.pl/';
          const obreb = $('#select-parcelsGugik').val()
          //const searchString = obreb ? that.teryt + '.' + obreb + '.' + queryString : queryString
          const searchString = obreb ? obreb + '.' + queryString : queryString

          if (that.searchItemsSource) {
               that.searchItemsSource.cancel('Cancel previous request');
          }
          const CancelToken = axios.CancelToken;
          that.searchItemsSource = CancelToken.source();

          const url = that.getParcelUrl + '?request=GetParcelById&result=geom_wkt&srid=4326&id=' + searchString
          return axios.get(url, {
               cancelToken: that.searchItemsSource.token
          })
     }


     sendRequest(queryString, inputField) {
          var that = this;
          //if (this.requestFlag === true) {
          this.removeChildrenIfExist();
          this.disactivateSuggestionsList();
          //this.requestFlag = false;
          this.animationOn();
          var requestType = $(inputField).attr('data-request');
          if (requestType == 'getParcelGugik') {
               this.animationGugikOn();
               var getparcel = that.getParcelGugik(
                    queryString
               );
               getparcel.then(function (response) {
                    that.searchItemsSource = undefined;
                    if (response && response.data) {
                         var dataGugik = that.getDataGugik(response.data);
                         var html = that.convertDataTableToHtml(
                              dataGugik,
                              'getParcelGugik',
                              queryString
                         );
                         that.appendFoundedList(
                              html,
                              'getParcelGugik'
                         );
                         that.activateSuggestionsList();
                         that.animationGugikOff();
                    }
               }).catch(function (thrown) {
                    if (axios.isCancel(thrown)) {
                         console.log('Request canceled', thrown.message);
                    }
               });
          }
     }


     getDataGugik(string) {
          var data = string.substr(2).split(`
        SRID=4326;`);
          $.each(data, function (i, elem) {
               data[i] = elem.substr(elem.indexOf('POLYGON'));
          });
          return data;
     }

     parseUrl(url) {
          return url
               .replace(/%osmid%/g, this.osmID)
               .replace(/%nazwa%/g, this.queryString);
     }

     appendFoundedList(listHtml, request) {
          this.$foundList = $(
               $.grep(this.$foundList, (x) =>
                    $(x).hasClass('search__input-found-list-' + request)
               )[0]
          );
          this.$foundList.html(listHtml);
     }

     activateSuggestionsList() {
          this.$foundList.removeClass('list-inactive').addClass('list-active');
     }

     disactivateSuggestionsList() {
          this.$foundList.removeClass('list-active').addClass('list-inactive');
     }

     animationOn() {
          this.$loadingAnim.css({
               opacity: 1,
          });
     }

     animationGugikOn() {
          this.$loadingAnimGugik.css({
               opacity: 1,
          });
     }

     animationOff() {
          this.$loadingAnim.css({
               opacity: 0,
          });
     }

     animationGugikOff() {
          this.$loadingAnimGugik.css({
               opacity: 0,
          });
     }

     convertDataTableToHtml(dataTable, searchType, queryString) {
          var htmlString = '',
               html;

          if (searchType == 'getAddress' && dataTable.length == 1) {
               if (dataTable[0].display_name == ' ==nie znaleziono==') {
                    dataTable = []
               }
          }

          if (dataTable.length != 0) {
               var length = dataTable.length;
               for (var i = 0; i < length; i++) {
                    var name =
                         typeof dataTable[i].display_name == 'undefined' ||
                              undefined
                              ? dataTable[i].name
                              : dataTable[i].display_name;
                    if (searchType == 'getParcelGugik') {
                         if (dataTable[i].indexOf('POLYGON') > -1 && (dataTable[i].match(/SRID/g) || []).length <= 1) {
                              var latlng = this.getGeomFromStr(dataTable[i])[0];
                              html =
                                   '<li class="sugg sugg-parcelGugik"><span data-index="' +
                                   i +
                                   `" data-lat="` +
                                   latlng[1] +
                                   `" data-lon="` +
                                   latlng[0] +
                                   `" data-geom="` +
                                   dataTable[i] +
                                   '">' +
                                   queryString +
                                   '</span></li>';
                              htmlString += html;
                         } else {
                              htmlString =
                                   '<li class="cant-be-active"><span>Nie znaleziono pasujących wyników</span></li>';
                         }
                    }
               }
          } else {
               htmlString =
                    '<li class="cant-be-active"><span>Nie znaleziono pasujących wyników</span></li>';
          }
          return htmlString;
     }

     getLatlngFromSugg($this) {
          var lat = $this.children('span').attr('data-lat'),
               lon = $this.children('span').attr('data-lon');
          return [lat, lon];
     }

     getGeomFromSugg($this) {
          var geomStr = $this.children('span').attr('data-geom'),
               geomArr = this.getGeomFromStr(geomStr);
          var wkt = new Wkt.Wkt();
          wkt.read(geomStr);
          geomArr = wkt.toJson();
          return geomArr;
     }

     getGeomFromStr(geomStr) {
          var geomArr = geomStr.substring(9, geomStr.length - 2).split(',');
          var geomArrNew = [];
          $.each(geomArr, function (i, v) {
               geomArrNew[i] = v.split(' ');
               $.each(geomArrNew, function (j, w) {
                    if (
                         geomArrNew[j][1] &&
                         geomArrNew[j][1].indexOf(')') > -1
                    ) {
                         geomArrNew[j][1] = geomArrNew[j][1].slice(0, -1);
                    }
               });
          });
          return geomArrNew;
     }

     checkLastItem(geom) {
          if (geom[0][0] == geom[geom.length - 1][0] && geom[0][1] == geom[geom.length - 1][1]) {
               geom.pop();
          }
          return geom;
     }

     fixGeom(geom) {
          this.checkLastItem(geom);
          var geomCopy = [];
          $.each(geom, function (i) {
               geomCopy[i] = [];
               geomCopy[i][0] = geom[i][1];
               geomCopy[i][1] = geom[i][0];
          });
          return geomCopy;
     }


     showGeomOnMapGugik(geom, latlng) {
          //geom = this.fixGeom(geom.coordinates[0]);
          // /var latlng = geom[0];
          var latlngArr;
          if ($.isArray(latlng)) {
               latlngArr = latlng;
          } else {
               latlngArr = [latlng.lat.toString(), latlng.lng.toString()];
          }
          var that = this,
               zoom = 18,
               id = 'hideGeom' + latlngArr[0].toString().replace('.', '') + latlngArr[1].toString().replace('.', '');

          // this.map.setView(latlng, zoom, {});

          // this.map.fitBounds(geom.getBounds());

          var html = '';
          var myStyle = {
               color: '#0080D4',
               weight: 5,
               opacity: 0.65,
          };
          var geojsonfeature = geom
          geojsonfeature.properties = {
               style: myStyle,
          };
          var polygon = L.geoJSON(geojsonfeature).addTo(this.map)
               .bindPopup(
                    html +
                    `<hr><button id="` +
                    id +
                    `">usuń zaznaczenie </button>`
               ).addTo(this.map);

          polygon.bringToFront();
          this.map.fitBounds(polygon.getBounds());
          polygon.openPopup().closePopup();

          var that = this;
          $(document).on('click', polygon, function () {
               var promises = that.getInfo('gugik');

               Promise.all(promises)
                    .then((responses) => {
                         var okResponses = responses.filter((response) => {
                              if (response.status == 200) {
                                   return response;
                              }
                         });
                         return Promise.all(
                              okResponses.map((res) => res.text())
                         );
                    })
                    .then((texts) => {
                         // that.getInfoDone(texts);
                         var html = $.trim(that.getDataHtml(texts));
                         polygon.bindPopup(
                              html +
                              `<hr><button id="` +
                              id +
                              `">usuń zaznaczenie </button>`
                         );
                    });

               $(document).on('click', '#' + id, function () {
                    that.map.removeLayer(polygon);
               });
          });

          $(document).on(
               'click',
               '.leaflet-popup-content-wrapper, .leaflet-popup-content',
               function (e) {
                    e.stopPropagation();
                    //     e.preventDefault()
                    //     return false;
               }
          );
     }

     closeSuggs(event) {
          var $clicked = $(event.target);
          if (!$clicked.hasClass('found-list')) {
               if (this.$foundList.hasClass('list-active')) {
                    this.$foundList
                         .removeClass('list-active')
                         .addClass('list-inactive');
                    this.removeChildrenIfExist();
               }
          }
     }

     enterPress(e) {
          var that = this._this,
               geom,
               selectedIndex = that.$foundList
                    .children('li.sugg-active')
                    .index(),
               $selected = $(e.target)
                    .siblings('ul')
                    .children('li')
                    .eq(selectedIndex);

          if (selectedIndex != -1) {
               var lat = that.$foundList
                    .children('li.sugg-active')
                    .children('span')
                    .attr('data-lat'),
                    lon = that.$foundList
                         .children('li.sugg-active')
                         .children('span')
                         .attr('data-lon'),
                    //        latlng = L.latLng(parseFloat(lat), parseFloat(lon));
                    latlng = [lat, lon];

               var $newActive = $(e.target).closest('li');
               var text = $newActive.children('span').text();


               //**********getinfo
               that.clickParams = {
                    latlng: latlng,
                    zoom: that.map.getZoom(),
                    //        xy: that.map.latLngToContainerPoint(latlng),
               };
               //**********getinfo end
               if (
                    $selected.hasClass('sugg-parcel') ||
                    $selected.hasClass('sugg-parcelGugik')
               ) {
                    geom = that.getGeomFromSugg($selected);
               }
               if (geom.length) {
                    that.showGeomOnMapGugik(geom);
               }
               that.closeSuggs(e);
          } else {
               var $newActive = that.$foundList.children('li').eq(0);
               $newActive.addClass('sugg-active');
               var text = $newActive.children('span').text();

               that.$field.val(text);
               that.setVisible($newActive, false);
               if ($newActive) {
                    latlng = that.getLatlngFromSugg($newActive);
                    if ($selected.hasClass('sugg-parcel')) {
                         geom = that.getGeomFromSugg($selected);
                    }
               }
          }
     }

     arrowUp() {
          var that = this._this;
          if (that.$field.is(':focus')) {
               var downArrowPressBool = false,
                    selectedIndex = that.$foundList
                         .children('li.sugg-active')
                         .index();
               if (selectedIndex != 0) {
                    that.$foundList
                         .children('li')
                         .eq(selectedIndex)
                         .removeClass('sugg-active');
                    var $newActive = that.$foundList
                         .children('li')
                         .eq(selectedIndex - 1);
                    $newActive.addClass('sugg-active');
                    var text = $newActive.children('span').text();
                    that.$field.val(text);
                    that.setVisible($newActive, downArrowPressBool);
               }
          }
     }

     arrowDown() {
          var that = this._this,
               $newActive,
               text;
          if (that.$field.is(':focus')) {
               var downArrowPressBool = true,
                    selectedIndex = that.$foundList
                         .children('li.sugg-active')
                         .index();
               if (selectedIndex == -1) {
                    if (
                         !that.$foundList
                              .children('li')
                              .eq(0)
                              .hasClass('cant-be-active')
                    ) {
                         that.$foundList
                              .children('li')
                              .eq(selectedIndex)
                              .removeClass('sugg-active');
                         $newActive = that.$foundList
                              .children('li')
                              .eq(selectedIndex + 1);
                         $newActive.addClass('sugg-active');
                         text = $newActive.children('span').text();
                         that.$field.val(text);
                         that.setVisible($newActive, downArrowPressBool);
                    }
               } else {
                    if (
                         !that.$foundList
                              .children('li')
                              .eq(selectedIndex + 1)
                              .hasClass('cant-be-active')
                    ) {
                         that.$foundList
                              .children('li')
                              .eq(selectedIndex)
                              .removeClass('sugg-active');
                         $newActive = that.$foundList
                              .children('li')
                              .eq(selectedIndex + 1);
                         $newActive.addClass('sugg-active');
                         text = $newActive.children('span').text();
                         that.$field.val(text);
                         that.setVisible($newActive, downArrowPressBool);
                    } else {
                         that.$foundList
                              .children('li')
                              .eq(selectedIndex)
                              .removeClass('sugg-active');
                         $newActive = that.$foundList.children('li').eq(0);
                         $newActive.addClass('sugg-active');
                         text = $newActive.children('span').text();
                         that.$field.val(text);
                         that.actions.setVisible(
                              $newActive,
                              downArrowPressBool
                         );
                    }
               }
          }
     }

     setVisible($activeListElem, downArrowPressBool) {
          var that = this;
          try {
               var elemPositionTop = $activeListElem.position().to,
                    elemHeight = $activeListElem.height(),
                    scrollTop = that.$foundList.scrollTop(),
                    containerHeight = that.$foundList.height();
               var diff = containerHeight - elemPositionTop;
               if (downArrowPressBool === true) {
                    if (diff < elemHeight) {
                         that.$foundList.scrollTop(scrollTop + 2 * elemHeight);
                    }
               } else {
                    if (diff > containerHeight) {
                         that.$foundList.scrollTop(scrollTop - 2 * elemHeight);
                    }
               }
          } catch (e) {
               that.$foundList.scrollTop(0);
          }
     }

     //****************************************************************
     getInfo(searcher) {
          this.activeLayersIds = this.activeLayersManager.getActiveLayersIds();
          let activeLayersIds = this.activeLayersIds,
               promises = [],
               layersUrls = [];

          var layerIDs = activeLayersIds;
          if (searcher) {
               if (searcher == 'gugik') {
                    var layerID = this.getLayerIDsByUrl(this.layerListKeys);
                    if (layerID) {
                         layerIDs.push(layerID[0]);
                    }
               } else {
                    var layerID = this.getLayerIDs(
                         this.layerListKeys,
                         searcher
                    );
                    if (layerID) {
                         layerIDs.push(layerID[0]);
                    }
               }
          }

          layerIDs.forEach((activeLayerId) => {
               if (activeLayerId) {
                    //    activeLayersIds.forEach(activeLayerId => {
                    let layer = this.layerListKeys[activeLayerId];
                    // tylko w wersji dev
                    // layer.proxy = false;

                    if (
                         layer.queryable &&
                         !layersUrls.includes(layer.queryUrl)
                    ) {
                         var request = layer.queryUrl
                              ? this.queryRequest(layer)
                              : this.wmsRequest(layer);
                         promises.push(request);
                    }

                    /* if (layer.link) {
                         if (layer.queryable) {
                           let linkPromise = this.linkPromise(layer);
                           promises.push(linkPromise);
                         }
                       } else {
                         if (layer.queryable && !layersUrls.includes(layer.queryUrl)) {
                           let request = this.getRequest(layer);
                           promises.push(request);
                         }
                         if (layer.queryUrl && !layersUrls.includes(layer.queryUrl)) {
                           layersUrls.push(layer.queryUrl);
                         }
                       }*/
               }
          });

          return promises;
     }

     getLayerIDs(layerList, phrase) {
          var layerIDs = [];
          $.each(layerList, (x, y) => {
               if (y.name.includes(phrase)) {
                    layerIDs.push(y.id);
               }
          });
          return layerIDs;
     }

     getLayerIDsByUrl(layerList) {
          var layerIDs = [];
          $.each(layerList, (x, y) => {
               if (
                    y.url.includes(
                         'integracja.gugik.gov.pl/cgi-bin/KrajowaIntegracjaEwidencjiGruntow'
                    )
               ) {
                    layerIDs.push(y.id);
               }
          });
          return layerIDs;
     }

     parseUrlInfo(url) {
          let lat = this.clickParams.latlng[0];
          let lng = this.clickParams.latlng[1];
          url = url
               .replace(/%lat%/g, lat)
               .replace(/%lon%|%lng%/g, lng)
               .replace(/%zoom%/g, this.clickParams.zoom);
          url = url + '&app=sip&username=' + localStorage['username'];
          return url;
     }

     queryRequest(layer) {
          var url =
               window.PROXY + this.encodeUrl(this.parseUrlInfo(layer.queryUrl));
          // var url = this.parseUrlInfo(layer.queryUrl);
          return fetch(url);
     }

     wmsRequest(layer) {
          var that = this,
               point = this.map.latLngToContainerPoint(that.clickParams.latlng),
               // point = that.clickParams.xy,
               layers = layer.options.layers
                    ? layer.options.layers.split(',')
                    : [],
               source = L.WMS.source(layer.url, layer.options);
          source._map = this.map;
          var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(
               source,
               point,
               layers
          );
          wmsParams.username = localStorage['username']
               ? localStorage['username']
               : '';
          var url = layer.url + L.Util.getParamString(wmsParams, layer.url);
          if (layer.proxy) {
               url =
                    window.PROXY +
                    this.encodeUrl(this.removeGwcServiceFromUrl(url));
          } else {
               url = this.removeGwcServiceFromUrl(url);
          }
          return fetch(url, {
               dataType: 'json',
          });
     }

     getDataHtml(dataArr) {
          var html = '',
               htmls = [],
               dataHtml;
          dataArr.forEach((data) => {
               if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {
                    dataHtml = this.getBodyHtml(data);
               } else if (/<\/ServiceException/g.test(data)) {
                    dataHtml = '';
               } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                    dataHtml = '';
               } else if ($.trim(data) == '[]' || data == []) {
                    dataHtml = '';
               } else {
                    dataHtml = this.parseJsonToHtml(data);
               }
               var check = 0;
               $.each(htmls, function (i, v) {
                    if (dataHtml == v) {
                         check++;
                    }
               });
               if (check == 0) {
                    if (dataHtml != '' && dataHtml.length > 1) {
                         htmls.push(dataHtml);
                    }
               }
          });
          html = htmls.join('<hr>');
          return html;
     }

     getBodyHtml(html) {
          var match = html.match(/<body>[\s\S]*?<\/body>/g);
          if (match && match[0]) {
               html = match[0].replace('<body>', '').replace('</body>', '');
          } else {
               return '';
          }
          return /\S/g.test(html) ? html : '';
     }

     parseJsonToHtml(data) {
          var html = '', that = this;
          try {
               var json = JSON.parse(data);
               if (json.popup) {
                    html = json.popup;
               } else {
                    if (json.length > 1) {
                         $.each(json, function (i, v) {
                              var json2 = JSON.parse(v);
                              html += '<br/>' + that.createParcelPopup(json2);
                         })
                    } else {
                         var json2 = JSON.parse(json);
                         html = this.createParcelPopup(json2);
                    }

               }
          } catch (err) {
               html = data;
          }
          return html;
     }

     createParcelPopup(json) {
          return (
               `<div><u><b>` +
               json.properties.layer +
               `</b></u><br><b>Numer:  </b><span>` +
               json.properties.numer +
               `</span><br><b>Obręb </b><span>` +
               json.properties.obreb +
               (json.properties.pow
                    ? `</span><br><b>Powierzchnia:  </b><span>` +
                    json.properties.pow +
                    `m<sup>2</sup>`
                    : ` `) +
               `</span><br><b>ID: </b><span>` +
               json.properties.label.substr(
                    0,
                    json.properties.label.indexOf('(')
               ) +
               `</span></div>`
          );
     }

     encodeUrl(url) {
          return encodeURIComponent(url);
     }

     removeGwcServiceFromUrl(url) {
          return url.replace('gwc/service/', '');
     }

     //****************************************************************
}
export default SearchGugik;
