import eService_searchAddress from './euslugi/Eservice_searchaddress';
import eService_searchParcels from './euslugi/Eservice_searchparcels';
import eService_searchCoords from './euslugi/Eservice_searchcoords';
import eService_parcelsInfo from './euslugi/Eservice_parcelsinfo';
import eService_addressInfo from './euslugi/Eservice_addressinfo';
import eService_terrainInfo from './euslugi/Eservice_terraininfo';
import $ from 'jquery';

class Eservices {
    constructor(config, settings, mapTools) {
        this.states = settings.states;
        this.greenCol = '#7DA83F';
        this.blueCol = '#0080D4';

        if (config.EAdres || config.EDzialka || config.ELatLng || config.EAdresInfo || config.EDzialkaInfo || config.ETerenInfo) {
            if ($(window).width() > 768) {
                $('.eservicePanel').css('display', 'block')
            } else {
                $('.eservicePanel-show').css('display', 'flex')
            }
            this.lp = 1;
            var that = this;

            if (config.EAdres) {
                this.eService_searchAddress = new eService_searchAddress(config, {
                    map: settings.map,
                    toolsClass: mapTools.toolsClass,
                    states: settings.states,
                    activeLayersManager: settings.activeLayersManager,
                }, that.lp);
                that.lp++;
            }

            if (config.EDzialka) {
                this.eService_searchParcels = new eService_searchParcels(config, {
                    map: settings.map,
                    toolsClass: mapTools.toolsClass,
                    states: settings.states,
                    activeLayersManager: settings.activeLayersManager,
                }, that.lp);
                that.lp++;
            }

            if (config.ELatLng) {
                this.eService_searchCoords = new eService_searchCoords(config, {
                    map: settings.map,
                    toolsClass: mapTools.toolsClass,
                    states: settings.states,
                    activeLayersManager: settings.activeLayersManager,
                }, that.lp);
                that.lp++;
            }

            if (config.EDzialkaInfo) {
                this.eService_parcelsInfo = new eService_parcelsInfo(config, {
                    map: settings.map,
                    toolsClass: mapTools.toolsClass,
                    states: settings.states,
                    activeLayersManager: settings.activeLayersManager,
                }, that.lp);
                that.lp++;
            }

            if (config.EAdresInfo) {
                this.eService_addressInfo = new eService_addressInfo(config, {
                    map: settings.map,
                    toolsClass: mapTools.toolsClass,
                    states: settings.states,
                    activeLayersManager: settings.activeLayersManager,
                }, that.lp);
                that.lp++;
            }

            if (config.ETerenInfo) {
                this.eService_terrainInfo = new eService_terrainInfo(config, {
                    map: settings.map,
                    toolsClass: mapTools.toolsClass,
                    states: settings.states,
                    activeLayersManager: settings.activeLayersManager,
                }, that.lp);
                that.lp++;
            }

            if (this.lp <= 2) {
                $('.eservicePanel').css({
                    height: '140px',
                    'max-height': '140px',
                    'min-height': '140px'
                })
            } else if (this.lp <= 3) {
                $('.eservicePanel').css({
                    height: '190px',
                    'max-height': '180px',
                    'min-height': '180px'
                })
            }
            this.events();
        }
    }

    events() {
        var that = this;
        $('.eservicePanel__body--item').on('click tap', function() {
            if ($(window).width() < 768) {
                $('.eservicePanel').css({
                    'display': 'none',
                })
                $('.eservicePanel-show').removeClass('si-focused')
                $('.eservicePanel-show').css('background-color', that.blueCol)
            }
        })

        $('.modePanel__body--btn-cancel').on('click tap', function() {
            if ($(window).width() < 768) {
                $('.eservicePanel').css({
                    'top': '40px',
                })
            }
        })
    }

}

export default Eservices