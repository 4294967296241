import $ from 'jquery';

class Help {
    constructor(config) {
        this.help = config.MapConf.Pomoc;
        if (this.help) {
            $('#top-help').addClass('bar-top__tools--item-show')
        }
    }
}

export default Help;