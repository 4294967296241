import Tools from './Tools';
import $ from 'jquery';
const tools = new Tools();

class NavigateMap {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.states = settings.states;
        this.layerListKeys = config.layerListKeys;
        this.navigateMapBackProps = tools.createElPropertiesFromClass(this.toolsClass + '__navigateMapBack');
        this.navigateMapForwardProps = tools.createElPropertiesFromClass(this.toolsClass + '__navigateMapForward');

        this.history = [];
        this.historyPosition = 0;
        this.historyLen = 1;
        this.eventCounter = 1;
        $(this.navigateMapBackProps.selector).find('.tools__tool-icon').parent().addClass('tools__tool-unavailable')
        $(this.navigateMapForwardProps.selector).find('.tools__tool-icon').parent().addClass('tools__tool-unavailable')
        this.history.push([this.map.getCenter(), this.map.getZoom()])
        this.events();
    }

    events() {
        const that = this;

        that.map.on("moveend", function (s) {
            if (that.eventCounter) {
                that.updateHistory()
            } else {
                that.eventCounter = 1
            }
        });

        $(document).on('click tap', this.navigateMapBackProps.selector, function () {
            if (!$(this).find('.tools__tool-icon').parent().hasClass('tools__tool-unavailable')) {
                that.moveBack()
            }
        })

        $(document).on('click tap', this.navigateMapForwardProps.selector, function () {
            if (!$(this).find('.tools__tool-icon').parent().hasClass('tools__tool-unavailable')) {
                that.moveForward()
            }
        })
    }

    moveForward() {
        const that = this;
        if (that.historyPosition + 1 < that.historyLen) {
            that.historyPosition++;
            that.map.setView(that.history[that.historyPosition][0], that.history[that.historyPosition][1])
            that.eventCounter = 0
            that.updateBtns()
        }
    }

    moveBack() {
        const that = this;
        if (that.historyPosition > 0) {
            that.historyPosition--;
            that.map.setView(that.history[that.historyPosition][0], that.history[that.historyPosition][1])
            that.eventCounter = 0
            that.updateBtns()
        }
    }

    updateHistory() {
        const that = this;
        if (that.historyPosition < that.historyLen - 1) {
            that.historyPosition++
            that.history.splice(that.historyPosition, that.history.length - that.historyPosition - 1)

            that.history[that.historyPosition] = [that.map.getCenter(), that.map.getZoom()]
            that.historyLen = that.historyPosition + 1
        } else {
            that.historyPosition++;
            that.historyLen++;
            that.history[that.historyPosition] = [that.map.getCenter(), that.map.getZoom()]

        }
        that.updateBtns()
    }

    updateBtns() {
        const that = this;
        if (that.historyPosition == 0) {
            $(this.navigateMapBackProps.selector).find('.tools__tool-icon').parent().addClass('tools__tool-unavailable')
            if (that.historyLen > 1) {
                $(this.navigateMapForwardProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-unavailable')
            } else {
                $(this.navigateMapForwardProps.selector).find('.tools__tool-icon').parent().addClass('tools__tool-unavailable')
            }
        } else {
            $(this.navigateMapBackProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-unavailable')
            if (that.historyLen > that.historyPosition + 1) {
                $(this.navigateMapForwardProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-unavailable')
            } else {
                $(this.navigateMapForwardProps.selector).find('.tools__tool-icon').parent().addClass('tools__tool-unavailable')
            }
        }
    }

    clearHistory() {

    }

}

export default NavigateMap;