
const changeFileInputsModal = function() {

    const inputFile = document.getElementById('input-attachment');
    let fileNameField = document.querySelector('.input-files--attachment__label');

    inputFile.addEventListener('change', function(e){
        let uploadedFileName = e.target.files[0].name;
        fileNameField.innerHTML =  `<span class="input-files--attachment__btn" role="button">Wybierz plik</span>wybrano: ${uploadedFileName}  `;
    });

    }

    export default changeFileInputsModal;


