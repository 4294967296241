import Tools from './Tools';
import '../vendor/colorPicker';
import L from 'leaflet';
import $ from 'jquery';

var tools = new Tools();

class SelectObject {
    constructor(config, settings) {
        this.map = settings.map;
        this.toolsClass = settings.toolsClass;
        this.tools = config.MapConf.tools || {};
        this.states = settings.states;
        this.states.onObjectSelect = false;
        this.layerListKeys = config.layerListKeys;
        this.activeLayersManager = settings.activeLayersManager;
        this.selectObjectWindowClass = '.selectObjectPopup';
        this.selectObjectWindowCloseClass = this.selectObjectWindowClass + '-close';
        this.selectObjectProps = tools.createElPropertiesFromClass(this.toolsClass + '__selectObject');
        this.addedRadios = [];
        this.pickedColor = '#7DA83F';
        this.pickedLayerID = null;
        this.pickedLayer = null;
        this.panelEvents();
        this.events();
        this.updateRadios();
    }

    panelEvents() {
        var that = this;

        $(document).on('click tap', this.selectObjectProps.selector, function() {
            if ($('.selectObjectPopup').hasClass('overlay--active')) {
                $('.selectObjectPopup').removeClass('overlay--active');
                $('.selectObjectPopup__tooltip').detach()
                $('.selectObjectPopup__title').removeClass('selectObjectPopup__title--tooltipActive')
                that.states.onObjectSelect = false;
                that.states.onInfo = true;
            } else {
                that.updateRadios()
                $('.selectObjectPopup').addClass('overlay--active');
                that.states.onObjectSelect = true;
                that.states.onInfo = false;
            }
        });

        $(document).on('click tap', this.selectObjectWindowCloseClass, function() {
            $(that.selectObjectProps.selector).find('.tools__tool-icon').removeClass('tools__tool-icon-focused');
            $(that.selectObjectProps.selector).find('.tools__tool-icon').parent().removeClass('tools__tool-focused');
            $('.selectObjectPopup__tooltip').detach();
            $('.selectObjectPopup__title').removeClass('selectObjectPopup__title--tooltipActive')
            that.states.onObjectSelect = false;
            that.states.onInfo = true;

        });

        $(document).on('click tap', "body", function() {
            that.pickedColor = $('.selectObjectPopup__body--colorPicker-btn').css('background-color')
        })

        $(document).on('click tap', '.selectObjectPopup__title--info', function() {
            if ($('.selectObjectPopup__title').hasClass('selectObjectPopup__title--tooltipActive')) {
                $('.selectObjectPopup__tooltip').detach();
                $('.selectObjectPopup__title').removeClass('selectObjectPopup__title--tooltipActive');
            } else {
                $('.selectObjectPopup__title').append(`<div class="selectObjectPopup__tooltip"><i class="fas fa-info-circle fa-2x selectObjectPopup__tooltip--icon"></i><div class="selectObjectPopup__tooltip--txt">Lista warstw dotyczy tylko tych, które wyświetlają się na mapie.</div></div>`)
                $('.selectObjectPopup__title').addClass('selectObjectPopup__title--tooltipActive');
            }
        })

        $(document).on('click tap', '.leaflet-control-layers-selector', function() {
            that.updateRadios()
        })

        $(document).on('click', '.selectObjectItem__radiobutton', function(e) {
            that.pickedLayerID = $(e.target).closest('input').attr('id');
            that.pickedLayer = that.getLayerName(that.layerListKeys, that.pickedLayerID)
        })

    }

    events() {
        var that = this;

        this.map.on('click tap', function(e) {
            if (that.states.onObjectSelect) {
                that.clickParams = {
                    latlng: e.latlng,
                    zoom: that.map.getZoom(),
                    xy: e.containerPoint,
                };
                if (that.pickedLayer) {
                    var promises = that.getGeom();

                    Promise.all(promises).then(responses => {
                        var okResponses = responses.filter(response => {
                            if (response.status == 200) {
                                return response;
                            }
                        });
                        return Promise.all(okResponses.map(res => res.text()));
                    }).then(texts => {
                        that.getGeomDone(texts);
                    });
                }
            }
        });

        $('.selectObjectPopup').on('click tap', function() {
            if ($(window).width() > 768 || $(window).height() > 768) {
                $(this).draggable({
                    cursor: 'grab',
                    containment: 'body',
                    opacity: 0.8,
                });
            }
        })

    }

    getGeom() {
        var promises = [],
            layer = this.layerListKeys[this.pickedLayerID],
            // request = layer.queryUrl ? this.querySelectRequest(layer) : this.wmsRequest(layer);
            request = layer.SelectUrl_ ? this.querySelectRequest(layer) : this.wmsRequest(layer);
        promises.push(request);
        return promises;
    }

    getGeomDone(texts) {
        var geomType, geomCoords;
        if (JSON.parse(texts[0])[0]) {
            geomType = JSON.parse(JSON.parse(texts[0])[0]).geometry.type;
            geomCoords = JSON.parse(JSON.parse(texts[0])[0]).geometry.coordinates[0];
        } else {
            geomType = JSON.parse(JSON.parse(texts[0]).geom).type;
            geomCoords = JSON.parse(JSON.parse(texts[0]).geom).coordinates;
        }
        this.drawObject(geomCoords, geomType, "")
    }

    checkLastItem(geom) {
        if (geom[0][0] == geom[geom.length - 1][0] && geom[0][1] == geom[geom.length - 1][1]) {
            geom.pop();
        }
        return geom;
    }

    fixGeom(geom) {
        this.checkLastItem(geom)
        var geomCopy = [];
        $.each(geom, function(i) {
            geomCopy[i] = []
            geomCopy[i][0] = geom[i][1]
            geomCopy[i][1] = geom[i][0]
        })
        return geomCopy;
    }

    drawObject(geom, geomType, html) {
        if (geomType == 'Polygon') {
            this.drawPolygon(geom, html)
        } else if (geomType == 'LineString') {
            this.drawPolyline(geom, html)
        } else if (geomType == 'Point') {
            this.drawPoint(geom, html)
        }
    }

    drawPolygon(geom) {
        geom = this.fixGeom(geom);
        var that = this,
            id = geom[0][0].toString().replace('.', '');
        //**********getinfo
        var promises = that.getInfo();

        Promise.all(promises).then(responses => {
            var okResponses = responses.filter(response => {
                if (response.status == 200) {
                    return response;
                }
            });
            return Promise.all(okResponses.map(res => res.text()));
        }).then(texts => {
            var html = $.trim(this.getDataHtml(texts));
            //**********getinfo end
            var polygon = L.polygon(geom, {
                id: 'pol' + id,
                color: that.pickedColor
            }).bindPopup(html + `<hr><button id="` + id + `">usuń zaznaczenie </button>`).addTo(this.map)
            polygon.bringToFront()
            polygon.openPopup().closePopup()

            $(document).on('click', polygon, function() {
                polygon.bindPopup(html + `<hr><button id="` + id + `">usuń zaznaczenie </button>`)

                $(document).on('click', '#' + id, function() {
                    that.map.removeLayer(polygon);
                })
            })
        })
    }

    drawPolyline(geom) {
        geom = this.fixGeom(geom);
        var that = this,
            id = geom[0][0].toString().replace('.', ''),

            //**********getinfo
            promises = that.getInfo();
        Promise.all(promises).then(responses => {
            var okResponses = responses.filter(response => {
                if (response.status == 200) {
                    return response;
                }
            });
            return Promise.all(okResponses.map(res => res.text()));
        }).then(texts => {
            var html = $.trim(this.getDataHtml(texts)),
                //**********getinfo end
                polyline = L.polyline(geom, {
                    color: that.pickedColor,
                    weight: 10
                }).bindPopup(html + `<hr><button id="` + id + `">usuń zaznaczenie </button>`).addTo(this.map)
            polyline.bringToFront()
            polyline.openPopup().closePopup()

            $(document).on('click', polyline, function() {
                polyline.bindPopup(html + `<hr><button id="` + id + `">usuń zaznaczenie </button>`)

                $(document).on('click', '#' + id, function() {
                    that.map.removeLayer(polyline);
                })
            })
        })
    }

    drawPoint(geom) {
        // var geom = geom.substring(6, geom.length - 1).split(' '),
        var geomNew = [geom[1], geom[0]],
            that = this,
            id = geomNew[0].toString().replace('.', ''),

            //**********getinfo
            promises = that.getInfo();

        Promise.all(promises).then(responses => {
            var okResponses = responses.filter(response => {
                if (response.status == 200) {
                    return response;
                }
            });
            return Promise.all(okResponses.map(res => res.text()));
        }).then(texts => {
            var html = $.trim(this.getDataHtml(texts));
            //**********getinfo end
            var awesomeIcon = L.divIcon({
                html: '<i class="fas fa-map-marker-alt fa-4x" style="color:' + that.pickedColor + '"></i>',
                iconSize: [36, 48],
                iconAnchor: [0, 0],
                className: 'selectObject-divIcon',
            });

            var point = L.marker(geomNew, {
                icon: awesomeIcon
            }).bindPopup(html + `<hr><button id="` + id + `">usuń zaznaczenie </button>`).addTo(this.map)
            point.openPopup().closePopup()
            $(document).on('click', point, function() {
                point.bindPopup(html + `<hr><button id="` + id + `">usuń zaznaczenie </button>`)
                $(document).on('click', '#' + id, function() {
                    that.map.removeLayer(point);
                })
            })
        })
    }

    querySelectRequest(layer) {
        // var url = this.parseUrlInfo(layer.selectUrl);
        var url = window.PROXY + this.encodeUrl(this.parseUrlInfo(layer.SelectUrl_));
        // var url = window.PROXY + this.encodeUrl(this.parseUrlInfo(layer.queryUrl));
        return fetch(url);
    }

    queryRequest(layer) {
        var url = window.PROXY + this.encodeUrl(this.parseUrlInfo(layer.queryUrl));
        return fetch(url);
    }

    parseUrlInfo(url) {
        let lat = this.clickParams.latlng.lat,
            lng = this.clickParams.latlng.lng;
        url = url.replace(/%lat%/g, lat)
            .replace(/%lon%|%lng%/g, lng)
            .replace(/%zoom%/g, this.clickParams.zoom);
        return url;
    }

    encodeUrl(url) {
        return encodeURIComponent(url);
    }

    wmsRequest(layer) {
        var point = this.clickParams.xy,
            layers = layer.options.layers.split(','),
            source = L.WMS.source(layer.url, layer.options);
        source._map = this.map;
        var wmsParams = L.WMS.Source.prototype.getFeatureInfoParams.call(source, point, layers),
            url = layer.url + L.Util.getParamString(wmsParams, layer.url);
        url = window.PROXY + this.encodeUrl(this.removeGwcServiceFromUrl(url));
        return fetch(url, {
            dataType: 'json'
        });
    }

    getLayerName(layerList, id) {
        var layerName;
        $.each(layerList, (x, layer) => {
            if (layer.id == id) {
                layerName = layer.name;
            }
        })
        return layerName || "";
    }

    hasSelectUrl(id) {
        var layerList = this.layerListKeys,
            ret = false;
        $.each(layerList, (x, layer) => {
            if (layer.id == id) {
                ret = layer.SelectUrl_ ? true : false;
                // ret = layer.queryUrl ? true : false;
            }
        })
        return ret;
    }

    updateRadios() {
        var that = this,
            active = this.activeLayersManager.getActiveLayersIds();
        $.each(active, function(i, id) {
            if (!that.addedRadios.includes(id) && that.hasSelectUrl(id)) {
                that.addRadioDiv(id)
                that.addedRadios.push(id)
            }
        })
        $.each(that.addedRadios, function(i, radioId) {
            if (radioId && !active.includes(radioId)) {
                that.deleteRadio(radioId)
            }
        })
        if (that.addedRadios.length < 1) {
            $('#selectObject-empty').addClass('selectObject-empty-active')
        } else {
            $('#selectObject-empty').removeClass('selectObject-empty-active')
        }
    }

    deleteRadio(id) {
        $("[data-idItem=" + id + "]").detach()
        this.addedRadios.splice(this.addedRadios.indexOf(id), 1);
    }

    addRadioDiv(id) {
        var name = this.getLayerName(this.layerListKeys, id),
            div = ` <div class="selectObjectItem` + (name.length >= 25 ? (name.length >= 45 ? (name.length >= 55 ? (name.length >= 85 ? (name.length >= 105 ? (name.length >= 125 ? ` selectObjectItem-xxxlg` : ` selectObjectItem-xxlg`) : ` selectObjectItem-xlg`) : ` selectObjectItem-lg`) : ` selectObjectItem-hd`) : ` selectObjectItem-md`) : ``) + `" data-idItem="` + id + `">
<label for="` + id + `" class="selectObjectItem__name"><div class="selectObjectItem__radiobutton" data-soLayerID="` + id + `"><input type="radio"  id="` + id + `" name="selectObjectRadios"><div class="selectObjectItem__radiobutton-primary"></div><span>` + name + `</span></div></label></div> `;
        $('.selectObjectPopup__body--layers').append(div)
    }

    //************************************Get info 

    getInfo() {
        let promises = [],
            layersUrls = [];
        var layerID = this.pickedLayerID;
        let layer = this.layerListKeys[layerID];
        if (layer.queryable && !layersUrls.includes(layer.queryUrl)) {
            var request = layer.queryUrl ? this.queryRequest(layer) : this.wmsRequest(layer);
            promises.push(request);
        }
        return promises;
    }

    getDataHtml(dataArr) {
        var html = '',
            htmls = [],
            dataHtml;
        dataArr.forEach((data) => {
            // $.each(dataArr1, (key, data) => {
            // if (/<html.*>[\s\S]*?<\/html>/g.test(data)) {} else 
            if (/<\/ServiceException/g.test(data)) {
                dataHtml = '';
            } else if (/ -\*- coding: UTF8 -\*-/g.test(data)) {
                dataHtml = '';
            } else {
                var dataArr1 = JSON.parse(dataArr[0]);
                dataHtml = this.parseJsonToHtml(dataArr1);
            }
            var check = 0;
            $.each(htmls, function(i, v) {
                if (dataHtml == v) {
                    check++;
                }
            })
            if (check == 0) {
                if (dataHtml != '' && dataHtml.length > 1) {
                    htmls.push(dataHtml);
                }
            }
        });
        html = htmls.join('<hr>');
        return html;
    }

    parseJsonToHtml(data) {
        var html = '';
        if (data.popup) {
            html = data.popup;
        } else {
            var json = JSON.parse(data)
            html = this.createParcelPopup(json)
        }
        return html;
    }

    createParcelPopup(json) {
        return `<div><u><b>` + json.properties.layer + `</b></u><br><b>Numer:  </b><span>` + json.properties.numer + `</span><br><b>Obręb </b><span>` + json.properties.obreb + (json.properties.pow ? `</span><br><b>Powierzchnia:  </b><span>` + json.properties.pow + `m<sup>2</sup>` : ` `) + `</span><br><b>ID: </b><span>` + json.properties.label.substr(0, json.properties.label.indexOf('(')) + `</span></div>`
    }

}

export default SelectObject;