import CoordinateSystem from "./CoordinateSystem";
import $ from "jquery";
//!CoordsSearch (data preparation) - parameters needed to transformation between systems
import { transform } from "./CoordinateSystem"; //! -  object needed for transformation between systems
import { coordsChangeSearchActive } from "./CoordsSearch"; //! -  function changing the params when window of modal is open
export const coordsParam = {
    name: "name", //! name = this.systems[n].name
    id: "id", //! id = this.systems[n].coordinateId === CoordinateSystems.js -> transform.key
    param: "param", //! param = CoordinateSystems.js -> transform.key.value
};
//!-----------------------------
class Coords {
    constructor(config, settings) {
        this.map = settings.map;
        this.tools = config.MapConf.tools || {};
        this.coordsContainerClass = ".bar-bottom__coords";
        
        this.strefa = config.strefaUkladuWspolrzednych2000

        this.defaultSystem = {
            name: "WGS84",
            displayName: "WGS84",
            coordinateId: "wgs84", //!  !CoordsSearch (data preparation) - coordinateId === CoordinateSystems.js -> transform.key
            EPSG: "4326",
            decimals: 7,
        };

        if (this.strefa) {
            let epsg;
            if (this.strefa == '5') {
                epsg = "2176"
            } else if (this.strefa == '6') {
                epsg = "2177"
            } else if (this.strefa == '7') {
                epsg = "2178"
            } else if (this.strefa == '8') {
                epsg = "2179"
            }

            this.systems2000 = [{
                name: "PUWG 2000 strefa " + this.strefa,
                displayName: "PUWG 2000 strefa " + this.strefa,
                coordinateId: "puwg2000_" + this.strefa,
                EPSG: epsg,
                decimals: 2,
            }]

        } else {
            this.systems2000 = [{
                name: "PUWG 2000 strefa 5",
                displayName: "PUWG 2000 strefa 5",
                coordinateId: "puwg2000_5",
                EPSG: "2176",
                decimals: 2,
            },
            {
                name: "PUWG 2000 strefa 6",
                displayName: "PUWG 2000 strefa 6",
                coordinateId: "puwg2000_6",
                EPSG: "2177",
                decimals: 2,
            },
            {
                name: "PUWG 2000 strefa 7",
                displayName: "PUWG 2000 strefa 7",
                coordinateId: "puwg2000_7",
                EPSG: "2178",
                decimals: 2,
            },
            {
                name: "PUWG 2000 strefa 8",
                displayName: "PUWG 2000 strefa 8",
                coordinateId: "puwg2000_8",
                EPSG: "2179",
                decimals: 2,
            },]
        }


        this.systems = this.systems2000.concat([
            {
                name: "PUWG 1992",
                displayName: "PUWG 1992",
                coordinateId: "puwg92",
                EPSG: "2180",
                decimals: 3,
            },
            {
                name: "UTM",
                displayName: "UTM",
                coordinateId: "utm",
                EPSG: "32433",
                decimals: 2,
            },
            {
                name: "WKID",
                EPSG: "3857",
                displayName: "WKID",
                coordinateId: "wkid",
                decimals: 2,
            },
        ])

        this.defaultChoosenSystemName = config.MapConf.DomyslnyUkladWspolrzednych
        this.selected = this.defaultChoosenSystemName ? this.defaultChoosenSystemName : "PUWG 2000 strefa 7";

        this.coordsSys = new CoordinateSystem();
        if (config.MapConf.Coords) {
            this.addCoords();
            this.addCoordsTrToContainer(this.systems);
            this.setCss();
        }
    }

    events() {
        var that = this;

        this.map.on("mousemove", function (e) {
            that.showCoords(e);
        });

        $(document).on("change", "#coordsList", function (e) {
            that.selected = $(this).val();
            //! CoordsSearch (data preparation) - added coords param before click on param lists in Coords.js
            coordsParam.name = $(this).val();
            coordsParam.id = e.target.selectedOptions[0].dataset.sid;
            coordsParam.param =
                transform[e.target.selectedOptions[0].dataset.sid];

            coordsChangeSearchActive(that);
            //!
        });
    }

    addCoords() {
        $(this.coordsContainerClass).css("display", "flex");
        this.events();
    }

    showCoords(event) {
        this.showDefaultCoords(event);
        this.showSelectedCoords(event);
    }

    showDefaultCoords(event) {
        var that = this,
            coords,
            system = {
                name: that.defaultSystem.name,
                decimals: that.defaultSystem.decimals,
                id: that.coordsSys.getSystemId(that.defaultSystem.name),
                coords: that.coordsSys.getCoords(
                    that.coordsSys.getSystemId(that.defaultSystem.name),
                    event
                ),
            };
        if (system.decimals || system.decimals === 0) {
            coords = system.coords.map((coords) =>
                coords.toFixed(system.decimals)
            );
        } else {
            coords = system.coords;
        }
        $("#defaultCoords").html(coords.join(", "));
    }

    showSelectedCoords(event) {
        var that = this,
            dec = $.grep(that.systems, (x) => x.name == that.selected)[0]
                .decimals,
            displayName = $.grep(
                that.systems,
                (x) => x.name == that.selected
            )[0].displayName,
            system = {
                name: that.selected,
                displayName: displayName,
                decimals: dec,
                id: that.coordsSys.getSystemId(that.selected),
                coords: that.coordsSys.getCoords(
                    that.coordsSys.getSystemId(that.selected),
                    event
                ),
            },
            coords;
        if (system.decimals || system.decimals === 0) {
            coords = system.coords.map((coords) =>
                coords.toFixed(system.decimals)
            );
        } else {
            coords = system.coords;
        }
        $("#cSelectedName").text(system.displayName + ": ");
        $("#cSelectedCoords").html(coords.join(", "));
    }

    setCss() {
        var numberOfItems = 2,
            numsWidth100 = [1, 3, 2],
            numsWidth50 = [4, 5],
            numsWidth33 = [6, 7],
            numsWidth25 = [8, 9];
        if ($.inArray(numberOfItems, numsWidth100) > -1) {
            $(".bar-bottom__coords--table-item").css("width", "100%");
        } else if ($.inArray(numberOfItems, numsWidth50) > -1) {
            $(".bar-bottom__coords--table-item").css("width", "50%");
        } else if ($.inArray(numberOfItems, numsWidth33) > -1) {
            $(".bar-bottom__coords--table-item").css("width", "33%");
        } else if ($.inArray(numberOfItems, numsWidth25) > -1) {
            $(".bar-bottom__coords--table-item").css({
                width: "25%",
                "font-size": "1rem",
            });
        }
        if (numberOfItems % 2 === 1 && numberOfItems !== 1) {
            $(".bar-bottom__coords--table-item").css({
                "font-size": "1rem",
            });
        }
    }

    addDefaultCoordsTrToContainer() {
        var tr =
            `<tr class="bar-bottom__coords--table-item bar-bottom__coords--` +
            this.defaultSystem.id +
            `" id="` +
            this.defaultSystem.id +
            `">
<td><b>` +
            this.defaultSystem.name +
            `: ` +
            `</b></td>
<td id="defaultCoords"></td>
</tr>`;
        $(".bar-bottom__coords--table").append(tr);
    }

    addSingleCoordsTrToContainer(id, name) {
        var tr =
            `<tr class="bar-bottom__coords--table-item bar-bottom__coords--` +
            id +
            `" id="` +
            id +
            `">
<td><b id="cSelectedName">` +
            name +
            ` : </b></td>
<td id="cSelectedCoords"></td>
</tr>`;

        $(".bar-bottom__coords--table").append(tr);
    }

    addCoordsSystemsList() {
        let that = this;
        $("#coordsToSelect")
            .append(`<div class="bar-bottom__coords--select-list">
<select id="coordsList"></select></div>`);

        $.each(this.systems, function (i, system) {
            if (system.name === that.selected) {
                //! dodanie parametrów do obiektu coordsParam po wczytaniu strony
                coordsParam.name = system.name;
                coordsParam.id = system.coordinateId;
                coordsParam.param = transform[system.coordinateId];
                //!

                $("#coordsList").append(
                    '<option  selected value="' +
                    system.name +
                    '"data-sId="' +
                    system.coordinateId +
                    '">' +
                    system.name +
                    " (EPSG:" +
                    system.EPSG +
                    ")  </option>"
                );
            } else {
                $("#coordsList").append(
                    '<option value="' +
                    system.name +
                    '"data-sId="' +
                    system.coordinateId +
                    '">' +
                    system.name +
                    " (EPSG:" +
                    system.EPSG +
                    ") </option>"
                );
            }
        });
    }

    addCoordsTrToContainer(systems) {
        var that = this;
        that.addDefaultCoordsTrToContainer();
        that.addSingleCoordsTrToContainer("cSelected", "");
        that.addCoordsSystemsList(systems);
    }
}

export default Coords;
